import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Const from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from '../../lang/strings';
import './nft-intro.scss';

const moment = require('moment');


var ua = navigator.userAgent.toLowerCase();
var isSafari = false
if (ua.indexOf('safari') != -1 || ua.indexOf('iphone') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isSafari = false
  } else {
    isSafari = true
  }
}

class NftIntro extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
    };

    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  componentDidMount() {
  }


  componentWillUnmount() {
  }

  render() {
    return (
      <div id="nft" className="nft-main">
        <img className="nft-header-img" src="/assets/img/nft/img_bg_nft_header.png" srcSet="/assets/img/nft/img_bg_nft_header.png 1x" />
        <div className="nft-video">
          <div className="video-content">
            <iframe
              src="https://www.youtube.com/embed/s0FSVXZ-wSs">
            </iframe>
            <img className="video-bg" src="/assets/img/ic_video_container.png"
              srcSet="/assets/img/ic_video_container.png 1x" />
          </div>
        </div>

        <div className="content about" data-scrollview="true">
          <img className="waterfall-img" src="/assets/img/nft/img_galaxy_waterfall.png" srcSet="/assets/img/nft/img_galaxy_waterfall.png 1x" />
          <div className="about-content" data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title">{strings.nft_about_title}</div>
            <div className="content-desc" style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}} dangerouslySetInnerHTML={{ __html: strings.nft_about_desc }} />
          </div>
        </div>

        <div className='maxform'>
          <img className="maxform-img" src="/assets/img/nft/ic_maxform.png" srcSet="/assets/img/nft/ic_maxform.png 1x" />
          <div className="content-desc" style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}} dangerouslySetInnerHTML={{ __html: strings.maxform_desc }} />
        </div>

        <div className="content technique" data-scrollview="true">
          <div className="content-bg">
            <img className="top" src="/assets/img/img_bg_distribution.png"
              srcSet="/assets/img/img_bg_distribution.png 1x" />
          </div>
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title">{strings.shape_made_tech}</div>
            <div className="content-desc" style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}} dangerouslySetInnerHTML={{ __html: strings.shape_made_tech_desc }} />
            <div className='image-container'>
              <img className='tech-img' src="/assets/img/nft/img_self_made_1.png"
                srcSet="/assets/img/nft/img_self_made_1.png 1x" />
              <div className="step-indicator">{this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "►" : "▼"}</div>
              <img className='tech-img' src="/assets/img/nft/img_self_made_2.png"
                srcSet="/assets/img/nft/img_self_made_2.png 1x" />
              <div className="step-indicator">{this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "►" : "▼"}</div>
              <img className='tech-img' src="/assets/img/nft/img_self_made_3.png"
                srcSet="/assets/img/nft/img_self_made_3.png 1x" />
            </div>
          </div>
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title">{strings.shape_made_system}</div>
            <div className="content-desc" style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}} dangerouslySetInnerHTML={{ __html: strings.shape_made_system_desc }} />
            <div className='image-container'>
              <img className='tech-img' src="/assets/img/nft/img_self_made_4.png"
                srcSet="/assets/img/nft/img_self_made_4.png 1x" />
              {this.uiState.display_mode === Const.DISPLAY.DESKTOP &&
                <div style={{ marginLeft: 40, marginRight: 40 }}></div>
              }
              <div className='tech-img' style={{ position: "relative" }}>
                <img style={{ width: "100%" }} src="/assets/img/nft/img_self_made_5.png"
                  srcSet="/assets/img/nft/img_self_made_5.png 1x" />
                <video autoPlay loop /*controls*/ muted>
                  <source src="/assets/video/Dark slezinger_Final.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title">{strings.hero_nft_card}</div>
            <div className="content-desc" style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}} dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc }} />
            <div className='nft-hero-img'>
              <img style={{ width: "100%" }} src="/assets/img/nft/img_nft_card.png"
                srcSet="/assets/img/nft/img_nft_card.png 1x" />
              <video autoPlay loop /*controls*/ muted>
                <source src="/assets/video/BitcoinLenged_Hero NFT Card Bitlegend.mp4" type="video/mp4" />
              </video>
              {/* <iframe
                autoplay
                muted
                style={{ marginTop: true ? 0 : ((1452 / 1920) * window.innerWidth) / 10 }}
                src="/assets/video/BitcoinLenged_Hero NFT Card Bitlegend.mp4">
              </iframe> */}
            </div>
          </div>
        </div>

        <div className="bottom-video">
          <div className="video-content" style={{ width: this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "60%" : "75%" }}>
            <video controls muted>
              <source src="/assets/video/Method for NFT card.mp4" type="video/mp4" />
            </video>
            <img className="video-bg" src="/assets/img/ic_video_container.png"
              srcSet="/assets/img/ic_video_container.png 1x" />
          </div>
        </div>

        <img
          style={{ marginTop: 0 - ((1683 / 3007) * (window.innerWidth * (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? 0.6 : 0.75))) / 2 }}
          className="img-hero-card" src="/assets/img/nft/img_hero_card.png" srcSet="/assets/img/nft/img_hero_card.png 1x" />

        <div className="content media-partners" data-scrollview="true">
          <div className="content-title">{strings.media_partners}</div>
          <div className='content-list'>
            <img src="/assets/img/nft/partner_1.png"
              srcSet="/assets/img/nft/partner_1.png 1x" />
            <img src="/assets/img/nft/partner_2.png"
              srcSet="/assets/img/nft/partner_2.png 1x" />
            <img src="/assets/img/nft/partner_3.png"
              srcSet="/assets/img/nft/partner_3.png 1x" />
            <img src="/assets/img/nft/partner_4.png"
              srcSet="/assets/img/nft/partner_4.png 1x" />
            <img src="/assets/img/nft/partner_5.png"
              srcSet="/assets/img/nft/partner_5.png 1x" />
            <img src="/assets/img/nft/partner_6.png"
              srcSet="/assets/img/nft/partner_6.png 1x" />
            <img src="/assets/img/nft/partner_7.png"
              srcSet="/assets/img/nft/partner_7.png 1x" />
            <img src="/assets/img/nft/partner_8.png"
              srcSet="/assets/img/nft/partner_8.png 1x" />
            <img src="/assets/img/nft/partner_9.png"
              srcSet="/assets/img/nft/partner_9.png 1x" />
            <img src="/assets/img/nft/partner_10.png"
              srcSet="/assets/img/nft/partner_10.png 1x" />
            <img src="/assets/img/nft/partner_11.png"
              srcSet="/assets/img/nft/partner_11.png 1x" />
            <img src="/assets/img/nft/partner_12.png"
              srcSet="/assets/img/nft/partner_12.png 1x" />
            <img src="/assets/img/nft/partner_13.png"
              srcSet="/assets/img/nft/partner_13.png 1x" />
            <img src="/assets/img/nft/partner_14.png"
              srcSet="/assets/img/nft/partner_14.png 1x" />
            <img src="/assets/img/nft/partner_15.png"
              srcSet="/assets/img/nft/partner_15.png 1x" />
            <img src="/assets/img/nft/partner_16.png"
              srcSet="/assets/img/nft/partner_16.png 1x" />
            <img src="/assets/img/nft/partner_17.png"
              srcSet="/assets/img/nft/partner_17.png 1x" />
            <img src="/assets/img/nft/partner_18.png"
              srcSet="/assets/img/nft/partner_18.png 1x" />
            <img src="/assets/img/nft/partner_19.png"
              srcSet="/assets/img/nft/partner_19.png 1x" />
            <img src="/assets/img/nft/partner_20.png"
              srcSet="/assets/img/nft/partner_20.png 1x" />
            <img src="/assets/img/nft/partner_21.png"
              srcSet="/assets/img/nft/partner_21.png 1x" />
            <img src="/assets/img/nft/partner_22.png"
              srcSet="/assets/img/nft/partner_22.png 1x" />
            <img src="/assets/img/nft/partner_23.png"
              srcSet="/assets/img/nft/partner_23.png 1x" />
            <img src="/assets/img/nft/partner_24.png"
              srcSet="/assets/img/nft/partner_24.png 1x" />
            <img src="/assets/img/nft/partner_25.png"
              srcSet="/assets/img/nft/partner_25.png 1x" />
            <img src="/assets/img/nft/partner_26.png"
              srcSet="/assets/img/nft/partner_26.png 1x" />
            <img src="/assets/img/nft/partner_27.png"
              srcSet="/assets/img/nft/partner_27.png 1x" />
            <img src="/assets/img/nft/partner_28.png"
              srcSet="/assets/img/nft/partner_28.png 1x" />
            <img src="/assets/img/nft/partner_29.png"
              srcSet="/assets/img/nft/partner_29.png 1x" />
            <img src="/assets/img/nft/partner_30.png"
              srcSet="/assets/img/nft/partner_30.png 1x" />
            <img src="/assets/img/nft/partner_31.png"
              srcSet="/assets/img/nft/partner_31.png 1x" />
            <img src="/assets/img/nft/partner_32.png"
              srcSet="/assets/img/nft/partner_32.png 1x" />
            <img src="/assets/img/nft/partner_33.png"
              srcSet="/assets/img/nft/partner_33.png 1x" />
            <img src="/assets/img/nft/partner_34.png"
              srcSet="/assets/img/nft/partner_34.png 1x" />
            <img src="/assets/img/nft/partner_35.png"
              srcSet="/assets/img/nft/partner_35.png 1x" />
            <img src="/assets/img/nft/partner_36.png"
              srcSet="/assets/img/nft/partner_36.png 1x" />
            <img src="/assets/img/nft/partner_37.png"
              srcSet="/assets/img/nft/partner_37.png 1x" />
            <img src="/assets/img/nft/partner_38.png"
              srcSet="/assets/img/nft/partner_38.png 1x" />
            <img src="/assets/img/nft/partner_39.png"
              srcSet="/assets/img/nft/partner_39.png 1x" />
            <img src="/assets/img/nft/partner_40.png"
              srcSet="/assets/img/nft/partner_40.png 1x" />
            <img src="/assets/img/nft/partner_41.png"
              srcSet="/assets/img/nft/partner_41.png 1x" />
            <img src="/assets/img/nft/partner_42.png"
              srcSet="/assets/img/nft/partner_42.png 1x" />
            <img src="/assets/img/nft/partner_43.png"
              srcSet="/assets/img/nft/partner_43.png 1x" />
            <img src="/assets/img/nft/partner_44.png"
              srcSet="/assets/img/nft/partner_44.png 1x" />
            <img src="/assets/img/nft/partner_45.png"
              srcSet="/assets/img/nft/partner_45.png 1x" />
            <img src="/assets/img/nft/partner_46.png"
              srcSet="/assets/img/nft/partner_46.png 1x" />
            <img src="/assets/img/nft/partner_47.png"
              srcSet="/assets/img/nft/partner_47.png 1x" />
            <img src="/assets/img/nft/partner_48.png"
              srcSet="/assets/img/nft/partner_48.png 1x" />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(inject('rootStore')(observer(NftIntro)));
