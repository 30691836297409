import { AIRDRO_END_DAY } from "../config/const";
const moment = require("moment");

export default {
  app_name: "BitcoinLegend",
  app_name_short: "BCL",

  bcl: "BCL",
  days: "DAYS",
  hours: "HOURS",
  minutes: "MINUTES",
  seconds: "SECONDS",

  header_menu_list: [
    { code: "home", title: "Home" },
    {
      code: "nft",
      title: "Hero NFT Card",
      sub_header_menu_list: [
        { code: "/whitelist", title: "White List of NFT" },
        { code: "/card", title: "NFT Card" },
        { code: "/mint", title: "NFT Mint" },
        { code: "/gamefi", title: "Game-Fi Card" },
      ],
    },
    // { code: "about", title: "About" },
    { code: "airdrop", title: "Airdrop&Migration" },
    // { code: "levelup", title: "Level Up Program" },
    { code: "kyc", title: "KYC Certification" },
    // { code: "total_supply", title: "Total Supply & Distribution" },
    { code: "roadmap", title: "RoadMap" },
    // { code: "levelup", title: "LevelUp", }
  ],

  widely_known_desc:
    "BitcoinLegend được biết đến rộng rãi. Chạm vào thanh tìm kiếm bên dưới.",

  earn_project_title: "3 Earn Project",
  earn_project_desc: "Mine to Earn, Make to Earn, Play to Earn",

  airdrop_ending_title: "AIRDROP Ending",
  airdrop_remain_count: "Airdrop Remaining Count",
  airdrop_first_desc: "1st AIRDROP End<br/>10M BCL",
  airdrop_second_desc: "2nd/3rd/4rd AIRDROP End<br/>15M BCL",
  airdrop_third_desc: "5th AIRDROP Running<br/>5M BCL",
  airdrop: "AIRDROP",
  pre_sale: "Pre-Sale",

  ntf_avatar_metaverse_game: "NFT Avatar Metaverse Game",
  three_earn_strategy: "3 Earn Strategy",
  mining_to_earn_desc:
    "Khai thác & giảm một nửa<br/>Giới thiệu (+ 25%)<br/>Kinh tế Token<br/>Ứng dụng Android, iOS<br/>Gói đa ngôn ngữ<br/> Khóa & Bảo mật",
  make_to_earn_desc:
    "Bán & Mua thẻ NFT, Nghệ thuật, bằng sáng chế<br/>Hệ thống tự tạo cho người dùng<br/>→ BCL Studio<br/>Phiên đấu giá thị trường NFT",
  play_to_earn_desc:
    "Trò chơi chiến đấu / PVP 1: 1<br/>Giao dịch bất động sản ảo<br/>Quảng cáo / Phí vào cửa trong giao tranh<br/>Kênh quảng bá<br/>Mua sắm trực tuyến",
  increase_strategy_title:
    "Mua lại mã thông báo, Đốt cháy, Kinh doanh NFT → Chiến lược giá để tăng",
  increase_strategy_0:
    "ICO(600m bcl): Người dùng 50m → 10m, Buring 10b bcl (từng bước)",
  increase_strategy_1: "IEO(CoinMarketCap : CMC Level)",
  increase_strategy_2: "CoinMarketCap",
  increase_strategy_3:
    "Danh sách đầu tiên / ngày 15 tháng 3. 2022. / Danh sách đầu tiên",
  increase_strategy_4: "LBank",
  increase_strategy_5: "Danh sách thứ 2 / Tháng 6. 2022. /PancakeSwap",
  increase_strategy_6: "PancakeSwap",
  increase_strategy_7: "Danh sách thứ 3 / Tháng 9. 2022. /~Lv.20",
  increase_strategy_7_1: "MEXC",
  increase_strategy_8: "4th Listing / Quarter 4. 2022. /~Lv.20",
  increase_strategy_9: "5th Listing / 2023./~Lv.10",
  increase_strategy_10: "6th Listing / 2023./~Lv.5",

  about_title: "About BitcoinLegend",
  about_desc:
    "<p>BitcoinLegend (BCL) là tài sản tiền điện tử dựa trên ERC-20." +
    " Chúng tôi sẽ làm nền tảng thị trường NFT (thẻ NFT, tác phẩm nghệ thuật).<br/>" +
    "Ngoài ra, Chúng tôi sẽ ra mắt một trò chơi metaverse với thẻ Hero NFT lấy cảm hứng từ Avengers của Marvel.</p>" +
    "<p> Thế giới ngày nay đang trong cuộc cách mạng công nghiệp lần thứ tư và chắc chắn rằng công nghệ blockchain sẽ đóng một vai trò quan trọng trong đó. <br/>" +
    "Được tạo ra để hiện thực hóa lý tưởng của mọi người, BITCOIN LEGEND (BCL) nhằm mục đích trở thành một nền tảng xây dựng một hệ sinh thái ứng dụng phi tập trung sử dụng công nghệ blockchain, tiền điện tử và các hệ thống metaverse trong lĩnh vực cách mạng công nghiệp lần thứ tư. </p>" +
    "<p> Là một loại tiền điện tử dựa trên ERC-20, BITCOIN LEGEND (BCL) có nền tảng thị trường NFT sắp ra mắt và một trò chơi metaverse trong tương lai với thẻ NFT anh hùng lấy cảm hứng từ Marvel’s Avengers. <p />" +
    "<p> Có 3 chiến lược Kiếm tiền trong BITCOIN LEGEND cung cấp cho người dùng 3 cách để kiếm thêm mã thông báo. <p />" +
    "<p> Giải pháp đầu tiên là Mine to Earn, giải quyết các vấn đề của việc khai thác Bitcoin bằng cách thực hiện quy trình khai thác trên điện thoại thông minh <br/>" +
    "Khi BCL khai thác được niêm yết trên các sàn giao dịch tiền điện tử lớn, nó đóng vai trò như một tài sản kỹ thuật số cũng như một khoản đầu tư. <P />" +
    "<p> Cái thứ hai là Kiếm để kiếm, người dùng có thể tự tạo hình đại diện NFT, vật phẩm NFT, sau đó mua và bán chúng để kiếm lợi nhuận. <br/>" +
    "BITCOIN LEGEND sử dụng công nghệ AI để tạo các nhân vật webtoon tương tự như chính người dùng thực. <br/>" +
    "Người dùng cũng có thể sản xuất quần áo, đồ trang trí và các vật phẩm trò chơi khác. <br/>" +
    "Lịch sử anh hùng được thực hiện bởi Webtoon và Song (hoạt hình Rap + NFT của Webtoon + Rapper '). <br/>" +
    "Nó đã nhận được 300.000 lượt xem trong 10 ngày sau khi được tải lên YouTube. <P />" +
    "<p> Mạng thứ ba là Chơi để kiếm tiền, mạng BITCOIN LEGEND sẽ thực hiện các hoạt động kinh tế trên metaverse bằng cách sử dụng thẻ avatar NFT đã tạo trước đó, cũng như bất động sản ảo, người dùng có thể chơi trò chơi hoặc đầu tư vào bất động sản ảo trên metaverse và kiếm lợi nhuận từ những hoạt động này. <p /> ",
  smart_contract: "Hợp đồng thông minh (Mã thông báo cũ: ERC20)",
  smart_contract1: "Hợp đồng thông minh (Mã thông báo mới: BEP20)",
  smart_contract2: "Đốt cháy (ERC20)",
  smart_url: "URL : ",
  smart_amount: "Lượng đốt cháy : ",

  token_symbol: "Biểu tượng mã thông báo : ",
  contract_address: "Địa chỉ hợp đồng : ",
  decimals: "Số thập phân : ",
  total_supply: "Tổng cung : ",
  blockchain: "Blockchain : ",
  white_paper: "Giấy trắng",

  airdrop_title: "Airdrop",
  airdrop_quantity: "Số lượng airdrop : ",
  airdrop_rules: "Quy tắc airdrop",
  airdrop_rules_airdrop_desc:
    "Người dùng trong cộng đồng có thể tham gia airdrop bằng cách cài đặt Ứng dụng BitcoinLegend.<br/>" +
    "Việc khai thác phải được thực hiện ít nhất cho đến khi kết thúc airdrop. Lượng airdrop ban đầu là 25 BCL.<br/>" +
    "Airdrop hoàn toàn miễn phí và phần thưởng airdrop sẽ được phân phối cho ứng dụng khai thác sau khi airdrop kết thúc.",
  invitation_rules: "Nội quy lời mời",
  invitation_rules_desc:
    "Người dùng có thể nhận thêm phần thưởng mã thông báo BCL bằng cách mời bạn bè tham gia AirDrop. <br /> Bạn có thể nhận được 50 mã thông báo cho mỗi người bạn mà bạn mời. Bạn có thể mời tối đa 50 người bạn và nhận được tới 2.500 mã thông báo BCL. <br /> Số lượng cho AirDrop bị hạn chế và việc phát hành BCL là nhỏ.",
  invite_team: "Mời nhóm",
  invite_team_desc:
    "+ 25 bcl/người dùng. Tối đa. 50 người dùng <br/>+@25% Số lượng khai thác của người dùng được mời",

  recommend_title: "Cách giới thiệu bạn bè của bạn",
  recommend_code_right: "Mã đề xuất của bạn ổn chứ?",
  recommend_input_code: "Nhập mã đề xuất",
  recommend_inputted_code: "Mã đề xuất của bạn",
  recommend_msg_copy: "Chạm vào đây",
  recommend_code_non_exist:
    "Đây là mã giới thiệu không hợp lệ. Vui lòng kiểm tra lại. ",
  recommend_code_verify: "Vui lòng nhập mã giới thiệu và xác minh.",
  recommend_msg_part1:
    "BitcoinLegend không phải là ứng dụng khai thác mà còn là trò chơi metaverse với thẻ Hero NFT lấy cảm hứng từ Avengers của Marvel.<br/><br/>☆Tham gia BitcoinLegend để nhận 10 BCL miễn phí.<br/>Mã đề xuất : ",
  recommend_msg_part2: "<br/><br/>☆Official website<br/>",

  pre_sale_title: "Pre-sale was closed",
  pre_sale_quantity: "Pre-sale số lượng : ",
  pre_sale_address: "Pre-sale địa chỉ : ",
  pre_sale_price: "Pre-sale giá : ",
  pre_sale_price_desc: "Mua tối thiểu là 0,1 ETH và mua tối đa là 10 ETH.",

  airdrop_rules_presale: "Tiêu chuẩn trước khi bán hàng",
  airdrop_rules_pre_sale_desc:
    "Người dùng có thể nhận thêm phần thưởng mã thông báo BCL bằng cách mời bạn bè tham gia AirDrop. <br /> Bạn có thể nhận được 50 mã thông báo cho mỗi người bạn mà bạn mời. Bạn có thể mời tối đa 50 người bạn và nhận được tới 2.500 mã thông báo BCL. <br /> Số lượng cho AirDrop bị hạn chế và việc phát hành BCL là nhỏ. Giá ước tính của BCL là $ 0,75. Nhanh lên và chia sẻ nó với bạn bè của bạn.",
  how_to_do_pre_sale: "Cách thực hiện Bán trước",
  how_to_do_pre_sale_desc:
    "Sử dụng ví của bạn để gửi ETH đến địa chỉ bán trước. Hệ thống của chúng tôi sẽ gửi mã thông báo BCL đến ví của bạn ngay lập tức. <br /> Chuyển tiền phải được thực hiện bằng cách sử dụng ví tiền điện tử cá nhân mà không cần xác minh KYC.",
  pre_sale_rules: " Pre-sale quy tắc",
  pre_sale_rules_desc:
    "Hệ thống sẽ kết thúc đợt bán trước lúc <h6><u> " +
    moment(AIRDRO_END_DAY).format("HH:mm (UTC+8) on MMMM DD, YYYY") +
    "</u></h6>.<br/>" +
    "Sử dụng ví của bạn để gửi ETH đến địa chỉ bán trước, và hệ thống sẽ gửi ngay số lượng BCL token tương ứng vào ví của bạn.<br/>" +
    "Số lượng mã thông báo BCL để bán trước là có hạn và hạn ngạch được sắp xếp theo thời gian xuất hiện của ETH. Ai đến trước được phục vụ trước.",

  once_the_pre_sale_over: "Sau khi đợt bán trước kết thúc",
  once_the_pre_sale_over_desc:
    "<h6><u>660% số tiền huy động được sẽ được sử dụng cho NFT Market và niêm yết trên LBank & MEXC.</u></h6><br/>" +
    "40% còn lạisố tiền huy động được sẽ được sử dụng để mua lại mã thông báo BCL. Hành động này sẽ ngăn cản mọi người bán phá giá.",

  check_pre_sale: "Check Pre-sale",
  go: "Go",
  check_pre_sale_hint:
    "No KYC, Send Immediately BCL to Pre-sale address after sending  ETH",

  total_supply_and_distribution: "Total supply & Distribution ",
  the_number_of_miner: "Số lượng thợ đào : ",
  users: "triệu người dùng",
  end_of_mining: "Kết thúc khai thác : ",
  end_of_mining_desc:
    "Quá trình khai thác sẽ kết thúc khi có 10 triệu người dùng tiếp cận.",
  total_supply1: "Tổng cung : ",
  burning_and_buyback: "Đốt & Mua lại : ",
  community_airdrop: "Airdrop cộng đồng : ",
  bcl_halving: "BitcoinLegend Halving",
  distribution_bcl_supply: "Distribution of BCL supply",

  roadmap: "Roadmap",
  roadmap_last_title: "Accomplished milestones up to date(Phase1 & Phase2)",
  phase_1_2_date: '020- Q2. 2021',
  roadmap_last_item_0: "Xây dựng nhóm phát triển dự án (BCL core team)",
  roadmap_last_item_1:
    "Ứng dụng dành cho thiết bị di động và Khái niệm BitcoinLegend đã được phát triển<br/>(Lợi thế về tiền xu, Phương pháp khai thác, Nền kinh tế mã thông báo, Ứng dụng khai thác & Thiết kế ví)",
  roadmap_last_item_3: "Giấy trắng V1.0 được sản xuất",
  roadmap_last_item_4: "Phát triển và mở rộng gói ngôn ngữ của 44 quốc gia",
  roadmap_last_item_5: "Phát hành ứng dụng di động iOS",
  roadmap_last_item_6: "Cập nhật Token Economy (bao gồm cả Burning)",
  roadmap_last_item_7:
    "Lập kế hoạch niêm yết trên các sàn giao dịch tiền điện tử lớn",

  now: "Now",
  phase3: "Phase3",
  phase3_date: "Q3.-Q4. 2021.",
  phase3_item_0: "Đã phát hành ứng dụng di động Android/iOS",
  phase3_item_1: "Cộng đồng chính thức (Telegram, Twitter, Youtube) đã mở",
  phase3_item_2: "Đã thêm gói ngôn ngữ mở rộng của √44 quốc gia",
  phase3_item_3: "Cập nhật nền kinh tế mã thông báo (bao gồm cả Burining)",
  phase3_item_4: "Đã hoàn thành thiết kế thẻ Hero NFT",

  phase4: "Phase4",
  phase4_sector_0_date: "Q1.-Q2. 2022",
  phase4_sector_0_item_0: "Ra mắt trang web chính thức",
  phase4_sector_0_item_1: "Gia hạn Giấy trắng V2.0 (chiến lược sử dụng 3 Kiếm tiền)",
  phase4_sector_0_item_2: "Đã phát triển chức năng ping và màn hình khai thác của người dùng",
  phase4_sector_0_item_3:
    "Hero NFT Card Webtoon và Rap Youtube đã được mở",
  phase4_sector_0_item_4:
    "Chính thức khai trương Discord",
  phase4_sector_0_item_5: "Ra mắt hệ thống cấp vốn Lockup & Staking",
  phase4_sector_0_item_6: "Phát triển mở rộng xác thực KYC Bắt đầu sau Sulvey",
  phase4_sector_0_item_7: "BCL được liệt kê trên L Bank và PancakeSwap",

  phase4_sector_1_date: "Q3.-Q4. 2022",
  phase4_sector_1_item_0:
    "Chuyển đổi mạng từ ERC20 sang BEP20 (Chuỗi thông minh Binance)",
  phase4_sector_1_item_1: "Danh sách trắng và quá trình đúc thẻ Hero NFT đã bắt đầu",
  phase4_sector_1_item_2: "Bắt đầu chứng nhận √KYC",
  phase4_sector_1_item_3: "Đã mở trang web thẻ Hero NFT",
  phase4_sector_1_item_4: "Trò chơi RPG NFT đầu tiên được hiện thực hóa (Dark Worrior)",
  phase4_sector_1_item_5: "BCL được liệt kê trên Coingecko và CoinMarketGap",

  phase4_sector_2_date: "June. 2022",
  phase4_sector_2_item_0:
    "Di chuyển mạng từ ERC20 sang BEP20 (Chuỗi thông minh Binance)",
  phase4_sector_2_item_1: "Đã ra mắt chương trình thăng cấp",
  phase4_sector_2_item_2: "BCL được liệt kê trên PancakeSwap",
  phase4_sector_2_item_3:
    "BCL được liệt kê trên các sàn giao dịch tiền điện tử MEXC",
  phase4_sector_2_item_4: "Đốt & Mua lại lần 2",

  phase5: "Phase5",
  phase5_sector_0_date: "Q3. 2022",
  phase5_sector_0_item_0: "Khai thác thẻ Hero NFT trên Binance NFT và Opensea",
  phase5_sector_0_item_1:
    "Phát triển thẻ Hero NFT bằng cách sử dụng studio tự tạo",
  phase5_sector_0_item_2:
    "Khái niệm hóa và phát triển NFT Plaform<br/>(bán, mua và đấu giá thẻ NFT và các mặt hàng)",
  phase5_sector_0_item_3:
    "BCL được niêm yết trên sàn giao dịch tiền điện tử MEXC",
  phase5_sector_0_item_4: "Đốt lần 3",
  phase5_sector_0_item0:
    'Danh sách trắng các thẻ NFT (chỉ Mua & Ghi BCL) trong Dự án "Kiếm tiền"',
  phase5_sector_0_item1: "Ủy quyền KYC đã bắt đầu",
  phase5_sector_0_item2: "Đã bắt đầu thanh toán và rút tiền BCL",

  phases5_sector_0_date: "Q1.-Q2 2023",
  phases5_sector_0_item_0: "BCL thanh toán và rút tiền bắt đầu",
  phases5_sector_0_item_1: "Đã thêm hệ thống đăng nhập của trò chơi RPG NFT (Siêu chiến binh & Chiến binh bóng tối)",
  phases5_sector_0_item_2:
    "Ra mắt website đổi thưởng game",
  phases5_sector_0_item_3: "Dịch vụ cho thuê NFT đã bắt đầu (bao gồm cả lợi thế về độ hiếm)",
  phases5_sector_0_item_4:
    "Cập nhật chức năng Web 3.0 của ứng dụng BCL (bao gồm trình duyệt web, trò chuyện và kết nối trò chơi NFT)",
  phases5_sector_0_item_5: "Trò chơi Roguelike thứ 3 được hiện thực hóa",

  phases5_sector_0_date_2: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_2: "Đã thêm chức năng đấu giá của trang web đúc Hero NFT",
  phases5_sector_0_item_1_2:
    "Thẻ NFT của ứng dụng BCL được hiển thị",
  phases5_sector_0_item_2_2:
    "Trò chơi bài NFT Hero thứ 4 được phát hành (có nhiều người chơi)",
  phases5_sector_0_item_3_2:
    'Trò chơi MMORG thứ 5 được phát hành',

  phases5_sector_0_date_3: "Tháng 12. 2022 ",
  phases5_sector_0_item_0_3: "1. Thẻ NFT Anh hùng thứ 6 được thăng hạng ",
  phases5_sector_0_item_1_3:
    "2. Thành phố MaxForm phân lớp 3D đã ăn trưa và danh sách trắng NFT bắt đầu ",
  phases5_sector_0_item_2_3:
    "3. Lịch sử phát triển của thẻ anh hùng Maxform đã chia sẻ ",
  phases5_sector_0_item_3_3:
    "4. Bắt đầu phát triển trò chơi chiến tranh thành phố Maxform (4 tháng) ",
  phases5_sector_0_item_4_3:
    "5. Trò chơi nguyên mẫu của PlaytoEarn được phát hành ",

  phases5_sector_0_date_4: "Q1. 2023",
  phases5_sector_0_item_0_4: "1. Đã mở thẻ NFT Hero thứ 6 trên YouTube ",
  phases5_sector_0_item_1_4: "2. MaxForm City NFT saled ",
  phases5_sector_0_item_2_4: "3. Trò chơi thẻ bài MaxForm Hero được phát hành ",
  phases5_sector_0_item_3_4:
    "4. Lịch sử phát triển của trò chơi chiến tranh MaxForm City được chia sẻ ",
  phases5_sector_0_item_4_4:
    "5. Phát triển thế giới MaxForm (Metaverse) (9 tháng) ",
  phases5_sector_0_item_5_4: "∙ Phát triển thẻ Hero NFT bằng studio tự tạo",
  phases5_sector_0_item_6_4:
    "∙ Khái niệm hóa và phát triển biểu mẫu NFT (bán, mua và đấu giá thẻ và vật phẩm NFT)",
  phases5_sector_0_item_7_4:
    "∙ Nền tảng hoạt động kinh tế hình đại diện do chính bạn tạo ra",

  phases5_sector_0_date_5: "Quý 2. 2023 ",
  phases5_sector_0_item_0_5:
    "1. Thẻ Hero NFT được bán ra (phần thưởng lợi nhuận cho người mua NFT tốt nhất)",
  phases5_sector_0_item_1_5:
    "2. Trò chơi thẻ bài MaxForm Hero đã được kích hoạt ",
  phases5_sector_0_item_2_5:
    "3. Trò chơi chiến tranh MaxForm City được phát hành ",
  phases5_sector_0_item_3_5:
    "4. Lịch sử phát triển của thế giới MaxForm đã chia sẻ ",

  phases6: "Giai đoạn 6",
  phases6_sector_0_date_5: "2024",
  phases6_sector_0_item_0_5:
    "Bắt đầu phát triển thế giới MaxForm (Metaverse)",
  phases6_sector_0_item_1_5: "Phát triển thẻ Hero NFT bằng studio tự tạo (sử dụng chức năng AI)",
  phases6_sector_0_item_2_5: "Nền tảng hoạt động kinh tế Avatar do chính bạn tạo ra",
  phases6_sector_0_item_3_5:
    "Trò chơi thẻ bài TCG of Hero thứ 6 được phát hành",

  phases7: "Giai đoạn 7",
  phases7_sector_0_date_5: "2025",
  phases7_sector_0_item_0_5:
    "Thế giới MaxForm (Metaverse) đã mở",
  phases7_sector_0_item_1_5: "Thêm nhiều quan hệ đối tác của MaxForm thế giới được ký kết",
  phases7_sector_0_item_2_5:
    "Thêm sự lan rộng của Hệ sinh thái BCL",
  phases7_sector_0_item_3_5:
    "Mạng chính BCL đã được phát triển",

  phase6: "Phase6",
  phase6_sector_0_date: "Q4. 2022",
  phase6_sector_0_item_0: 'Nền tảng NFT được ra mắt trong Dự án "Kiếm tiền"',
  phase6_sector_0_item_1: "Phát triển trò chơi Metaverse bằng thẻ NFT",
  phase6_sector_0_item_2:
    "BCL được niêm yết trên các sàn giao dịch lớn theo CMC Lv.20",
  phase6_sector_0_item_3: "Đốt thứ 4",

  phase7: "Phase7",
  phase7_sector_0_date: "2023",
  phase7_sector_0_item_0:
    'Thử nghiệm beta trò chơi NFT Avatar Metaverse trong Dự án "Chơi để kiếm tiền"<br/>• Trò ​​chơi 1: 1 và PVP (trò chơi Avatar)<br/>• Cho thuê và bán bất động sản ảo',
  phase7_sector_0_item_1:
    "Nền tảng hoạt động kinh tế Avatar do chính bạn tạo ra",
  phase7_sector_0_item_2:
    "BCL được niêm yết trên các sàn giao dịch lớn theo CMC Lv.10 & Lv. 5",
  phase7_sector_0_item_3: "Đốt lần thứ 5",

  download_now: "Download now",
  download_now_desc:
    "Tải xuống ứng dụng tệp androroid, iOS hoặc APK và bắt đầu khai thác ngay hôm nay.",
  referring_hint: "Kiếm thêm 25% bằng cách giới thiệu người khác",
  join_us_hint:
    "Tham gia với chúng tôi trên kênh trực tuyến của chúng tôi để ở lại<br/>" +
    "Cập nhật cộng đồng BitcoinLegend",
  license: "© 2021 BitcoinLegend. All rights reserved",

  nft_about_title: "Giới thiệu về Thẻ Hero NFT & Metaverse",
  nft_about_desc:
    '<p> metaverse lần đầu tiên được đề cập trong " Snow Crash "của Neal Stephenson vào năm 1992. <br/>' +
    "Metaverse là một nền tảng cuộc sống thứ hai, là một thế giới mà bạn có thể kết nối với tâm trí của người khác bất cứ lúc nào và ở bất cứ đâu. <br/>" +
    "Siêu nghịch đảo là trạng thái trong đó thực tại và các thế giới khác nhau được kết hợp, chẳng hạn như vũ trụ 1, vũ trụ 2 và vũ trụ 3, <br/>" +
    "và nó ở trong tình trạng giả thực tế đến mức chúng ta không biết ai mới là tôi thật trong metaverse, người sống một cuộc sống khác với tôi thật. <p />" +
    "<p> Hình đại diện có thể gặp gỡ và giao tiếp trong một không gian siêu việt và tham gia vào các hoạt động kinh tế bằng cách sử dụng các chức năng AR và VR ẩn danh. <br/>" +
    "Gặp gỡ như một hình đại diện trong một không gian siêu việt là một sự đổi mới. Nó sẽ dẫn đầu sự đổi mới của thế giới trong 20 năm tới dưới dạng sự sụp đổ của khái niệm thời gian và không gian. </p>" +
    "<p> Mọi người đều có tầm nhìn và ước mơ. <br/>" +
    "Tuy nhiên, hầu hết họ đều không thể biến nó thành hiện thực và luôn ghi nhớ nó. <br/>" +
    "Đặc biệt, những nỗ lực đang được thực hiện để giải quyết tình trạng nghèo đói về kinh tế và sự không hài lòng với thực tế. <br/>" +
    "Tuy nhiên, kết quả của những nỗ lực này rất khó đạt được trên thực tế. </p>" +
    "<p> Bitcoin Legend được tạo ra để hiện thực hóa lý tưởng của mọi người. </p>" +
    "<p> Người dùng tạo hình đại diện NFT của riêng họ, tạo các vật phẩm NFT và mua bán chúng để kiếm lợi nhuận. <br/>" +
    "BCL Core Team sử dụng công nghệ AI để tạo các nhân vật webtoon giống với tôi thật. <br/>" +
    "Các công nghệ được áp dụng tại thời điểm này là Facial Landmark Engine và Facial Landmark Trigger trong Self Made Studio. <br/>" +
    "Sử dụng công nghệ này, người dùng có thể trực tiếp tạo hình đại diện giống với tôi thật, có thể thay đổi kiểu tóc, hình dạng khuôn mặt và thậm chí cả màu mắt. <br/>" +
    "Ngoài ra, bạn có thể sản xuất các loại quần áo, đồ trang trí và các vật phẩm trò chơi khác nhau. Ngoài ra, bạn có thể mua chúng do mình làm ra. <br/>" +
    "Những vật phẩm này có thể được bán đấu giá bằng cách mã hóa chúng bằng công nghệ chế tạo NFT. </p>",
  maxform_desc:
    "<p> MaxForm Studio là một hệ thống tự tạo lấy người dùng làm trung tâm. <br/>" +
    "Người dùng thay đổi khuôn mặt sau khi chụp ảnh và khôi phục trạng thái ẩn danh bằng mặt nạ. <br/>" +
    "Nó cũng được trang trí bằng Nội dung do người dùng tạo (UGC). <br/>" +
    "Maxform là không gian nơi các hình đại diện hoạt động trong không gian metaverse được tạo bởi Thẻ Hero NFT. </p>",
  shape_made_tech: "Shape made Tech",
  shape_made_tech_desc:
    "<p> Khi bạn chụp bằng điện thoại thông minh, Công cụ tạo mốc khuôn mặt và Trình kích hoạt sẽ hoạt động theo chức năng nhận dạng khuôn mặt AI. <br/>" +
    "Khuôn mặt được tạo bởi hình ảnh Webtoon và một số người dùng có xu hướng ẩn danh khác nhau. <br/>" +
    "Khi bạn muốn thay đổi khuôn mặt của mình, bạn có thể thay đổi màu sắc của khuôn mặt, màu tóc, kiểu tóc, lông mày, v.v. <br/>",
  shape_made_system: "Hệ thống tự tạo cho người dùng",
  shape_made_system_desc:
    "<p> Tất cả phụ kiện amd của trang phục đều có thể được thiết kế và sản xuất bởi chính người đó trên nền tảng sản xuất trang phục. <br/>" +
    "Ngoài ra, bạn có thể chọn loại mặt nạ mà bạn muốn để duy trì sự thanh lịch. <br/>" +
    "Cái này có thể được mua và bán trên thị trường NFT và trong trò chơi metaverse Max Form, <br/>" +
    "Các phụ kiện và trang phục giành được trong các trò chơi đối kháng 1: 1 sẽ trở nên nổi tiếng hơn và giá của chúng sẽ tăng lên. <br/>" +
    "Và nhà thiết kế đã làm ra nó ngày càng được tôn vinh nhiều hơn. Đương nhiên, giải thưởng của anh ấy cũng tăng theo. </p>",
  hero_nft_card: "Thẻ NFT Anh hùng",
  hero_nft_card_desc:
    "<p> BitcoinLegend không chỉ là một ứng dụng khai thác, mà sẽ trở thành Top Tier trong kinh doanh thẻ NFT và trò chơi metaverse. <br/>" +
    "Mạng BCL dự định đi tiên phong trong hệ sinh thái tiền điện tử bằng cách thiết lập chiến lược 3 Kiếm tiền. <br/>" +
    'Đây là chiến lược " Kiếm tiền "trong số 3 chiến lược Kiếm tiền. Thẻ Hero NFT đặt cuộc sống của một Anh hùng trên một thẻ. <br/>' +
    "Anh hùng thẻ Hero NFT xuất hiện trong trò chơi metaverse. Thẻ này có một câu chuyện anh hùng và một kho vật phẩm. <br/>" +
    "Những câu chuyện về anh hùng cho phép người dùng kể những câu chuyện của riêng họ. Khoảng không quảng cáo có thể lưu trữ các kỹ năng, thiết bị và nguyên liệu. <br/>" +
    "Tất cả những vật phẩm này đều có thể được mua, bán và đấu giá. </p>",
  media_partners: "Media & Partners",
  nft_modal_content:
    "<p> Hoạt động khai thác tốc độ cao gấp 4 lần (tất cả người dùng) <br/> <br /> " +
    "☑️1NFT = 3,900BCL/94Matic/0.05 ETH</p>",
  go_hero_nft_card: "Mua thẻ Hero NFT",
  go_hero_nft_close: "Đóng cửa sổ",
  bep_title: "Di chuyển từ ERC20 sang BEP20",
  bep_title1: "Mục đích di cư",
  bep_desc1: "Để giảm phí gas phát sinh trong quá trình rút và đúc NFT",
  bep_title2: "Tỷ lệ hoán đổi khi di chuyển",
  bep_desc2:
    "1. Hoán đổi được thực hiện cho người dùng đã mua BCL trước đó, <br /> và các khoản thanh toán bổ sung được thực hiện để giảm chi phí phí khí đốt phát sinh khi gửi. <br /> khai thác BCL được trả là BEP20 khi được thanh toán sau KYC, vì vậy không cần phải trao đổi. <br /> 2. Tăng tỷ lệ thanh toán bổ sung khi trao đổi tiền điện tử lbank cho hoạt động giao dịch cho thanh khoản BCL",
  bep_title3: "Tiền điện tử Lbank",
  bep_desc3: "ERC20 : BEP20 = 1 : 1.1 (10% thanh toán bổ sung)",
  bep_title4: "Cách thực hiện Di chuyển",
  bep_desc4:
    "1. Trao đổi tiền điện tử Lbank <br />" +
    "Bạn không phải làm gì cả. BCL được hoán đổi bên trong sàn giao dịch Lbandk và được gửi vào ví mới. <br />" +
    "2. Trang web BCL (Tại đây)<br />" +
    "Sử dụng ví cá nhân như Metamask, Trust Wallet hoặc Im Wallet mà bạn đã gửi trước đó.<br />" +
    "Gửi BCL dựa trên ERC20 đến địa chỉ của BCL foudation như sau. Hệ thống của chúng tôi sẽ gửi mã thông báo BCL dựa trên BEP20 đến ví của bạn ngay lập tức.",
  bep_desc41: "3. Vật lạnh cá nhân có thể di chuyển <br />",
  bep_desc42: " Metamask ( Cách nhận địa chỉ BEP20 ) ",
  bep_desc43: " Trust wallet ( Cách nhận địa chỉ BEP20 )  ",
  bel_desc44:
    "Etherscan: Kiểm tra chuyển từ ví của bạn sang BCL Foundation bằng cách sử dụng BCL dựa trên ERC20",

  bep_title5: "Ứng dụng di chuyển",
  bep_desc5:
    "Vui lòng chuẩn bị sao chép địa chỉ ví ERC20 và địa chỉ ví BEP20 trước khi đăng ký di chuyển.",
  bep_button_title: "Hoán đổi tại lbank trước ngày 29 tháng 6, làm ơn",
  bep_submit: "Nộp",
  bep_exit: "Lối ra",
  bep_popup_desc1:
    '[Thông báo về việc di chuyển] <br /> 1. Vui lòng đảm bảo rằng địa chỉ ví của bạn là chính xác trước khi chạm vào nút "gửi" <br /> 2. Chúng tôi không chịu trách nhiệm về việc ghi sai hoặc bỏ sót địa chỉ ví <br /> 3. Giao dịch diễn ra trong khoảng 3-5 phút.',
  bep_popup_title1: "Ứng dụng di chuyển",
  bep_popup_agree: "Đồng ý với thông báo trên",
  bep_input_email: "Nhập địa chỉ email",
  bep_input_erc: "Nhập địa chỉ ERC20",
  bep_input_ber: "Nhập địa chỉ BEP20",
  bep_input_amount: "Số lượng đầu vào",
  bep_check_box: "Vui lòng nhấp vào hộp kiểm",
  bep_request_complete: "yêu cầu đã được hoàn thành.",
  bep_request_check: "Vui lòng kiểm tra mặt hàng",
  bep_title8:
    "<font color='#aaaaaa'>Địa chỉ gửi tiền của BCL foudation :</font> <font color='#34D5FF'>0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  bep_title9: "Đang di chuyển ở đâu",
  bep_title10:
    "1. Trao đổi Crytocurrency Lbank <br/> Tiền gửi bị cấm vào ngày trước ngày được thông báo trước. Trao đổi trong LBank trong 3 ngày. Tiền gửi mới, giao dịch và rút tiền sẽ được tiến hành. <br/> 2. Trang web BCL (tại đây) <br/> Đối với người dùng không thể tham gia hoán đổi trao đổi tiền điện tử LBank, trang web BCL thực hiện di chuyển riêng.",
  bep_title11:
    "Tỷ lệ hoán đổi khi di chuyển <br />1. Trao đổi Crytocurrency lbank <br />ERC20: BEP20 = 1: 1.1 (UTIL 29 tháng 6 2 giờ chiều UTC+8) <br />2. Trang web Bitcoinlegend (từ ngày 1 tháng 7) <br />ERC20: BEP20 = 1: 1.03",
  // bep_title12: "<font color='yellow'>Trao đổi CryTocurrency Lbank:</font><br />ERC20: BEP20 = 1: 1.1 (10% thanh toán bổ sung)",
  // bep_title13: "<font color='yellow'>Trang web Bitcoinlegend</font><br />ERC20: BEP20 = 1: 1.1 (3% thanh toán bổ sung)",
  levelup_title1:
    "Chương trình này được phát triển cho người dùng muốn tăng tốc độ khai thác với chi phí tối thiểu.",
  levelup_desc1:
    "Chương trình tăng cấp được thiết kế để tăng tốc độ khai thác trong một khoảng thời gian đã thiết lập. <br /> 1. Sau khi nộp đơn và thanh toán, nó sẽ được áp dụng ngay lập tức. <br /> 2. Thời gian ứng dụng hoạt động như một bộ đếm thời gian. <br /> 3. Sau khi hẹn giờ hết hạn, tốc độ khai thác tương ứng với thời gian bán hủy được khôi phục.",
  levelup_title2: "Hiện nay",
  levelup_title3: "Lên cấp chương trình lên",
  levelup_popup_title1: "Biểu mẫu ứng dụng để tăng cấp",
  levelup_popup_desc1:
    "[Thông báo về cấp độ] <br /> 1. Google Play Store App Store, khi cài đặt khai thác Bitcin Legend <br /> Ứng dụng dưới dạng tệp APK, hãy chắc chắn nhập địa chỉ email đăng ký. <br /> 2. Vui lòng tham khảo hướng dẫn ở trên để biết cách kiểm tra địa chỉ email của bạn. <br /> 3. Chương trình tăng cấp bắt đầu ngay sau khi ứng dụng <br /> 4. Thời gian hoạt động của chương trình tăng cấp là 30 ngày sau khi đăng ký.",
  levelup_popup_confirm: "Xác nhận",
  bep_button_title2: "Kiểm tra sự di chuyển của bạn",
  levelup_title4: "Cách kiểm tra địa chỉ e-mail của bạn",
  levelup_title5:
    "1. Biểu tượng cài đặt Chạm vào ứng dụng khai thác của Bitcoinlegend.<br />2. Kiểm tra email BCL đã đăng ký và nhập giống như hiển thị.",

  nft_about_title2: "Giới thiệu về MaxForm Hero Card & Metaverse",
  nft_about_desc2:
    '<p>Metaverse lần đầu tiên được đề cập trong Neal Stephenson "Snow Crash" vào năm 1992.<br/>' +
    "Metaverse là một nền tảng cuộc sống thứ hai là một thế giới nơi bạn có thể kết nối với tâm trí của người khác mọi lúc và mọi nơi.<br/>" +
    "Metaverse là một trạng thái trong đó thực tế và các thế giới khác nhau được kết hợp, chẳng hạn như Universe 1, Universe 2 và Universe 3, <br/>" +
    "Và đó là trong tình trạng giả chính đến mức chúng ta không biết ai là người thực sự của tôi trong Metaverse, người sống một cuộc sống khác với tôi thật.<p/>" +
    "<p>Avatar có thể gặp gỡ và giao tiếp trong một không gian siêu việt và tham gia vào các hoạt động kinh tế bằng cách sử dụng các chức năng AR và VR với sự ẩn danh.<br/>" +
    "Gặp nhau như một hình đại diện trong một không gian siêu việt là một sự đổi mới. Nó sẽ dẫn đầu sự đổi mới thế giới trong 20 năm tới dưới hình thức sụp đổ của khái niệm thời gian và không gian</p>" +
    "<p> Mọi người đều có tầm nhìn và giấc mơ. <br/>" +
    "Tuy nhiên, hầu hết trong số họ không thể biến nó thành hiện thực và giữ nó trong tâm trí của họ. <br/>" +
    "Đặc biệt, những nỗ lực đang được thực hiện để giải quyết nghèo đói kinh tế và sự không hài lòng với thực tế. <br/>" +
    "Tuy nhiên, kết quả của những nỗ lực này rất khó đạt được trong thực tế. </p> " +
    "<p> Truyền thuyết bitcoin được tạo ra để nhận ra lý tưởng của mọi người. </p>" +
    "Nhóm Bcl Core sử dụng công nghệ AI để tạo các ký tự webtoon tương tự như tôi. <br/>" +
    "Các công nghệ được áp dụng tại thời điểm này là động cơ mang tính bước ngoặt trên khuôn mặt và kích hoạt mốc trên khuôn mặt trong studio tự tạo. <br/>" +
    "Sử dụng công nghệ này, người dùng có thể trực tiếp tạo một hình đại diện giống với tôi thực sự. Có thể thay đổi kiểu tóc, hình dạng khuôn mặt và thậm chí là màu mắt. <br/>" +
    "Ngoài ra, bạn có thể sản xuất các loại quần áo, đồ trang trí và vật phẩm trò chơi khác nhau. Ngoài ra, bạn có thể mua chúng mà bạn đã làm. <br/>" +
    "Các mặt hàng này có thể được bán đấu giá bằng cách mã hóa chúng bằng công nghệ làm NFT. </p>",
  hero_nft_card2: "Thẻ anh hùng trò chơi-fi",
  hero_nft_card3: "Danh sách trắng cho thẻ NFT MaxForm Hero",
  hero_nft_card3_desc:
    "Người bình thường có thể là anh hùng. <br /> Không chỉ các anh hùng tốt trở thành anh hùng, <br /> mà ngay cả những kẻ ác trở thành anh hùng nếu họ có thể giành chiến thắng trong trò chơi. <br /> thử thách chính mình.",
  hero_nft_card4: "Danh sách trắng cho thẻ anh hùng MaxForm",
  hero_nft_card_title1: "Những gì NFT?",
  hero_nft_card_desc1:
    "Mã thông báo không bị nấm (NFT) là mã thông báo ảo sử dụng công nghệ blockchain để chứng minh chủ sở hữu của một tài sản kỹ thuật số. <br /> " +
    "Địa chỉ trỏ đến các tệp kỹ thuật số như hình ảnh và video được lưu trữ trong mã thông báo và được sử dụng để biểu thị tính nguyên bản và quyền sở hữu độc đáo của chúng. <br />" +
    "Đó là, một số loại giấy chứng nhận xác thực ảo. <br />" +
    "Thẻ NFT anh hùng được thiết kế với khái niệm rằng không chỉ một nhân vật cụ thể có thể trở thành một anh hùng, mà cả những người bình thường có thể trở thành một anh hùng.",
  hero_nft_card_title2: "Các đặc điểm của thẻ anh hùng MaxForm là gì?",
  hero_nft_card_desc2:
    "Thẻ MaxForm Hero sẽ được đúc qua Chuỗi Polygon và bất kỳ ai cũng có thể mua và bán nó tại OpenSea. <br /> " +
    "Nó được tạo ra với 2130 x 2800 pixel theo kỹ thuật sản xuất chính xác. <br />" +
    "Một danh sách trắng không chỉ là mua NFT từ quan điểm của người mua, mà còn để mua NFT với giá chiết khấu. Và bạn có thể nhận được các dịch vụ bổ sung. <br />" +
    "BCL Foundation cung cấp các lợi ích tăng cấp cho những người mua thẻ NFT Hero thông qua danh sách trắng để tăng tốc độ khai thác của họ. <br />" +
    "Những người mua trực tiếp từ OpenSea hoặc Binance NFT không có lợi ích này.",
  hero_nft_card_title3: "The Price and payment method for whitelist",
  hero_nft_card_desc3:
    "☑️ Danh sách trắng đầu tiên (1.500 NFT)<br /> " +
    "1. Giá: 1.000 BCL 2. Phương pháp thanh toán: Chỉ BEP20 dựa trên BECL<br />\n" +
    "☑️ Danh sách trắng thứ 2 (4.500 NFT) <br />\n" +
    "1. Giá: 0,05 ETH2. Phương pháp thanh toán: BCL, ETH, Matic (polygon) hoặc các phương pháp thanh toán khác<br />\n" +
    "☑️ Danh sách trắng thứ 3 (2.000 NFT) <br />\n" +
    "1. Giá: 0,05 ETH2. Phương pháp thanh toán: BCL, ETH, Matic (polygon) hoặc các phương pháp thanh toán khác<br />\n" +
    "☑️ Bắt đầu đấu giá Hà Lan (1.000 NFT) <br />" +
    "Bán & Mua ở ngoài khơi <br />",
  hero_nft_card_title4: "Lịch trình cho danh sách trắng",
  hero_nft_card_desc4:
    "☑️ Danh sách trắng đầu tiên (1.500 NFT) <br /> " +
    "1. Bắt đầu danh sách trắng đầu tiên trên trang web BCL: 17 tháng 8 - 31 tháng 8 <br />" +
    "2. Hạn nộp tiền: 17 tháng 8 - 31 tháng 8 <br />" +
    "3. Trang web đúc tiền được thêm vào trang web BCL: 16 tháng 8 - 19 tháng 8 <br />" +
    "4 .. Phát hành kênh tại OpenSea dưới dạng hộp quà: Ngày 5 tháng 9 <br />" +
    "5. Tiết lộ thẻ anh hùng ngẫu nhiên: ngày 7 tháng 9 <br />" +
    "☑️ Danh sách trắng thứ 2 (4.500 NFT) <br />" +
    "1. Bắt đầu danh sách trắng thứ hai trên trang web BCL: 14 tháng 9 - 23 tháng 9 <br />" +
    "2. Thời hạn gửi tiền : 14 tháng 9 - 23 tháng 9 <br />" +
    "3. Phát hành kênh tại OpenSea dưới dạng hộp quà: ngày 27 tháng 9 <br />" +
    "4. Tiết lộ thẻ anh hùng ngẫu nhiên: ngày 29 tháng 9 <br />" +
    "☑️ Danh sách trắng thứ 3 (2.000 NFT) <br />" +
    "1. Bắt đầu danh sách trắng thứ 3 trên trang web BCL: 4 tháng 10 - 14 tháng 10 <br />" +
    "2. Thời hạn gửi tiền (BCL & thanh toán khác): 4 tháng 10 - 14 tháng 10 <br />" +
    "3. Phát hành kênh tại OpenSea dưới dạng hộp quà: 18 tháng 10 <br />" +
    "4. Tiết lộ thẻ anh hùng ngẫu nhiên: ngày 20 tháng 10 <br />" +
    "☑️Bắt đầu Đấu giá Hà Lan (1.000 NFT): ngày 26 tháng 10",
  hero_nft_card_title5: "Cách đăng ký danh sách trắng",
  hero_nft_card_desc5:
    "1. Mua BCL từ Lbank hoặc Pancake Swap. <Div class = 'gain_strategy_mark2' style = 'display: inline' onclick = \" window.open ('https://www.lbank.info'); \"'> LBank </div> <div class = 'gain_strategy_mark2' style = 'display: inline' onclick = \"window.open ('https://pancakeswap.finance'); \"> PancakeSwap </div> <br /> " +
    "2. Gửi BCL dựa trên BEP20 vào ví tiền điện tử cá nhân của bạn (Metamask và Trustwallet). <br />" +
    "* Cách lấy địa chỉ BEP20 và Polygon (matic) <div class = 'gain_strategy_mark2' style = 'display: inline' onclick = \" window.open ('https://youtu.be/Y_gaBqbGvvo'); \"> Đa giác </ div> <br /> " +
    "3. Gửi BCL dựa trên BEP20 đến địa chỉ của BCL foudation như sau:",
  hero_nft_card_desc6:
    "<font color='#aaaaaa'>BCL  Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  hero_nft_card_desc7: "Kiểm tra tiền gửi của BCL Foundation",
  hero_nft_card_desc8:
    "4. Nhập địa chỉ email đăng ký ứng dụng BCL của bạn và gửi địa chỉ ví BCL trong cửa sổ bên dưới.<br />" +
    "*Để tham gia vào sự kiện tăng tốc khai thác, hãy chắc chắn nhập địa chỉ ứng dụng đăng ký BCL của bạn.",
  hero_nft_card_desc9: "Biểu mẫu ứng dụng để áp dụng danh sách trắng",
  hero_nft_card_desc10_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>Kiểm tra email bạn đăng ký trên ứng dụng BCL</span> ',
  hero_nft_card_desc10_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>Nhập địa chỉ email của bạn và số tiền và địa chỉ BCL dựa trên BEP20 <br /> đã gửi <br /> <font color = '# FFD500'> * 1NFT = 1,000BCL, số dư không được hoàn lại, <br /> vì vậy vui lòng nhập 1000 các đơn vị. <br /> Không có giới hạn về số lượng NFT được mua cho mỗi người mua </font></span> ",
  hero_nft_card_desc10_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>Địa chỉ nhận thẻ NFT tự động được <br /> đăng ký là địa chỉ Đa giác (MATIC) của tài khoản tiền gửi.</span> ',
  hero_nft_card_desc11:
    "5. Kiểm tra danh sách trắng để mua Hero NFT <br />" +
    "*Sau khi mua NFT trong danh sách trắng thứ 1, nhóm BCL Core sẽ đăng ở đây danh sách người mua.<br />" +
    "Sau khi đặt ra danh sách, người mua sẽ nhận được lợi thế của việc khai thác tốc độ và thông tin của Maxform Hero Thẻ. ",
  bep_input_nft_email: "Địa chỉ email đầu vào",
  bep_input_bep_address: "Địa chỉ BCL đầu vào",
  bep_input_bep_amount: "Đầu vào Bcl số lượng",
  bep_input_bep_from: "'Ví' phải được kết nối.",
  bep_input_polygon_address: "Nhập địa chỉ đa giác",
  bep_input_bep_amount2: "Vui lòng nhập một số trên 3900",
  not_match_bep: "Kiểm tra địa chỉ BEP20 BCL bạn đã gửi và số lượng BEP20 BCL.",
  not_match_eth: "Kiểm tra địa chỉ ETH bạn đã gửi và số lượng ETH.",
  not_match_pol: "Kiểm tra địa chỉ MATIC bạn đã gửi và số lượng MATIC.",
  hero_nft_card_desc51:
    "* Nếu bạn gửi BCL tại Lbank, bạn không thể nhận được thẻ NFT. <br /> " +
    "Hãy chắc chắn làm điều đó trong ví tiền điện tử cá nhân của bạn <br />" +
    "* 1NFT = 1,000BCL, số dư không được hoàn lại, <br />" +
    "vì vậy vui lòng nhập 1000 đơn vị. <br />" +
    "Không có giới hạn về số lượng NFT được mua cho mỗi người mua",
  nft_request_complete: "đã được xử lý",

  hero_nft_card_desc12:
    "1. Gửi Ethereum (ETH) hoặc Polygon (MATIC) đến địa chỉ của BCL foudation như sau:<br />" +
    "* Cách lấy địa chỉ Polygon (matic)<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />",
  hero_nft_card_desc52:
    "*1NFT = 0.05 ETH, 1 NFT = 40 MATIC <br />" +
    "số dư không được hoàn lại <br />" +
    "Không có giới hạn về số lượng NFT được mua cho mỗi người mua",
  hero_nft_card_desc61:
    "<font color='#aaaaaa'>Polygon(Matic) Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   /><br />",
  hero_nft_card_desc71: "Kiểm tra tiền gửi ETH",
  hero_nft_card_desc72: "Kiểm tra tiền gửi MATIC",
  hero_nft_card_desc81:
    "2. Nhập địa chỉ email đăng ký ứng dụng BCL của bạn và địa chỉ ví BCL đã gửi trong cửa sổ bên dưới. <br />" +
    " * Để tham gia sự kiện Tăng tốc độ khai thác, hãy nhớ nhập địa chỉ email ứng dụng đăng ký BCL của bạn.",
  hero_nft_card_desc91: "Biểu mẫu ETH / MATIC để áp dụng Danh sách trắng",
  hero_nft_card_desc11_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>Kiểm tra email bạn đã đăng ký trên ứng dụng BCL</span> ',
  hero_nft_card_desc11_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>Nhập địa chỉ email của bạn và gửi ETH hoặc MATIC <br />địa chỉ và số tiền <br /><font color='#FFD500'>*1NFT = 0.05 ETH, 1NFT = 40 MATIC,<br />" +
    "số dư không được hoàn lại. <br />" +
    "Không có giới hạn về số lượng NFT được mua cho mỗi người mua</font></span> ",
  hero_nft_card_desc11_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>Nhập địa chỉ dựa trên Đa giác đã nhận được NFT của bạn.</span> ',
  hero_nft_card_desc82:
    "Sau khi mua NFT trong danh sách trắng đầu tiên, đội ngũ cốt lõi của BCL sẽ đăng danh sách người mua tại đây.  <br />" +
    "Sau khi tạo danh sách, người mua sẽ nhận được lợi thế của Tốc độ khai thác và thông tin của thẻ Maxform Hero.",
  nft_event_string1:
    "🟢 Thẻ anh hùng Maxform NFT đang được đúc và được thực hiện bởi nhóm Bitcoinlegend Core 🟢<br />" +
    "                        Khái niệm thẻ anh hùng của huyền thoại Bitcoin là bất cứ ai nói chung đều có thể trở thành anh hùng.<br />" +
    "Đó là một nửa quần áo bình thường và một nửa trong quần áo anh hùng.<br />\n" +
    "                        Điều này thậm chí còn độc đáo hơn bởi chương trình hiếm.",
  nft_event_string2: "🎉Chỉ 300 thẻ Hero NFT sẽ được cung cấp miễn phí.🎉",
  nft_event_string3:
    " ☑️ Danh sách trắng đầu tiên Giá: 𝟬.022 ETH / 45 MATIC / 1.000 BCL mỗi NFT <br />" +
    "                        ☑️ Danh sách trắng thứ 2 ~ 3 Giá: 𝟬.05 ETH / 105 MATIC / 2.200 BCL mỗi NFT",
  nft_event_string4:
    " ☑️ Sự kiện khác (sử dụng thu thập 3 triệu người dùng)<br /><br />" +
    "                        ▶️ Khai thác tốc độ cao gấp 4 lần: 6 → 24BCL / ngày<br />" +
    "                       (Người mua NFT + @ 24 BCL / ngày, trong 1 tháng)<br /><br />" +
    "                         ▶️ Phần thưởng giới thiệu lên: 25 → 50 BCL / Người dùng",
  nft_event_string5: "Cảm ơn bạn đã tham gia sự kiện!",
  nft_event_string6: "🏷️ Thông tin bán hàng",
  go_hero_nft_event: "Sự kiện thẻ NFT anh hùng",
  bep_request_check2: "Không tìm thấy thông tin tài khoản phù hợp.",
  nft_event_qa:
    "Nếu nội dung chính bị thiếu, hãy gửi <a href='https://forms.gle/SxLB81u6t5hBzUiV6' target='_blank'> <span style = 'text-decoration: underline; font-weight: bold' Để thực hiện việc này, vui lòng> nhấp vào </span> </a> vào đây.",
  hero_nft_card_title11: "[Khuyến mãi đặc biệt]",
  hero_nft_card_desc11_desc:
    "☑️Ranking cạnh tranh (Cập nhật thời gian thực) <br />" +
    "● Vị trí thứ nhất <br />" +
    "1. Quảng cáo anh hùng thứ 6:  “You're Hero“ <br />" +
    "(ảnh và câu chuyện của bạn → sản xuất anh hùng + sản ​​xuất webtoon + rap) <br />" +
    "Người hùng thứ nhất: <a href='https://youtu.be/s0FSVXZ-wSs' target='_blank'>https://youtu.be/s0FSVXZ-wSs</a> <br />" +
    "Anh hùng thứ 2: <a href='https://youtu.be/8Al9l7YVLC4' target='_blank'>https://youtu.be/8Al9l7YVLC4</a> <br />" +
    "Người thừa kế thứ 3 - thứ 5: <a href='https://youtu.be/gfjBqRpmh6U' target='_blank'>https://youtu.be/gfjBqRpmh6U</a> <br />" +
    "<br />" +
    "2. Phiên bản giới hạn 1.000NFT (Anh hùng thứ 6) với 26 vật phẩm <br />" +
    "1,000NFT * 0,1ETH (giá trong danh sách trắng) = 100ETH <br />" +
    "Phần thưởng 5% trên số tiền bán hàng (5ETH) <br />" +
    "● Vị trí thứ 2 - 3 <br />" +
    "Vị trí thứ 2) 5 NFT bổ sung + 2% phần thưởng cho Anh hùng thứ 6 (2ETH) <br />" +
    "Vị trí thứ 3) 3 NFT bổ sung + 1% phần thưởng cho Anh hùng thứ 6 (1ETH) <br />" +
    "● Vị trí thứ 4-10 <br />" +
    "2 NFT bổ sung <br />" +
    "* NFT trả thêm sẽ được thanh toán bằng NFT hiện đang bán. <br />" +
    "<br />" +
    "☑️Khuyến mãi thành viên <br />" +
    "● Bạch kim (Mua từ 21NFT trở lên) <br />" +
    "Tăng tốc độ tối thiểu (+ 24BCL / ngày), ưu tiên KYC, NFT + 1ea / 20ea <br />" +
    "● Vàng (Mua 11 ~ 20 NFT) <br />" +
    "Tăng tốc độ tối thiểu (+24 BCL / ngày), ưu tiên KYC, NFT + 1 <br />" +
    "● Bạc (Mua 5-10 NFT) <br />" +
    "Tăng tốc độ tối thiểu (+24 BCL / ngày), Mức độ ưu tiên KYC <br />" +
    "● Đồng (Mua 1 ~ 4NFT) <br />" +
    "Tăng tốc độ tối thiểu (24BCL / ngày)",
  kyc_certification: "Chứng nhận KYC",
  vision_of_bitcoinLegend: "Tầm nhìn của BitcoinLegend",
  kyc_desc1:
    "Bitcoin Legend là một loại tiền điện tử khai thác hiện đang được liệt kê. <br />" +
    "Điều này là để mã thông báo BCL đóng một vai trò thực tế như là đơn vị tiền tệ vận hành của hệ sinh thái Maxform. <br />" +
    "Hệ sinh thái Maxform phát hành thẻ NFT anh hùng và chơi các trò chơi thẻ bằng cách sử dụng chúng. <br />" +
    "Ngoài ra, Thành phố Maxform nơi anh hùng cư ngụ được hiện thực hóa trong thế giới 3D, <br />" +
    "và thành phố cư trú được biến thành NFT. <br />" +
    "Thành phố này cũng được thực hiện dưới dạng trò chơi giống như trận chiến trong thành phố. <br />" +
    "Nếu con người và môi trường được tạo ra bởi NFT, <br />" +
    "BCL Foundation sẽ tạo ra một thế giới siêu nghịch đảo được gọi là Maxform. <br />" +
    "Heros gặp nhau tại các quán cà phê hoặc trung tâm rèn luyện sức khỏe để nói chuyện và chơi trò chơi. <br />" +
    "Phương tiện tiền tệ được sử dụng trong hệ sinh thái này là BCL. <br />" +
    "Đây là tầm nhìn và mục tiêu của Bitcoin Legend.",
  kyc_title2: "Mục đích của Chứng nhận KYC",
  kyc_desc2:
    "Tổ chức BCL tiến hành xác minh KYC để tạo uy tín cho BCL <br />" +
    "được khai thác bởi tất cả người dùng thông qua máy chủ đám mây. <br />" +
    "Đây là quá trình trong đó một người khai thác bằng một thiết bị di động và chứng minh <br />" +
    "rằng họ là con người thật. <br />" +
    "Chúng tôi muốn ngăn chặn việc thu lợi bất chính bằng cách tạo tài khoản giả.",
  kyc_title3: "Thực hiện theo quy trình KYC",
  bep_input_name: "Tên đầu vào",
  bep_input_number: "Số đầu vào",
  bep_input_address: "Nhập địa chỉ",
  kyc_popup_title1: "[Giới hạn độ tuổi cho Chứng nhận KYC]",
  kyc_popup_desc1:
    "Để tuân thủ luật pháp quốc tế về thu thập dữ liệu của trẻ em và người chưa phải là người lớn, chứng nhận KYC bị hạn chế đối với những người trên 18 tuổi",
  kyc_popup_desc2:
    "Đối với những người dưới 18 tuổi, tổ chức BCL có kế hoạch thiết lập và triển khai một hệ thống riêng biệt với sự đồng ý của cha mẹ.",
  kyc_popup_desc3: "[Các lưu ý khi áp dụng KYC]",
  kyc_popup_desc4:
    "1. Nếu bạn đăng ký ngay cả khi bạn dưới 18 tuổi, sẽ xảy ra lỗi và chỉ có phí KYC là không được hoàn lại.",
  kyc_popup_desc5:
    "2. Tên thật phải khớp với tên trên thẻ căn cước quốc gia, hộ chiếu, giấy phép lái xe hoặc ID cử tri.",
  kyc_popup_desc6: "3. Email phải là email khi bạn đăng ký Bitcoin Legend.",
  kyc_popup_desc7:
    "4. Số điện thoại di động phải là số điện thoại mà bạn đã cài đặt ứng dụng Bitcoin Legend.",
  kyc_popup_desc8: "Tên thật",
  kyc_popup_desc9: "Email cho ứng dụng BCL",
  kyc_popup_desc10: "Số điện thoại  di động",
  kyc_popup_desc10_1: "Transaction Hash",
  kyc_popup_title2: "[Chuẩn bị KYC]",
  kyc_popup_desc11:
    "1. Tài liệu (ID quốc gia, Giấy phép lái xe, Hộ chiếu hoặc ID cử tri) chưa hết hạn.",
  kyc_popup_desc12:
    "Nếu bạn KYC với tài liệu hết hạn, sẽ xảy ra lỗi. Do đó, phí KYC sẽ không được hoàn lại.",
  kyc_popup_desc13: "2. Chúng tôi có thể sử dụng thông tin Tài liệu của bạn.",
  kyc_popup_desc14:
    "3. Địa chỉ, số điện thoại di động và thông tin quốc gia của bạn sẽ được bs sử dụng để xác minh KYC.",
  kyc_popup_desc15:
    "4. Thông tin này chỉ được sử dụng để xác minh KYC và được sử dụng để xác định xem bạn có thực sự sử dụng ứng dụng theo chính sách của Bitcoin Legend hay không.",
  kyc_popup_desc16: "Bạn đồng ý với các quyền sử dụng tương ứng.",
  kyc_popup_agree: "Đồng ý",
  kyc_popup_reject: "Từ chối",
  kyc_popup_kyc: "ĐI KYC",
  kyc_popup_title3: "[Chứng nhận KYC]",
  kyc_popup_desc17:
    "Sau khi điện thoại của bạn có tài liệu chưa hết hạn (ID quốc gia, Giấy phép lái xe, Hộ chiếu hoặc ID cử tri) và các ứng dụng được cài đặt, Hãy bắt đầu với xác minh KYC.",
  kyc_popup_desc18: "Phí xác minh KYC là 6 đô la.",
  kyc_popup_desc19: "Bạn có muốn tiếp tục xác minh KYC không?",
  kyc_result1:
    "Bạn đã là người dùng được KYC xác minh. Hãy truy cập ứng dụng và thanh toán trong ứng dụng theo đơn đặt hàng KYC.",
  kyc_result2: "Không có thông tin phù hợp.",
  kyc_popup_title4: "[Kết thúc xác minh KYC]",
  kyc_popup_desc20: "Cảm ơn bạn đã xác minh KYC.",
  kyc_popup_desc21:
    "Nếu quá trình xác minh KYC được hoàn tất bình thường, biểu tượng trên màn hình chính của pp của bạn sẽ thay đổi như sau.",
  kyc_popup_desc22:
    "Nếu nó không được thay đổi, điều đó có nghĩa là điểm chứng nhận được đánh giá là không phù hợp.",
  kyc_popup_desc23:
    "Những điều này sẽ được BCL Foundation xác minh theo cách thủ công một lần nữa.",
  kyc_popup_desc24: "Cảm ơn rất nhiều",
  kyc_popup_desc25: "Bằng chứng xác minh KYC",
  kyc_popup_pay: "thanh toán",
  kyc_result5:
    "Trong ứng dụng BitcoinLegend, hãy chuyển đến hồ sơ của bạn và đồng ý với KYC.",
  kyc_result6:
    "Đây là số điện thoại đã được xác minh KYC. Truy cập ứng dụng và thanh toán trong ứng dụng theo thứ tự KYC.",
  kyc_result7: "KYC đang được tiến hành.",
  kyc_popup_desc26: "※ Vui lòng bắt đầu với ứng dụng trước tiên",
  kyc_popup_desc27:
    "5. Nếu có thắc mắc, vui lòng đặt <a href='https://forms.gle/94ggLz5t1fUbh1Cq6' target='_blank' style='text-decoration: underline;'> <B> <underline> tại đây </underline> </B></a>.",
  kyc_result8:
    "Sử dụng chức năng thanh toán trong ứng dụng trong ứng dụng. (Phiên bản 1.4.8)",
  kyc_1: "[Thông báo về việc tạm ngừng xác minh KYC]",
  kyc_2:
    "Do phản đối mạnh mẽ của Stripe, nên việc hoàn tiền 100% KYC đang được tiến hành. <br /> <br /> Chúng tôi được thông báo rằng sẽ có sự chậm trễ từ 10 đến 15 ngày tùy thuộc vào công ty phát hành thẻ tín dụng theo các quốc gia. <br /> Nếu bạn không nhận được tiền hoàn lại sau đó, vui lòng liên hệ với công ty phát hành thẻ tín dụng của bạn. <br /> USDT, TRX, thanh toán trong ứng dụng (Google) đang được tiến hành bằng cách mở rộng thanh toán KYC.",
  nft_1: "Khuyến mại đặc biệt cho Danh sách trắng",
  nft_2: "☑Ranking Competition (Cập nhật thời gian thực)",
  nft_3: "● Vị trí đầu tiên",
  nft_4:
    "1. Quảng cáo Anh hùng thứ 6:“ You're Hero ”<br /> (ảnh và câu chuyện của bạn → sản xuất anh hùng + sản ​​xuất webtoon + rap) <br /> Anh hùng thứ nhất: https://youtu.be/s0FSVXZ-wSs < br /> Anh hùng thứ 2: https://youtu.be/8Al9l7YVLC4 <br /> Người thừa thứ 3 - 5: https://youtu.be/8Al9l7YVLC4 ",
  nft_5:
    "2. Phiên bản giới hạn 1.000NFT (Anh hùng thứ 6) với 26 vật phẩm <br /> 1.000NFT * 0,1ETH (giá trong danh sách trắng) = 100ETH <br /> Phần thưởng 5% trên số lượng bán hàng (5ETH)",
  nft_6: "● Vị trí thứ 2 - 3",
  nft_7:
    "Vị trí thứ 2) 5 NFT bổ sung + 2% phần thưởng cho Anh hùng thứ 6 (2ETH) <br /> hạng 3) 3 NFT bổ sung + 1% phần thưởng cho Anh hùng thứ 6 (1ETH)",
  nft_8: "● Vị trí thứ 4-10",
  nft_9: "2 NFT bổ sung",
  nft_10: "* NFT trả thêm sẽ được thanh toán bằng NFT hiện đang bán.",
  nft_11: "☑ Khuyến mại thành viên",
  nft_12: "● Bạch kim (Mua từ 21NFT trở lên)",
  nft_13:
    "Tăng tốc độ khai thác (+ 24BCL / ngày), ưu tiên KYC, NFT + 1ea / 20ea",
  nft_14: "● Vàng (Mua 11 ~ 20 NFT)",
  nft_15: "Tăng tốc độ khai thác (+24 BCL / ngày), ưu tiên KYC, NFT + 1",
  nft_16: "● Bạc (Mua 5-10 NFT)",
  nft_17: "Tăng tốc độ khai thác (+24 BCL / ngày), Mức độ ưu tiên KYC",
  nft_18: "● Đồng (Mua 1 ~ 4NFT)",
  nft_19: "Tăng tốc độ khai thác (24BCL / ngày)",
  nft_20:
    "Thẻ Maxform NFT Hero được phát triển bởi nhóm BitcoinLegend Core. <br />" +
    "Nó bao gồm các BỘ SƯU TẬP KỸ THUẬT SỐ DUY NHẤT trên chuỗi khối Ethereum ERC-721. <br />" +
    "<br />" +
    "Năm anh hùng được tái sinh bằng thẻ NFT. Nó được thiết kế với quan niệm rằng bất kỳ người bình thường nào cũng có thể trở thành anh hùng. <br />" +
    "Đó là một nửa trang phục bình thường và một nửa trang phục anh hùng. <br />" +
    "<br />" +
    "Điều này thậm chí còn độc đáo hơn bởi chương trình hiếm. Không có hình thức giống hệt nhau trong số các thẻ đã phát hành. Tóc, quần áo, vũ khí, phụ kiện, mặt nạ, lý lịch, v.v. Tất cả các vật phẩm đều khác nhau. <br />" +
    "Trong số đó, không có một nhân vật mà có tổng cộng 5 nhân vật ở các tư thế và tư thế khác nhau. <br />" +
    "Đối với mỗi nhân vật như vậy, 2000 thẻ anh hùng được phát hành. <br />" +
    "Cách tiếp cận khác với các NFT khác là tạo ra 10.000 mảnh với một ký tự. <br />" +
    "Theo cách này, tổng cộng năm ký tự xuất hiện trong 2000 mỗi ký tự, tạo nên tổng số 10.000 thẻ. <br />" +
    "Bạn sẽ có vật phẩm hiếm và độc nhất của mình.",
  kyc_result9:
    "Đây là địa chỉ Tron đã được kiểm tra. Không cần thanh toán thêm.",
  kyc_result10: "Kiểm tra tài khoản",
  kyc_result11: "Kiểm tra địa chỉ email của bạn",
  kyc_result12: "Thanh toán, xác minh KYC đã hoàn tất",
  kyc_result13: "Thanh toán chưa hoàn tất, KYC đã hoàn tất",
  kyc_result14: "Thanh toán chưa hoàn thành, KYC chưa hoàn thành",
  kyc_result15:
    "Thanh toán hoàn tất, KYC chưa hoàn thành. Vào ứng dụng và tiến hành KYC theo thứ tự KYC",
  kyc_result16:
    "5. Đối với USDT, bạn cần gửi 6 đô la vào ví nền tảng. <font color = 'red'> (Hãy nhớ gửi vào ví cá nhân của bạn) </font>",
  kyc_result17: "- Địa chỉ USDT: TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG",
  kyc_result18: "- Chi phí: USDT 6 $",
  kyc_result19:
    "Quá trình xác minh KYC đã được hoàn tất, nhưng chi tiết tiền gửi chưa được xác nhận. Xác nhận số tiền gửi bằng USDT và TRON.",
  kyc_result20:
    "Xác minh KYC chưa được hoàn tất. Chi tiết tiền gửi không được xác nhận. Xác nhận số tiền gửi bằng USDT và TRON.",
  kyc_result21: "[Thông báo]",
  kyc_result22:
    "Xác thực KYC hiện đang bị tạm ngưng do sự cố máy chủ của Passbase, một công ty xác thực KYC.<br /><br />Chúng tôi đang liên hệ với Passbase và sẽ thông báo lại cho bạn khi máy chủ KYC hoạt động bình thường. <br /><br />Sau khi thanh toán phí chứng nhận KYC, chúng tôi sẽ thực hiện hành động để những người dùng chưa thực hiện KYC có thể nhận được chứng nhận KYC mà không phải trả thêm phí.",
  kyc_result23:
    "[Để ý]\nKhi gửi tiền bằng UDST và Tron, vui lòng đặt cọc không bao gồm phí gas thông thường * 6USDT * 100TRX",
  kyc_result24: "[Thông báo liên quan đến xác minh và thanh toán KYC]",
  kyc_result25:
    "1. Xác minh KYC bị trì hoãn <br />" +
    "Khi xác minh KYC không được xác minh do lỗi người dùng, chúng tôi đã thành lập một nhóm xác minh thủ công riêng để xác minh. (Khi xác minh KYC hoàn tất, nó sẽ chuyển sang biểu tượng màu xanh lục; <br />" +
    "Chúng tôi xin lỗi vì đã tiếp tục khiến bạn nhầm lẫn với các công việc liên quan đến thanh toán KYC. Vui lòng dừng thanh toán trong ứng dụng KYC một thời gian. <br />" +
    "<br />" +
    "2. Lỗi thanh toán KYC và hoàn lại tiền <br />" +
    "1) Rất khó để chúng tôi trả lời 1: 1 vì chúng tôi đang xử lý khoản hoàn trả đầy đủ cho thanh toán gấp đôi hoặc thiếu sót trong quá trình thanh toán theo dải. Vui lòng đợi cho đến khi khoản hoàn trả được xử lý. <br />" +
    "3) Nếu thanh toán đã được thực hiện hai lần do không đủ thanh toán bằng USDT và Tron, chúng tôi sẽ trả lời sau khi kiểm tra thủ công. <br />" +
    "3) Đã xảy ra hoàn tiền trong ứng dụng do nhóm cốt lõi BCL của chúng tôi bỏ sót mã xác nhận trong ứng dụng. Hiện tại, đang tiến hành mã hóa xác nhận trong ứng dụng. Vui lòng ngừng thanh toán trước khi thông báo phiên bản cập nhật mới. <br />" +
    "4) USDT và Tron có thể tiến hành thanh toán KYC. <br />" +
    "<br />" +
    "* Trường hợp trên cần huy động nhiều nhân lực nên BCL Foundation đã đảm bảo nhân lực và đang xử lý hết sức có thể, mong các bạn chờ. <br />" +
    "* Việc quyết toán và rút BCL sẽ được tiến hành sau khi hoạt động bình thường của doanh nghiệp trên được thực hiện.",
  kyc_result26: "Đây là email hoặc số điện thoại đã được xác minh KYC.",
  kyc_result27: "Không có thông tin điện thoại phù hợp.",
  kyc_result28: "Lịch sử gửi tiền <br /> Ảnh",
  kyc_result29:
    "Yêu cầu của bạn đã được hoàn thành. Vui lòng đợi 1-2 ngày làm việc để xử lý khoản tiền gửi của bạn.",
  bep_input_block: "Vui lòng nhập BLOCK",
  kyc_result30:
    "Vui lòng viết khối địa chỉ nơi bạn gửi tiền. <a href='https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers' target=\"_new\"> ĐI </a>",
  kyc_result31:
    'Chỉ những người dùng có biểu tượng "KYC Không thành công" được hiển thị trong ứng dụng mới có thể đăng ký.',
  kyc_result32: "Không có kết quả KYC",
  kyc3_1: "Các lưu ý khi áp dụng KYC",
  kyc3_2: "※ Cập nhật lên ứng dụng mới nhất trước khi tiến hành KYC.",
  kyc3_3: "Bạn đã đặt cọc, có vấn đề gì không?",
  kyc3_4: "Vui lòng chọn phương thức thanh toán mà bạn đã đặt cọc và bù đắp cho các tài liệu còn thiếu.",
  kyc3_5: "sao chép hoàn tất",
  kyc3_6: "Vui lòng chọn loại tiền điện tử bạn đã gửi.",
  kyc3_7: "Liên kết ví của bạn",
  kyc3_8: "Vui lòng nhập email",
  kyc3_9: "1. Gửi tiền từ ví cá nhân của bạn đến địa chỉ bên dưới và chọn loại tiền điện tử bạn đã gửi.",
  kyc3_10: "Chuyển đến Tiếp theo",
  kyc3_11: "2. Dán giá trị băm",
  kyc3_12: "Nơi dán giá trị băm đã sao chép",
  kyc3_13: "3. Nhập địa chỉ email đã đăng ký trong ứng dụng Bitcoinlegend.",
  kyc3_14: "4. Thanh toán đã được thực hiện.",
  kyc3_15: "Tiến hành xác thực KYC trên ứng dụng Bitcoinlegend.",
  kyc3_16: "4. Nó chưa được ký gửi.",
  kyc3_17: "Vui lòng gửi lại phí KYC.",
  kyc3_18: "Đi đặt cọc",
  kyc3_19: "tiền gửi, nhưng có một vấn đề",
};
