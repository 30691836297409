import zh from './zh';
import en from './en';
import ko from './ko';
import vn from './vn';
import ar from './ar';

import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({ ko, en, zh, vn, ar });

try {
    let a = window.localStorage.getItem("GlobalState");
    let b = JSON.parse(a);
    console.log(b.lang);
    strings.setLanguage(b.lang);
} catch (e) {
    strings.setLanguage("en");
}
export default strings;
