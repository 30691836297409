import {inject, observer} from 'mobx-react';
import React from 'react';
import {withRouter} from 'react-router-dom';
import Const, {BASE_URL, BCL_CONTRACT_ADDRESS} from "../../config/const";
import {PageSettings} from "../../config/page-settings";
import strings from '../../lang/strings';
import './nft-intro.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from "swiper";
import 'swiper/swiper-bundle.css';
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import axios from "axios";
import VerifyButton, {start} from "@passbase/verify-in-browser/react";
import {Modal, ModalBody} from "reactstrap";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import {loadStripe} from "@stripe/stripe-js";
import Swal from 'sweetalert2'
import {ClipLoader, PacmanLoader, PulseLoader} from "react-spinners";
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal,Web3Button } from '@web3modal/react'
import { configureChains, createClient, WagmiConfig } from 'wagmi'
import { mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom } from 'wagmi/chains'

// import {
//   PayPalScriptProvider,
//   PayPalHostedFieldsProvider,
//   PayPalHostedField,
//   usePayPalHostedFields,
// } from "@paypal/react-paypal-js";

const moment = require('moment');

const youtube = ['https://www.youtube.com/embed/gfjBqRpmh6U', 'https://www.youtube.com/embed/8Al9l7YVLC4', 'https://www.youtube.com/embed/s0FSVXZ-wSs'];
// const stripePromise = loadStripe("pk_test_51LI7nzJ13Kc9U5mkVZflUq2QFnNzkP9GbFnvev2mU4pg89wtdnk0M4XrTo2x0QWYJ6HDx8jAVOjevexD0J6rs1lW008Zg3HIFx");
// const stripePromise = loadStripe("pk_live_51LI7nzJ13Kc9U5mkQP8icxVCs7zdbOUolGoYKjwyCkGKDJY3YqsC592nOaFqKg5ApyGpDtOKb4rjid9dtLIr1lg000Cgu5gPxS");

var ua = navigator.userAgent.toLowerCase();
var isSafari = false
if (ua.indexOf('safari') != -1 || ua.indexOf('iphone') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isSafari = false
  } else {
    isSafari = true
  }
}


const columns = [
  {
    field: "id",
    headerName: "Rank",
    flex: 2
  },
  {
    field: "from",
    headerName: "Address",
    flex: 2
  },
  {
    field: "amt",
    headerName: "Quantity",
    flex: 2
  },
  {
    field: "cnt",
    headerName: "Count",
    flex: 2
  }
];

const queryParams = new URLSearchParams(window.location.search)
const success = queryParams.get("success")

const chains = [mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom]
const projectId = 'af08c6e0d4815705e1e4cdf18d577e8e'

const { provider } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiClient = createClient({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 1, chains }),
    provider
})
const ethereumClient = new EthereumClient(wagmiClient, chains)

class Kyc extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      showGuideModal: false,
      inputEmail: '',
      inputName: '',
      inputNumber: '',
      step1: true,
      step1_1: true,
      step1_2: false,
      step1_3: false,
      step1_4: false,
      step1_5: false,
      inputTronAddress: '',
      block: '',
      step1_payment: false,
      step1_confirm: true,
      payType: 1,
      step2: false,
      step3: false,
      step4: false,
      key: null,
      option: {},
      clientSecret: '',
      payed: false,
      showStripe: false,
      ip: '',
      body: null,
      loading: false,
      // step1: true,
      // step2: false,
      // step3: false,
      // step4: false,
    };
    this.iframeInt = 0;
    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;

  }

   getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    console.log(res.data);
     this.setState({ip: res.data.IPv4});
  }

  componentDidMount() {
    this.getData();
    if (success == "true") {
      this.setState({step1: false});
      this.setState({step1_payment: false});
      this.setState({step1_confirm: true});

      this.setState({step2: false});
      this.setState({step3: false});
      this.setState({step4: true});
    }
    console.log(window.location.pathname)
    const query = new URLSearchParams(window.location.search);
    const imp_success = query.get('imp_success');
    const error_msg = query.get('error_msg');
    console.log(imp_success);
    if (imp_success != null && imp_success != '') {
      if (imp_success != 'false') {
        alert('SUCCESS');
      } else {
        alert('Fail');
      }
    }
    const _clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (_clientSecret) {
      this.setState({
        option: {
          clientSecret: _clientSecret,
          theme: 'stripe'
        }
      });
      this.setState({clientSecret: _clientSecret});
      this.setState({showStripe: true});
    }
    try {
      let inputEmail = localStorage.getItem('inputEmail');
      if (!inputEmail) {
        return;
      }
      this.setState({inputEmail: inputEmail});
    } catch (e) {
      console.log(e);
    }

    try {
      let inputName = localStorage.getItem('inputName');
      if (!inputName) {
        return;
      }
      this.setState({inputName: inputName});
    } catch (e) {
      console.log(e);
    }

    try {
      let inputNumber = (localStorage.getItem('inputNumber'));
      console.log(inputNumber);
      if (!inputNumber) {
        return;
      }
      this.setState({inputNumber: inputNumber});
    } catch (e) {
      console.log(e);
    }
  }

  handleClose() {
    this.setState({showStripe: false});
  }

  checkStripe = () => {
    let params = new URLSearchParams();
    params.append('email', this.state.inputEmail.trim());
    params.append('type', 'kyc');


    axios.post(`${BASE_URL}/payment/create-payment-intent`,
      params)
      .then((res) => {
        console.log(res.data.clientSecret);
        this.setState({
          option: {
            clientSecret: res.data.clientSecret,
            theme: 'stripe'
          }
        });
        this.setState({clientSecret: res.data.clientSecret});

      });
  }

  checkAccount = () => {
    if (this.state.inputEmail.trim() == "") {
      alert(strings.bep_input_nft_email)
      return
    }
    let params = new URLSearchParams();
    params.append('email', this.state.inputEmail.trim());
    axios.post(`${BASE_URL}/user/checkEmail`,
      params)
      .then((res) => {
        if (res.data.userKey == -2) {
          alert(strings.kyc_result11);
        }
        if (res.data.is_kyc == -2) {
          alert(strings.kyc_result7);
        }
        if (res.data.is_kyc == 1 &&  res.data.pay_new_kyc == 1) {
          alert(strings.kyc_result12);
        }
        if (res.data.is_kyc == 1 &&  res.data.pay_new_kyc == 0) {
          alert(strings.kyc_result13);
        }
        if (res.data.is_kyc == 0 &&  res.data.pay_new_kyc == 0) {
          alert(strings.kyc_result14);
        }
        if (res.data.is_kyc == 0 &&  res.data.pay_new_kyc == 1) {
          alert(strings.kyc_result15);
        }
        console.log(res.data);
      });
  }

  goStep2 = (payType) => {
    // const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
    // const walletConnect = window.localStorage.getItem("wagmi.connected");

    // if(!walletAccount || !walletConnect)
    // {
    //     walletAccount['state']['data'] && alert(strings.bep_input_bep_from);
    //     return;
    // }
    // if(!walletAccount['state']['data']['account'])
    // {
    //     alert(strings.bep_input_bep_from);
    //     return;
    // }
    if (this.state.inputEmail.trim() == "") {
      alert(strings.bep_input_nft_email)
      return
    }
    if (this.state.inputName.trim() == "") {
      alert(strings.bep_input_name)
      return
    }
    if (this.state.inputNumber.trim() == "") {
      alert(strings.bep_input_number)
      return
    }

    if (this.state.payType == 1) {
      alert(strings.kyc_result23);
      if (this.state.inputTronAddress.trim() == "") {
        alert(strings.bep_input_address)
        return
      }
      // if (this.state.inputTronAddress.trim() == "") {
      //   alert(strings.bep_input_block)
      //   return
      // }
    }

    localStorage.setItem('inputEmail', this.state.inputEmail.trim());
    localStorage.setItem('inputName', this.state.inputName.trim());
    localStorage.setItem('inputNumber', this.state.inputNumber.trim());
    localStorage.setItem('inputTronAddress',this.state.inputTronAddress.trim());
    // localStorage.setItem('block',this.state.block.trim());
    let params = new URLSearchParams();
    params.append('email', this.state.inputEmail.trim());
    params.append('name', this.state.inputName.trim());
    params.append('phone', this.state.inputNumber.trim());
    params.append('address', this.state.inputTronAddress);
    // params.append('from', walletAccount['state']['data']['account']);
    // params.append('block', this.state.block);
    if (this.state.payType == 2) {
      axios.post(`${BASE_URL}/user/checkAuth`,
        params)
        .then((res) => {
          console.log(res.data);
          if (res.data.userKey == -3) {
            alert(strings.kyc_result26)
          } else if (res.data.userKey == -99) {
              alert(strings.kyc_result27)
          } else {
            if (res.data.pay_new_kyc == 1 && res.data.is_kyc == 0) {
              this.setState({payed: true});
              this.setState({step1_payment: false});
              this.setState({step1_confirm: true});

              this.setState({step1: false});
              this.setState({step2: true});
              this.setState({step3: false});
              this.setState({step4: false});
            } else if (res.data.pay_new_kyc == 1 && res.data.is_kyc == 1) {
              alert(strings.kyc_result12);
            } else {
              alert(strings.kyc_result8);
            }
          }
          // if (res.data.userKey == -1) {
          //   alert(strings.kyc_result4);
          // } else if (res.data.userKey == -2) {
          //   alert(strings.kyc_result3);
          // } else if (res.data.userKey == -3) {
          //   alert(strings.kyc_result6);
          // } else if (res.data) {
          //   if (res.data.is_kyc == 1) {
          //     alert(strings.kyc_result1);
          //   } else if (res.data.be_kyc == 0) {
          //     alert(strings.kyc_result5);
          //   } else if (res.data.is_kyc == -2) {
          //     alert(strings.kyc_result7);
          //   } else {
          //     if (res.data.pay_kyc == 1) {
          //       if (payType == 1) {
          //         // if (this.state.ip == "175.197.43.10") {
          //         //   this.checkStripe();
          //         //   this.setState({showStripe: true});
          //         // } else {
          //         //   alert(strings.kyc_result8)
          //         // }
          //         this.goStep3();
          //         // this.checkStripe();
          //         // this.setState({showStripe: true});
          //         return;
          //       } else {
          //         return
          //       }
          //     }
          //     this.setState({payed: res.data.pay_kyc == 0 ? false : true});
          //     this.setState({step1_payment: false});
          //     this.setState({step1_confirm: true});
          //
          //     this.setState({step1: false});
          //     this.setState({step2: true});
          //     this.setState({step3: false});
          //     this.setState({step4: false});
          //   }
          // } else {
          //   alert(strings.kyc_result2);
          // }
          // if (res.data.data === "success") {
          //   alert(strings.bep_request_complete)
          // } else if (res.data.data === "checked") {
          //   alert(strings.nft_request_complete)
          // } else {
          //   alert(strings.bep_request_check)
          // }
        })
        .catch((err) => {
          console.log(err);
          // alert(strings.not_match_bep)
        });
    }
    // if (this.state.payType == 1) {
    //   axios.post(`${BASE_URL}/user/checkAuthUSDTCheck`, params).then((result) => {
    //     const formData = new FormData();
    //     formData.append("file", this.state.body);
    //     axios
    //       .post(
    //         `${BASE_URL}/user/checkAuthUSDTCheckPhoto/` + result.data.id,
    //         formData,
    //         {
    //           headers: {
    //             Authorization:
    //               "Bearer " +
    //               "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MTE5NjM1OSwiZXhwIjoxNjgyNzMyMzU5fQ.5ZxqvUdLOS8zrbCZuDqZqv4Zjox1POUrZ0Ah0u9LEbs",
    //           },
    //         }
    //       ).then((r) => alert(strings.kyc_result29));
    //   }).catch((err) => console.log(err));
    // }
    // return ;
    if (this.state.payType == 1) {
      axios.post(`${BASE_URL}/user/checkAuthUSDTV2`,
        params)
        .then((res) => {
          console.log(res.data);
          if (res.data.userKey == -1) {
            alert(strings.kyc_result4);
          } else if (res.data.userKey == -2) {
            alert(strings.kyc_result3);
          } else if (res.data.userKey == -3) {
            alert(strings.kyc_result6);
          } else if (res.data.userKey == -4) {
            alert(strings.kyc_result9);
          } else if (res.data.userKey == -5) {
            alert(strings.kyc_result2);
          } else if (res.data) {
            if (res.data.is_kyc == 1 && res.data.pay_new_kyc == 1) {
              alert(strings.kyc_result1);
            } else if (res.data.is_kyc == 1 && res.data.pay_new_kyc == 0) {
              alert(strings.kyc_result19);
            } else if (res.data.is_kyc == 0 && res.data.pay_new_kyc == 0) {
              alert(strings.kyc_result20);
            } else if (res.data.be_kyc == 0) {
              alert(strings.kyc_result5);
            } else if (res.data.is_kyc == -2) {
              alert(strings.kyc_result7);
            } else {
              // if (res.data.pay_new_kyc == 0) {
                // if (payType == 1) {
                //   // if (this.state.ip == "175.197.43.10") {
                //   //   this.checkStripe();
                //   //   this.setState({showStripe: true});
                //   // } else {
                //   //   alert(strings.kyc_result8)
                //   // }
                //   // this.checkStripe();
                //   // this.setState({showStripe: true});
                //   return;
                // } else {
                //   return
                // }
              // }
              this.setState({payed: res.data.pay_new_kyc == 0 ? false : true});
              this.setState({step1_payment: false});
              this.setState({step1_confirm: true});

              this.setState({step1: false});
              this.setState({step2: true});
              this.setState({step3: false});
              this.setState({step4: false});
            }
          } else {
            alert(strings.kyc_result2);
          }
          // if (res.data.data === "success") {
          //   alert(strings.bep_request_complete)
          // } else if (res.data.data === "checked") {
          //   alert(strings.nft_request_complete)
          // } else {
          //   alert(strings.bep_request_check)
          // }
        })
        .catch((err) => {
          console.log(err);
          // alert(strings.not_match_bep)
        });
    }
    //
    // this.setState({ step1: false });
    // this.setState({ step2: true });
    // this.setState({ step3: false });
    // this.setState({ step4: false });
  }

  goStep1 = () => {
    this.setState({step1: true});
    this.setState({step1_payment: false});
    this.setState({step1_confirm: true});

    this.setState({step2: false});
    this.setState({step3: false});
    this.setState({step4: false});
  }

  onCopy() {
    // alert(strings.kyc_result22.replace(/(<br \/>)/ig, '\n'));
    var copyText = document.querySelector("#tron_address");
    function handler (event){
      event.clipboardData.setData('text/plain', copyText.value);
      event.preventDefault();
      document.removeEventListener('copy', handler, true);
    }

    document.addEventListener('copy', handler, true);
    document.execCommand('copy');
    setTimeout(() => {
      alert("Copied");
    }, 500)
  }

  // onBodyChange = (e: any) => {
  //   console.log(e.target.files[0]);
  //    this.setState({ body: e.target.files[0]});
  // };

  goStep1Payment = () => {
    this.setState({step1: true});
    this.setState({step1_payment: true});
    this.setState({step1_confirm: false});

    this.setState({step2: false});
    this.setState({step3: false});
    this.setState({step4: false});
  }

  goStep3 = () => {
    if (this.state.payed) {
      axios.get(`${BASE_URL}/user/passbase-token/${this.state.inputEmail}`, null)
        .then((res) => {
          console.log(res.data);
          this.setState({key: res.data.token});
        })
        .catch((err) => {
          console.log(err);
          // alert(strings.not_match_bep)
        });
    }
    this.setState({step1: false});
    this.setState({step1_payment: false});
    this.setState({step1_confirm: true});

    this.setState({step2: false});
    this.setState({step3: true});
    this.setState({step4: false});

  }

  goStep4 = () => {
    axios.get(`${BASE_URL}/user/passbase-token-ing/${this.state.inputEmail}`, null)
      .then((res) => {
        console.log(res.data);
        this.setState({key: res.data.token});
      })
      .catch((err) => {
        console.log(err);
        // alert(strings.not_match_bep)
      });
    this.setState({step1: false});
    this.setState({step1_payment: false});
    this.setState({step1_confirm: true});

    this.setState({step2: false});
    this.setState({step3: false});
    this.setState({step4: true});
  }

  componentWillUnmount() {
    const meta = document.createElement('meta')
    meta.name = 'google'
    meta.content = 'notranslate'
    document.getElementsByTagName('head')[0].appendChild(meta)
  }

  render() {
    return (

      <div id="kyc" className="nft-main notranslate">
        <div style={{ position:'fixed', top:'calc(50% - 80px)', left:'calc(50% - 140px)', 
                      width:'280px', height:'200px', zIndex:'11', backgroundColor:'#fff'
                      ,textAlign:'center',lineHeight:'40px', borderRadius: '10px'
                    }}>
          We are renewing and <br />correcting errors.<br />Estimated period 2023-05-11<br />
          Don't send USDT and TRX util fixing errors, please.
        </div>
        <div style={{ position:'fixed', top:'0', left:'0', 
                      width:'100vw', height:'100vh', zIndex:'9', backgroundColor:'#000'
                      ,opacity:'0.5'
                    }}></div>
        <div className="content about2" data-scrollview="true" style={{paddingBottom: 0}}>
          <div className="content-title"
               style={{marginTop: this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "0" : "5vh"}}>{strings.kyc_certification}</div>
        </div>
        <div className="airdrop-content media-partners" data-scrollview="true"
             style={{backgroundColor: "#272831", paddingTop: 0}}>
          <div className="section-title" style={{marginTop: 30}}>{strings.kyc_title2}</div>
          <div className="content-desc m-t-15" style={{textAlign: "center"}}
               dangerouslySetInnerHTML={{__html: strings.kyc_desc1}}/>
          <div className="section-title" style={{marginTop: 30}}>{strings.kyc_title3}</div>
          <div className="content-desc m-t-15" style={{textAlign: "center"}}
               dangerouslySetInnerHTML={{__html: strings.kyc_desc2}}/>
        </div>
        <div className="airdrop-content media-partners" data-scrollview="true"
             style={{backgroundColor: "#272831", width: '100%', textAlign: 'center', paddingTop: 0}}>
          {this.state.step1 &&
          <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
            marginTop: 15,
            marginBottom: 15,
            paddingTop: 30,
            paddingBottom: 30,
            // backgroundImage: 'url(/assets/img/img_kyc_popup_back.png)',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
            borderRadius: 30,
            width: '50vw',

            paddingLeft: '5vw',
            paddingRight: '5vw',
          } : {
            marginTop: 15,
            width: '100vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            paddingBottom: '5vh',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
          })}>

            <div className="content-desc1 m-t-15" style={{textAlign: "center", color: 'blue'}}>
              {strings.kyc_popup_title1}
            </div>
            <div className="content-desc1 m-t-5" style={{textAlign: "center", color: 'blue'}}>
              {strings.kyc_popup_desc26}
            </div>
            <div className="content-desc1 m-t-5" style={{textAlign: "center"}}
                 dangerouslySetInnerHTML={{__html: "<a href='https://play.google.com/store/apps/details?id=com.bitlegendapp' target='_blank'>[ ANDROID ]</a>   &nbsp;&nbsp;&nbsp; <a href='https://apps.apple.com/us/app/bitcoinlegend/id1591021356' target='_blank'>[ IOS ]</a>"}}/>

            <div className="content-desc1 m-t-5" style={{color: 'black'}}>
              {strings.kyc_popup_desc1}<br/>
              <br/>
              {strings.kyc_popup_desc2}
            </div>
            <div className="content-desc1 m-t-5" style={{color: 'blue'}}>
              {strings.kyc_popup_desc3} <br/>
              {strings.kyc_popup_desc4}<br/>
              {strings.kyc_popup_desc5}<br/>
              {strings.kyc_popup_desc6}<br/>
              {strings.kyc_popup_desc7}<br/>
              {/*<div className="content-desc1 m-t-5" style={{color: 'blue'}}*/}
              {/*     dangerouslySetInnerHTML={{__html: strings.kyc_popup_desc27}}/>*/}
              {this.state.payType == 1 &&
                <>
              <div className="content-desc1" style={{color: 'blue'}}
                   dangerouslySetInnerHTML={{__html: strings.kyc_result16}}/>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                <div className="content-desc1" style={{color: 'blue',marginLeft: 20}}
                dangerouslySetInnerHTML={{__html: strings.kyc_result17}}  onClick={() => this.onCopy()} />
                  <a className="link-action" target="_blank"
                     // onClick={() => this.onCopy()}
                     href={`https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers`}>
                    &nbsp; <img
                    src="/assets/img/ic_link.png" srcSet="/assets/img/ic_link.png 1x" />
                  </a>
                  </div>
                <div className="content-desc1" style={{color: 'blue',marginLeft: 20}}
                dangerouslySetInnerHTML={{__html: strings.kyc_result18}}/>
                  <div className="content-desc1" style={{color: 'blue',marginLeft: 20}}>
                <input id="tron_address" ref={input => this.inputElement = input} value="TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG" type="hidden" readOnly={true} />
                  </div>
                </>
              }
              <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
                <a className="link-action" target="_blank" onClick={() => {

                    Swal.fire({
                      title: 'Input BCL App EMAIL',
                      input: 'text',
                      inputAttributes: {
                        autocapitalize: 'off'
                      },
                      showCancelButton: true,
                      confirmButtonText: 'Look up',
                      showLoaderOnConfirm: true,
                      preConfirm: (login) => {
                        return axios.get(`https://pay.bitcoinlegend.org/admin/api/checkPayedKYC/2?email=${login}`)
                          .then(response => {
                            console.log(response);
                            if (response.data.ok==-1) {
                              throw new Error(strings.kyc_result3 + ' (USDT Paid users only)')
                            }
                            return response
                          })
                          .catch(error => {
                            Swal.showValidationMessage(
                              `Request failed: ${error}`
                            )
                          })
                      },
                      allowOutsideClick: () => !Swal.isLoading()
                    }).then((result) => {
                      if (result.isConfirmed) {
                        const anchor = document.createElement('a');
                        anchor.href = 'https://forms.gle/nF3pcQ7naj9uKiKm7';
                        anchor.target="_blank";
                        anchor.click();
                      }
                    })
                  }}
                   // href={`https://forms.gle/nF3pcQ7naj9uKiKm7`}
                >
                <img src="/assets/img/img_icon_usdt_in.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "50%"} : { width: "30%" }} />
                </a>
                <a className="link-action" target="_blank" onClick={() => {
                  Swal.fire({
                    title: 'Input BCL App EMAIL',
                    input: 'text',
                    inputAttributes: {
                      autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: 'Look up',
                    showLoaderOnConfirm: true,
                    preConfirm: (login) => {
                      return axios.get(`https://pay.bitcoinlegend.org/admin/api/checkPayedKYC/1?email=${login}`)
                        .then(response => {
                          console.log(response);
                          if (response.data.ok==-1) {
                            throw new Error(strings.kyc_result3 + ' (In-app Paid users only)')
                          }
                          return response
                        })
                        .catch(error => {
                          Swal.showValidationMessage(
                            `Request failed: ${error}`
                          )
                        })
                    },
                    allowOutsideClick: () => !Swal.isLoading()
                  }).then((result) => {
                    if (result.isConfirmed) {
                      const anchor = document.createElement('a');
                      anchor.href = 'https://forms.gle/augtSKNc2MoRhFxj8';
                      anchor.target="_blank";
                      anchor.click();
                    }
                  })

                }}>
                <img src="/assets/img/img_icon_inapp_in.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "50%"} : { marginLeft: 30, width: "30%" }}/>
                </a>
              </div>
            </div>
            <div className="content-desc1 m-t-5" style={{color: 'black', textAlign: 'center',display: 'flex'}}>
              <div className="content-desc1 m-t-5" style={{color: 'black', textAlign: 'center',width: '100%'}} onClick={(e) => {
                alert(strings.kyc_result23);
                this.setState({payType: 1});
              }} >
                {this.state.payType == 1 &&
                 <>
                  <input type='radio' name='payType' value='1' checked /> USDT(TRON20)
                 </>
                }
                {this.state.payType == 2 &&
                <>
                  <input type='radio' name='payType' value='1'  /> USDT(TRON20)
                </>
                }
                <br />
                <div style={{ fontSize: "small",color: '#AAAAAA', marginLeft: 20,overflowWrap: "break-word" }} >
                  TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG<br />
                  COST : USDT 6$
                </div>
              </div>
              {/*<div className="content-desc1 m-t-5" style={{marginLeft: 30, color: 'black', textAlign: 'left',width: '50%'}} onClick={(e) => {*/}
              {/*  this.setState({payType: 2});*/}
              {/*}} >*/}
              {/*  {this.state.payType == 1 &&*/}
              {/*  <>*/}
              {/*  <input type='radio' name='payType' value='2' /> CARD PAYMENT*/}
              {/*  </>*/}
              {/*  }*/}
              {/*  {this.state.payType == 2 &&*/}
              {/*  <>*/}
              {/*    <input type='radio' name='payType' value='2' checked /> CARD PAYMENT*/}
              {/*  </>*/}
              {/*  }*/}
              {/*  <br />*/}
              {/*  <div style={{ fontSize: "small",color: '#AAAAAA', marginLeft: 20 }} >*/}
              {/*  Waiting Please. <br />*/}
              {/*  Developing other payment except Stripe*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            {/* <WagmiConfig client={wagmiClient}>
                <Web3Button />
                </WagmiConfig>
                <Web3Modal projectId={projectId} ethereumClient={ethereumClient} /> */}
            <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
              <PacmanLoader color={"#36d7b7"} size={50} loading={this.state.loading} />
            </div>
            <div className="content-desc1 m-t-5" style={{textAlign: "center",}}>
              <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
                width: "33vw",
                display: "-webkit-inline-box",
                flexWrap: "wrap",
              } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>
                <div style={{
                  width: "29%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                  border: "1px solid black",
                 whiteSpace: "pre-line",
                }} >{strings.kyc_popup_desc8} <img src="/assets/img/question.png"
                                                   style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "5vw",  } : {}}
                                                   onClick={() => {
                                                     Swal.fire({
                                                       html: "",
                                                       imageUrl: '/assets/img/img_name_step1.png',
                                                       imageAlt: 'name'
                                                     })
                                                   }} />
                </div>
                <div style={{
                  width: "64%",
                  background: "#FFFFFF",
                  // height: "5vh",
                  border: "1px solid black",
                  padding: 5,
                }}>
                  <input type="text" placeholder="somalin"
                         value={this.state.inputName} onChange={(e) => {
                    this.setState({inputName: e.target.value});
                  }} style={{width: "100%", height: "100%", textAlign: "left"}}/>
                </div>

                <div style={{
                  width: "4%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                }}><img src={ this.state.step1_2 ?  "/assets/img/right_on.png"  :  "/assets/img/right.png" }
                        style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: 30} : { width: 50 }}
                        onClick={() => {
                          this.setState({loading: true});
                          if (!this.state.step1_2) {
                            if (this.state.inputName.trim() == "") {
                              alert(strings.bep_input_name);
                              this.setState({loading: false});
                              return
                            }
                            let params = new URLSearchParams();
                            params.append('name', this.state.inputName.trim());

                            axios.post(`${BASE_URL}/user/checkName`,
                              params)
                              .then((res) => {
                                if (parseInt(res.data) === 1) {
                                  this.setState({step1_2: true});
                                }
                              }).finally(() => {
                              this.setState({loading: false});
                            })
                          }
                        }}
                />
                </div>
              </div>
            </div>
            {this.state.step1_2 &&
            <div className="content-desc1 m-t-5" style={{textAlign: "center",}}>
              <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
                width: "33vw",
                display: "-webkit-inline-box",
                flexWrap: "wrap",

              } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>
                <div style={{
                  width: "29%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                  border: "1px solid black",
                }} >{strings.kyc_popup_desc9} <img src="/assets/img/question.png"
                                                   style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "5vw",  } : {}}
                                                   onClick={() => {
                                                     Swal.fire({
                                                       html: "",
                                                       imageUrl: '/assets/img/img_email_step1.png',
                                                       imageAlt: 'name'
                                                     })
                                                   }} />
                </div>
                <div style={{
                  width: "64%",
                  background: "#FFFFFF",
                  // height: "5vh",
                  padding: 5,
                  border: "1px solid black",

                }}>
                  <input type="text" placeholder="somalin@gmail.com"
                         value={this.state.inputEmail} onChange={(e) => {
                    this.setState({inputEmail: e.target.value});
                  }} style={{width: "100%", height: "100%", textAlign: "left"}}/>
                </div>
                <div style={{
                  width: "4%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                }}><img src={ this.state.step1_3 ?  "/assets/img/right_on.png"  :  "/assets/img/right.png" }
                        style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: 30} : { width: 50 }}
                        onClick={() => {
                          this.setState({loading: true});
                          if (this.state.inputName.trim() == "") {
                            alert(strings.bep_input_name)
                            return
                          }
                          let params = new URLSearchParams();
                          params.append('name', this.state.inputName.trim());
                          params.append('email', this.state.inputEmail.trim());
                          axios.post(`${BASE_URL}/user/checkNameAndEmail`,
                            params)
                            .then((res) => {
                              if (parseInt(res.data) === 1) {
                                this.setState({step1_3: true});
                              } else {
                                alert(strings.kyc_result2);
                              }
                            }).finally(() => {
                            this.setState({loading: false});
                          });
                        }}
                />
                </div>
              </div>
            </div>
            }
            {/*<div className="content-desc1 m-t-5" style={{textAlign: "center",}}>*/}
            {/*  <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {*/}
            {/*    width: "33vw",*/}
            {/*    display: "-webkit-inline-box",*/}
            {/*    flexWrap: "wrap",*/}

            {/*  } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>*/}
            {/*    <div style={{*/}
            {/*      width: "29%",*/}
            {/*      marginRight: 5,*/}
            {/*      // height: "5vh",*/}
            {/*      padding: 5,*/}
            {/*      background: "#FFFFFF",*/}
            {/*      color: '#000000',*/}
            {/*      // border: "1px solid black",*/}
            {/*    }}>*/}
            {/*    </div>*/}
            {/*    <div style={{*/}
            {/*      width: "69%",*/}
            {/*      background: "#FFFFFF",*/}
            {/*      // height: "5vh",*/}
            {/*      padding: 5,*/}
            {/*      // border: "1px solid black",*/}

            {/*    }}>*/}
            {/*      <div style={{*/}
            {/*        height: "4vh",*/}
            {/*        paddingTop: 10,*/}
            {/*        backgroundImage: 'url(/assets/img/nft/img_round_button.png)',*/}
            {/*        backgroundRepeat: "no-repeat",*/}
            {/*        backgroundPosition: "center",*/}
            {/*        backgroundSize: "contain",*/}
            {/*        color: "white",*/}
            {/*        width: '100%',*/}
            {/*        fontSize: 12,*/}
            {/*        verticalAlign: "middle"*/}
            {/*      }} onClick={() => {*/}
            {/*        this.checkAccount();*/}
            {/*      }}>*/}
            {/*        {strings.kyc_result10}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {this.state.step1_3 &&
            <div className="content-desc1 m-t-5" style={{textAlign: "center",}}>
              <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
                width: "33vw",
                display: "-webkit-inline-box",
                flexWrap: "wrap",

              } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>
                <div style={{
                  width: "29%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                  border: "1px solid black",

                }} >{strings.kyc_popup_desc10}
                  <img src="/assets/img/question.png"
                       style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "5vw",  } : {}}
                       onClick={() => {
                         Swal.fire({
                           html: "",
                           imageUrl: '/assets/img/img_phone_step1.png',
                           imageAlt: 'name'
                         })
                       }} />
                </div>
                <div style={{
                  width: "64%",
                  background: "#FFFFFF",
                  // height: "5vh",
                  padding: 5,
                  textAlign: 'right',
                  border: "1px solid black",

                }}>
                  <input type="text" placeholder="651043152345"
                         value={this.state.inputNumber} onChange={(e) => {
                    this.setState({inputNumber: e.target.value});
                  }} style={{width: "100%", height: "100%", textAlign: "left"}}/>
                </div>
                <div style={{
                  width: "4%",
                  marginRight: 5,
                  // height: "5vh",
                  padding: 5,
                  background: "#FFFFFF",
                  color: '#000000',
                }}><img src={ this.state.step1_4 ?  "/assets/img/right_on.png"  :  "/assets/img/right.png" }
                        style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: 30} : { width: 50 }}
                        onClick={() => {
                          this.setState({loading: true});
                          if (this.state.inputName.trim() == "") {
                            alert(strings.bep_input_number)
                            return
                          }
                          let params = new URLSearchParams();
                          params.append('name', this.state.inputName.trim());
                          params.append('email', this.state.inputEmail.trim());
                          params.append('phone', this.state.inputNumber.trim());
                          axios.post(`${BASE_URL}/user/checkNameAndEmail`,
                            params)
                            .then((res) => {
                              if (parseInt(res.data) === 1) {
                                this.setState({step1_4: true});
                              } else {
                                alert(strings.kyc_result2);
                              }
                            }).finally(() => {
                            this.setState({loading: false});
                          });
                        }}
                />
                </div>
              </div>
            </div>
            }
            {this.state.payType == 1 &&
              <>
                {this.state.step1_4 &&
                <div className="content-desc1 m-t-5" style={{textAlign: "center",}}>
                  <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
                    width: "33vw",
                    display: "-webkit-inline-box",
                    flexWrap: "wrap",

                  } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>
                    <div style={{
                      width: "29%",
                      marginRight: 5,
                      // height: "5vh",
                      padding: 5,
                      background: "#FFFFFF",
                      color: '#000000',
                      border: "1px solid black",

                    }} >{strings.kyc_popup_desc10_1} <img src="/assets/img/question.png"
                                                          style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "5vw",  } : {}}
                                                          onClick={() => {
                                                            Swal.fire({
                                                              html: "",
                                                              imageUrl: '/assets/img/img_email_step1.jpg',
                                                              imageAlt: 'name'
                                                            })
                                                          }} />
                    </div>
                    <div style={{
                      width: "64%",
                      background: "#FFFFFF",
                      // height: "5vh",
                      padding: 5,
                      border: "1px solid black",

                    }}>
                      <input type="text" placeholder="TP7dFvVRUUfCz8f1FM7NnNmto123456789"
                             value={this.state.inputTronAddress} onChange={(e) => {
                        this.setState({inputTronAddress: e.target.value});
                      }} style={{width: "100%", height: "100%", textAlign: "left"}}/>
                    </div>
                    <div style={{
                      width: "4%",
                      marginRight: 5,
                      // height: "5vh",
                      padding: 5,
                      background: "#FFFFFF",
                      color: '#000000',
                    }}><img src={ this.state.step1_5 ?  "/assets/img/right_on.png"  :  "/assets/img/right.png" }
                            style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: 30} : { width: 50 }}
                            onClick={() => {
                              this.setState({loading: true});
                              if (this.state.inputTronAddress.trim() == "") {
                                alert(strings.bep_input_address)
                                return
                              }
                              let params = new URLSearchParams();
                              params.append('address', this.state.inputTronAddress.trim());
                              axios.post(`${BASE_URL}/user/checkTronHash`,
                                params)
                                .then((res) => {
                                  if (parseInt(res.data) === 1) {
                                    this.setState({step1_5: true});
                                  } else {
                                    // alert(strings.kyc_result2);
                                    alert("Already registered hash value");
                                  }
                                }).finally(() => {
                                this.setState({loading: false});
                              });
                            }}
                    />
                    </div>
                  </div>
                </div>
                }
                {false &&
                <div className="content-desc1 m-t-5" style={{textAlign: "center",}}>
                  <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
                    width: "33vw",
                    display: "-webkit-inline-box",
                    flexWrap: "wrap",

                  } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>
                    <div style={{
                      width: "29%",
                      marginRight: 5,
                      // height: "5vh",
                      padding: 5,
                      background: "#FFFFFF",
                      color: '#000000',
                      border: "1px solid black",

                    }}>BLOCK
                      &nbsp;<img src="/assets/img/question.png"
                                 style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "5vw",} : {}}
                                 onClick={() => {
                                   Swal.fire({
                                     html: strings.kyc_result30,
                                     imageUrl: '/assets/img/tronscan.png',
                                     imageAlt: 'tronscan'
                                   })
                                 }}
                      />
                    </div>
                    <div style={{
                      width: "69%",
                      background: "#FFFFFF",
                      // height: "5vh",
                      padding: 5,
                      border: "1px solid black",

                    }}>
                      <input type="text" placeholder="000000"
                             value={this.state.block} onChange={(e) => {
                        this.setState({block: e.target.value});
                      }} style={{width: "100%", height: "100%", textAlign: "left"}}/>
                    </div>
                  </div>
                </div>
                }
                {/*<div className="content-desc1 m-t-5" style={{textAlign: "center",}}>*/}
                {/*  <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {*/}
                {/*    width: "33vw",*/}
                {/*    display: "-webkit-inline-box",*/}
                {/*    flexWrap: "wrap",*/}

                {/*  } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>*/}
                {/*    <div style={{*/}
                {/*      width: "29%",*/}
                {/*      marginRight: 5,*/}
                {/*      // height: "5vh",*/}
                {/*      padding: 5,*/}
                {/*      background: "#FFFFFF",*/}
                {/*      color: '#000000',*/}
                {/*      border: "1px solid black",*/}

                {/*    }} dangerouslySetInnerHTML={{__html: strings.kyc_result28}}>*/}
                {/*    </div>*/}
                {/*    <div style={{*/}
                {/*      width: "69%",*/}
                {/*      background: "#FFFFFF",*/}
                {/*      // height: "5vh",*/}
                {/*      color: "#000000",*/}
                {/*      padding: 5,*/}
                {/*      border: "1px solid black",*/}

                {/*    }}>*/}
                {/*      <input type="file" style={{width: "100%", height: "100%", textAlign: "left"}} onChange={this.onBodyChange} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
            </>
            }
            {this.state.step1_payment &&
            <div className="content-desc1 m-t-5"
                 style={{textAlign: "center", display: 'flex', flexDirection: "column", justifyContent: "center"}}>

              <div style={{
                height: '5vh',
                paddingTop: 10,
                width: '100%',
                background: '#625EFF',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                marginBottom: 10,
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep2(1)
              }}>
                Stripe
              </div>
              {/*<PayPalScriptProvider*/}
              {/*  options={{*/}
              {/*    "client-id": "your-client-id",*/}
              {/*    "data-client-token": "your-data-client-token",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <PayPalHostedFieldsProvider*/}
              {/*    createOrder={() => {*/}
              {/*      // Here define the call to create and order*/}
              {/*      return fetch(*/}
              {/*        "/your-server-side-integration-endpoint/orders"*/}
              {/*      )*/}
              {/*        .then((response) => response.json())*/}
              {/*        .then((order) => order.id)*/}
              {/*        .catch((err) => {*/}
              {/*          // Handle any error*/}
              {/*        });*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <PayPalHostedField*/}
              {/*      id="card-number"*/}
              {/*      hostedFieldType="number"*/}
              {/*      options={{ selector: "#card-number" }}*/}
              {/*    />*/}
              {/*    <PayPalHostedField*/}
              {/*      id="cvv"*/}
              {/*      hostedFieldType="cvv"*/}
              {/*      options={{ selector: "#cvv" }}*/}
              {/*    />*/}
              {/*    <PayPalHostedField*/}
              {/*      id="expiration-date"*/}
              {/*      hostedFieldType="expirationDate"*/}
              {/*      options={{*/}
              {/*        selector: "#expiration-date",*/}
              {/*        placeholder: "MM/YY",*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    <SubmitPayment />*/}
              {/*  </PayPalHostedFieldsProvider>*/}
              {/*</PayPalScriptProvider>*/}
              {/*<div style={{*/}
              {/*  height: '5vh',*/}
              {/*  width: '30%',*/}
              {/*  paddingTop: 10,*/}
              {/*  backgroundImage: 'url(/assets/img/nft/img_round_button.png)',*/}
              {/*  backgroundRepeat: "no-repeat",*/}
              {/*  backgroundPosition: "center",*/}
              {/*  backgroundSize: "contain",*/}
              {/*  color: "white",*/}
              {/*  verticalAlign: "middle"*/}
              {/*}} onClick={() => {*/}
              {/*  // this.goStep2(2)*/}
              {/*}}>*/}
              {/*  Paypal*/}
              {/*</div>*/}
              <div style={{
                height: '5vh',
                width: '100%',
                paddingTop: 10,
                marginTop: 10,
                background: '#aa8686',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep2(3)
              }}>
                USDT
              </div>
              <div style={{
                height: '5vh',

                paddingTop: 10,
                backgroundImage: 'url(/assets/img/nft/img_round_button.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep1()
              }}>
                Reject
              </div>
            </div>
            }
            {this.state.step1_confirm && this.state.step1_5 &&
            <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
              <div style={{justifyContent: "center", textAlign: "center", display: "flex",flexDirection: "row", width: '100%'}}>
              <div style={{
                height: "5vh",
                paddingTop: 10,
                backgroundImage: 'url(/assets/img/nft/img_round_button.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                width: '100%',
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep2(1)
              }}>
                {strings.levelup_popup_confirm}
              </div>
              </div>

              <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
                <img src="/assets/img/step1.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "80vw"} : {}}/>
              </div>
            </div>
            }
          </div>
          }
          {this.state.step2 &&
          <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
            marginTop: 15,
            marginBottom: 15,
            paddingTop: 30,
            paddingBottom: 30,
            // backgroundImage: 'url(/assets/img/img_kyc_popup_back.png)',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
            borderRadius: 30,
            width: '50vw',

            paddingLeft: '5vw',
            paddingRight: '5vw',
          } : {
            marginTop: 15,
            width: '100vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
          })}>

            <div className="content-desc1 m-t-15"
                 style={{textAlign: "center", color: 'blue'}}>{strings.kyc_popup_title2}
            </div>
            <div className="content-desc1 m-t-5" style={{color: "black"}}>
              {strings.kyc_popup_desc11} <br/>
              {strings.kyc_popup_desc12} <br/>
              {strings.kyc_popup_desc13} <br/>
              {strings.kyc_popup_desc14} <br/>
              {strings.kyc_popup_desc15} <br/>
              <br/>
              {strings.kyc_popup_desc16}

            </div>
            <div className="content-desc1 m-t-15" style={{textAlign: "center", display: "inherit",}}>
              <div style={{
                height: "5vh",
                paddingTop: 10,
                backgroundImage: 'url(/assets/img/img_button_agree.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep3()
              }}>
                {strings.kyc_popup_agree}
              </div>
              <div style={{
                height: "5vh",
                marginTop: 10,
                paddingTop: 10,
                backgroundImage: 'url(/assets/img/img_button_reject.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep1();
              }}>
                {strings.kyc_popup_reject}
              </div>
              <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
                <img src="/assets/img/step2.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "80vw"} : {}}/>
              </div>
            </div>

          </div>
          }
          {this.state.step3 &&
          <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
            marginTop: 15,
            marginBottom: 15,
            paddingTop: 30,
            paddingBottom: 30,
            // backgroundImage: 'url(/assets/img/img_kyc_popup_back.png)',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
            borderRadius: 30,
            width: '50vw',

            paddingLeft: '5vw',
            paddingRight: '5vw',
          } : {
            marginTop: 15,
            width: '100vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            paddingBottom: '5vh',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
          })}>

            <div className="content-desc1 m-t-15"
                 style={{textAlign: "center", color: 'blue'}}>{strings.kyc_popup_title3}
            </div>
            <div className="content-desc1 m-t-5" style={{color: 'black'}}>
              {strings.kyc_popup_desc17}<br/>
              <br/>
              <br/>
              {strings.kyc_popup_desc19}
            </div>
            <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
              {this.state.payed == true &&
              this.state.key &&
              <div style={{
                display: "inline-block",
                paddingTop: 10,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }}>

                <VerifyButton
                  style={{display: 'inherit'}}
                  //apiKey="T54wD2A3L1efWFeTCfXCjSd4vOHYGdbJ1jB8tSU7jb9zakRIgexL6ZyQrRLuypkt"
                  apiKey="BHoPfPgeV0jrfed4eDVnCVOW3tBJBwYOb4z4d092Uz55ywDfc6nNS1fnvPUhoBDL"
                  customerPayload={this.state.key}
                  onStart={() => {
                    console.log('onStart success');
                  }}
                  onError={(error, context) => {
                    console.log('onStart error' + error);
                  }}
                  onFinish={() => {
                    console.log('onFinish ');
                  }}
                  onSubmitted={() => {
                    this.goStep4();
                    localStorage.setItem('inputEmail', '');
                    localStorage.setItem('inputName', '');
                    localStorage.setItem('inputNumber', '');
                    localStorage.setItem('inputTronAddress','');
                    localStorage.setItem('block', '');
                  }}
                  prefillAttributes={{
                    email: this.state.inputEmail,
                  }}
                  hidden={true}
                />
              </div>
              }
              {this.state.payed == false &&

              <div style={{
                height: "5vh",
                paddingTop: 10,

                backgroundImage: 'url(/assets/img/nft/img_round_button.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.setState({showStripe: true});
              }}>
                {strings.kyc_popup_pay}
              </div>
              }
              <div style={{
                height: "5vh",
                marginTop: 10,
                paddingTop: 10,
                backgroundImage: 'url(/assets/img/nft/img_round_button.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep1()
              }}>
                {strings.kyc_popup_reject}
              </div>
              <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
                <img src="/assets/img/step3.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "80vw"} : {}}/>
              </div>
            </div>
          </div>
          }
          {this.state.step4 &&
          <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {
            marginTop: 15,
            marginBottom: 15,
            paddingTop: 30,
            paddingBottom: 30,
            // backgroundImage: 'url(/assets/img/img_kyc_popup_back.png)',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
            borderRadius: 30,
            width: '50vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
          } : {
            marginTop: 15,
            width: '100vw',
            paddingLeft: '5vw',
            paddingRight: '5vw',
            paddingBottom: '5vh',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: "inline-block",
            overflow: "hidden",
            background: "#ffffff",
          })}>

            <div className="content-desc1 m-t-15"
                 style={{textAlign: "center", color: 'blue'}}>{strings.kyc_popup_title4}
            </div>
            <div className="content-desc1 m-t-5" style={{color: "black"}}>
              {strings.kyc_popup_desc20} <br/>
              {strings.kyc_popup_desc21}.<br/>
              {strings.kyc_popup_desc22}<br/>
              {strings.kyc_popup_desc23}<br/>
              {strings.kyc_popup_desc24}
            </div>
            <div className="content-desc1 m-t-15" style={{color: "black", textAlign: "center", display: "inherit",}}>

              {strings.kyc_popup_desc25}
              <div className=" m-t-15" style={{width: '100%', textAlign: "center", display: "inherit",}}>
                <img src="/assets/img/img_kyc_proof.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "50vw"} : {width: "20vw"}}/>
              </div>
              <div style={{
                height: "5vh",
                marginTop: 10,
                paddingTop: 10,
                backgroundImage: 'url(/assets/img/nft/img_round_button.png)',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                color: "white",
                verticalAlign: "middle"
              }} onClick={() => {
                this.goStep1();
              }}>
                {strings.levelup_popup_confirm}
              </div>
              <div className=" m-t-15" style={{textAlign: "center", display: "inherit",}}>
                <img src="/assets/img/step4.png"
                     style={this.uiState.display_mode !== Const.DISPLAY.DESKTOP ? {width: "80vw"} : {}}/>
              </div>
            </div>
          </div>
          }
        </div>
        {/*<Modal isOpen={this.state.showStripe}*/}
        {/*       style={{background: 'white', maxWidth: 480, margin: '100px auto', borderRadius: 20}}>*/}
        {/*  <ModalBody>*/}
        {/*    {this.state.clientSecret && (*/}
        {/*      <Elements options={this.state.option} stripe={stripePromise} clientSecret={this.state.clientSecret}>*/}
        {/*        <CheckoutForm email={this.state.inputEmail}/>*/}
        {/*      </Elements>*/}
        {/*    )}*/}
        {/*    <button className={'button3'} style={{marginTop: 30}} onClick={() => {*/}
        {/*      this.setState({showStripe: false});*/}
        {/*      window.location = window.location.origin + `/kyc`;*/}
        {/*    }}>*/}
        {/*    <span id="button-text">*/}
        {/*     CLOSE*/}
        {/*    </span>*/}
        {/*    </button>*/}
        {/*  </ModalBody>*/}
        {/*</Modal>*/}
        <div style={{marginBottom: 150}}/>
      </div>

    )
  }
}

export default withRouter(inject('rootStore')(observer(Kyc)));
