import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Const, {APK_URL, BCL_PRESALE_RECV_ADDRESS} from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from '../../lang/strings';
import './main.scss';
import utils from "../../config/util";

const moment = require('moment');


var ua = navigator.userAgent.toLowerCase();
var isSafari = false
if (ua.indexOf('safari') != -1 || ua.indexOf('iphone') != -1) {
  if (ua.indexOf('chrome') > -1) {
    isSafari = false
  } else {
    isSafari = true
  }
}

class PreSale extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
    };

    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  onClickCopyPreSaleAddress() {
    var copyText = document.querySelector("#presale_address");
    copyText.select();
    document.execCommand("copy");
    setTimeout(() => {
        alert("Copied")
    }, 500)
  }

  componentDidMount() {
  }


  componentWillUnmount() {
  }

  render() {
    return (
      <div id="main" className="main">
          <div id="home" className="content has-bg home">
          <div >
              <div className="container home-content">
                  <div className="video-content">
                      <iframe
                          src="https://www.youtube.com/embed/3Ez-3ioMOB0">
                      </iframe>
                      <img className="video-bg" src="/assets/img/ic_video_container.png"
                           srcSet="/assets/img/ic_video_container.png 1x" />
                  </div>
              </div>
          </div>
          </div>
          <div id="airdrop" className="content airdrop" data-scrollview="true">
              <div className="content-bg">
                  <img className="one" src="/assets/img/빼기 4.png" srcSet="/assets/img/빼기 4.png 1x" />
                  <img className="two" src="/assets/img/빼기 5.png" srcSet="/assets/img/빼기 5.png 1x" />
                  <img className="three" src="/assets/img/빼기 7.png" srcSet="/assets/img/빼기 7.png 1x" />
                  <img className="five" src="/assets/img/빼기 5.png" srcSet="/assets/img/빼기 5.png 1x" />
                  <img className="four" src="/assets/img/빼기 8.png" srcSet="/assets/img/빼기 8.png 1x" />
              </div>
              <div className="airdrop-content" data-animation="true" data-animation-type="fadeInDown">
                  <div
                      id="presale" data-scrollview="true"
                      className={"content-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-100" : "m-t-80")}>{strings.pre_sale_title}</div>
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-40")}>
                      {strings.pre_sale_quantity}
                      <span className="quantity-value">{utils.currencyFormat(5000000)}</span>
                      <span className="quantity-unit">{strings.bcl}</span>
                  </div>
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-50" : "m-t-40")}>
                      {strings.pre_sale_address}  {BCL_PRESALE_RECV_ADDRESS}
                      <button className={"action " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "" : "")}
                onClick={() => this.onClickCopyPreSaleAddress()}>
                <img src="/assets/img/ic_copy.png" srcSet="/assets/img/ic_copy.png 1x" />
              </button>
                      <div className="text-value">
                          {/*{strings.pre_sale_title}*/}
                          {/* {BCL_PRESALE_RECV_ADDRESS}*/}

                          {/* <button className={"action " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "" : "hide")}
                  onClick={() => this.onClickCopyPreSaleAddress()}>
                  <img src="/assets/img/ic_copy.png" srcSet="/assets/img/ic_copy.png 1x" />
                </button> */}
                      </div>
                  </div>
                  {/* copy 용으로 만든  */}
                  <input id="presale_address" ref={input => this.inputElement = input} value={BCL_PRESALE_RECV_ADDRESS} style={{ fontSize: 10, padding: 0, color: 'transparent', height: isSafari ? 1 : 0 }} readOnly={true} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-50" : "m-t-40")}>
                      {strings.pre_sale_price}
                      <span className="text-value big">1 ETH = 30,000 BCL.</span>
                  </div>
                  <div className="content-desc m-t-15"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.pre_sale_price_desc }} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.invitation_rules}</div>
                  <div className="content-desc m-t-15"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.airdrop_rules_pre_sale_desc }} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.airdrop_rules_presale}</div>
                  <div className="content-desc m-t-15"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.airdrop_rules_pre_sale_desc }} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.how_to_do_pre_sale}</div>
                  <div className="content-desc m-t-15"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.how_to_do_pre_sale_desc }} />

                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.pre_sale_rules}</div>
                  <div className="content-desc m-t-15"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.pre_sale_rules_desc }} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.once_the_pre_sale_over}</div>
                  <div className="content-desc m-t-15 underline"
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}
                       dangerouslySetInnerHTML={{ __html: strings.once_the_pre_sale_over_desc }} />

                  <div className="check-pre-sale">
                      <input type="text" placeholder={strings.check_pre_sale} onClick={() => this.onClickPreSaleGo()} readOnly />
                      <button onClick={() => this.onClickPreSaleGo()}>{strings.go}</button>
                  </div>
                  <div className="check-pre-sale-hint">{strings.check_pre_sale_hint}</div>
              </div>

          </div>
          <div id="download" className="content download">

              <div className="action-content">
                  <div className="title" style={{ backgroundImage: 'url(/assets/img/ic_gradient2.png)' }}>
                      <div>{strings.download_now}</div>
                  </div>
                  <div className="download-hint">{strings.download_now_desc}</div>
                  <div className="referring-hint">{strings.referring_hint}</div>
                  <img className={"mark-icon " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-100" : "m-t-50")}
                       src="/assets/img/ic_logo_bottom.png" srcSet="/assets/img/ic_logo_bottom.png 1x" />

                  <div className="market-link">
                      <a href="http://bit.ly/3h8MgBy" target="_blank">
                          <img src="/assets/img/ic_download_google.png" srcSet="/assets/img/ic_download_google.png 1x" />
                      </a>
                      <a href="http://apple.co/3oEODPq" target="_blank">
                          <img src="/assets/img/ic_download_apple.png" srcSet="/assets/img/ic_download_apple.png 1x" />
                      </a>
                      <a href={APK_URL} target="_blank">
                          <img src="/assets/img/ic_download_apk.png" srcSet="/assets/img/ic_download_apk.png 1x" />
                      </a>
                  </div>
                  <div className="join-us-hint" dangerouslySetInnerHTML={{ __html: strings.join_us_hint }} />
                  <div className="link-content">
                      <a href="https://www.bitcoinlegend.org" target="_blank">
                          <img src="/assets/img/ic_sns_website.png" srcSet="/assets/img/ic_sns_website.png 1x" />
                      </a>
                      {/* <a href="https://www.facebook.com" target="_blank">
                <img src="/assets/img/ic_sns_facebook.png" srcSet="/assets/img/ic_sns_facebook.png 1x" />
              </a> */}
                      <a href="http://bit.ly/3tDkKkA" target="_blank">
                          <img src="/assets/img/ic_sns_insta.png" srcSet="/assets/img/ic_sns_insta.png 1x" />
                      </a>
                      <a href="http://bit.ly/3AsXqcJ" target="_blank">
                          <img src="/assets/img/ic_sns_youtube.png" srcSet="/assets/img/ic_sns_youtube.png 1x" />
                      </a>
                      <a href="http://bit.ly/38WoRyN" target="_blank">
                          <img src="/assets/img/ic_sns_telegram.png" srcSet="/assets/img/ic_sns_telegram.png 1x" />
                      </a>
                      <a href="http://bit.ly/3tEQDt4" target="_blank">
                          <img src="/assets/img/ic_sns_twitter.png" srcSet="/assets/img/ic_sns_twitter.png 1x" />
                      </a>
                      <a href="https://cafe.naver.com/bitcoinlegendkorea/807" target="_blank">
                          <img src="/assets/img/ic_sns_kakao.png" srcSet="/assets/img/ic_sns_kakao.png 1x" />
                      </a>
                  </div>
              </div>
          </div>

      </div>
    )
  }
}

export default withRouter(inject('rootStore')(observer(PreSale)));
