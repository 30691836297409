import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import Const, { BASE_URL } from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import "./nft-intro2.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Modal, ModalBody } from "reactstrap";
import axios from "axios";
import $ from "jquery/src/jquery";
import MultiColorProgressBar from "../../components/controls/multi-progress-bar";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

const columns = [
  {
    field: "id",
    headerName: "Rank",
    flex: 2,
  },
  {
    field: "from",
    headerName: "Address",
    flex: 2,
  },
  {
    field: "amt",
    headerName: "Quantity",
    flex: 2,
  },
  {
    field: "cnt",
    headerName: "Count",
    flex: 2,
  },
];

const youtube = [
  "https://www.youtube.com/embed/gfjBqRpmh6U",
  "https://www.youtube.com/embed/8Al9l7YVLC4",
  "https://www.youtube.com/embed/s0FSVXZ-wSs",
];

var ua = navigator.userAgent.toLowerCase();
var isSafari = false;
if (ua.indexOf("safari") != -1 || ua.indexOf("iphone") != -1) {
  if (ua.indexOf("chrome") > -1) {
    isSafari = false;
  } else {
    isSafari = true;
  }
}

class GameFi extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      showGuideModal: false,
      inputNftEmail: "",
      inputBepAddress: "",
      inputBepAmount: "",
      // inputPolygonAddress: '',
      inputNftEmail2: "",
      inputBepAddress2: "",
      inputBepType2: 1,
      inputBepAmount2: "",
      // inputPolygonAddress2: '',
      showMore1: false,
      showMore2: false,
      showMore3: false,
      remain_first: 0,
      remain_first2: 0,
      griddata: [],
      readings: [
        {
          name: "EVENT",
          value: 700,
          percent: 60,
          color: "#ff0000",
        },
        {
          name: "SALES including Event",
          value: 635,
          percent: 30,
          color: "yellow",
        },
        {
          name: "",
          value: 18,
          percent: 10,
          color: "#aaaaaa",
        },
      ],
    };
    this.iframeInt = 0;
    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  componentDidMount() {
    $("#a1").css("display", "none");
    $("#a2").css("display", "none");
    $("#a3").css("display", "none");
    $("#a4").css("display", "none");
    $("#a5").css("display", "none");
    $("#a6").css("display", "none");

    $("#b1").css("display", "none");
    $("#b2").css("display", "none");
    $("#b3").css("display", "none");
    $("#b4").css("display", "none");
    $("#b5").css("display", "none");
    $("#b6").css("display", "none");

    // $("#c1").css("display",'none');
    // $("#c2").css("display",'none');
    // $("#c3").css("display",'none');
    this.getTotaFirstCount();
    this.getTotaFirstCount2();
    axios
      .get(`${BASE_URL}/user/nftRank`, {
        headers: {
          Authorization:
            "Bearer " +
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MTE5NjM1OSwiZXhwIjoxNjgyNzMyMzU5fQ.5ZxqvUdLOS8zrbCZuDqZqv4Zjox1POUrZ0Ah0u9LEbs",
        },
      })
      .then(({ data }) => {
        var i = 1;
        const newData = data.map((row) => {
          const createdAt = new Date(row["createdAt"]).toLocaleString();
          row["id"] = i;
          // props.setNumber((number: any) => {
          //     row["no"] = number + 1;
          //     return number + 1;
          // });
          row["from"] = row["from"];
          // row["notSettle"] = row["miningAmount"] - row["settleAmount"];
          i++;
          return row;
        });
        this.state.griddata = newData;
        console.log(this.state.griddata);
        // this.state( griddata: .setData(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTotaFirstCount2 = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo2`, {})
      .then((res) => {
        const max = 3000;
        // const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 3900);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.05);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 94);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt) / max) * 100
        );
        console.log(bcl_cnt + eth_cnt + poly_cnt);
        // const sales_percent =  Math.round(sales / max * 100) ;
        const other_percent = 100 - cnt_percent; // - sales_percent;
        this.state.remain_first2 = other_cnt;

        this.state.readings2 = [
          {
            name: "SALES",
            value: bcl_cnt + eth_cnt + poly_cnt, //+ sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "green",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  getTotaFirstCount = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo`, {})
      .then((res) => {
        const max = 1500;
        const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 1000);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.022);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 45);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt + sales);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt + sales) / max) * 100
        );
        const sales_percent = Math.round((sales / max) * 100);
        const other_percent = 100 - cnt_percent - sales_percent;
        this.state.remain_first = other_cnt;
        this.state.readings = [
          // {
          //     name: 'EVENT',
          //     value: sales,
          //     percent: sales_percent,
          //     color: '#ff0000'
          // },
          {
            name: "SALES including Event",
            value: bcl_cnt + eth_cnt + poly_cnt + sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "#aaaaaa",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  clickMore1(flag) {
    console.log(flag);
    if (!flag) {
      $("#a1").css("display", "none");
      $("#a2").css("display", "none");
      $("#a3").css("display", "none");
      $("#a4").css("display", "none");
      $("#a5").css("display", "none");
      $("#a6").css("display", "none");
    } else {
      $("#a1").css("display", "");
      $("#a2").css("display", "");
      $("#a3").css("display", "");
      $("#a4").css("display", "");
      $("#a5").css("display", "");
      $("#a6").css("display", "inline-block");
    }
  }

  clickMore2(flag) {
    console.log(flag);
    if (!flag) {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly.png");
      $("#b1").css("display", "none");
      $("#b2").css("display", "none");
      $("#b3").css("display", "none");
      $("#b4").css("display", "none");
      $("#b5").css("display", "none");
      $("#b6").css("display", "none");
    } else {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly_on.png");
      $("#b1").css("display", "");
      $("#b2").css("display", "");
      $("#b3").css("display", "");
      $("#b4").css("display", "");
      $("#b5").css("display", "");
      $("#b6").css("display", "inline-block");
    }
  }
  clickMore3(flag) {
    console.log(flag);
    if (!flag) {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist.png");
      $("#c1").css("display", "none");
      $("#c2").css("display", "none");
      $("#c3").css("display", "none");
    } else {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist_on.png");
      $("#c1").css("display", "");
      $("#c2").css("display", "");
      $("#c3").css("display", "");
    }
  }

  onCopy() {
    var copyText = document.querySelector("#migration_address");

    function handler(event) {
      event.clipboardData.setData("text/plain", copyText.value);
      event.preventDefault();
      document.removeEventListener("copy", handler, true);
    }

    document.addEventListener("copy", handler, true);
    document.execCommand("copy");
    setTimeout(() => {
      alert("Copied");
    }, 500);
  }

  onConfirmTrasaction2() {
    if (this.state.inputNftEmail2 == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress2 == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount2 == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    // if (this.state.inputBepAmount < 1000) {
    //     alert(strings.bep_input_bep_amount2)
    //     return
    // }
    // if (this.state.inputPolygonAddress2 == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail2);
    params.append("bep_address", this.state.inputBepAddress2);
    params.append("amount_type", this.state.inputBepType2);
    params.append("bep_amount", this.state.inputBepAmount2);
    // params.append('polygon_address', this.state.inputPolygonAddress2);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst2`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        if (this.state.inputBepType2 == 1) {
          alert(strings.not_match_eth);
        } else {
          alert(strings.not_match_pol);
        }
      });
  }

  onConfirmTrasaction() {
    if (this.state.inputNftEmail == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    if (this.state.inputBepAmount < 3900) {
      alert(strings.bep_input_bep_amount2);
      return;
    }
    // if (this.state.inputPolygonAddress == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail);
    params.append("bep_address", this.state.inputBepAddress);
    params.append("bep_amount", this.state.inputBepAmount);
    // params.append('polygon_address', this.state.inputPolygonAddress);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(strings.not_match_bep);
      });
  }

  checkBEP2 = () => {
    {
      window.open(
        `https://bscscan.com/token/0x02b84f8b64e6c67ae6c797ff4ec4efb06ff19b44?a=0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };

  checkEtheScan = () => {
    {
      window.open(
        `https://etherscan.io/address/0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };
  checkPolyScan = () => {
    {
      window.open(
        `https://polygonscan.com/address/0xF30Eea37313Cf59D67448346F8319826c1B131c6`
      );
    }
  };

  componentWillUnmount() {}

  render() {
    return (
      <div id="nft" className="nft-main">
        <div className="content technique" data-scrollview="true">
          <div
            className="content-bg"
            style={{ backgroundImage: "url(/assets/img/nft/img_bg_nft.png)" }}
            data-paroller="true"
            data-paroller-factor="0.5"
            data-paroller-factor-xs="0.25"
          ></div>
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title" style={{ color: "#EB8F22" }}>
              {strings.hero_nft_card2}
            </div>
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc }}
            />
            <div className="nft-hero-img2">
              <img
                style={{ width: "100%" }}
                src="/assets/img/nft/img_hero_five.png"
                srcSet="/assets/img/nft/img_hero_five.png 1x"
              />
              <video
                autoPlay
                loop
                /*controls*/ muted
                style={{
                  position: "absolute",
                  width: "30%",
                  left: "34%",
                  borderWidth: "0px",
                }}
              >
                <source
                  src="/assets/video/BitcoinLenged_Hero NFT Card Bitlegend.mp4"
                  type="video/mp4"
                />
              </video>
              {/* <iframe
                autoplay
                muted
                style={{ marginTop: true ? 0 : ((1452 / 1920) * window.innerWidth) / 10 }}
                src="/assets/video/BitcoinLenged_Hero NFT Card Bitlegend.mp4">
              </iframe> */}
            </div>
          </div>
        </div>

        <div className="bottom-video" style={{ marginTop: "-30%" }}>
          <div
            className="video-content"
            style={{
              width:
                this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "50%"
                  : "70%",
            }}
          >
            <video controls muted>
              <source
                src="/assets/video/Method for NFT card.mp4"
                type="video/mp4"
              />
            </video>
            <img
              className="video-bg"
              src="/assets/img/ic_video_container2.png"
              srcSet="/assets/img/ic_video_container2.png 1x"
            />
          </div>
        </div>

        <img
          style={{ marginTop: "30px" }}
          className="img-hero-card"
          src="/assets/img/nft/img_nft2_herocard.png"
          srcSet="/assets/img/nft/img_nft2_herocard.png 1x"
        />
      </div>
    );
  }
}

export default withRouter(inject("rootStore")(observer(GameFi)));
