import React, { useEffect } from 'react';
import Const from "../../config/const";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import CheckoutForm from "./CheckoutForm";
import {Elements} from "@stripe/react-stripe-js";
import strings from "../../lang/strings";
import {loadStripe} from "@stripe/stripe-js";
import {Modal, ModalBody} from "reactstrap";

const stripePromise = loadStripe("pk_test_51LI7nzJ13Kc9U5mkVZflUq2QFnNzkP9GbFnvev2mU4pg89wtdnk0M4XrTo2x0QWYJ6HDx8jAVOjevexD0J6rs1lW008Zg3HIFx");


class PaymentStatus extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      strings: strings,
      showBepModal: false,
      inputEmail: '',
      showGuideModal: false,
      showStripe: false,
      options: [],
      selectProduct: null,
      // clientSecret: clientSecret,
      option: { option : {
          clientSecret:  new URLSearchParams(window.location.search).get(
            'setup_intent_client_secret'
          ),
          theme: 'stripe'
        }},
    };

    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;

  }
  render() {
    return (
      <div id="levelup" className="main">

        <div id="levelup" className="content has-bg home">
          <div>
            <div className="container home-content">
              <div>
                <img className="video-bg"
                     src="/assets/img/levelup/img_levelup.png" srcSet="/assets/img/levelup/img_levelup.png 1x"/>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={true}  style={{ background: 'white', maxWidth: 480, margin: '100px auto', borderRadius: 20}}>
          <ModalBody>
            {this.state.clientSecret && (
              <Elements  stripe={stripePromise}>

              </Elements>
            )}
          </ModalBody>
        </Modal>
        <div id="levelup" className="content airdrop" data-scrollview="true" st>
          <div className="content-bg" style={{marginTop: "60vh"}}>
            <img className="top" src="/assets/img/img_bg_distribution.png"
                 srcSet="/assets/img/img_bg_distribution.png 1x"/>
          </div>
        </div>
      </div>
    );
  }
};

export default withRouter(inject('rootStore')(observer(PaymentStatus)));
