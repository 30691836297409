import React from 'react';
import strings from '../../lang/strings.js';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Const from '../../config/const';
import './header-desktop.scss';
import $ from 'jquery/src/jquery';
import scrollMonitor from 'scrollmonitor/index';

class HeaderDesktop extends React.Component {
  constructor(props) {
    super(props);
    this.langList = [
      { lang: "en", title: 'English', icon: '/assets/img/ic_lang_eng.png' },
      { lang: "zh", title: 'Chinese', icon: '/assets/img/ic_lang_china.png' },
      { lang: "ko", title: 'Korean', icon: '/assets/img/ic_lang_kor.png' },
      { lang: "vn", title: 'Vietnam', icon: '/assets/img/ic_lang_vietnam.png' },
      { lang: "ar", title: 'Arabic', icon: '/assets/img/ic_lang_arabic.png' },
    ];
    this.state = {
      currentLang: this.langList[0],
    };

    this.globalState = this.props.rootStore.globalState;
    strings.setLanguage(this.globalState.getLang());
  }

  componentDidMount() {
    for (let i = 0; i < this.langList.length; i++) {
      if (this.langList[i].lang == this.globalState.getLang()) {
        this.setState({ currentLang: this.langList[i] })
        break;
      }
    }

    $(window).on('scroll load', function () {
      if ($('#header').attr('data-state-change') != 'disabled') {
        var totalScroll = $(window).scrollTop();
        var headerHeight = $('#header').height();
        if (totalScroll > headerHeight) {
          $('#header').addClass('navbar-sm');
        } else {
          $('#header').removeClass('navbar-sm');
        }
      }
    });

    $(document).on('click', '[data-click=scroll-to-target]', function (e) {
      try {
        e.preventDefault();
        e.stopPropagation();
        var target = ($(this).attr('data-scroll-target')) ? $(this).attr('data-scroll-target') : '';
        var target = (!target && $(this).attr('href')) ? $(this).attr('href') : target;
        var headerHeight = 50;
        $('html, body').animate({
          scrollTop: $(target).offset().top - headerHeight
        }, 500);

        if ($(this).attr('data-action') == "button") {
          return;
        }
        var targetLi = $(this).closest('.nav-item');
        if ($(targetLi).hasClass('dropdown')) {
          if ($(targetLi).hasClass('open')) {
            $(targetLi).removeClass('open');
          } else {
            $(targetLi).addClass('open');
          }
        }
        if ($(window).width() < 767) {
          $('#header [data-toggle="collapse"]').trigger('click');
        }
      } catch (error) {
      }

    });
    $(document).click(function (e) {
      if (!e.isPropagationStopped()) {
        $('.dropdown.open').removeClass('open');
      }
    });

    $('[data-scrollview="true"]').each(function () {
      var myElement = $(this);
      var elementWatcher = scrollMonitor.create(myElement, 60);

      elementWatcher.enterViewport(function () {
        $(myElement).find('[data-animation=true]').each(function () {
          var targetAnimation = $(this).attr('data-animation-type');
          var targetElement = $(this);
          if (!$(targetElement).hasClass('contentAnimated')) {
            if (targetAnimation == 'number') {
              var finalNumber = parseInt($(targetElement).attr('data-final-number'));
              $({
                animateNumber: 0
              }).animate({
                animateNumber: finalNumber
              }, {
                duration: 1000,
                easing: 'swing',
                step: function () {
                  var displayNumber = this.handleAddCommasToNumber(Math.ceil(this.animateNumber));
                  $(targetElement).text(displayNumber).addClass('contentAnimated');
                }
              });
            } else {
              $(this).addClass(targetAnimation + ' contentAnimated');
              setTimeout(function () {
                $(targetElement).addClass('finishAnimated');
              }, 1500);
            }
          }
        });
      });
    });

    if (typeof $.fn.paroller !== 'undefined') {
      if ($('[data-paroller="true"]').length !== 0) {
        $('[data-paroller="true"]').paroller();
      }
    }
  }

  handleAddCommasToNumber(value) {
    return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  };
  setLang(lang) {
    strings.setLanguage(lang);
    this.globalState.setLang(lang);
    for (let i = 0; i < this.langList.length; i++) {
      if (this.langList[i].lang == lang) {
        this.setState({ currentLang: this.langList[i] });
        break;
      }
    }
  }


  render() {
    return (
      <div id="header" className="header header-desktop navbar navbar-default navbar-fixed-top navbar-expand-lg">
        <div className="container-header">
          <a href="index.html" className="navbar-brand">
            <img alt='' src='/assets/img/ic_logo_top.png' srcSet="/assets/img/ic_logo_top.png 1x" />
          </a>
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
            data-target="#header-navbar">
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="header-navbar">
            <ul className="nav navbar-nav navbar-right">

              {strings.header_menu_list.map((item, index) => {
                return (
                  <li className="nav-item" key={index}>
                    { item.sub_header_menu_list == undefined &&

                      <a className={"nav-link"} href={"#" + item.code}
                         onClick={(e) => {
                           if (item.code == "home") {
                             this.props.history.push("/")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else if (item.code == "nft") {
                             if (!window.location.pathname.includes("nft_intro")) {
                               this.props.history.push("/nft_intro_v2")
                               setTimeout(() => {
                                 if (window.scrollY == 0) {
                                   window.scrollTo(0, 1)
                                 }
                                 $(`a[href='#${item.code}']`).trigger("click")
                               }, 500)
                             }
                           } else if (item.code == "levelup") {
                             this.props.history.push("/levelup")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               // $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else if (item.code == "kyc") {
                             this.props.history.push("/kyc")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               // $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else if (item.code == "about") {
                             this.props.history.push("/")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               // $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else if (item.code == "airdrop") {
                             this.props.history.push("/")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               // $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else if (item.code == "roadmap") {
                             this.props.history.push("/")
                             setTimeout(() => {
                               if (window.scrollY == 0) {
                                 window.scrollTo(0, 1)
                               }
                               $(`a[href='#${item.code}']`).trigger("click")
                             }, 500)
                           } else {
                             if (window.location.pathname.includes("nft_intro")) {
                               this.props.history.push("/")
                               setTimeout(() => {
                                 if (window.scrollY == 0) {
                                   window.scrollTo(0, 1)
                                 }
                                 $(`a[href='#${item.code}']`).trigger("click")
                               }, 500)
                             }
                           }
                         }}
                         data-click="scroll-to-target">{item.title}</a>
                    }
                    { item.sub_header_menu_list!= undefined &&
                          <ul className="nav-item dropdown" style={{ marginLeft: 0, paddingInlineStart: 0 }}>
                            <a className="nav-link active" href="#home" data-click="scroll-to-target"
                               data-scroll-target="#home" data-toggle="dropdown">
                              {item.title}
                            </a>

                            <li className="dropdown-menu dropdown-menu-left animated fadeInDown" style={{top: 15 }}>
                              {item.sub_header_menu_list.map((item2, index2) => {
                                return (
                                  <div style={{ color: 'white', fontSize: '14px' }} key={index2} className="dropdown-item"  href={"#" + item2.code}
                                     onClick={(e) => {
                                       if (item2.code == "/whitelist") {
                                         this.props.history.push("/whitelist")
                                         setTimeout(() => {
                                           if (window.scrollY == 0) {
                                             window.scrollTo(0, 1)
                                           }
                                           // $(`a[href='#${item2.code}']`).trigger("click")
                                         }, 500)
                                       } else if (item2.code == "/gamefi") {
                                         this.props.history.push("/gamefi")
                                         setTimeout(() => {
                                           if (window.scrollY == 0) {
                                             window.scrollTo(0, 1)
                                           }
                                           // $(`a[href='#${item2.code}']`).trigger("click")
                                         }, 500)
                                       } else if (item2.code == "/card") {
                                         this.props.history.push("/card")
                                         setTimeout(() => {
                                           if (window.scrollY == 0) {
                                             window.scrollTo(0, 1)
                                           }
                                           // $(`a[href='#${item2.code}']`).trigger("click")
                                         }, 500)
                                       } else if (item2.code == "/mint") {
                                         window.open('https://herocard.bitcoinlegend.org/', '_blank');
                                       }
                                     }}
                                  >
                                    {item2.title}
                                  </div>
                                )
                              })}
                            </li>
                          </ul>

                    }

                  </li>
                )
              })}

              <li className="nav-item dropdown ">
                <a className="nav-link active" href="#home" data-click="scroll-to-target"
                  data-scroll-target="#home" data-toggle="dropdown">
                  <img alt='' src={this.state.currentLang.icon} srcSet={this.state.currentLang.icon + " 1x"} />
                </a>
                <div className="dropdown-menu dropdown-menu-left animated fadeInDown">

                  {this.langList.map((item, index) => {
                    return (
                      <a key={index} className="dropdown-item" onClick={() => this.setLang(item.lang)}>
                        <img alt='' src={item.icon} srcSet={item.icon + " 1x"} />
                      </a>
                    )
                  })}

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(inject('rootStore')(observer(HeaderDesktop)));
