const moment = require('moment');

module.exports = {
    BASE_URL: false ? 'http://localhost:3001/api' : 'https://bitcoinlegend.io/api',

    PATH_PREFIX: '',
    AIRDRO_END_DAY: moment("2022-03-15 10:00"),
    AIRDROP_COUNT: "34000000",
    BCL_CONTRACT_ADDRESS: "0x7b75e328829f66bb1da0b3bcbe7e8123751a05f7",
    NEW_BCL_CONTRACT_ADDRESS: "0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44",
    BCL_PRESALE_RECV_ADDRESS: "0xf5Cb9710aBa765e92f0C32eD440Ce671E8327247", // 이더리움을 입금받는 주소
    BCL_PRESALE_SEND_ADDRESS: "0x133Fa7690C457e03C99Bf3D6311bD769432cA9e2", // BCL을 출금하는 주소
    WHITE_PAPER_URL: "https://www.dropbox.com/s/s6gwtuc6" +
        "jmkyv6o/white%20paper%20V%202.1_ENG.pdf?dl=0",
    APK_URL: "https://www.dropbox.com/sh/b251gwhyiccb3el/AAB9T5u3YF4qKHXzssw6pWZDa?dl=0",
    STORE: {
        GlobalState: "GlobalState",
        UiState: "UiState",
    },
    DISPLAY: {
        MOBILE: 'mobile',
        PAD: 'pad',
        DESKTOP: 'desktop',
    },

    GOOGLE_LINK: "http://bit.ly/3h8MgBy",
    APPLE_LINK: "http://apple.co/3oEODPq",
    APK_LINK: "http://bit.ly/3Ije0hU"
};
