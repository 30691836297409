import React from "react";
import $ from "jquery/src/jquery";
import { withRouter } from "react-router-dom";
import strings from "../../lang/strings";
import { inject, observer } from "mobx-react";
import "./main.scss";
import { PageSettings } from "../../config/page-settings";
import utils from "../../config/util";
import MuiltiProgressBar from "../../components/controls/multi-progress-bar";

import Const, {
  AIRDROP_COUNT,
  AIRDRO_END_DAY,
  APK_LINK,
  APK_URL,
  APPLE_LINK,
  BASE_URL,
  BCL_CONTRACT_ADDRESS,
  BCL_PRESALE_RECV_ADDRESS,
  BCL_PRESALE_SEND_ADDRESS,
  GOOGLE_LINK,
  WHITE_PAPER_URL,
  NEW_BCL_CONTRACT_ADDRESS,
} from "../../config/const";
import axios from "axios";
import { EmailShareButton } from "react-share";
import { Modal, ModalBody } from "reactstrap";

const moment = require("moment");

var ua = navigator.userAgent.toLowerCase();
var isSafari = false;
if (ua.indexOf("safari") != -1 || ua.indexOf("iphone") != -1) {
  if (ua.indexOf("chrome") > -1) {
    isSafari = false;
  } else {
    isSafari = true;
  }
}

class Main extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      seconds: null,
      totalUser: 0,
      airdropRemainCount: 10000000,
      inputtedRecommendCode: "",
      recommendMsg: "",
      shareMsg: "",
      showBepModal: false,
      inputEmail: "",
      inputErcAddress: "",
      inputBerAddress: "",
      inputAmount: 0,
    };

    this.presaleEndsAt = null;
    this.totalUser = null;
    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  componentDidMount() {
    this.getSettingInfo();
    // this.checkBEP()
  }

  checkBEP = () => {
    this.setState({ showBepModal: true });
  };

  checkBEP2 = () => {
    // this.setState({ showBepModal: true })
    // window.open(`https://bscscan.com/token/0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44`)
    // let str = strings.bep_button_title2;
    // if (window.confirm(str))
    {
      window.open(
          `https://bscscan.com/token/0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44`
      );
    }
  };

  getTotalMinedCount = () => {
    axios
        .get(`${BASE_URL}/user/totalUserCnt`, {})
        .then((res) => {
          this.state.totalUser = res.data.userCount;
        })
        .catch((err) => console.log("오류발생", err));
  };

  startAirDropTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(() => {
      const secs = Math.round(
          moment.duration(moment(this.presaleEndsAt).diff(moment())).asSeconds()
      );
      if (secs < 0) {
        this.setState({ seconds: 0 });
      }
      this.setState({ seconds: secs });
    }, 1000);
  };

  getSettingInfo = () => {
    axios
        .get(`${BASE_URL}/setting`, {})
        .then((res) => {
          console.log(JSON.stringify(res));
          if (res.data.presaleEndsAt) {
            this.presaleEndsAt = res.data.presaleEndsAt;
            this.startAirDropTimer();

            this.setState({
              airdropRemainCount: Math.min(
                  5000000,
                  30000000 - res.data.tmpAirdroppedAmount
              ),
            });
          } else {
            this.presaleEndsAt = AIRDRO_END_DAY;
            this.startAirDropTimer();
          }
          this.getTotalMinedCount();
        })
        .catch((err) => {
          console.log("설정정보 불러오기 에러", err);
          this.presaleEndsAt = AIRDRO_END_DAY;
          this.startAirDropTimer();
        });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  onClickAirDrop() {
    console.log("onClickAirDrop");
  }

  onConfirmTrasaction() {
    if (this.state.inputEmail == "") {
      alert(strings.bep_input_email);
      return;
    }
    if (this.state.inputErcAddress == "") {
      alert(strings.bep_input_erc);
      return;
    }
    if (this.state.inputBerAddress == "") {
      alert(strings.bep_input_ber);
      return;
    }
    if (this.state.inputAmount == "") {
      alert(strings.bep_input_amount);
      return;
    }
    let params = new URLSearchParams();
    params.append("email", this.state.inputEmail);
    params.append("erc_address", this.state.inputErcAddress);
    params.append("ber_address", this.state.inputBerAddress);
    params.append("amount", this.state.inputAmount);

    axios
        .post(`${BASE_URL}/payment/bepCoinswap`, params)
        .then((res) => {
          if (res.data.data == "success") {
            alert(strings.bep_request_complete);
            this.setState({ showBepModal: false });
          } else {
            console.log("false");
            alert(strings.bep_request_check);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(<strings className="bep_request_check"></strings>);
        });
  }

  onConfirmRecommendCode() {
    if (this.state.inputtedRecommendCode == "") {
      alert(strings.recommend_code_non_exist);
      return;
    }
    axios
        .get(
            `${BASE_URL}/auth/checkRecommendCode?code=${this.state.inputtedRecommendCode}`,
            {}
        )
        .then((res) => {
          if (res.data == "success") {
            this.setState({
              recommendMsg:
                  strings.recommend_msg_part1 +
                  this.state.inputtedRecommendCode +
                  strings.recommend_msg_part2 +
                  window.location.host,
            });
          } else {
            alert(strings.recommend_code_non_exist);
          }
        })
        .catch((err) => {
          console.log("설정정보 불러오기 에러", err);
          alert(strings.recommend_code_non_exist);
        });
  }

  onShare() {
    if (this.state.recommendMsg == "") {
      alert(strings.recommend_code_verify);
      return;
    }

    navigator.clipboard
        .writeText(this.state.recommendMsg.replace(/<br\/>/g, "\n"))
        .then(() => {
          alert("Copied");
        })
        .catch(() => {
          alert("something went wrong");
        });
  }

  onClickPreSale() {
    console.log("onClickPreSale");
  }

  onClickPreSaleGo() {
    window.open(`https://etherscan.io/address/${BCL_PRESALE_SEND_ADDRESS}`);
  }

  onClickShare() {
    console.log("onClickShare");
  }

  onClickSendMsg() {
    console.log("onClickSendMsg");
    window.open("http://bit.ly/38WoRyN", "_blank");
  }

  onClickTwitter() {
    console.log("onClickTwitter");
    window.open("http://bit.ly/3tEQDt4", "_blank");
  }

  onCopy() {
    var copyText = document.querySelector("#migration_address");
    function handler(event) {
      event.clipboardData.setData("text/plain", copyText.value);
      event.preventDefault();
      document.removeEventListener("copy", handler, true);
    }

    document.addEventListener("copy", handler, true);
    document.execCommand("copy");
    setTimeout(() => {
      alert("Copied");
    }, 500);
    // copyText.select();
    // document.execCommand("copy",false , "0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7");
    // // document.execCommand("copy",false,copyText.value);
    // setTimeout(() => {
    //   alert("Copied");
    //
    // }, 500)
  }

  onClickCopyPreSaleAddress() {
    var copyText = document.querySelector("#presale_address");
    copyText.select();
    document.execCommand("copy");
    setTimeout(() => {
      alert("Copied");
    }, 500);
  }

  render() {
    // return (
    //   <div style={{ marginTop: 200, height: 100, width: 100 }}>
    //     <EmailShareButton body={"abc"} subject='DEF' separator="EEEE" style={{ width: "100%", height: "100%" }} >
    //       <div>EMAIL</div>
    //     </EmailShareButton>
    //     <FacebookShareButton quote="adlkjfkdjfkdjf" hashtag='dfekjfkejfk'>
    //       <div>FACEBOOK SHARE</div>
    //     </FacebookShareButton>
    //     <OKShareButton title="adlkjfkdjfkdjf" description='dfekjfkejfk' url="d">
    //       <div>OK SHARE</div>
    //     </OKShareButton>
    //     <WhatsappShareButton title="adlkjfkdjfkdjf" seperator='dfekjfkejfk' url="d">
    //       <div>Whats app SHARE</div>
    //     </WhatsappShareButton>
    //     <TwitterShareButton title="adlkjfkdjfkdjf" seperator='dfekjfkejfk' url="d">
    //       <div>Twitter SHARE</div>
    //     </TwitterShareButton>
    //     <TelegramShareButton title="ddd" url="abc">
    //       <div>Telegram SHARE</div>
    //     </TelegramShareButton>
    //   </div>
    // )
    return (
        <div className="main">
          <div id="home" className="content has-bg home">
            <div className="home-top">
              <div
                  className="content-bg"
                  style={{ backgroundImage: "url(/assets/img/img_bg_stars1.png)" }}
                  data-paroller="true"
                  data-paroller-factor="0.5"
                  data-paroller-factor-xs="0.25"
              ></div>
              <img
                  className="bottom-hill"
                  src="/assets/img/img_pattern_hills.png"
                  srcSet="/assets/img/img_pattern_hills.png 1x"
              />
              <div className="container home-content">
                <div className="video-content">
                  <iframe src="https://www.youtube.com/embed/TJgIVSCwCQg"></iframe>
                  <img
                      className="video-bg"
                      src="/assets/img/ic_video_container1.png"
                      srcSet="/assets/img/ic_video_container1.png 1x"
                  />
                </div>
                <div className="search-content">
                  {/* <div className="desc">{strings.widely_known_desc}</div> */}
                  <div className="center" style={{}}>
                    <a
                        className="search-box google"
                        target="_blank"
                        href="https://www.google.com/search?q=bitcoinlegend&oq=bi&aqs=chrome.0.69i59j69i57j69i59j69i61j69i60l3.1216j0j15&sourceid=chrome&ie=UTF-8"
                    >
                      <img
                          src="/assets/img/ic_button_google_search.png"
                          srcSet="/assets/img/ic_button_google_search.png 1x"
                      />
                    </a>
                    <a
                        className="search-box youtube"
                        target="_blank"
                        href="http://bit.ly/3AsXqcJ"
                    >
                      <img
                          src="/assets/img/ic_button_youtube_search.png"
                          srcSet="/assets/img/ic_button_youtube_search.png 1x"
                      />
                    </a>
                    <a
                        className="search-box youtube"
                        target="_blank"
                        href="http://bit.ly/3rvhXtb"
                    >
                      <img
                          src="/assets/img/ic_button_naver_search.png"
                          srcSet="/assets/img/ic_button_naver_search.png 1x"
                      />
                    </a>
                    {/* <a className="search-box youtube" target="_blank"
                    href="https://www.lbank.info/exchange/bcl/usdt">
                    <img src="/assets/img/ic_lbank_notice.png" style={{ borderRadius: 32, overflow: "hidden" }}
                      srcSet="/assets/img/ic_lbank_notice.png 1x" />
                  </a> */}
                  </div>
                </div>
                <div className="link-content">
                  <a href="http://bit.ly/3OkgtfX" target="_blank">
                    <img
                        src="/assets/img/ic_sns_discord.png"
                        srcSet="/assets/img/ic_sns_discord.png 1x"
                    />
                  </a>
                  {/* <a href="https://www.facebook.com" target="_blank">
                  <img src="/assets/img/ic_sns_facebook.png" srcSet="/assets/img/ic_sns_facebook.png 1x" />
                </a> */}
                  <a href="http://bit.ly/3tDkKkA" target="_blank">
                    <img
                        src="/assets/img/ic_sns_insta.png"
                        srcSet="/assets/img/ic_sns_insta.png 1x"
                    />
                  </a>
                  <a href="http://bit.ly/3AsXqcJ" target="_blank">
                    <img
                        src="/assets/img/ic_sns_youtube.png"
                        srcSet="/assets/img/ic_sns_youtube.png 1x"
                    />
                  </a>
                  <a href="http://bit.ly/38WoRyN" target="_blank">
                    <img
                        src="/assets/img/ic_sns_telegram.png"
                        srcSet="/assets/img/ic_sns_telegram.png 1x"
                    />
                  </a>
                  <a href="http://bit.ly/3tEQDt4" target="_blank">
                    <img
                        src="/assets/img/ic_sns_twitter.png"
                        srcSet="/assets/img/ic_sns_twitter.png 1x"
                    />
                  </a>
                  <a
                      href="https://cafe.naver.com/bitcoinlegendkorea/807"
                      target="_blank"
                  >
                    <img
                        src="/assets/img/ic_sns_kakao.png"
                        srcSet="/assets/img/ic_sns_kakao.png 1x"
                    />
                  </a>
                </div>
              {/*  /!* <div className="link-content">*/}
              {/*  <a href="https://open.kakao.com/o/gOLzUjJd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao1.png" srcSet="/assets/img/ic_sns_kakao1.png 1x" />*/}
              {/*  </a>*/}
              {/*  <a href="https://open.kakao.com/o/g9i901Wd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao2.png" srcSet="/assets/img/ic_sns_kakao2.png 1x" />*/}
              {/*  </a>*/}
              {/*  <a href="https://open.kakao.com/o/giYf21Wd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao3.png" srcSet="/assets/img/ic_sns_kakao3.png 1x" />*/}
              {/*  </a>*/}
              {/*  <a href="https://open.kakao.com/o/giZKgNXd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao4.png" srcSet="/assets/img/ic_sns_kakao4.png 1x" />*/}
              {/*  </a>*/}
              {/*  <a href="https://open.kakao.com/o/g4Pk8PXd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao5.png" srcSet="/assets/img/ic_sns_kakao5.png 1x" />*/}
              {/*  </a>*/}
              {/*  <a href="https://open.kakao.com/o/gjWM8PXd" target="_blank">*/}
              {/*    <img src="/assets/img/ic_sns_kakao6.png" srcSet="/assets/img/ic_sns_kakao6.png 1x" />*/}
              {/*  </a>*/}
              {/*</div> *!/*/}
              </div>
            </div>
            <div className="home-main">
              <div className="earn-project-content">
                <div className="title">{strings.earn_project_title}</div>
                <div className="desc">{strings.earn_project_desc}</div>
              </div>
              <div
                  className="airdrop-ending-content"
                  style={{
                    backgroundImage: "url(/assets/img/img_bg_airdrop_ending.png)",
                  }}
              >
                <div className="title">{strings.airdrop_ending_title}</div>
                <div className="number-panel">
                  <div className="panel">
                    <div
                        className="pad"
                        style={{
                          backgroundImage: "url(/assets/img/ic_timer_bg.png)",
                        }}
                    >
                      {parseInt(Math.max(0, this.state.seconds) / (24 * 3600))}
                    </div>
                    <div className="desc">{strings.days}</div>
                  </div>
                  <div className="panel">
                    <div
                        className="pad"
                        style={{
                          backgroundImage: "url(/assets/img/ic_timer_bg.png)",
                        }}
                    >
                      {parseInt(
                          (Math.max(0, this.state.seconds) % (24 * 3600)) / 3600
                      )}
                    </div>
                    <div className="desc">{strings.hours}</div>
                  </div>
                  <div className="panel">
                    <div
                        className="pad"
                        style={{
                          backgroundImage: "url(/assets/img/ic_timer_bg.png)",
                        }}
                    >
                      {parseInt((Math.max(0, this.state.seconds) % 3600) / 60)}
                    </div>
                    <div className="desc">{strings.minutes}</div>
                  </div>

                  <div className="panel">
                    <div
                        className="pad"
                        style={{
                          backgroundImage: "url(/assets/img/ic_timer_bg.png)",
                        }}
                    >
                      {Math.max(0, this.state.seconds) % 60}
                    </div>
                    <div className="desc">{strings.seconds}</div>
                  </div>
                </div>

                <div className="remain-count-content">
                  {/* <span className="title">{strings.airdrop_remain_count}</span>
                <span className="value">{utils.currencyFormat(this.state.airdropRemainCount)}</span>
                <span className="unit">{strings.bcl}</span> */}
                  <br />
                  <span className="title_user">
                  {"Total Users"}{" "}
                    <span className="title_count">
                    {utils.currencyFormat(this.state.totalUser)}
                  </span>
                </span>
                  <br />
                  <br />
                  <span className="title">
                  {
                    "The Recommended Event is to be continued until Users reaches 3 million"
                  }
                </span>
                </div>
                {/* <div className="airdrop-desc-content">
                <button className="ended" dangerouslySetInnerHTML={{ __html: strings.airdrop_first_desc }}></button>
                <button className="ended" dangerouslySetInnerHTML={{ __html: strings.airdrop_second_desc }}></button>
                <button className="progressing" dangerouslySetInnerHTML={{ __html: strings.airdrop_third_desc }}></button>
              </div> */}
                <div className="action-content">
                  <button
                      className="airdrop-btn"
                      href="#airdrop"
                      data-click="scroll-to-target"
                      data-action="button"
                  >
                    {strings.airdrop}
                  </button>
                  <button
                      className="pre-sale"
                      href="#presale"
                      data-click="scroll-to-target"
                      data-action="button"
                  >
                    {strings.pre_sale}
                  </button>
                </div>
                <div
                    className="game-desc-content"
                    // style={{
                    //   backgroundImage: "url(/assets/img/ic_game_desc_content.png)",
                    // }}
                >
                  <div
                      className="title"
                      style={{
                        backgroundImage: "url(/assets/img/ic_gradient1.png)",
                      }}
                  ></div>
                  <div className="three_earn_strategy">
                    {strings.three_earn_strategy}
                  </div>
                  <div className="content-round">
                    <div className="row">
                      <div className="col-4 col-md-4 h-center icon">
                        <img
                            src="/assets/img/ic_mine_to_earn.png"
                            srcSet="/assets/img/ic_mine_to_earn.png 1x"
                        />
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: strings.mining_to_earn_desc,
                            }}
                        ></div>
                      </div>
                      <div className="col-4 col-md-4 h-center icon">
                        <img
                            src="/assets/img/ic_mine_to_earn.png"
                            srcSet="/assets/img/ic_make_to_earn.png 1x"
                        />
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: strings.make_to_earn_desc,
                            }}
                        ></div>
                      </div>
                      <div className="col-4 col-md-4 h-center icon">
                        <img
                            src="/assets/img/ic_mine_to_earn.png"
                            srcSet="/assets/img/ic_play_to_earn.png 1x"
                        />
                        <div
                            className="desc"
                            dangerouslySetInnerHTML={{
                              __html: strings.play_to_earn_desc,
                            }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div className="increase_strategy_title">
                    {strings.increase_strategy_title}
                  </div>
                  <div className="increase_strategy_desc">
                    {strings.increase_strategy_0}
                  </div>
                  <div
                      className="increase_strategy_desc"
                      style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                        className="increase_strategy_mark"
                        style={{ visibility: "hidden" }}
                    >
                      {strings.increase_strategy_2}
                    </div>
                    {strings.increase_strategy_1}
                    <a
                        className="increase_strategy_mark"
                        target="_blank"
                        href="https://coinmarketcap.com/rankings/exchanges/"
                    >
                      {strings.increase_strategy_2}
                    </a>
                  </div>

                  <div
                      className="increase_strategy_desc"
                      style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                        className="increase_strategy_mark"
                        style={{ visibility: "hidden" }}
                    >
                      {strings.increase_strategy_4}
                    </div>
                    {strings.increase_strategy_3}
                    <a
                        className="increase_strategy_mark"
                        target="_blank"
                        href="https://www.lbank.info/"
                    >
                      {strings.increase_strategy_4}
                    </a>
                  </div>
                  <div
                      className="increase_strategy_desc"
                      style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                        className="increase_strategy_mark"
                        style={{ visibility: "hidden" }}
                    >
                      {strings.increase_strategy_6}
                    </div>
                    {strings.increase_strategy_5}
                    <a
                        className="increase_strategy_mark"
                        target="_blank"
                        href="https://pancakeswap.finance/"
                    >
                      {strings.increase_strategy_6}
                    </a>
                  </div>
                  <div
                      className="increase_strategy_desc"
                      style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                        className="increase_strategy_mark"
                        style={{ visibility: "hidden" }}
                    >
                      {strings.increase_strategy_6}
                    </div>
                    {strings.increase_strategy_7}
                    <a
                        className="increase_strategy_mark"
                        target="_blank"
                        href="https://m.mexc.com/"
                    >
                      {strings.increase_strategy_7_1}
                    </a>
                  </div>
                  <div className="increase_strategy_desc">
                    {strings.increase_strategy_8}
                  </div>
                  <div className="increase_strategy_desc">
                    {strings.increase_strategy_9}
                  </div>
                  <div className="increase_strategy_desc">
                    {strings.increase_strategy_10}
                  </div>
                </div>
                <div className="robot-content">
                  <img
                      src="/assets/img/ic_logo_miner.png"
                      srcSet="/assets/img/ic_logo_miner.png 1x"
                  />
                </div>
              </div>
            </div>
          </div>

          <div
              id="about"
              className="content about"
              data-scrollview="true"
              style={{
                backgroundImage: "url(/assets/img/img_bg_header1.png)",
              }}
          >
            {/* <div className="content-bg left">
            <img
              src="/assets/img/ic_logo_trans.png"
              srcSet="/assets/img/ic_logo_trans.png 1x"
            />
          </div> */}
            {/* <div className="content-bg right">
            <img
              src="/assets/img/ic_logo_trans.png"
              srcSet="/assets/img/ic_logo_trans.png 1x"
            />
          </div> */}
            <div
                className="about-content"
                data-animation="true"
                data-animation-type="fadeInDown"
            >
              <div className="content-title">{strings.about_title}</div>
              <div
                  className="content-desc"
                  style={
                    this.globalState.getLang() == "ar"
                        ? { textAlign: "right" }
                        : this.uiState.display_mode === Const.DISPLAY.DESKTOP
                            ? { textAlign: "center" }
                            : { textAlign: "left" }
                  }
                  dangerouslySetInnerHTML={{ __html: strings.about_desc }}
              />
              <div
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? { width: "100%", display: "flex" }
                        : { width: "100%", display: "block" }
                  }
              >
                <div
                    className="box-content"
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? { width: "45%" }
                          : { width: "95%" }
                    }
                >
                  <div className="title">{strings.smart_contract}</div>
                  <ul>
                    <li>
                      {strings.token_symbol}
                      <span className="value">BCL</span>
                    </li>
                    <li>
                    <span style={{ minWidth: 84 }}>
                      {strings.contract_address}
                    </span>
                      <span className="value">{BCL_CONTRACT_ADDRESS}</span>
                      <a
                          className="link-action"
                          target="_blank"
                          href={`https://etherscan.io/token/${BCL_CONTRACT_ADDRESS}`}
                      >
                        <img
                            src="/assets/img/ic_link.png"
                            srcSet="/assets/img/ic_link.png 1x"
                        />
                      </a>
                    </li>
                    <li>
                      {strings.decimals}
                      <span className="value">18</span>
                    </li>
                    <li>
                      {strings.total_supply}
                      <span className="value">21,000,000,000</span>
                    </li>
                    {/*<li>{strings.blockchain}<span className="value">Ethereum ERC20</span></li>*/}
                  </ul>
                </div>
                {/*{ this.uiState.display_mode }*/}
                {/*{ Const.DISPLAY.DESKTOP }*/}
                <div
                    className="box-content"
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? { width: "45%", marginLeft: "3vw" }
                          : { width: "95%", marginTop: "5px" }
                    }
                >
                  <div className="title">{strings.smart_contract1}</div>
                  <ul>
                    <li>
                      {strings.token_symbol}
                      <span className="value">BCL</span>
                    </li>
                    <li>
                    <span style={{ minWidth: 84 }}>
                      {strings.contract_address}
                    </span>
                      <span className="value">{NEW_BCL_CONTRACT_ADDRESS}</span>
                      <a
                          className="link-action"
                          target="_blank"
                          href={`https://bscscan.com/token/${NEW_BCL_CONTRACT_ADDRESS}`}
                      >
                        <img
                            src="/assets/img/ic_link.png"
                            srcSet="/assets/img/ic_link.png 1x"
                        />
                      </a>
                    </li>
                    <li>
                      {strings.decimals}
                      <span className="value">18</span>
                    </li>
                    <li>
                      {strings.total_supply}
                      <span className="value">16,000,000,000</span>
                    </li>
                    {/*<li>{strings.blockchain}<span className="value">Ethereum ERC20</span></li>*/}
                  </ul>
                </div>
              </div>
              <div
                  className="box-content"
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? { width: "95%", marginTop: "50px" }
                        : { width: "95%", marginTop: "5px" }
                  }
              >
                <div className="title">{strings.smart_contract2}</div>
                <ul>
                  <li>
                    <span style={{ minWidth: 84 }}>{strings.smart_url}</span>
                    <span className="value">
                    https://etherscan.io/token/{BCL_CONTRACT_ADDRESS}
                      ?a=0x0000000000000000000000000000000000000000
                  </span>
                    <a
                        className="link-action"
                        target="_blank"
                        href={`https://etherscan.io/token/${BCL_CONTRACT_ADDRESS}?a=0x0000000000000000000000000000000000000000`}
                    >
                      <img
                          src="/assets/img/ic_link.png"
                          srcSet="/assets/img/ic_link.png 1x"
                      />
                    </a>
                  </li>
                  <li>
                    {strings.smart_amount}
                    <span className="value">5,000,000,000</span>
                  </li>
                </ul>
              </div>
              <div className="box-content white-paper m-t-40">
                <a href={WHITE_PAPER_URL} target="_blank">
                  {strings.white_paper}
                </a>
              </div>
            </div>
          </div>

          <div id="airdrop" className="content airdrop" data-scrollview="true">
            {/* <div className="content-bg">
            <img
              className="one"
              src="/assets/img/f.png"
              srcSet="/assets/img/f.png 1x"
            />
            <img
              className="one"
              src="/assets/img/a.png"
              srcSet="/assets/img/a.png 1x"
            />
            <img
              className="two"
              src="/assets/img/b.png"
              srcSet="/assets/img/b.png 1x"
            />
            <img
              className="three"
              src="/assets/img/c.png"
              srcSet="/assets/img/c.png 1x"
            />
            <img
              className="five"
              src="/assets/img/b.png"
              srcSet="/assets/img/b.png 1x"
            />
            <img
              className="four"
              src="/assets/img/d.png"
              srcSet="/assets/img/d.png 1x"
            />
          </div> */}
            <div
                className="airdrop-content"
                data-animation="true"
                data-animation-type="fadeInDown"
            >
              <div className="content-title">{strings.airdrop_title}</div>
              {/* <div
              className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>
              {strings.airdrop_quantity}
              <span className="quantity-value">{utils.currencyFormat(5000000)}</span>
              <span className="quantity-unit">{strings.bcl}</span>
            </div> */}
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.airdrop_rules}
              </div>
              <div
                  className="content-desc m-t-25"
                  style={
                    this.globalState.getLang() == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "center" }
                  }
                  dangerouslySetInnerHTML={{
                    __html: strings.airdrop_rules_airdrop_desc,
                  }}
              />
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.invitation_rules}
              </div>
              <div
                  className="content-desc m-t-25"
                  style={
                    this.globalState.getLang() == "ar"
                        ? { textAlign: "right" }
                        : { textAlign: "center" }
                  }
                  dangerouslySetInnerHTML={{
                    __html: strings.invitation_rules_desc,
                  }}
              />
              <div
                  className={
                      "box-content " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-60"
                          : "m-t-30")
                  }
              >
                <div className="title">{strings.invite_team}</div>
                <div
                    className={"content"}
                    dangerouslySetInnerHTML={{ __html: strings.invite_team_desc }}
                />
                {/* <div className="link-content">
                <button className="first" onClick={() => this.onClickShare()}>
                  <img src="/assets/img/ic_share.png" srcSet="/assets/img/ic_share.png 1x" />
                </button>
                <button className="second" onClick={() => this.onClickSendMsg()}>
                  <img src="/assets/img/ic_share_telegram.png" srcSet="/assets/img/ic_share_telegram.png 1x" />
                </button>
                <button className="third" onClick={() => this.onClickTwitter()}>
                  <img src="/assets/img/ic_share_twitter.png" srcSet="/assets/img/ic_share_twitter.png 1x" />
                </button>
              </div> */}
              </div>

              <div className="recommend-section">
                <div
                    className={
                        "section-title " +
                        (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                            ? "m-b-65"
                            : "m-b-20")
                    }
                >
                  {strings.recommend_title}
                </div>

                <div className="input-container">
                  <div className="code-input">
                    <input
                        type="text"
                        placeholder={strings.recommend_input_code}
                        value={this.state.inputtedRecommendCode}
                        onChange={(e) => {
                          this.setState({
                            inputtedRecommendCode: e.target.value,
                            recommendMsg: "",
                          });
                        }}
                    />
                    <button onClick={() => this.onConfirmRecommendCode()}>
                      {strings.go}
                    </button>
                  </div>
                  <div className="step-indicator">
                    {this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? "►"
                        : "▼"}
                  </div>
                  <div className="code-confirm-container">
                    <span>{strings.recommend_code_right}</span>
                    <div
                        className="confirmed-code-box"
                        dangerouslySetInnerHTML={{
                          __html:
                              this.state.recommendMsg == ""
                                  ? strings.recommend_inputted_code
                                  : this.state.recommendMsg,
                        }}
                    />
                  </div>
                  <div className="step-indicator">
                    {this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? "►"
                        : "▼"}
                  </div>
                  <div
                      className="share-button-container"
                      onClick={() => this.onShare()}
                  >
                    <span>{strings.recommend_msg_copy}</span>
                    <img
                        src="/assets/img/ic_share1.png"
                        srcSet="/assets/img/ic_share1.png 1x"
                        style={
                          this.uiState.display_mode === Const.DISPLAY.DESKTOP
                              ? { height: "66px" }
                              : { height: "40px" }
                        }
                    />
                  </div>
                  {/* <EmailShareButton ref={input => this.shareBtnElement = input} body={this.state.shareMsg} subject='[BitcoinLegend] referral link' separator="  | Thank you" style={{ visibility: "hidden" }} >
                  <div>EMAIL</div>
                </EmailShareButton> */}
                </div>

                <div className="link-content">
                  <button onClick={() => this.onClickSendMsg()}>
                    <img
                        src="/assets/img/ic_share_telegram1.png"
                        srcSet="/assets/img/ic_share_telegram1.png 1x"
                    />
                  </button>
                  <button onClick={() => this.onClickTwitter()}>
                    <img
                        src="/assets/img/ic_share_twitter1.png"
                        srcSet="/assets/img/ic_share_twitter1.png 1x"
                    />
                  </button>
                </div>
              </div>

              <div className="market-link">
                <a href="http://bit.ly/3h8MgBy" target="_blank">
                  <img
                      src="/assets/img/ic_download_google.png"
                      srcSet="/assets/img/ic_download_google.png 1x"
                  />
                </a>
                <a href="http://apple.co/3oEODPq" target="_blank">
                  <img
                      src="/assets/img/ic_download_apple.png"
                      srcSet="/assets/img/ic_download_apple.png 1x"
                  />
                </a>
                {/* <a href={APK_URL} target="_blank">
                <img
                  src="/assets/img/ic_download_apk.png"
                  srcSet="/assets/img/ic_download_apk.png 1x"
                />
              </a> */}
              </div>

              <div
                  id="presale"
                  name="presale"
                  data-scrollview="true"
                  className={
                      "content-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-40")
                  }
              >
                {strings.bep_title}
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-40")
                  }
              >
                {strings.bep_title1}
                {/* <button className={"action " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "hide" : "")}
                onClick={() => this.onClickCopyPreSaleAddress()}>
                <img src="/assets/img/ic_copy.png" srcSet="/assets/img/ic_copy.png 1x" />
              </button> */}
                <div className="text-value">
                  {strings.bep_desc1}
                  {/* {BCL_PRESALE_RECV_ADDRESS} */}

                  {/* <button className={"action " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "" : "hide")}
                  onClick={() => this.onClickCopyPreSaleAddress()}>
                  <img src="/assets/img/ic_copy.png" srcSet="/assets/img/ic_copy.png 1x" />
                </button> */}
                </div>
              </div>
              {/* copy 용으로 만든  */}
              <input
                  id="presale_address"
                  ref={(input) => (this.inputElement = input)}
                  value={BCL_PRESALE_RECV_ADDRESS}
                  style={{
                    fontSize: 10,
                    padding: 0,
                    color: "transparent",
                    height: isSafari ? 1 : 0,
                  }}
                  readOnly={true}
              />
              <input
                  id="migration_address"
                  ref={(input) => (this.inputElement = input)}
                  value="0x7b1b0Db7Ed528908F6cD601fBE54C84bfEAd881e"
                  type="hidden"
                  readOnly={true}
              />
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-20"
                          : "m-t-10")
                  }
              >
                {strings.bep_title2}
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-20"
                          : "m-t-10")
                  }
              >
                <div
                    className="content-desc m-t-20"
                    style={
                      this.globalState.getLang() == "ar"
                          ? { textAlign: "right" }
                          : this.uiState.display_mode === Const.DISPLAY.DESKTOP
                              ? { textAlign: "center" }
                              : { textAlign: "left" }
                    }
                    dangerouslySetInnerHTML={{ __html: strings.bep_desc2 }}
                />
              </div>

              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                <div
                    className="content-desc m-t-20"
                    style={
                      this.globalState.getLang() == "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "center" }
                    }
                >
                <span
                    dangerouslySetInnerHTML={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? { __html: strings.bep_title6 }
                          : { __html: strings.bep_title12 }
                    }
                />
                  {/* <a className="increase_strategy_mark2"
                   onClick='return false;'>Completed</a> */}
                </div>
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-30"
                          : "m-t-15")
                  }
              >
                {/*<img src="/assets/img/img_bep1.png" style={{}} srcSet="/assets/img/img_bep1.png 1x" />*/}
                <div
                    className="content-desc m-t-20"
                    style={
                      this.globalState.getLang() == "ar"
                          ? { textAlign: "right" }
                          : { textAlign: "center" }
                    }
                >
                <span
                    dangerouslySetInnerHTML={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? { __html: strings.bep_title7 }
                          : { __html: strings.bep_title13 }
                    }
                />
                </div>
              </div>

              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.bep_title9}
              </div>
              <div
                  className="content-desc m-t-20"
                  style={
                    this.globalState.getLang() == "ar"
                        ? { textAlign: "right" }
                        : this.uiState.display_mode === Const.DISPLAY.DESKTOP
                            ? { textAlign: "center" }
                            : { textAlign: "left" }
                  }
                  dangerouslySetInnerHTML={{ __html: strings.bep_title10 }}
              />

              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.bep_title4}
              </div>
              <div
                  className="content-desc m-t-20"
                  style={
                    this.globalState.getLang() == "ar"
                        ? { textAlign: "right" }
                        : this.uiState.display_mode === Const.DISPLAY.DESKTOP
                            ? { textAlign: "center" }
                            : { textAlign: "left" }
                  }
                  dangerouslySetInnerHTML={{ __html: strings.bep_desc4 }}
              />
              {/* <div className="content-desc"
                 style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { textAlign: "center" } : { textAlign: "left"})  }
                 dangerouslySetInnerHTML={{ __html: strings.bep_desc41 }} />
            <div className="content-desc"
                 style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { textAlign: "center" } : { textAlign: "left"})  }
            >{strings.bep_desc42} &nbsp;
              <a className="link-action" target="_blank"
                 href={`https://www.youtube.com/watch?v=1l5MPDuX85I`}>
                <img src="/assets/img/ic_link.png" srcSet="/assets/img/ic_link.png 1x" style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { } : { width: "5vw"})} />
              </a>
            </div>
            <div className="content-desc"
                 style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { textAlign: "center" } : { textAlign: "left"})  }
            >{strings.bep_desc43 } &nbsp;
            <a className="link-action" target="_blank"
               href={`https://www.youtube.com/watch?v=tbj4ci9wtqs`}>
              <img src="/assets/img/ic_link.png" srcSet="/assets/img/ic_link.png 1x" style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { } : { width: "5vw"})} />
            </a>
          </div> */}
              {/*<a className="how-to-presale" target="_blank"*/}
              {/*  href="https://youtu.be/1DnXE67ACkg">*/}
              {/*  <img src="/assets/img/ic_how_to_presale.png" style={{}}*/}
              {/*    srcSet="/assets/img/ic_how_to_presale.png 1x" />*/}
              {/*</a>*/}

              {/*<div className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.bep_title5}</div>*/}
              {/*<div className="content-desc m-t-15"*/}
              {/*  style={this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}}*/}
              {/*  dangerouslySetInnerHTML={{ __html: strings.bep_desc5 }} />*/}
              <div
                  className="content-desc m-t-20"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{ __html: strings.bep_title8 }}
                  onClick={() => this.onCopy()}
              >
                {/*<div className="share-button-container" onClick={() => this.onShare()}>*/}
                {/*  <span>{strings.recommend_msg_copy}</span>*/}

                {/*</div>*/}
              </div>
              <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center", color: "#aaaaaa" }}
              >
                {" "}
                {strings.bel_desc44}
                <a
                    className="increase_strategy_mark2"
                    target="_blank"
                    href={`https://etherscan.io/token/0x7b75e328829f66bb1da0b3bcbe7e8123751a05f7`}
                >
                  Etherscan
                </a>
                {/* <div
                className="share-button-container"
                onClick={() => this.onShare()}
              >
                <span>{strings.recommend_msg_copy}</span>
              </div> */}
              </div>
              <div className="check-pre-sale">
                <input
                    type="text"
                    placeholder={strings.bep_button_title2}
                    onClick={() => this.checkBEP2()}
                    readOnly
                />
                <button onClick={() => this.checkBEP2()}>{strings.go}</button>
              </div>
            </div>
          </div>

          <div
              id="total_supply"
              className="content total-supply"
              data-scrollview="true"
          >
            <div className="content-bg">
              <img
                  className="top"
                  src="/assets/img/img_bg_distribution.png"
                  srcSet="/assets/img/img_bg_distribution.png 1x"
              />
              {/* <img
              className="bottom"
              src="/assets/img/e.png"
              srcSet="/assets/img/e.png 1x"
            /> */}
            </div>
            <div
                className="total-supply-content"
                data-animation="true"
                data-animation-type="fadeInDown"
            >
              <div className="content-title">
                {strings.total_supply_and_distribution}
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-60"
                          : "m-t-30")
                  }
              >
                {strings.the_number_of_miner}
                <div>
                  <span className="number-value">50M</span>
                  <span className="number-unit">{strings.users}</span>
                  <span className="number-unit">
                  <img
                      className="arrow"
                      src="/assets/img/img_bg_arrow.png"
                      srcSet="/assets/img/img_bg_arrow.png 1x"
                      width="30"
                      height="10"
                  />
                </span>
                  <span className="number-value">10M</span>
                  <span className="number-unit">{strings.users}</span>
                </div>
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.end_of_mining}
                <div>
                  <span className="text-value">{strings.end_of_mining_desc}</span>
                </div>
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.total_supply1}
                <div>
                <span className="number-value">
                  {utils.currencyFormat(21000000000)}
                </span>
                  <span className="number-unit">{strings.bcl}</span>
                  <span className="number-unit" style={{ fontWeight: "bold" }}>
                  <img
                      className="arrow"
                      src="/assets/img/img_bg_arrow.png"
                      srcSet="/assets/img/img_bg_arrow.png 1x"
                      width="30"
                      height="10"
                  />
                </span>
                  <span className="number-value">
                  {utils.currencyFormat(11000000000)}
                </span>
                  <span className="number-unit">{strings.bcl}</span>
                </div>
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.burning_and_buyback}
                <div>
                <span className="number-value">
                  {utils.currencyFormat(10000000000)}
                </span>
                  <span className="number-unit">{strings.bcl}</span>
                </div>
              </div>
              <div
                  className={
                      "section-title " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-50"
                          : "m-t-25")
                  }
              >
                {strings.community_airdrop}
                <div>
                <span className="number-value">
                  {utils.currencyFormat(AIRDROP_COUNT)}
                </span>
                  <span className="number-unit">{strings.bcl}</span>
                </div>
              </div>
              <div className="halving-chart">
                <div>
                  <img
                      src="/assets/img/img_halving_chart1.png"
                      srcSet="/assets/img/img_halving_chart1.png 1x"
                  />
                </div>
              </div>
              <div
                  className="content-title"
                  style={{
                    marginTop:
                        this.uiState.display_mode === Const.DISPLAY.DESKTOP ? 120 : 0,
                  }}
              >
                {strings.distribution_bcl_supply}
              </div>
              <div className="table-content">
                <img
                    src="/assets/img/img_distribution.png"
                    srcSet="/assets/img/img_distribution.png 1x"
                />
              </div>
            </div>
          </div>

          <div id="roadmap" className="content roadmap" data-scrollview="true">
            <div className="content-bg">
              <img
                  src="/assets/img/img_bg_loadmap1.png"
                  srcSet="/assets/img/img_bg_loadmap1.png 1x"
              />
            </div>
            <div
                className="roadmap-content"
                data-animation="true"
                data-animation-type="fadeInDown"
            >
              <div className="content-title">{strings.roadmap}</div>
              <div
                  className={
                      "item-content " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-80"
                          : "m-t-30")
                  }
              >
                {/*<div className="title long">{strings.roadmap_last_title}</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phase_1_2_date}</div>
                <ul className="check-circle icon" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.roadmap_last_item_0}</li>
                  <li
                      dangerouslySetInnerHTML={{
                        __html: strings.roadmap_last_item_1,
                      }}
                  />
                  <li>{strings.roadmap_last_item_3}</li>
                </ul>
              </div>
              <div className="item-connector">
                <div className="circle-big" />
                <div className="circle-small" />
              </div>
              <div className="item-content">
                {/*<div className="title">*/}
                {/*  /!* <div>{strings.now}</div> *!/*/}
                {/*  {strings.phase3}*/}
                {/*</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phase3_date}</div>
                <ul className="check-circle icon" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phase3_item_0}</li>
                  <li>{strings.phase3_item_1}</li>
                  <li>{strings.phase3_item_2}</li>
                  <li>{strings.phase3_item_3}</li>
                  <li>{strings.phase3_item_4}</li>
                </ul>
              </div>
              <div className="item-connector">
                <div className="circle-big" />
                <div className="circle-small" />
              </div>
              <div className="item-content">
                {/*<div className="title">{strings.phase4}</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phase4_sector_0_date}</div>
                <ul className="check-circle icon" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phase4_sector_0_item_0}</li>
                  <li>{strings.phase4_sector_0_item_1}</li>
                  <li>{strings.phase4_sector_0_item_2}</li>
                  <li>{strings.phase4_sector_0_item_3}</li>
                  <li>{strings.phase4_sector_0_item_4}</li>
                  <li>{strings.phase4_sector_0_item_5}</li>
                  <li>{strings.phase4_sector_0_item_6}</li>
                  <li>{strings.phase4_sector_0_item_7}</li>
                </ul>
                <div
                    className={
                        "date " +
                        (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                            ? "m-t-50"
                            : "m-t-20")
                    }
                    style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}
                >
                  {strings.phase4_sector_1_date}
                </div>
                <ul className="check-circle icon" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phase4_sector_1_item_0}</li>
                  <li>{strings.phase4_sector_1_item_1}</li>
                  <li>{strings.phase4_sector_1_item_2}</li>
                  <li>{strings.phase4_sector_1_item_3}</li>
                  <li>{strings.phase4_sector_1_item_4}</li>
                  <li>{strings.phase4_sector_1_item_5}</li>
                </ul>
                {/*<div*/}
                {/*  className={*/}
                {/*    "date " +*/}
                {/*    (this.uiState.display_mode === Const.DISPLAY.DESKTOP*/}
                {/*      ? "m-t-50"*/}
                {/*      : "m-t-20")*/}
                {/*  }*/}
                {/*>*/}
                {/*  {strings.phase4_sector_2_date}*/}
                {/*</div>*/}
                {/*<ul className="check-circle icon">*/}
                {/*  <li>{strings.phase4_sector_2_item_0}</li>*/}
                {/*  <li>{strings.phase4_sector_2_item_1}</li>*/}
                {/*  <li>{strings.phase4_sector_2_item_2}</li>*/}
                {/*  <li>{strings.phase4_sector_2_item_3}</li>*/}
                {/*  <li>{strings.phase4_sector_2_item_4}</li>*/}
                {/*</ul>*/}
              </div>
              <div className="item-connector">
                <div className="circle-big" />
                <div className="circle-small" />
              </div>
              <div className="item-content">
                {/*<div className="title">{strings.phase5}</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phases5_sector_0_date}</div>
                <ul className="check-circle icon" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phases5_sector_0_item_0}</li>
                  <li>{strings.phases5_sector_0_item_1}</li>
                  <li>{strings.phases5_sector_0_item_2}</li>
                  <li>{strings.phases5_sector_0_item_4}</li>
                </ul>
                <ul className="check-circle" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phases5_sector_0_item_3}</li>
                  <li>{strings.phases5_sector_0_item_5}</li>
                </ul>
                <br />
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phases5_sector_0_date_2}</div>
                <ul className="check-circle" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phases5_sector_0_item_0_2}</li>
                  <li>{strings.phases5_sector_0_item_1_2}</li>
                  <li>{strings.phases5_sector_0_item_2_2}</li>
                  <li>{strings.phases5_sector_0_item_3_2}</li>
                </ul>
              </div>
              <div className="item-connector">
                <div className="circle-big" />
                <div className="circle-small" />
              </div>
              <div className="item-content">
                {/*<div className="title">{strings.phases6}</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phases6_sector_0_date_5}</div>
                <ul className="check-circle" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li>{strings.phases6_sector_0_item_0_5}</li>
                  <li>{strings.phases6_sector_0_item_1_5}</li>
                  <li>{strings.phases6_sector_0_item_2_5}</li>
                  <li>{strings.phases6_sector_0_item_3_5}</li>
                  {/*<li>{strings.phase6_sector_0_item_3}</li>*/}
                </ul>
              </div>
              <div className="item-connector">
                <div className="circle-big" />
                <div className="circle-small" />
              </div>
              <div className="item-content">
                {/*<div className="title">{strings.phases7}</div>*/}
                <div className="date" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>{strings.phases7_sector_0_date_5}</div>
                <ul className="check-circle" style={this.globalState.getLang() == "ar"? { textAlign: "right" }: { textAlign: "left" }}>
                  <li
                      dangerouslySetInnerHTML={{
                        __html: strings.phases7_sector_0_item_0_5,
                      }}
                  />
                  <li>{strings.phases7_sector_0_item_1_5}</li>
                  <li>{strings.phases7_sector_0_item_2_5}</li>
                  <li>{strings.phases7_sector_0_item_3_5}</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="download" className="content download">
            <div className="action-content">
              <div
                  className="title"
                  style={{
                    backgroundImage: "url(/assets/img/ic_download.png)",
                  }}
              >
                {/* <div>{strings.download_now}</div> */}
              </div>
              <div className="download-hint">{strings.download_now_desc}</div>
              <div className="referring-hint">{strings.referring_hint}</div>
              <img
                  className={
                      "mark-icon " +
                      (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                          ? "m-t-100"
                          : "m-t-50")
                  }
                  src="/assets/img/ic_logo_bottom.png"
                  srcSet="/assets/img/ic_logo_bottom.png 1x"
              />

              <div className="market-link">
                <a href="http://bit.ly/3h8MgBy" target="_blank">
                  <img
                      src="/assets/img/ic_download_google.png"
                      srcSet="/assets/img/ic_download_google.png 1x"
                  />
                </a>
                <a href="http://apple.co/3oEODPq" target="_blank">
                  <img
                      src="/assets/img/ic_download_apple.png"
                      srcSet="/assets/img/ic_download_apple.png 1x"
                  />
                </a>
                {/* <a href={APK_URL} target="_blank">
                <img
                  src="/assets/img/ic_download_apk.png"
                  srcSet="/assets/img/ic_download_apk.png 1x"
                />
              </a> */}
              </div>
              <div
                  className="join-us-hint"
                  dangerouslySetInnerHTML={{ __html: strings.join_us_hint }}
              />
              <div className="link-content">
                <a href="http://bit.ly/3OkgtfX" target="_blank">
                  <img
                      src="/assets/img/ic_sns_discord.png"
                      srcSet="/assets/img/ic_sns_discord.png 1x"
                  />
                </a>
                {/* <a href="https://www.facebook.com" target="_blank">
                <img src="/assets/img/ic_sns_facebook.png" srcSet="/assets/img/ic_sns_facebook.png 1x" />
              </a> */}
                <a href="http://bit.ly/3tDkKkA" target="_blank">
                  <img
                      src="/assets/img/ic_sns_insta.png"
                      srcSet="/assets/img/ic_sns_insta.png 1x"
                  />
                </a>
                <a href="http://bit.ly/3AsXqcJ" target="_blank">
                  <img
                      src="/assets/img/ic_sns_youtube.png"
                      srcSet="/assets/img/ic_sns_youtube.png 1x"
                  />
                </a>
                <a href="http://bit.ly/38WoRyN" target="_blank">
                  <img
                      src="/assets/img/ic_sns_telegram.png"
                      srcSet="/assets/img/ic_sns_telegram.png 1x"
                  />
                </a>
                <a href="http://bit.ly/3tEQDt4" target="_blank">
                  <img
                      src="/assets/img/ic_sns_twitter.png"
                      srcSet="/assets/img/ic_sns_twitter.png 1x"
                  />
                </a>
                <a
                    href="https://cafe.naver.com/bitcoinlegendkorea/807"
                    target="_blank"
                >
                  <img
                      src="/assets/img/ic_sns_kakao.png"
                      srcSet="/assets/img/ic_sns_kakao.png 1x"
                  />
                </a>
              </div>
              {/* <div className="link-content">
              <a href="https://open.kakao.com/o/gOLzUjJd" target="_blank">
                <img src="/assets/img/ic_sns_kakao1.png" srcSet="/assets/img/ic_sns_kakao1.png 1x" />
              </a>
              <a href="https://open.kakao.com/o/g9i901Wd" target="_blank">
                <img src="/assets/img/ic_sns_kakao2.png" srcSet="/assets/img/ic_sns_kakao2.png 1x" />
              </a>
              <a href="https://open.kakao.com/o/giYf21Wd" target="_blank">
                <img src="/assets/img/ic_sns_kakao3.png" srcSet="/assets/img/ic_sns_kakao3.png 1x" />
              </a>
              <a href="https://open.kakao.com/o/giZKgNXd" target="_blank">
                <img src="/assets/img/ic_sns_kakao4.png" srcSet="/assets/img/ic_sns_kakao4.png 1x" />
              </a>
              <a href="https://open.kakao.com/o/g4Pk8PXd" target="_blank">
                <img src="/assets/img/ic_sns_kakao5.png" srcSet="/assets/img/ic_sns_kakao5.png 1x" />
              </a>
              <a href="https://open.kakao.com/o/gjWM8PXd" target="_blank">
                <img src="/assets/img/ic_sns_kakao6.png" srcSet="/assets/img/ic_sns_kakao6.png 1x" />
              </a>
            </div> */}

              <div className="agree-policy">
                I agree to{" "}
                <a
                    href="https://www.dropbox.com/s/mx782mtb3uryi9z/TERMS%20OF%20SERVICES%20Rev0%28211012%29.pdf?dl=0
"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                >
                  the terms and conditions
                </a>{" "}
                and&nbsp;
                <a
                    href="https://www.dropbox.com/s/k4t4qfidp7ugsje/Policy%20Rev0%28211012%29.pdf?dl=0
"
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                >
                  privacy policy
                </a>
                .
              </div>

              <div className="license">{strings.license}</div>
            </div>
          </div>

          <Modal
              isOpen={this.state.showBepModal}
              toggle={this.toggleModal}
              style={{ maxWidth: 363, margin: "100px auto", borderRadius: 20 }}
          >
            <ModalBody
                style={{ padding: 0, borderRadius: 20, background: "#272831" }}
            >
              <div className="column text-center">
                <div
                    className="desc"
                    style={{
                      marginTop: 30,
                      fontSize: 20,
                      color: "white",
                      marginLeft: 18,
                      marginRight: 18,
                    }}
                >
                  {" "}
                  [Notice of temporary suspension of KYC verification]
                </div>
                <div
                    className="desc"
                    style={{
                      marginTop: 30,
                      fontSize: 13,
                      display: "flex",
                      marginLeft: 18,
                      marginRight: 18,
                    }}
                >
                  We apologize for any inconvenience caused by credit card
                  payment. <br />
                  <br />
                  It is currently 3 days for KYC verification, but due to
                  excessive card payment related to KYC verification, an emergency
                  check has been sent to the card company for abnormal access.
                  <br />
                  <br />
                  Currently, card payment has been suspended, and the BCL
                  Foundation is planning to proceed urgently to explain that it is
                  card payment related to KYC authentication.
                  <br />
                  <br />
                  We will stop KYC verification for a while.
                  {/*    <div style={{ width: "30%", marginRight: 5, height:30, padding: 5, background: "#FFFFFF"}}>E-mail</div>*/}
                  {/*    <div style={{ width: "70%", background: "#FFFFFF",height:30, padding: 5}}>*/}
                  {/*      <input type="text" placeholder="" value={this.state.inputEmail} onChange={(e) => {*/}
                  {/*        this.setState({ inputEmail: e.target.value });*/}
                  {/*      }} style={{ width: "100%", height: "100%" }}  />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="desc"  style={{ marginTop: 5, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>*/}
                  {/*    <div style={{ width: "30%", marginRight: 5, height:30, padding: 5, background: "#FFFFFF"}}>From ERC20</div>*/}
                  {/*    <div style={{ width: "70%", background: "#FFFFFF",height:30, padding: 5}}>*/}
                  {/*      <input type="text" placeholder="" value={this.state.inputErcAddress} onChange={(e) => {*/}
                  {/*        this.setState({ inputErcAddress: e.target.value });*/}
                  {/*      }} style={{ width: "100%", height: "100%" }} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="desc"  style={{ marginTop: 5, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>*/}
                  {/*    <div style={{ width: "30%", marginRight: 5, height:30, padding: 5, background: "#FFFFFF"}}>To BEP20</div>*/}
                  {/*    <div style={{ width: "70%", background: "#FFFFFF",height:30, padding: 5}}>*/}
                  {/*      <input type="text" placeholder="" value={this.state.inputBerAddress} onChange={(e) => {*/}
                  {/*        this.setState({ inputBerAddress: e.target.value });*/}
                  {/*      }} style={{ width: "100%", height: "100%" }} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="desc"  style={{ marginTop: 5, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>*/}
                  {/*    <div style={{ width: "30%", marginRight: 5, height:30, padding: 5, background: "#FFFFFF"}}>Amount</div>*/}
                  {/*    <div style={{ width: "70%", background: "#FFFFFF",height:30, padding: 5}}>*/}
                  {/*      <input type="number" placeholder="" value={this.state.inputAmount} onChange={(e) => {*/}
                  {/*        this.setState({ inputAmount: e.target.value });*/}
                  {/*      }} style={{ width: "100%", height: "100%" }} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="desc" dangerouslySetInnerHTML={{ __html: strings.bep_popup_desc1 }} style={{ marginTop: 30, fontSize: 13, color: "white", textAlign: "left", marginLeft:18, marginRight:18 }}></div>*/}
                  {/*  <div className="desc"  style={{ marginTop: 30, fontSize: 13, color: "white",  marginLeft:18, marginRight:18 }}>*/}
                  {/*    <input type="checkbox" name="bep_check" id="bep_check" value="1" />*/}
                  {/*    {strings.bep_popup_agree}*/}
                  {/*  </div>*/}
                  {/*  <div className="desc"  style={{ marginTop: 30, fontSize: 13 }}>*/}
                  {/*  <button style={{ width: "30%", backgroundColor: "#D07674", borderRadius: 25, height: 50, color: "white",  fontSize: 16, marginBottom: 18 }}*/}
                  {/*          onClick={() => {*/}
                  {/*            this.setState({ showBepModal: false })*/}
                  {/*          }}>*/}
                  {/*    {strings.bep_exit}*/}
                  {/*  </button>*/}
                  {/*  <button style={{  width: "30%", backgroundColor: "#D07674", borderRadius: 25, height: 50, color: "white", marginLeft:28,  fontSize: 16, marginBottom: 18 }}*/}
                  {/*          onClick={() => {*/}
                  {/*            if ($("#bep_check").prop("checked")) {*/}
                  {/*              this.onConfirmTrasaction();*/}
                  {/*              // this.setState({ showBepModal: false })*/}
                  {/*            } else {*/}
                  {/*              alert(strings.bep_check_box)*/}
                  {/*            }*/}
                  {/*          }}>*/}
                  {/*    {strings.bep_submit}*/}
                  {/*  </button>*/}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
    );
  }
}

export default withRouter(inject("rootStore")(observer(Main)));
