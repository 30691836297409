import React from 'react';
import strings from '../../lang/strings.js';
import { Redirect, Switch } from 'react-router';
import { Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import routes from './../../config/page-route.jsx';
import Const from '../../config/const';
import U from '../../config/util';

function setTitle(path, routeArray) {
    var pageTitle;
    for (var i = 0; i < routeArray.length; i++) {
        if (routeArray[i].path === path) {
            if (routeArray[i].title) {
                pageTitle = strings.app_name + ' | ' + routeArray[i].title;
            } else {
                pageTitle = strings.app_name;
            }
        }
    }
    document.title = (pageTitle) ? pageTitle : strings.app_name;
}

class Content extends React.Component {
    constructor(props) {
        super(props);

        props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes);
        });
    }

    componentDidMount() {
        setTitle(this.props.history.location.pathname, routes);
    }

    render() {
        return (
            <div>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={
                                route.access === 'public' || localStorage.getItem(Const.STORE.GlobalState) ? route.component :
                                    (() => (
                                        <Redirect to={U.makePath('main')} />
                                    ))
                                }
                        />
                    ))}
                    <Route component={() => <Redirect to='/' />} />
                </Switch>
            </div>
        )
    }
}

export default withRouter(inject('rootStore')(observer(Content)));
