import { AIRDRO_END_DAY } from "../config/const";
import React from "react";

const moment = require("moment");

export default {
  app_name: "BitcoinLegend",
  app_name_short: "BCL",

  bcl: "BCL",
  days: "DAYS",
  hours: "HOURS",
  minutes: "MINUTES",
  seconds: "SECONDS",

  header_menu_list: [
    { code: "home", title: "Home" },
    {
      code: "nft",
      title: "Hero NFT Card",
      sub_header_menu_list: [
        { code: "/whitelist", title: "White List of NFT" },
        { code: "/card", title: "NFT Card" },
        { code: "/mint", title: "NFT Mint" },
        { code: "/gamefi", title: "Game-Fi Card" },
      ],
    },
    // { code: "about", title: "About" },
    { code: "airdrop", title: "Airdrop&Migration" },
    // { code: "levelup", title: "Level Up Program" },
    { code: "kyc", title: "KYC Certification" },
    // { code: "total_supply", title: "Total Supply & Distribution" },
    { code: "roadmap", title: "RoadMap" },
    // { code: "levelup", title: "LevelUp", }
  ],

  widely_known_desc:
    "BitcoinLegend is widely known. Touch the search bar below.",

  earn_project_title: "3 Earn Project",
  earn_project_desc: "Mine to Earn, Make to Earn, Play to Earn",

  airdrop_ending_title: "AIRDROP Ending",
  airdrop_remain_count: "Airdrop Remaining Count",
  airdrop_first_desc: "1st AIRDROP End<br/>10M BCL",
  airdrop_second_desc: "2nd/3rd/4rd AIRDROP End<br/>15M BCL",
  airdrop_third_desc: "5th AIRDROP Running<br/>5M BCL",
  airdrop: "AIRDROP",
  pre_sale: "Pre-Sale",

  ntf_avatar_metaverse_game: "NFT Avatar Metaverse Game",
  three_earn_strategy: "3 Earn Strategy",
  mining_to_earn_desc:
    "Mining & halving<br/>Referrals(+25%)<br/>Token Economy<br/>Android, iOS app<br/>Multi language Pack<br/>Lockup & Staking",
  make_to_earn_desc:
    "Sell & Buy NFTcard, Arts, Patent<br/>Self made system for user<br/>→BCL Studio<br/>NTF Market Auction",
  play_to_earn_desc:
    "1:1 Fighting/PVP game<br/>Virtual Real Estate transaction<br/>Ads/Enterance fee in fighting<br/>Broadcast channel<br/>On-line shopping",
  increase_strategy_title:
    "Token Buyback, Burning, NFT Business -> Price Strategy for Increase",
  increase_strategy_0:
    "ICO(600M BCL) : User 50M -> 10M, Burning 10B BCL(Step by Step)",
  increase_strategy_1: "IEO(CoinMarketCap : CMC Level)",
  increase_strategy_2: "CoinMarketCap",
  increase_strategy_3: "1st Listing / 15th March. 2022. / First Listing",
  increase_strategy_4: "LBank",
  increase_strategy_5: "2nd Listing / June. 2022. / PancakeSwap",
  increase_strategy_6: "PancakeSwap",
  increase_strategy_7: "3rd Listing / September. 2022. / ~Lv.20",
  increase_strategy_7_1: "MEXC",
  increase_strategy_8: "4th Listing / Quarter 4. 2022. / ~Lv.20",
  increase_strategy_9: "5th Listing / 2023. / ~Lv.10",
  increase_strategy_10: "6th Listing / 2023. / ~Lv.5",

  about_title: "About BitcoinLegend",
  about_desc:
    "<p>BitcoinLegend(BCL) is cryptocurrency assets based on ERC-20." +
    " We will make NFT market platform(NFT cards, artworks).<br/>" +
    "Also, We will launch a metaverse game with a Hero NFT card inspired by Marvel's Avengers.</p>" +
    "<p>Today’s world is in the fourth industrial revolution, and there is no doubt that blockchain technology will play an important role in it.<br/>" +
    "Created to realize people’s ideals, BITCOIN LEGEND (BCL) aims to become a platform that builds a decentralized application ecosystem using blockchain technology, cryptocurrency, and metaverse systems in the field of the fourth industrial revolution.</p>" +
    "<p>As a cryptocurrency based on ERC-20, BITCOIN LEGEND (BCL) features an upcoming NFT market platform and a future metaverse game with hero NFT card inspired by Marvel’s Avengers.<p/>" +
    "<p>There is a 3 Earn strategy in BITCOIN LEGEND that provide users 3 ways to earn more tokens.<p/>" +
    "<p>The first one is Mine to Earn, which addresses the problems of Bitcoin mining by making the mining process on smartphone<br/>" +
    "When the mined BCL is listed on major cryptocurrency exchanges, it serves as a digital asset as well as an investment.<p/>" +
    "<p>The second one is Make to Earn, users can make their own NFT avatars, NFT items, then buy and sell them to make a profit.<br/>" +
    "BITCOIN LEGEND uses AI technology to create webtoon characters similar to the real users themselves.<br/>" +
    "Users can also produce clothing, ornaments and other game items.<br/>" +
    "Hero History is made by Webtoon and Song (Webtoon+Rapper' Rap+NFT animation).<br/>" +
    "It got 300,000 views in 10 days after it was uploaded on YouTube.<p/>" +
    "<p>The third one is Play to Earn, BITCOIN LEGEND network will implement economic activities on the metaverse using previously created NFT avatar cards, as well as virtual real estate, users can play the game or invest in virtual real estate on the metaverse and make a profit from these activities.<p/>",
  smart_contract: "Smart Contract(Old token :ERC20)",
  smart_contract1: "Smart Contract(New token : BEP20)",
  smart_contract2: "Burning(ERC20)",
  smart_url: "URL : ",
  smart_amount: "Burning Amount : ",

  token_symbol: "Token Symbol : ",
  contract_address: "Contract Address : ",
  decimals: "Decimals : ",
  total_supply: "Total Supply : ",
  blockchain: "Blockchain : ",
  white_paper: "White Paper",

  airdrop_title: "Airdrop",
  airdrop_quantity: "Airdrop quantity : ",
  airdrop_rules: "Airdrop rules",
  airdrop_rules_airdrop_desc:
    "Community users can participate in ther airdrop by installing BitcoinLegend App.<br/>" +
    "Mining must be done at least until the end of the airdrop. The initial amount of airdrop is 25 BCL.<br/>" +
    "The airdrop is completely free, and the airdrop rewards will be distributed to mining app after the airdrop is over.",
  invitation_rules: "Invitation rules",
  invitation_rules_desc:
    "Users can get additional BCL token rewards by inviting friends to participate in the airdrop. <br /> You can get 25 BCL tokens for each friend you invite. You can invite up to 50 friends, and get up to 1,250 BCL tokens.<br /> The quantity for airdrop is limited, and BCL issuance is small.",
  invite_team: "Invite Team",
  invite_team_desc:
    "+ 25 BCL/User. Max. 50 Users<br/>+@25% mining count of Invited User",

  recommend_title: "How to recommend your friends",
  recommend_code_right: "Is your recommended code alright?",
  recommend_input_code: "Input Recommend code",
  recommend_inputted_code: "Your recommended code",
  recommend_msg_copy: "Touch Here",
  recommend_code_non_exist:
    "This is an invalid referral code. Please check it again.",
  recommend_code_verify: "Please enter the referral code and verify it.",
  recommend_msg_part1:
    "BitcoinLegend is not mining app but also metaverse game with a Hero NFT card inspired by Marvel's Avengers.<br/><br/>☆Join BitcoinLegend to get 10 BCL for free.<br/>Recommended code : ",
  recommend_msg_part2: "<br/><br/>☆Official website<br/>",

  pre_sale_title: "Pre-sale",
  pre_sale_quantity: "Pre-sale quantity : ",
  pre_sale_address: "Pre-sale address : ",
  pre_sale_price: "Pre-sale price : ",
  pre_sale_price_desc:
    "The minimum purchase is 0.1 ETH, and the maximum purchase is 10 ETH.",

  airdrop_rules_presale: "Pre-sale Standards",
  airdrop_rules_pre_sale_desc:
    "Users can get additional BCL token rewards by inviting friends to participate in the airdrop. <br /> You can get 50 BCL tokens for each friend you invite. You can invite up to 25 friends, and get up to 2,500 BCL tokens.<br /> The quantity for airdrop is limited, and BCL issuance is small. The estimated price of BCL is $0.75. Hurry up and share it with your friends.",
  how_to_do_pre_sale: "How to do Pre-sale",
  how_to_do_pre_sale_desc:
    "Use your wallet to send ETH to the Pre-sale address. Our system will send BCL tokens to your wallet instantly.<br /> Remittances must be made using a personal cryptocurrency wallet without KYC verification.<br /> Personal cryptocurrency wallets include metamask, trustwallet, mew wallet, and imToken etc.",
  pre_sale_rules: "Pre-sale rules",
  pre_sale_rules_desc:
    "The system will end the pre-sale <h6><u>at " +
    moment(AIRDRO_END_DAY).format("HH:mm (UTC+8) on MMMM DD, YYYY") +
    "</u></h6>.<br/>" +
    "Use your wallet to send ETH to the pre-sale address, and the system will immediately send the corresponding amount of BCL tokens to your wallet. <br/>" +
    "The quantity of BCL tokens for pre-sale is limited, and the quota is sorted according to the arrival time of ETH. First come first served.",

  once_the_pre_sale_over: "Once the pre-sale is over",
  once_the_pre_sale_over_desc:
    "<h6><u>60% of the funds raised will be used for NFT Market and listing on LBank & MEXC.</u></h6><br/>" +
    "The other 40% of the funds raised will be used to buyback BCL tokens. This action will prevent people from dumping.",

  check_pre_sale: "Check Pre-sale",
  go: "Go",
  check_pre_sale_hint:
    "No KYC, Send Immediately BCL to Pre-sale address after sending  ETH",

  total_supply_and_distribution: "Total supply & Distribution",
  the_number_of_miner: "The number of Miner : ",
  users: "Users",
  end_of_mining: "End of Mining : ",
  end_of_mining_desc: "Mining will be finished when 10M users approached.",
  total_supply1: "Total Supply : ",
  burning_and_buyback: "Burning & Buyback : ",
  community_airdrop: "Community airdrop : ",
  bcl_halving: "BitcoinLegend Halving",
  distribution_bcl_supply: "Distribution of BCL supply",

  roadmap: "Roadmap",
  roadmap_last_title: "Accomplished milestones up to date(Phase1 & Phase2)",
  phase_1_2_date: '020- Q2. 2021',
  roadmap_last_item_0: "Project development team(BCL core team) builded",
  roadmap_last_item_1:
    "Mobile app and BitcoinLegend Concept developed<br/>(Coin Advantage, Mining  Method, Token Economy, Mining App & Wallet Design)",
  roadmap_last_item_3: "Whiter paper V1.0 made",

  now: "Now",
  phase3: "Phase3",
  phase3_date: "Q3.-Q4. 2021.",
  phase3_item_0: "Android/iOS mobile app released",
  phase3_item_1: "Official community(Telegram, Twitter, Youtube) opend",
  phase3_item_2: "44 countries' expanded language pack added ",
  phase3_item_3: "Update Token Economy(including Burining)",
  phase3_item_4: "Hero NFT card design completed",

  phase4: "Phase4",
  phase4_sector_0_date: "Q1.-Q2. 2022",
  phase4_sector_0_item_0: "Official website launched",
  phase4_sector_0_item_1: "Renewal of White paper V2.0(expending 3 Earn strategy)",
  phase4_sector_0_item_2: "User's mining monitor and ping fuction developed",
  phase4_sector_0_item_3: "Hero NFT Card Webtoon and Rap Youtube opened",
  phase4_sector_0_item_4: "Official Discord opened",
  phase4_sector_0_item_5: "Lockup & Staking funding system launched",
  phase4_sector_0_item_6: "Expansion development of KYC authentication Started after Sulvey",
  phase4_sector_0_item_7: "BCL listed on the L Bank and PancakeSwap",

  phase4_sector_1_date: "Q3.-Q4. 2022",
  phase4_sector_1_item_0: "Network migration from ERC20 to BEP20(Binance Smart Chain)",
  phase4_sector_1_item_1: "Whitelist and minting of Hero NFT card started",
  phase4_sector_1_item_2:
    "KYC certification started",
  phase4_sector_1_item_3: "Hero NFT card websited opened",
  phase4_sector_1_item_4: "1st NFT RPG game realeased(Dark worrior)",
  phase4_sector_1_item_5: "BCL listed on the  Coingecko and CoinMarketGap",

  phase4_sector_2_date: "Q1.-Q2 2023",
  phase4_sector_2_item_0:
    "BCL settlement and withdrawal started",
  phase4_sector_2_item_1: "Login sytem of NFT RPG games added(Super Fighter&Dark worrior)",
  phase4_sector_2_item_2: "Game reward website released",
  phase4_sector_2_item_3: "NFT rental service started(including rarity advatage)",
  phase4_sector_2_item_4: "Web 3.0 fuction of the BCL app updated \n (including web browser, chatting and NFT games connection)",

  phase5: "Phase5",
  phase5_sector_0_date: "Q3.-Q4. 2022",
  phase5_sector_0_item_0: "Network migration from ERC20 to BEP20(Binance Smart Chain)",
  phase5_sector_0_item_1: "Whitelist and minting of Hero NFT card started",
  phase5_sector_0_item_2:
    "Conceptualize and develop NFT Plaform<br/>(selling , buying and Auction NFT card and items)",
  phase5_sector_0_item_3: "BCL listed on MEXC Crytocurrency exchange",
  phase5_sector_0_item_4: "3rd Burning",
  phase5_sector_0_item0:
    'White List of NFT cards(Buy & Burn only BCL) in the "Make to Earn" Project  ',
  phase5_sector_0_item1: "KYC  Authonification Started",
  phase5_sector_0_item2: "BCL Settlement and Withdrawal Started",

  phases5_sector_0_date: "Q1.-Q2 2023",
  phases5_sector_0_item_0: "BCL settlement and withdrawal started",
  phases5_sector_0_item_1: "Login sytem of NFT RPG games added(Super Fighter&Dark worrior)",
  phases5_sector_0_item_2:
    "Game reward website released",
  phases5_sector_0_item_3: "NFT rental service started(including rarity advatage)",
  phases5_sector_0_item_4:
    "Web 3.0 fuction of the BCL app updated \n(including web browser, chatting and NFT games connection)",
  phases5_sector_0_item_5: "3rd Roguelike game realeased",
  phases5_sector_0_item_6: "7. Mining standard revised",
  phases5_sector_0_item_7: "8. NFT project promotional video opened",

  phases5_sector_0_date_2: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_2: "Auction fuction of Hero NFT minting website added",
  phases5_sector_0_item_1_2: "NFT card of BCL app displayed",
  phases5_sector_0_item_2_2:
    "4th NFT Hero card game released(with multiplayer)",
  phases5_sector_0_item_3_2: "5th MMORG game released",

  phases5_sector_0_date_3: "December. 2022",
  phases5_sector_0_item_0_3: "1. 6th Hero NFT card promoted",
  phases5_sector_0_item_1_3:
    "2. 3D layered MaxForm City launched and NFT whitelist started",
  phases5_sector_0_item_2_3:
    "3. Development history of Maxform hero card shared",
  phases5_sector_0_item_3_3:
    "4. Development of Maxform city war game started(4 months)",
  phases5_sector_0_item_4_3: "5. Prototype game of PlaytoEarn released",

  phases5_sector_0_date_4: "Q1. 2023",
  phases5_sector_0_item_0_4: "BCL settlement and withdrawal started",
  phases5_sector_0_item_1_4: "Login sytem of NFT RPG games added(Super Fighter&Dark worrior)",
  phases5_sector_0_item_2_4: "Game reward website released",
  phases5_sector_0_item_3_4:
    "NFT rental service started(including rarity advatage)",
  phases5_sector_0_item_4_4:
    "Web 3.0 fuction of the BCL app updated <br> (including web browser, chatting and NFT games connection)",
  phases5_sector_0_item_5_4: "3rd Roguelike game realeased",
  phases5_sector_0_item_6_4:
    "∙ Conceptualize and develop NFT platform (selling, buying and Auction NFT card and items)",
  phases5_sector_0_item_7_4:
    "∙ Avatar economic activity platform created by yourself",

  phases5_sector_0_date_5: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_5:
    "Auction fuction of Hero NFT minting website added",
  phases5_sector_0_item_1_5: "NFT card of BCL app displayed",
  phases5_sector_0_item_2_5: "4th NFT Hero card game released(with multiplayer)",
  phases5_sector_0_item_3_5: "5th MMORG game released",

  phases6: "Phase6",
  phases6_sector_0_date_5: "2024",
  phases6_sector_0_item_0_5: "Development of MaxForm world (Metaverse) started",
  phases6_sector_0_item_1_5: "Develop Hero NFT card using self made studio(using AI function)",
  phases6_sector_0_item_2_5: "Avatar economic activity platform created by yourself",
  phases6_sector_0_item_3_5: "6th TCG of Hero card game released",

  phases7: "Phase7",
  phases7_sector_0_date_5: "2025",
  phases7_sector_0_item_0_5: "MaxForm world(Metaverse) opened",
  phases7_sector_0_item_1_5: "More partnerships of MaxForm world signed",
  phases7_sector_0_item_2_5:
    "More of BCL Ecosystem spreaded",
  phases7_sector_0_item_3_5: "BCL mainnet developed",

  phase6: "Phase6",
  phase6_sector_0_date: "Q4. 2022",
  phase6_sector_0_item_0:
    'NFT platform launched in the "Make to Earn" Project ',
  phase6_sector_0_item_1: "Develop Metaverse Game using NFT cards",
  phase6_sector_0_item_2: "BCL listed on Major exchanges under CMC Lv.20",
  phase6_sector_0_item_3: "4th Burning",

  phase7: "Phase7",
  phase7_sector_0_date: "2023",
  phase7_sector_0_item_0:
    'Beta testfied NFT Avatar Metaverse game in the " Play to Earn" Project <br/>• 1:1 and PVP Game (Avatar game)<br/>• Rental and sale of virtual real estate',
  phase7_sector_0_item_1:
    "Avatar economic activity platform created by yourself  ",
  phase7_sector_0_item_2:
    "BCL listed on Major exchanges under CMC Lv.10 & Lv. 5",
  phase7_sector_0_item_3: "5th Burning",

  download_now: "Download now",
  download_now_desc:
    "Download the android, iOS or APK file appAnd start mining today.",
  referring_hint: "Earn 25% extra by referring someone else",
  join_us_hint:
    "Join us on our online channel to stay<br/>" +
    "Up-to-date with BitcoinLegend community",
  license: "© 2021 BitcoinLegend. All rights reserved",

  nft_about_title: "About Hero NFT Card & Metaverse",
  nft_about_desc:
    '<p>☑The metaverse was first mentioned in Neal Stephenson\'s "Snow Crash" in 1992. <br/>' +
    "The metaverse is a second life platform that is a world where you can connect with the minds of others anytime and anywhere.<br/>" +
    "The metaverse is a state in which reality and various worlds are combined, such as universe 1, universe 2, and universe 3, <br/>" +
    "and it is in a state of pseudo-reality to the point where we do not know which one is the real me in the metaverse, who lives a different life from the real me.<p/>" +
    "<p>☑Avatar can meet and communicate in a transcendent space and engage in economic activities using AR and VR functions with anonymity.<br/>" +
    "To meet as an avatar in a transcendent space is an innovation. It will lead the world innovation for the next 20 years in the form of a collapse of the concept of time and space.</p>" +
    "<p>☑Everyone has a vision and a dream.<br/>" +
    "However, most of them cannot make it a reality and keep it in their mind.<br/>" +
    "In particular, efforts are being made to resolve economic poverty and dissatisfaction with reality.<br/>" +
    "However, the results of these efforts are difficult to achieve in reality.</p>" +
    "<p>Bitcoin Legend was created to realize people's ideals.</p>" +
    "<p>☑Users make their own NFT avatars, make NFT items, and buy and sell them to make a profit. <br/>" +
    "BCL Core Team use AI technology to create webtoon characters similar to the real me. <br/>" +
    "The technologies applied at this time are Facial Landmark Engine and Facial Landmark Trigger in the Self Made Studio. <br/>" +
    "Using this technology, users can directly create a avatar that resembles the real me. It is possible to change the hair style, face shape, and even eye color. <br/>" +
    "In addition, You can produce the various clothing, ornaments and game items. Also, you can buy them that you made. <br/>" +
    "These items can be auctioned by encrypting them using NFT making technology.</p>",
  maxform_desc:
    "<p>MaxForm Studio is a user-centered self-made system.<br/>" +
    "The user changes the face after taking a photo, and restores anonymity with a mask.<br/>" +
    "It is also decorated with User Generated Contents(UGC).<br/>" +
    "Maxform is a space where avatars are active in the metaverse space created by Hero NFT Card.</p>",
  shape_made_tech: "Shape made Tech",
  shape_made_tech_desc:
    "<p>When you shoot with a smartphone, Facial landmark Engine and Trigger is working according to the AI ​​face recognition function.<br/>" +
    "Face is made by Webtoon image and some users have different tendencies toward anonymity. <br/>" +
    "When you want to change your face, you can change the color of your face, hair color, hair style, eyebrows, etc.<br/>",
  shape_made_system: "Self made system for user",
  shape_made_system_desc:
    "<p>All costumes amd accessories can be designed and produced by one's own on the costume making platform. <br/>" +
    "In addition, you can choose the type of mask you want to maintain elegance.<br/>" +
    "This can be bought and sold in the NFT market, and in the Max Form metaverse game, <br/>" +
    "Accessories and costumess won in 1:1 fighting games will become more famous and their prices will rise.<br/>" +
    "And the designer who made it rises to honor more and more. Naturally, his prize increases in price.</p>",
  hero_nft_card: "Hero NFT Card",
  hero_nft_card_desc:
    "<p>BitcoinLegend is not just a mining app, but will become Top Tier in the NFT card business and metaverse game.<br/>" +
    "BCL network intend to pioneer the cryptocurrency ecosystem by establishing  3 Earn strategy. <br/>" +
    'This is "Make to Earn" strategy among the 3 Earn strategies. The Hero NFT Card puts the life of a Hero on a card. <br/>' +
    "The Hero NFT card hero appears in the metaverse game. The card has a hero story and an item inventory.<br/>" +
    "Hero stories allow users to tell their own stories. Inventory can store skills, equipments, and materials. <br/>" +
    "All of these items can be bought, sold, and auctioned.</p>",
  media_partners: "Media & Partners",
  nft_modal_content:
    "<p>☑️4x high speed mining event(All users) <br/><br />" +
    "☑️1NFT = 3,900BCL/94Matic/0.05 ETH</p>",
  go_hero_nft_card: "Buy Hero NFT Card",
  go_hero_nft_close: "Close the window",
  bep_title: "Migration from ERC20 to BEP20",
  bep_title1: "Purpose of Migration",
  bep_desc1: "To reduce gas fee incurred during withdrawal and minting NFT",
  bep_title2: "Swap ratio when migrating",
  bep_desc2:
    "1. The swap is conducted for users who have purchased BCL previously,<br /> and additional payments are made to reduce the cost of gas fee incurred when depositing. <br /> BCL mined is paid as BEP20 when paid after KYC, so there is no need for swap. <br /> 2. Increase the additional payment rate when swapping Lbank cryptocurrency exchanges for trading activity for BCL liquidity",
  bep_title3: "Lbank crytocurrency",
  bep_desc3: "ERC20 : BEP20 = 1 : 1.1 (10% additional payment)",
  bep_title4: "How to do Migration",
  bep_desc4:
    "1. Lbank Crytocurrency exchange\n <br />" +
    "There is nothing for you to do. BCL is swapped inside the Lbank exchange and deposited into a new wallet.\n <br />" +
    "2. BCL Website (Here)\n<br />" +
    "Use a personal wallet such as Metamask, Trust Wallet, or Im Wallet that you deposited previously.\n<br />" +
    "Send ERC20 based BCL to the BCL foundation's address as follows. Our system will send BEP20 based BCL token to your wallet instantly. <br />",
  bep_desc41: "3. Personal cryto-wallet that can be migrated <br />",
  bep_desc42: " Metamask ( How to get BEP20 address ) ",
  bep_desc43: " Trust wallet ( How to get BEP20 address )  ",
  bel_desc44:
    "Etherscan : Check to transfer from your wallet to BCL Foundation using ERC20 based BCL",
  bep_title5: "Migration application",
  bep_desc5:
    "Please prepare to copy the ERC20 wallet address and BEP20 wallet address before applying for migration.",
  bep_button_title: "Swap at Lbank by June 29th, please",
  bep_submit: "Submit",
  bep_exit: "Exit",
  bep_popup_desc1:
    '[ Notice on Migration ]<br /> 1. Please make sure that your wallet address is correct before touching the button "submit"<br /> 2. We are not responsible for incorrect or omission of wallet address<br /> 3. The transaction takes about 3-5 minutes.',
  bep_popup_title1: "Migration application",
  bep_popup_agree: "Agree to above notice",
  bep_input_email: "Input Email Address",
  bep_input_erc: "Input ERC20 Address",
  bep_input_ber: "Input BEP20 Address",
  bep_input_amount: "Input Amount",
  bep_check_box: "Please click the checkbox",
  bep_request_complete: "request has been completed.",
  bep_request_check: "Please check the item",
  bep_title6:
    "<font color='yellow'>Lbank crytocurrency exchange:</font> ERC20 : BEP20 = 1 : 1.1 (10% additional payment)",
  bep_title7:
    "<font color='yellow'>BitcoinLegend Website:</font> ERC20 : BEP20 = 1 : 1.03 (3% additional payment)",
  bep_title8:
    "<font color='#aaaaaa'>BCL foundation's Deposit Address :</font> <font color='#34D5FF'>0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  bep_title9: "Where to do Migration",
  bep_title10:
    "1. Lbank Crytocurrency exchange <br/>Deposit is prohibited the day before the date notified in advance. Swap within Lbank for 3 days. New deposit, transactions, and withdrawals will be conducted.<br/>2. BCL Website (Here)<br/>For users who could not participate in the Lbank cryptocurrency exchange swap, the BCL website performs its own migration.",
  bep_title11:
    "☑ Swap ratio when migrating <br />1. Lbank crytocurrency exchange <br />ERC20 : BEP20 = 1 : 1.1(util 29th June 2pm UTC+8) <br /> 2. BitcoinLegend Website(From 1st July) <br />ERC20 : BEP20 = 1 : 1.03",
  bep_title12:
    "<font color='yellow'>Lbank crytocurrency exchange</font><br />ERC20 : BEP20 = 1 : 1.1 (10% additional payment)",
  bep_title13:
    "<font color='yellow'>BitcoinLegend Website</font><br />ERC20 : BEP20 = 1 : 1.03 (3% additional payment)",
  levelup_title1:
    "This program was developed for users who want to increase the mining speed with minimal cost. ",
  levelup_desc1:
    "The level-up program is designed to increase the mining speed for a set period of time. <br />" +
    "1. After application and payment, it will be applied immediately. <br />" +
    "2. The application period operates as a timer. <br />" +
    "3. After the timer expires, the mining speed corresponding to the half-life is restored.",
  levelup_title2: "Present",
  levelup_title3: "Level Up Program",
  levelup_popup_title1: "Application form for Level Up",
  levelup_popup_desc1:
    "[ Notice on Level Up ] <br />" +
    "1. Google Play Store Apple App Store, when installing Bitcin Legend mining <br />" +
    "     app as APK file, be sure to enter the register email address. <br />" +
    "2. Please refer to the manual above for how to check your email address. <br />" +
    "3. The level-up program starts immediately after application <br />" +
    "4. The operating time of the level-up program is 30 days after registration.",
  levelup_popup_confirm: "Confirm",
  bep_button_title2: "Check out the amount of new BEP20",
  levelup_title4: "How to check your E-mail address",
  levelup_title5:
    "1. Touch Setting icon in the mining app of BitcoinLegend.<br />2. Check the registered BCL email and enter the same as displayed.",

  nft_about_title2: "About MaxForm Hero Card & Metaverse",
  nft_about_desc2:
    '<p>The metaverse was first mentioned in Neal Stephenson\'s "Snow Crash" in 1992. <br/>' +
    "The metaverse is a second life platform that is a world where you can connect with the minds of others anytime and anywhere.<br/>" +
    "The metaverse is a state in which reality and various worlds are combined, such as universe 1, universe 2, and universe 3, <br/>" +
    "and it is in a state of pseudo-reality to the point where we do not know which one is the real me in the metaverse, who lives a different life from the real me.<p/>" +
    "<p>Avatar can meet and communicate in a transcendent space and engage in economic activities using AR and VR functions with anonymity.<br/>" +
    "To meet as an avatar in a transcendent space is an innovation. It will lead the world innovation for the next 20 years in the form of a collapse of the concept of time and space.</p>" +
    "<p>Everyone has a vision and a dream.<br/>" +
    "However, most of them cannot make it a reality and keep it in their mind.<br/>" +
    "In particular, efforts are being made to resolve economic poverty and dissatisfaction with reality.<br/>" +
    "However, the results of these efforts are difficult to achieve in reality.</p>" +
    "<p>Bitcoin Legend was created to realize people's ideals.</p>" +
    "<p>Users make their own NFT avatars, make NFT items, and buy and sell them to make a profit. <br/>" +
    "BCL Core Team use AI technology to create webtoon characters similar to the real me. <br/>" +
    "The technologies applied at this time are Facial Landmark Engine and Facial Landmark Trigger in the Self Made Studio. <br/>" +
    "Using this technology, users can directly create a avatar that resembles the real me. It is possible to change the hair style, face shape, and even eye color. <br/>" +
    "In addition, You can produce the various clothing, ornaments and game items. Also, you can buy them that you made. <br/>" +
    "These items can be auctioned by encrypting them using NFT making technology.</p>",
  hero_nft_card2: "Game-Fi Hero Card",
  hero_nft_card3: "White List for MaxForm Hero NFT Card",
  hero_nft_card3_desc:
    "Normal people can be heroes. <br />Not only good heroes become heroes,<br /> but even villains become heroes if they can win in the game.<br />Challenge yourself.",
  hero_nft_card4: "White List for Maxform Hero Card",
  hero_nft_card_title1: "What’s NFT?",
  hero_nft_card_desc1:
    "A non-fungible token (NFT) is a virtual token that uses blockchain technology to prove the owner of a digital asset. <br />" +
    "Addresses pointing to digital files such as pictures and videos are stored in tokens, and are used to indicate their unique originality and ownership. <br />" +
    "That is, some sort of virtual certificate of authenticity. <br />" +
    "The Hero NFT card is designed with the concept that not only a specific character can become a hero, but also ordinary people can become a hero.",
  hero_nft_card_title2:
    "What are the characteristics of the MaxForm Hero card?",
  hero_nft_card_desc2:
    "MaxForm Hero Card will be minted through Polygon chain, and anyone can buy and sell it at OpenSea.<br />" +
    "It was created with 2130 x 2800 pixels by precision manufacturing technique.<br />" +
    "A whitelist is not just to purchase NFTs from the point of view of a buyer, but to purchase NFTs at a discounted price. And you can get additional services. <br />" +
    "The BCL Foundation provides level-up benefits to those who purchase Hero NFT cards through the whitelist to increase their mining speed. <br />" +
    "Those who purchase directly from Opensea or Binance NFT do not have this benefit.",
  hero_nft_card_title3: "The Price and payment method for whitelist",
  hero_nft_card_desc3:
    "☑️ 1st Whitelist (1,500 NFTs)<br />" +
    "1. Price : 1,000 BCL 2. Payment Method : Only BEP20 based BCL<br />" +
    "☑️ 2nd Whitelist (4,500 NFTs)<br />" +
    "1. Price : 0.05 ETH 2. Payment Method : BCL, ETH, Matic(polygon) or other payment method<br />" +
    "☑️ 3rd White list (2,000 NFTs)<br />" +
    "1. Price : 0.05 ETH 2. Payment Method : BCL, ETH, Matic(polygon) or other payment method<br />" +
    "☑️ Start Dutch Auction(1,000 NFTs)<br />" +
    "Sell & Buy on Opensea<br />",
  // "Sell & buy on Opensea",
  hero_nft_card_title4: "The Schedule for white List",
  hero_nft_card_desc4:
    "☑️ 1st whitelist(1,500 NFTs) <br />" +
    "1. Start 1st whitelist on the BCL website : August 17th - August 31th<br />" +
    "2. Deposit Deadline : August 17th - August 31th<br />" +
    "3. Minting site added on BCL website : August 16th - August 19th<br />" +
    "4. Release channel at OpenSea in the form of a gift box : September 5th<br />" +
    "5. Random Hero Card Reveal : September 7th<br />" +
    "☑️ 2nd whitelist(4,500 NFTs)<br />" +
    "1. Start 2nd whitelist on the BCL website : September 14th - September 23th<br />" +
    "2. Deposit Deadline : September 14th - September 23th<br />" +
    "3. Release channel at OpenSea in the form of a gift box : September 27th<br />" +
    "4. Random Hero Card Reveal : September 29th<br />" +
    "☑️ 3rd whitelist(2,000 NFTs)<br />" +
    "1. Start 3nd whitelist on the BCL website : October 4th - October 14th<br />" +
    "2. Deposit Deadline(BCL&other payment) : October 4th - October 14th<br />" +
    "3. Release channel at OpenSea in the form of a gift box : October 18th<br />" +
    "4. Random Hero Card Reveal : October 20th<br />" +
    "☑️ Start Dutch Auction(1,000 NFTs) : October 26th",
  hero_nft_card_title5: "How to apply for a whitelist",
  hero_nft_card_desc5:
    "1. Buy BCL from Lbank or Pancake Swap. <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://www.lbank.info');\"'>LBank</div> <div class='increase_strategy_mark2' style='display: inline'  onclick=\"window.open('https://pancakeswap.finance');\">PancakeSwap</div><br />" +
    "2. Deposit BEP20 based BCL into your personal Cryto-wallet(Metamask and Trustwallet). <br />" +
    "* How to get address BEP20 and Polygon(matic)  <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />" +
    "3. Send BEP20 based BCL to the BCL foundation's address as follows:",
  hero_nft_card_desc6:
    "<font color='#aaaaaa'>BCL Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  hero_nft_card_desc7: "Check Deposit of BCL foundation",
  hero_nft_card_desc8:
    "4. Enter your BCL app subscription email address and sent BCL wallet address in the window below.<br />" +
    "*To participate in the mining speed Up event, be sure to enter your BCL subscription app email address.",
  hero_nft_card_desc9: "Application form for applying White List",
  hero_nft_card_desc10_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>Check the email you subscripted on BCL app</span> ',
  hero_nft_card_desc10_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>Enter your email address and sended BEP20 <br /> based BCL address and amount <br /><font color='#FFD500'>*1NFT = 1,000BCL, the balance is non-refundable,<br />so please input 1,000 units. <br />There is no limit on the number of NFTs purchased per buyer</font></span> ",
  hero_nft_card_desc10_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>The NFT card receiving address is automatically registered<br /> as the Polygon (MATIC) address of the deposit account.</span> ',
  hero_nft_card_desc11:
    "5. Check White List for Buying Hero NFT <br />" +
    "*After buying  NFT during 1st white list, BCL core team will post here the list of buyer. <br />" +
    "After posing list, the buyer will get advantage of Mining speed up and information of Maxform Hero card. ",
  bep_input_nft_email: "Input Email Address",
  bep_input_bep_address: "Input BCL Address",
  bep_input_bep_amount: "Input BCL Amount",
  bep_input_bep_from: "'Wallet' must be connected.",
  bep_input_polygon_address: "Input Polygon Address",
  bep_input_bep_amount2: "Please enter a number over 3900",
  not_match_bep:
    "Check the BEP20 BCL address you deposited and the amount of BEP20 BCL.",
  not_match_eth: "Check the ETH address you deposited and the amount of ETH.",
  not_match_pol:
    "Check the MATIC address you deposited and the amount of MATIC.",
  hero_nft_card_desc51:
    "*If you deposit BCL at Lbank, you cannot receive an NFT card. <br />" +
    "Be sure to do it in your personal cryptocurrency wallet<br />" +
    "*1NFT = 1,000BCL, the balance is non-refundable,<br />" +
    "so please input 1000 units. <br />" +
    "There is no limit on the number of NFTs purchased per buyer",
  nft_request_complete: "already processed",

  hero_nft_card_desc12:
    "1. Send Ethereum(ETH) or Polygon(MATIC) to the BCL foundation's address as follows:<br />" +
    "* How to get address Polygon (matic)<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />",
  hero_nft_card_desc52:
    "*1NFT = 0.05 ETH, 1 NFT = 40 MATIC <br />" +
    "the balance is non-refundable <br />" +
    "There is no limit on the number of NFTs purchased per buyer",
  hero_nft_card_desc61:
    "<font color='#aaaaaa'>Polygon(Matic) Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   /><br />",
  hero_nft_card_desc71: "Check Deposit of ETH",
  hero_nft_card_desc72: "Check Deposit of MATIC",
  hero_nft_card_desc81:
    "2. Enter your BCL app subscription email address and sent BCL wallet address in the window below. <br />" +
    "*To participate in the mining speed Up event, be sure to enter your BCL subscription app email address.",
  hero_nft_card_desc91: "ETH/MATIC form for applying White List",
  hero_nft_card_desc11_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>Check the email you subscripted on BCL app</span> ',
  hero_nft_card_desc11_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>Enter your email address and sended ETH or MATIC <br />address and amount\n <br /><font color='#FFD500'>*1NFT = 0.05 ETH, 1NFT = 40 MATIC, <br />" +
    "the balance is non-refundable. <br />" +
    "There is no limit on the number of NFTs purchased per buyer</font></span> ",
  hero_nft_card_desc11_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>Enter your Polygon based address that received your NFT. </span> ',
  hero_nft_card_desc82:
    "After buying NFT during 1st white list, BCL core team will post here the list of buyer.  <br />" +
    "After posing list, the buyer will get advantage of Mining speed up and information of Maxform Hero card. ",
  nft_event_string1:
    "🟢 Maxform NFT hero card is minting and is made by Bitcoinlegend Core team 🟢<br />" +
    "                        Bitcoin Legend's hero card concept is that anyone in general can become a hero.<br />" +
    "It's half normal clothing and half in hero clothing.<br />\n" +
    "                        This is made even more unique by the rarity program.",
  nft_event_string2: "🎉Only 300 Hero NFT Card will be supplied for free.🎉",
  nft_event_string3:
    " ☑️ 1st White list Price: 𝟬.022 ETH/45 MATIC/1,000 BCL per NFT <br />" +
    "                        ☑️2nd~3rd White list Price: 𝟬.05 ETH/105 MATIC/2,200 BCL per NFT",
  nft_event_string4:
    " ☑️More Event(util gathering 3 million users)<br /><br />" +
    "                        ▶️ 4X high speed mining : 6→24BCL/day<br />" +
    "                        (NFT buyer +@24 BCL/day, for 1month)<br /><br />" +
    "                         ▶️ Referral bonus up : 25→50 BCL/User",
  nft_event_string5: "Thanks for participating Event !",
  nft_event_string6: "🏷️Sale Info",
  go_hero_nft_event: "Hero NFT Card Event",
  bep_request_check2: "No matching account information found.",
  nft_event_qa:
    "If the parent contents is missing, send <a href='https://forms.gle/SxLB81u6t5hBzUiV6' target='_blank'><span style='text-decoration: underline;font-weight: bold' To do this, please >click</span></a> here.",
  hero_nft_card_title11: "[Special Promotion]",
  hero_nft_card_desc11_desc:
    "☑️Ranking competition(Real-time update) <br />" +
    "●1st place <br />" +
    "1. 6th Hero promotion : “You're Hero” <br />" +
    "(your photo&story → hero production + webtoon + rap production) <br />" +
    "1st Hero : <a href='https://youtu.be/s0FSVXZ-wSs' target='_blank'>https://youtu.be/s0FSVXZ-wSs</a> <br />" +
    "2nd Hero :  <a href='https://youtu.be/8Al9l7YVLC4' target='_blank'>https://youtu.be/8Al9l7YVLC4</a> <br />" +
    "3rd - 5th Heros : <a href='https://youtu.be/gfjBqRpmh6U' target='_blank'>https://youtu.be/gfjBqRpmh6U</a> <br />" +
    "<br />" +
    "2. 1,000NFT limited edition(6th Hero) with 26 items <br />" +
    "1,000NFT*0.1ETH(whitelist price)=100ETH <br />" +
    "5% reward on sales amount(5ETH) <br />" +
    "●2nd - 3rd place <br />" +
    "2nd place)5 additional NFTs + 2% reward on 6th Hero(2ETH) <br />" +
    "3rd place)3 additional NFTs + 1% reward on 6th Hero(1ETH) <br />" +
    "●4th -10th place <br />" +
    "2 additional NFTs <br />" +
    "*Additional paid NFT will be paid with currently sold NFT. <br />" +
    "<br />" +
    "☑️Membership promotion <br />" +
    "●Platinum(Buy 21NFT or more) <br />" +
    "Mining Speed Up(+24BCL/day), KYC priority, NFT+1ea/20ea <br />" +
    "●Gold(Buy 11~20 NFT) <br />" +
    "Mining Speed Up(+24 BCL/day), KYC priority, NFT+1 <br />" +
    "●Silver(Buy 5-10 NFT) <br />" +
    "Mining Speed Up(+24 BCL/day), KYC Priority <br />" +
    "●Bronze(Buy 1~4NFT) <br />" +
    "Mining Speed Up(24BCL/day)",
  kyc_certification: "KYC Certification",
  vision_of_bitcoinLegend: "Vision of BitcoinLegend",
  kyc_desc1:
    "Bitcoin Legend is a currently listed mining type cryptocurrency. <br />" +
    "This is for the BCL token to play an actual role as the operating currency of the Maxform ecosystem. <br />" +
    "The Maxform ecosystem issues hero NFT cards and plays card games using them. <br />" +
    "In addition, the Maxform City where the hero resides is realized in 3D world, <br />" +
    "and the city of residence is made into an NFT. <br />" +
    "This city is also implemented in the form of a game like a city battle. <br />" +
    "If people and the environment are made by NFT, <br />" +
    "The BCL Foundation will create a metaverse world called Maxform. <br />" +
    "Heros meet at coffee shops or health training centers to talk and play games. <br />" +
    "The monetary means used in this ecosystem is BCL. <br />" +
    "This is the vision and goal of Bitcoin Legend.",
  kyc_title2: "Purpose of KYC Certification",
  kyc_desc2:
    "The BCL Foundation proceeds with KYC verification to give credibility to the BCL  <br />" +
    "mined by all users through the cloud server. <br />" +
    "This is a process in which one person mines with one mobile and proves  <br />" +
    "that they are the real person.  <br />" +
    "We want to prevent unfairly taking profits by creating fake accounts.",
  kyc_title3: "Follow the KYC process",
  bep_input_name: "Input Name",
  bep_input_number: "Input Number",
  bep_input_address: "Input Address",
  kyc_popup_title1: "[ Age Restriction for KYC Certification ]",
  kyc_popup_desc1:
    "In order to comply with international laws for data collection of children and person who is not adult, KYC certification is restricted to those over the age of 18.",
  kyc_popup_desc2:
    "For those under the age of 18, BCL foundation plan to establish and implement a separate system with parental consent.",
  kyc_popup_desc3: "[Precautions when applying KYC ]",
  kyc_popup_desc4:
    "1. If you apply even if you are under the age of 18, an error occurs and only the KYC fee is non-refundable.",
  kyc_popup_desc5:
    "2. The Real Name must match the name on National ID card, Passport, Drivers License, or Voter ID.",
  kyc_popup_desc6:
    "3. The email should be the email when you signed up for Bitcoin Legend.",
  kyc_popup_desc7:
    "4. The mobile phone number should be the phone number where you installed the app of Bitcoin Legend.",
  kyc_popup_desc8: "Real Name",
  kyc_popup_desc9: "E-mail for BCL app",
  kyc_popup_desc10: "Mobile PhoneNumber",
  kyc_popup_desc10_1: "Transaction Hash",
  kyc_popup_title2: "[ KYC Preparation ]",
  kyc_popup_desc11:
    "1. Documents(National ID, Drivers License, Passport or Voter ID) is not Expired.",
  kyc_popup_desc12:
    "If you KYC with expired documents, an error will occur. As a result, KYC fees are non-refundable.",
  kyc_popup_desc13: "2. We may use the information of your Documents.",
  kyc_popup_desc14:
    "3. Your address, mobile phone number and country information will bs used for KYC verification.",
  kyc_popup_desc15:
    "4. This information is only used for KYC verification, and is used to determine whether you actually use the app in accordance with the policy of Bitcoin Legend.",
  kyc_popup_desc16: "You agree to the usage rights accordingly.",
  kyc_popup_agree: "Agree",
  kyc_popup_reject: "Reject",
  kyc_popup_kyc: "GO KYC",
  kyc_popup_title3: "[ KYC Certification ]",
  kyc_popup_desc17:
    "Once you have your phone with unexpired  documents(National ID, Drivers License, Passport or Voter ID) and apps installed, Let's start with KYC verification.",
  kyc_popup_desc18: "There is a 6$ fee for KYC verification.",
  kyc_popup_desc19: "Do you want to proceed with KYC verification?",
  kyc_result1:
    "You are already a KYC-verified user.Go to the app and pay in-app according to the KYC order.",
  kyc_result2: "No matching information.",
  kyc_popup_title4: "[ End of KYC verification ]",
  kyc_popup_desc20: "Thank you for your KYC verification.",
  kyc_popup_desc21:
    "If the KYC verification was completed normally, the icon on the main screen of your pp would have changed as follows.",
  kyc_popup_desc22:
    "If it is not changed, it means that the certification score was judged as non-conforming. ",
  kyc_popup_desc23:
    "These will be manually verified once again by the BCL Foundation.  ",
  kyc_popup_desc24: "Thank you so much",
  kyc_popup_desc25: "Proof of KYC verification",
  kyc_result3: "Email is wrong",
  kyc_result4:
    "Please check the name and phone number corresponding to the email and re-enter it.",
  kyc_popup_pay: "Payment",
  kyc_result5: "In the BitcoinLegend App, go to your profile and agree to KYC.",
  kyc_result6:
    "This is a phone number that has already been KYC-verified. Go to the app and pay in-app according to the KYC order.",
  kyc_result7: "KYC is in progress.",
  kyc_popup_desc26: "※ Please start with the app first",
  kyc_popup_desc27:
    "5. For other inquiries, please put <a href='https://forms.gle/94ggLz5t1fUbh1Cq6' target='_blank' style='text-decoration: underline;'><B><underline>here</underline></B></a>.",
  kyc_result8: "Use the in-app payment function in the app. (Ver 1.4.8)",
  kyc_1: "[Notice of temporary suspension of KYC verification]",
  kyc_2:
    "As a result of a strong protest against Stripe, 100% KYC refund is in progress. <br /><br />We was informed that there will be a delay of 10 to 15 days depending on the credit card company according to the countries. <br />If you do not receive a refund after that, please contact your credit card company.  <br />USDT, TRX, in-app payment (Google) is in progress by expanding KYC payment.",
  nft_1: "Special promotion for white List",
  nft_2: "☑ Ranking competition(Real-time update)",
  nft_3: "●1st place",
  nft_4:
    "1. 6th Hero promotion : “You're Hero” <br />(your photo&story → hero production + webtoon + rap production) <br />1st Hero : https://youtu.be/s0FSVXZ-wSs <br/>2nd Hero : https://youtu.be/8Al9l7YVLC4 <br />3rd - 5th Heros : https://youtu.be/8Al9l7YVLC4",
  nft_5:
    "2. 1,000NFT limited edition(6th Hero) with 26 items <br />1,000NFT*0.1ETH(whitelist price)=100ETH <br />5% reward on sales amount(5ETH) ",
  nft_6: "●2nd - 3rd place",
  nft_7:
    "2nd place)5 additional NFTs + 2% reward on 6th Hero(2ETH) <br />3rd place)3 additional NFTs + 1% reward on 6th Hero(1ETH)",
  nft_8: "●4th - 10th place",
  nft_9: "2 additional NFTs",
  nft_10: "*Additional paid NFT will be paid with currently sold NFT.",
  nft_11: "☑ Membership promotion",
  nft_12: "●Platinum(Buy 21NFT or more)",
  nft_13: "Mining Speed Up(+24BCL/day), KYC priority, NFT+1ea/20ea",
  nft_14: "●Gold(Buy 11~20 NFT)",
  nft_15: "Mining Speed Up(+24 BCL/day), KYC priority, NFT+1",
  nft_16: "●Silver(Buy 5-10 NFT)",
  nft_17: "Mining Speed Up(+24 BCL/day), KYC Priority",
  nft_18: "●Bronze(Buy 1~4 NFT)",
  nft_19: "Mining Speed Up(24BCL/day)",
  nft_20:
    "Maxform NFT Hero Card was developed by the BitcoinLegend Core team. <br />" +
    "It was composed of UNIQUE DIGITAL COLLECTIBLES on the Ethereum blockchain ERC-721. <br />" +
    "<br />" +
    "Five heroes are reborn with NFT cards. It is designed with the concept that any ordinary person can become a hero.<br />" +
    " It's half normal attire and half in hero attire.  <br />" +
    "<br />" +
    "This is made even more unique by the rarity program.  There is no identical form among the issued cards.  Hair, clothing, weapons, accessories, mask, background, etc. item items are all different.  <br />" +
    "Among them, there is not one character but a total of 5 characters in different poses and postures.  <br />" +
    "For each such character, 2000 hero cards are issued.  <br />" +
    "The approach is different from other NFTs that create 10,000 pieces with one character.  <br />" +
    "In this way, a total of five characters appearing in 2000 each, making a total of 10,000 cards.  <br />" +
    "You will have your rare and unique item.",
  kyc_result9:
    "This is a Tron address that has already been checked. No additional payment required.",
  kyc_result10: "KYC Status Check",
  kyc_result11: "Check your email address",
  kyc_result12: "Payment, KYC verification completed",
  kyc_result13: "Payment incomplete, KYC completed",
  kyc_result14: "Payment incomplete, KYC incomplete",
  kyc_result15:
    "Payment completed, KYC not completed. Go to the app and proceed with KYC according to the KYC order",
  kyc_result16:
    "5. For USDT, you need to send 6$ to the foundation wallet.<font color='red'>(Be sure to send it to your personal wallet)</font>",
  kyc_result17: "- USDT address: TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG",
  kyc_result18: "- Cost: USDT 6$",
  kyc_result19:
    "KYC verification has been completed, but the deposit details are not confirmed. Confirm deposit amount in USDT and TRON.",
  kyc_result20:
    "KYC verification has not been completed. Deposit details are not confirmed. Confirm deposit amount in USDT and TRON.",
  kyc_result21: "[Notice] ",
  kyc_result22:
    "KYC authentication is currently suspended due to server problems of Passbase, a KYC authentication company.<br /><br />We are in contact with the Passbase and will notify you again when the KYC server is running normally.  <br /><br />After paying the KYC certification fee, we will take action so that users who have not performed KYC can receive KYC certification at no additional cost.",
  kyc_result23:
    "[Notice]\nWhen depositing with UDST and Tron, please deposit excluding gas fees normally *6USDT *100TRX",
  kyc_result24: "[Notice related to KYC verification and payment]",
  kyc_result25:
    "1. Delayed KYC verification<br />" +
    "When KYC verification is not verified due to user error, we have put in a separate manual verification team to verify it. (When KYC verification is completed, it changes to a green icon;<br />" +
    " We apologize for continuing to confuse you with KYC payment related tasks. Please stop KYC in-app payment for a while.<br />" +
    "<br />" +
    "2. KYC payment error and refund<br />" +
    "1) It is difficult for us to respond 1:1 because we are processing a full refund for double or omission of payment during stripe payment. Please wait until the refund is processed.<br />" +
    "3) If payment has been made twice due to insufficient USDT and Tron payment, we will reply after manual check.<br />" +
    "3) In-app refund occurred due to omission of in-app confirmation coding by our BCL core team. Currently in-app confirmation coding is in progress. Please stop paying before announcing a new updated version.<br />" +
    "4) USDT and Tron can proceed with KYC payment.<br />" +
    "<br />" +
    "*The above case requires a lot of manpower mobilization, so the BCL Foundation has secured a manpower and is handling it as much as possible, so please wait.<br />" +
    "* BCL settlement and withdrawal will proceed after the normal operation of the above business is carried out.",
  kyc_result26:
    "This is the email or phone number that has already been KYC-verified.",
  kyc_result27: "There is no matching phone information.",
  kyc_result28: "Deposit history <br />Photo",
  kyc_result29:
    "Your request has been completed. Please allow 1-2 business days to process your deposit.",
  bep_input_block: "Please input BLOCK",
  kyc_result30:
    "Please write the block of the address where you deposited. <a href='https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers' target='_new'>GO</a>",
  kyc_result31:
    'Only users with the "KYC Failed" icon displayed in the app can register.',
  kyc_result32: "No KYC results",
  kyc3_1 : "Precautions when applying KYC",
  kyc3_2 : "※ Update to the latest app before proceeding with KYC.",
  kyc3_3 : "You made a deposit, is there a problem?",
  kyc3_4 : "Please select the payment method that you deposited and make up for the lack of documents.",
  kyc3_5 : "Copy Complete",
  kyc3_6 : "Please select the type of cryptocurrency you have deposited.",
  kyc3_7 : "Link your wallet",
  kyc3_8 : "Please enter your e-mail",
  kyc3_9 : "1. Deposit from your personal wallet to the address below, and select the type of cryptocurrency you deposited.",
  kyc3_10 : "Go to Next",
  kyc3_11 : "2. Paste hash value",
  kyc3_12 : "Where to paste the copied hash value",
  kyc3_13 : "3. Enter the email address registered in the Bitcoinlegend app.",
  kyc3_14 : "4. The payment has been made.",
  kyc3_15 : "Proceed with KYC authentication on the Bitcoinlegend app.",
  kyc3_16 : "4. It has not been deposited.",
  kyc3_17 : "Please deposit the KYC fee again.",
  kyc3_18 : "Going to make a deposit",
  kyc3_19 : "deposit, but there’s a problem",
};
