import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app.jsx';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'mobx-react';
import RootStore from './stores/root-store';
// css
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery-ui-dist/jquery-ui.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-quill/dist/quill.snow.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles/react.scss';
import 'react-image-lightbox/style.css';

import 'paroller.js/dist/jquery.paroller.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery-ui-dist/jquery-ui.min';
// ========================================

ReactDOM.render(
    <BrowserRouter>
        <Provider rootStore={new RootStore()}>
            <App/>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);
