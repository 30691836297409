import React from 'react';
import Main from '../pages/main/main';
import NftIntro from '../pages/main/nft-intro';
import PreSale from '../pages/main/pre-sale'
import Levelup from '../pages/main/levelup'
// import NftIntroV2 from '../pages/main/nft-intro-v2';
import NftEvent from '../pages/main/nft-event';
import Kyc from '../pages/main/kyc'
import KycV3 from '../pages/main/kyc-v3'
import PaymentStatus from "../pages/main/PaymentStatus";
import Whitelist from "../pages/main/whitelist"
import GameFi from "../pages/main/GameFi";
import NftCard from "../pages/main/NftCard";
const routes = [
  {
    path: '/',
    exact: true,
    title: '',
    access: 'public',
    component: () => <Main />
  },
  {
    path: '/nft_intro',
    exact: true,
    title: '',
    access: 'public',
    component: () => <NftIntro />
  },
  // {
  //   path: '/nft_intro_v2',
  //   exact: true,
  //   title: '',
  //   access: 'public',
  //   component: () => <NftIntroV2 />
  // },
  {
    path: '/nft_event',
    exact: true,
    title: '',
    access: 'public',
    component: () => <NftEvent />
  },
  {
    path: '/pre-sale',
    exact: true,
    title: '',
    access: 'public',
    component: () => <PreSale />
  },
  {
    path: '/levelup',
    exact: true,
    title: '',
    access: 'public',
    component: () => <Levelup />
  },
  {
    path: '/kyc',
    exact: true,
    title: '',
    access: 'public',
    component: () => <KycV3 />
  },
  {
    path: '/kyc3',
    exact: true,
    title: '',
    access: 'public',
    component: () => <KycV3 />
  },
  {
    path: '/complete',
    exact: true,
    title: '',
    access: 'public',
    component: () => <PaymentStatus />
  },
  {
    path: '/whitelist',
    exact: true,
    title: '',
    access: 'public',
    component: () => <Whitelist />
  },
  {
    path: '/gamefi',
    exact: true,
    title: '',
    access: 'public',
    component: () => <GameFi />
  },
  {
    path: '/card',
    exact: true,
    title: '',
    access: 'public',
    component: () => <NftCard />
  },

];

export default routes;
