import React from 'react';

class MultiColorProgressBar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const parent = this.props;

        let values = parent.readings && parent.readings.length && parent.readings.map(function(item, i) {
            if(item.name != '' ) {
                return (
                    <div className="content-desc value" style={{'color': item.color, 'width': ( item.percent < 100 ? 100 : item.percent) + '%',textAlign: "center", display: "inline-table",padding: 0 }}  key={i}>
                       {item.name} <br />{item.value}
                    </div>
                )
            }

        }, this);
        let bars = parent.readings && parent.readings.length && parent.readings.map(function(item, i) {
            if(item.value > 0) {
                return (
                    <div className="bar" style={{'backgroundColor': item.color, 'width': item.percent + '%'}}  key={i}>

                    </div>
                )
            }
        }, this);



        return (
            <div className="multicolor-bar">
                <div className="values" style={{ display: "flex" }}>
                    {values == ''?'':values}
                </div>
                <div className="bars">
                    {bars == ''?'':bars}
                </div>
            </div>
        );
    }
}

export default MultiColorProgressBar;
