import {inject, observer} from 'mobx-react';
import React from 'react';
import {withRouter} from 'react-router-dom';
import Const, {BASE_URL} from "../../config/const";
import {PageSettings} from "../../config/page-settings";
import strings from '../../lang/strings';
import './nft-intro.scss';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Navigation} from "swiper";
import 'swiper/swiper-bundle.css';
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import {Modal, ModalBody} from "reactstrap";
import axios from "axios";
import $ from 'jquery/src/jquery';
import MultiColorProgressBar from "../../components/controls/multi-progress-bar";
import {DataGrid} from "@material-ui/data-grid";

const moment = require('moment');

const youtube = ['https://www.youtube.com/embed/gfjBqRpmh6U', 'https://www.youtube.com/embed/8Al9l7YVLC4', 'https://www.youtube.com/embed/s0FSVXZ-wSs'];

var ua = navigator.userAgent.toLowerCase();
var isSafari = false
if (ua.indexOf('safari') != -1 || ua.indexOf('iphone') != -1) {
    if (ua.indexOf('chrome') > -1) {
        isSafari = false
    } else {
        isSafari = true
    }
}


const columns = [
    {
        field: "id",
        headerName: "Rank",
        flex: 2
    },
    {
        field: "from",
        headerName: "Address",
        flex: 2
    },
    {
        field: "amt",
        headerName: "Quantity",
        flex: 2
    },
    {
        field: "cnt",
        headerName: "Count",
        flex: 2
    }
];

class NftEvent extends React.Component {
    static contextType = PageSettings;

    constructor(props) {
        super(props);
        this.state = {
            strings: strings,
            showGuideModal: false,
            inputNftEmail: '',
            inputBepAddress: '',
            inputBepAmount: '',
            inputPolygonAddress: '',
            inputNftEmail2: '',
            inputBepAddress2: '',
            inputBepType2: 1,
            inputBepAmount2: '',
            inputPolygonAddress2: '',
            remain_first2: 0,
            remain_first: 0,
            showMore1: false,
            showMore2: false,
            showMore3: false,
            griddata: [],
        };
        this.iframeInt = 0;
        this.uiState = this.props.rootStore.uiState;
        this.globalState = this.props.rootStore.globalState;
    }

    embedCompetition(el, html) {
        el.innerHTML = html;

        Array.from(el.querySelectorAll("script")).forEach(oldScript => {
            const newScript = document.createElement("script");
            Array.from(oldScript.attributes).forEach(attr => newScript.setAttribute(attr.name, attr.value));
            newScript.appendChild(document.createTextNode(oldScript.innerHTML));
            oldScript.parentNode.replaceChild(newScript, oldScript);
        });
    }

    componentDidMount() {

        /* Replace with your embed code */
        const embedCode = '<a class="e-widget no-button" href="https://gleam.io/WnR9Z/free-airdrops-to-celebrate-maxform-hero-card-minting" rel="nofollow">Free Airdrops to Celebrate Maxform Hero Card Minting</a><script type="text/javascript" src="https://widget.gleamjs.io/e.js" async="true"></script>';
        this.embedCompetition(document.querySelector('#gleam_competition'), embedCode);
        this.getEventTotaFirstCount();
        this.getEventTotaFirstCount2();
        axios
            .get(`${BASE_URL}/user/nftRank`, {
                headers: {
                    Authorization:
                        "Bearer " +
                        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MTE5NjM1OSwiZXhwIjoxNjgyNzMyMzU5fQ.5ZxqvUdLOS8zrbCZuDqZqv4Zjox1POUrZ0Ah0u9LEbs",
                },
            })
            .then(({ data }) => {
                var i = 1;
                const newData = data.map((row) => {
                    const createdAt = new Date(row["createdAt"]).toLocaleString();
                    row["id"] = i;
                    // props.setNumber((number: any) => {
                    //     row["no"] = number + 1;
                    //     return number + 1;
                    // });
                    row["from"] = row["from"];
                    // row["notSettle"] = row["miningAmount"] - row["settleAmount"];
                    i++;
                    return row;
                });
                this.state.griddata =  newData  ;
                console.log(this.state.griddata);
                // this.state( griddata: .setData(newData);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getEventTotaFirstCount2 = () => {
        axios
            .get(`${BASE_URL}/user/nftFirstInfo2`, {
            })
            .then((res) => {
                const max = 3000;
                // const sales = 700;

                const bcl_cnt =  Math.floor(res.data[0].bcl_amount / 3900) ;
                const eth_cnt =  Math.floor(res.data[0].eth_amount / 0.05) ;
                const poly_cnt = Math.floor(res.data[0].poly_amount / 94);
                const other_cnt =   max - (bcl_cnt + eth_cnt + poly_cnt  ) ;
                const cnt_percent =  Math.round(( bcl_cnt + eth_cnt + poly_cnt  ) / max * 100) ;
                console.log(( bcl_cnt + eth_cnt + poly_cnt  ));
                // const sales_percent =  Math.round(sales / max * 100) ;
                const other_percent =  100 - cnt_percent; // - sales_percent;
                this.state.remain_first2 = other_cnt;

                this.state.readings2 = [
                    {
                        name: 'SALES',
                        value: bcl_cnt + eth_cnt + poly_cnt , //+ sales,
                        percent: cnt_percent,
                        color: 'yellow'
                    },
                    {
                        name: '',
                        value: other_cnt,
                        percent: other_percent,
                        color: 'green'
                    }
                ]
            })
            .catch((err) => console.log("오류발생", err));
    };

    getEventTotaFirstCount = () => {
        axios
            .get(`${BASE_URL}/user/nftFirstInfo`, {
            })
            .then((res) => {
                const max = 1500;
                const sales = 700;


                const bcl_cnt =  Math.floor(res.data[0].bcl_amount / 1000) ;
                const eth_cnt =  Math.floor(res.data[0].eth_amount / 0.022) ;
                const poly_cnt = Math.floor(res.data[0].poly_amount / 45);
                const other_cnt =   max - (bcl_cnt + eth_cnt + poly_cnt + sales ) ;
                const cnt_percent =  Math.round(( bcl_cnt + eth_cnt + poly_cnt + sales ) / max * 100) ;
                const sales_percent =  Math.round(sales / max * 100) ;
                const other_percent =  100 - cnt_percent - sales_percent;

                this.state.remain_first = other_cnt;
                this.state.readings = [
                    // {
                    //     name: 'EVENT',
                    //     value: sales,
                    //     percent: sales_percent,
                    //     color: '#ff0000'
                    // },
                    {
                        name: 'SALES',
                        value: bcl_cnt + eth_cnt + poly_cnt + sales,
                        percent: cnt_percent,
                        color: 'yellow'
                    },
                    {
                        name: '',
                        value: other_cnt,
                        percent: other_percent,
                        color: '#aaaaaa'
                    }
                ]
            })
            .catch((err) => console.log("오류발생", err));
    };


    componentWillUnmount() {
    }

    render() {
        return (

            <div id="nft" className="nft-main" style={{ height: "100%",textAlign: "center", marginTop: "30px" }}>
                <img  src="/assets/img/nft/img_nft_event.jpg" srcSet="/assets/img/nft/img_nft_event.jpg 1x" style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP? {} : { width: "100vw" })}/>
                <div className="airdrop-content media-partners" data-scrollview="true" style={{ backgroundColor: "#272831"}} >
                    <div className="section-title"  dangerouslySetInnerHTML={{__html: strings.nft_event_string1}}/>
                    <div className="content-desc m-t-15" style={{ textAlign: "center" }}>{ strings.nft_event_string2 }</div>
                    <div
                        className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-30" : "m-t-30")} style={{ textAlign: "center" }}>
                        First WhiteList Until 2022.09.06 ( Sold out )
                        {/*( Remain : {this.state.remain_first} / 1500 )*/}
                    </div>

                    <div
                        className={"section-title "}>
                        <MultiColorProgressBar readings={this.state.readings} />
                    </div>

                    <div
                        className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-30" : "m-t-30")} style={{ textAlign: "center" }}>
                        2nd whitelist until 2022.09.27
                        ( Remain : {this.state.remain_first2} / 3000 )
                    </div>
                    <div
                        className={"section-title "}>
                        <MultiColorProgressBar readings={this.state.readings2} />
                    </div>
                    <button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50, fontSize: 16, width: '60%' }}
                            onClick={() => {
                                this.setState({ showNftModal: false })
                                setTimeout(() => {
                                    this.props.history.push("/nft_intro_v2")
                                    // window.location.href = '//nft_intro_v2'
                                }, 500)

                            }}>
                        {strings.go_hero_nft_card}
                    </button>


                    <div className="section-title" style={{ marginTop: "30px" }}>{strings.nft_event_string6}</div>
                    <div className="content-desc m-t-15" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: strings.hero_nft_card_desc3 }}/>
                    {/*<div className="content-desc m-t-15" style={{ textAlign: "center" }} dangerouslySetInnerHTML={{__html: strings.nft_event_string4 }}>*/}
                    {/*</div>*/}
                    <div
                         className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")}>{strings.hero_nft_card_title11}</div>
                    <div className="content-desc m-t-15" style={this.globalState.getLang() == "ar" ? { display: "block", textAlign: "right"} : { display: "block", textAlign: "center"}}
                         dangerouslySetInnerHTML={{__html: strings.hero_nft_card_desc11_desc}}>
                    </div>
                    <div className="section-title" style={{ marginTop: "30px" }}>{strings.nft_event_string5}</div>
                    <div
                        style={{height: 500}} className={"content-desc m-t-15 "}>
                        <DataGrid
                            disableColumnMenu
                            disableColumnSelector={true}
                            style={{  background: '#fff' }}
                            rows={this.state.griddata}
                            columns={columns}
                            pageSize={100}
                        />
                    </div>
                    <div className="section-title" > [Free airdrop of NFT hero card]</div>

                </div>

                <div id="gleam_competition" style={{ paddingBottom: "100px" }}></div>
            </div>

        )
    }
}

export default withRouter(inject('rootStore')(observer(NftEvent)));
