import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import Const, { BASE_URL } from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import "./nft-intro2.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Modal, ModalBody } from "reactstrap";
import axios from "axios";
import $ from "jquery/src/jquery";
import MultiColorProgressBar from "../../components/controls/multi-progress-bar";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

const columns = [
  {
    field: "id",
    headerName: "Rank",
    flex: 2,
  },
  {
    field: "from",
    headerName: "Address",
    flex: 2,
  },
  {
    field: "amt",
    headerName: "Quantity",
    flex: 2,
  },
  {
    field: "cnt",
    headerName: "Count",
    flex: 2,
  },
];

const youtube = [
  "https://www.youtube.com/embed/gfjBqRpmh6U",
  "https://www.youtube.com/embed/8Al9l7YVLC4",
  "https://www.youtube.com/embed/s0FSVXZ-wSs",
];

var ua = navigator.userAgent.toLowerCase();
var isSafari = false;
if (ua.indexOf("safari") != -1 || ua.indexOf("iphone") != -1) {
  if (ua.indexOf("chrome") > -1) {
    isSafari = false;
  } else {
    isSafari = true;
  }
}

class NftCard extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      showGuideModal: false,
      inputNftEmail: "",
      inputBepAddress: "",
      inputBepAmount: "",
      // inputPolygonAddress: '',
      inputNftEmail2: "",
      inputBepAddress2: "",
      inputBepType2: 1,
      inputBepAmount2: "",
      // inputPolygonAddress2: '',
      showMore1: false,
      showMore2: false,
      showMore3: false,
      remain_first: 0,
      remain_first2: 0,
      griddata: [],
      readings: [
        {
          name: "EVENT",
          value: 700,
          percent: 60,
          color: "#ff0000",
        },
        {
          name: "SALES including Event",
          value: 635,
          percent: 30,
          color: "yellow",
        },
        {
          name: "",
          value: 18,
          percent: 10,
          color: "#aaaaaa",
        },
      ],
    };
    this.iframeInt = 0;
    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  componentDidMount() {
    $("#a1").css("display", "none");
    $("#a2").css("display", "none");
    $("#a3").css("display", "none");
    $("#a4").css("display", "none");
    $("#a5").css("display", "none");
    $("#a6").css("display", "none");

    $("#b1").css("display", "none");
    $("#b2").css("display", "none");
    $("#b3").css("display", "none");
    $("#b4").css("display", "none");
    $("#b5").css("display", "none");
    $("#b6").css("display", "none");

    // $("#c1").css("display",'none');
    // $("#c2").css("display",'none');
    // $("#c3").css("display",'none');
    this.getTotaFirstCount();
    this.getTotaFirstCount2();
    axios
      .get(`${BASE_URL}/user/nftRank`, {
        headers: {
          Authorization:
            "Bearer " +
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MTE5NjM1OSwiZXhwIjoxNjgyNzMyMzU5fQ.5ZxqvUdLOS8zrbCZuDqZqv4Zjox1POUrZ0Ah0u9LEbs",
        },
      })
      .then(({ data }) => {
        var i = 1;
        const newData = data.map((row) => {
          const createdAt = new Date(row["createdAt"]).toLocaleString();
          row["id"] = i;
          // props.setNumber((number: any) => {
          //     row["no"] = number + 1;
          //     return number + 1;
          // });
          row["from"] = row["from"];
          // row["notSettle"] = row["miningAmount"] - row["settleAmount"];
          i++;
          return row;
        });
        this.state.griddata = newData;
        console.log(this.state.griddata);
        // this.state( griddata: .setData(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTotaFirstCount2 = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo2`, {})
      .then((res) => {
        const max = 3000;
        // const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 3900);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.05);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 94);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt) / max) * 100
        );
        console.log(bcl_cnt + eth_cnt + poly_cnt);
        // const sales_percent =  Math.round(sales / max * 100) ;
        const other_percent = 100 - cnt_percent; // - sales_percent;
        this.state.remain_first2 = other_cnt;

        this.state.readings2 = [
          {
            name: "SALES",
            value: bcl_cnt + eth_cnt + poly_cnt, //+ sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "green",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  getTotaFirstCount = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo`, {})
      .then((res) => {
        const max = 1500;
        const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 1000);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.022);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 45);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt + sales);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt + sales) / max) * 100
        );
        const sales_percent = Math.round((sales / max) * 100);
        const other_percent = 100 - cnt_percent - sales_percent;
        this.state.remain_first = other_cnt;
        this.state.readings = [
          // {
          //     name: 'EVENT',
          //     value: sales,
          //     percent: sales_percent,
          //     color: '#ff0000'
          // },
          {
            name: "SALES including Event",
            value: bcl_cnt + eth_cnt + poly_cnt + sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "#aaaaaa",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  clickMore1(flag) {
    console.log(flag);
    if (!flag) {
      $("#a1").css("display", "none");
      $("#a2").css("display", "none");
      $("#a3").css("display", "none");
      $("#a4").css("display", "none");
      $("#a5").css("display", "none");
      $("#a6").css("display", "none");
    } else {
      $("#a1").css("display", "");
      $("#a2").css("display", "");
      $("#a3").css("display", "");
      $("#a4").css("display", "");
      $("#a5").css("display", "");
      $("#a6").css("display", "inline-block");
    }
  }

  clickMore2(flag) {
    console.log(flag);
    if (!flag) {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly.png");
      $("#b1").css("display", "none");
      $("#b2").css("display", "none");
      $("#b3").css("display", "none");
      $("#b4").css("display", "none");
      $("#b5").css("display", "none");
      $("#b6").css("display", "none");
    } else {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly_on.png");
      $("#b1").css("display", "");
      $("#b2").css("display", "");
      $("#b3").css("display", "");
      $("#b4").css("display", "");
      $("#b5").css("display", "");
      $("#b6").css("display", "inline-block");
    }
  }
  clickMore3(flag) {
    console.log(flag);
    if (!flag) {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist.png");
      $("#c1").css("display", "none");
      $("#c2").css("display", "none");
      $("#c3").css("display", "none");
    } else {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist_on.png");
      $("#c1").css("display", "");
      $("#c2").css("display", "");
      $("#c3").css("display", "");
    }
  }

  onCopy() {
    var copyText = document.querySelector("#migration_address");

    function handler(event) {
      event.clipboardData.setData("text/plain", copyText.value);
      event.preventDefault();
      document.removeEventListener("copy", handler, true);
    }

    document.addEventListener("copy", handler, true);
    document.execCommand("copy");
    setTimeout(() => {
      alert("Copied");
    }, 500);
  }

  onConfirmTrasaction2() {
    if (this.state.inputNftEmail2 == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress2 == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount2 == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    // if (this.state.inputBepAmount < 1000) {
    //     alert(strings.bep_input_bep_amount2)
    //     return
    // }
    // if (this.state.inputPolygonAddress2 == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail2);
    params.append("bep_address", this.state.inputBepAddress2);
    params.append("amount_type", this.state.inputBepType2);
    params.append("bep_amount", this.state.inputBepAmount2);
    // params.append('polygon_address', this.state.inputPolygonAddress2);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst2`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        if (this.state.inputBepType2 == 1) {
          alert(strings.not_match_eth);
        } else {
          alert(strings.not_match_pol);
        }
      });
  }

  onConfirmTrasaction() {
    if (this.state.inputNftEmail == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    if (this.state.inputBepAmount < 3900) {
      alert(strings.bep_input_bep_amount2);
      return;
    }
    // if (this.state.inputPolygonAddress == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail);
    params.append("bep_address", this.state.inputBepAddress);
    params.append("bep_amount", this.state.inputBepAmount);
    // params.append('polygon_address', this.state.inputPolygonAddress);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(strings.not_match_bep);
      });
  }

  checkBEP2 = () => {
    {
      window.open(
        `https://bscscan.com/token/0x02b84f8b64e6c67ae6c797ff4ec4efb06ff19b44?a=0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };

  checkEtheScan = () => {
    {
      window.open(
        `https://etherscan.io/address/0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };
  checkPolyScan = () => {
    {
      window.open(
        `https://polygonscan.com/address/0xF30Eea37313Cf59D67448346F8319826c1B131c6`
      );
    }
  };

  componentWillUnmount() {}

  render() {
    return (
      <div id="nft" className="nft-main2">
        <img
          className="nft-header-img"
          src="/assets/img/nft/nft_background1.png"
          srcSet="/assets/img/nft/nft_background1.png 1x"
        />
        <div className="nft-video">
          <div
            className="video-content"
            style={{
              textAlign: "center",
            }}
          >
            <iframe
              id="myIframe"
              src="https://www.youtube.com/embed/gfjBqRpmh6U"
            ></iframe>
            <img
              className="video-bg"
              src="/assets/img/ic_video_container1.png"
              srcSet="/assets/img/ic_video_container1.png 1x"
            />
            <div style={{ position: "absolute", width: "100%" }}>
              <img
                src="/assets/img/nft/img_back_control1.png"
                style={{ height: 50, marginTop: "5px", zIndex: 1 }}
              />
              <div style={{ textAlign: "center", marginTop: "-45px" }}>
                <img
                  src="/assets/img/nft/img_prev_control1.png"
                  style={{ width: 75, height: 30, margin: "6px" }}
                  onClick={() => {
                    this.iframeInt--;
                    if (this.iframeInt < 0) {
                      this.iframeInt = youtube.length - 1;
                    }
                    document.getElementById("myIframe").src =
                      youtube[this.iframeInt];
                  }}
                />
                <img
                  src="/assets/img/nft/img_next_control1.png"
                  style={{
                    width: 75,
                    height: 30,
                    top: 0,
                    zIndex: 1,
                    margin: "6px",
                  }}
                  onClick={() => {
                    this.iframeInt++;
                    if (this.iframeInt > youtube.length - 1) {
                      this.iframeInt = 0;
                    }
                    document.getElementById("myIframe").src =
                      youtube[this.iframeInt];
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content about2" data-scrollview="true">
          <div
            className="about-content"
            data-animation="true"
            data-animation-type="fadeInDown"
            style={{ paddingTop: "600px" }}
          >
            <div
              className="content-title"
              style={{
                marginTop:
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? "-15%"
                    : "5vh",
              }}
            >
              {strings.nft_about_title2}
            </div>
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_about_desc }}
            />
          </div>
        </div>

        <div
          className="maxform"
          style={{
            backgroundImage: "url(/assets/img/img_herocard_background.png)",
          }}
        >
          <img
            className="maxform-img"
            src="/assets/img/nft/ic_maxform.png"
            srcSet="/assets/img/nft/ic_maxform.png 1x"
          />
          <div
            className="content-desc"
            style={
              this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}
            }
            dangerouslySetInnerHTML={{ __html: strings.maxform_desc }}
          />
          {/* <img
            className="waterfall-img"
            src="/assets/img/nft/img_herocard_background.png"
            srcSet="/assets/img/nft/img_herocard_background.png 1x"
          /> */}
          <img
            className="waterfall-img"
            src="/assets/img/nft/img_nft_wipple1.png"
            srcSet="/assets/img/nft/img_nft_wipple1.png 1x"
          />
        </div>

        <div className="content ">
          <div className="content-bg" style={{ position: "absolute" }}>
            <img
              className="top"
              src="/assets/img/nft/img_bg_nft8.png"
              srcSet="/assets/img/nft/img_bg_nft8.png 1x"
            />
          </div>
          <div
            className="content media-partners"
            data-scrollview="true"
            style={{ background: "transparent", paddingBottom: 0 }}
          >
            <div
              style={{
                background: "#000000aa",
                margin: "5vw 3vw",
                paddingLeft: "3vw",
                paddingRight: "3vw",
              }}
            >
              <div className="content-title" style={{}}>
                {strings.hero_nft_card3}
              </div>
              <div
                className="content-desc"
                style={
                  this.globalState.getLang() == "ar"
                    ? {
                        textAlign: "right",
                        color: "white",
                      }
                    : { textAlign: "center", color: "white" }
                }
                dangerouslySetInnerHTML={{
                  __html: strings.hero_nft_card3_desc,
                }}
              />

              <Swiper
                style={{ padding: "0px 50px 10px 50px", background: "black" }}
                spaceBetween={3}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  765: {
                    // width: 400,
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/1.png"
                    srcSet="/assets/img/nft/card/1.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/2.png"
                    srcSet="/assets/img/nft/card/2.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/3.png"
                    srcSet="/assets/img/nft/card/3.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/4.png"
                    srcSet="/assets/img/nft/card/4.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/5.png"
                    srcSet="/assets/img/nft/card/5.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/6.png"
                    srcSet="/assets/img/nft/card/6.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/7.png"
                    srcSet="/assets/img/nft/card/7.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/8.png"
                    srcSet="/assets/img/nft/card/8.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/9.png"
                    srcSet="/assets/img/nft/card/9.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/10.png"
                    srcSet="/assets/img/nft/card/10.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/12.png"
                    srcSet="/assets/img/nft/card/12.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/13.png"
                    srcSet="/assets/img/nft/card/13.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/14.png"
                    srcSet="/assets/img/nft/card/14.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/15.png"
                    srcSet="/assets/img/nft/card/15.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/16.png"
                    srcSet="/assets/img/nft/card/16.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/17.png"
                    srcSet="/assets/img/nft/card/17.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/18.png"
                    srcSet="/assets/img/nft/card/18.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/19.png"
                    srcSet="/assets/img/nft/card/19.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/20.png"
                    srcSet="/assets/img/nft/card/20.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/21.png"
                    srcSet="/assets/img/nft/card/21.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/22.png"
                    srcSet="/assets/img/nft/card/22.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/23.png"
                    srcSet="/assets/img/nft/card/23.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/24.png"
                    srcSet="/assets/img/nft/card/24.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/25.png"
                    srcSet="/assets/img/nft/card/25.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/26.png"
                    srcSet="/assets/img/nft/card/26.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/27.png"
                    srcSet="/assets/img/nft/card/27.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/28.png"
                    srcSet="/assets/img/nft/card/28.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/29.png"
                    srcSet="/assets/img/nft/card/29.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/30.png"
                    srcSet="/assets/img/nft/card/30.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/31.png"
                    srcSet="/assets/img/nft/card/31.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/32.png"
                    srcSet="/assets/img/nft/card/32.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/33.png"
                    srcSet="/assets/img/nft/card/33.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/34.png"
                    srcSet="/assets/img/nft/card/34.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/35.png"
                    srcSet="/assets/img/nft/card/35.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/36.png"
                    srcSet="/assets/img/nft/card/36.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/37.png"
                    srcSet="/assets/img/nft/card/37.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/38.png"
                    srcSet="/assets/img/nft/card/38.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/39.png"
                    srcSet="/assets/img/nft/card/39.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/40.png"
                    srcSet="/assets/img/nft/card/40.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/card/50.png"
                    srcSet="/assets/img/nft/card/50.png 1x"
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                style={{ padding: "0px 50px 10px 50px", background: "black" }}
                spaceBetween={3}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  reverseDirection: true,
                  disableOnInteraction: false,
                }}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  765: {
                    // width: 768,
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/1.png"
                    srcSet="/assets/img/nft/two/1.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/2.png"
                    srcSet="/assets/img/nft/two/2.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/3.png"
                    srcSet="/assets/img/nft/two/3.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/4.png"
                    srcSet="/assets/img/nft/two/4.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/5.png"
                    srcSet="/assets/img/nft/two/5.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/6.png"
                    srcSet="/assets/img/nft/two/6.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/7.png"
                    srcSet="/assets/img/nft/two/7.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/8.png"
                    srcSet="/assets/img/nft/two/8.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/9.png"
                    srcSet="/assets/img/nft/two/9.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/10.png"
                    srcSet="/assets/img/nft/two/10.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/11.png"
                    srcSet="/assets/img/nft/two/11.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/12.png"
                    srcSet="/assets/img/nft/two/12.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/13.png"
                    srcSet="/assets/img/nft/two/13.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/14.png"
                    srcSet="/assets/img/nft/two/14.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/15.png"
                    srcSet="/assets/img/nft/two/15.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/16.png"
                    srcSet="/assets/img/nft/two/16.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/17.png"
                    srcSet="/assets/img/nft/two/17.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/18.png"
                    srcSet="/assets/img/nft/two/18.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/19.png"
                    srcSet="/assets/img/nft/two/19.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/20.png"
                    srcSet="/assets/img/nft/two/20.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/21.png"
                    srcSet="/assets/img/nft/two/21.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/22.png"
                    srcSet="/assets/img/nft/two/22.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/23.png"
                    srcSet="/assets/img/nft/two/23.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/24.png"
                    srcSet="/assets/img/nft/two/24.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/25.png"
                    srcSet="/assets/img/nft/two/25.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/26.png"
                    srcSet="/assets/img/nft/two/26.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/27.png"
                    srcSet="/assets/img/nft/two/27.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/28.png"
                    srcSet="/assets/img/nft/two/28.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/29.png"
                    srcSet="/assets/img/nft/two/29.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/30.png"
                    srcSet="/assets/img/nft/two/30.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/31.png"
                    srcSet="/assets/img/nft/two/31.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/32.png"
                    srcSet="/assets/img/nft/two/32.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/33.png"
                    srcSet="/assets/img/nft/two/33.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/34.png"
                    srcSet="/assets/img/nft/two/34.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/35.png"
                    srcSet="/assets/img/nft/two/35.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/36.png"
                    srcSet="/assets/img/nft/two/36.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/37.png"
                    srcSet="/assets/img/nft/two/37.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/38.png"
                    srcSet="/assets/img/nft/two/38.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/39.png"
                    srcSet="/assets/img/nft/two/39.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/two/40.png"
                    srcSet="/assets/img/nft/two/40.png 1x"
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                style={{ padding: "0px 50px 10px 50px", background: "black" }}
                spaceBetween={3}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  765: {
                    // width: 400,
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/1.png"
                    srcSet="/assets/img/nft/hero3/1.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/2.png"
                    srcSet="/assets/img/nft/hero3/2.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/3.png"
                    srcSet="/assets/img/nft/hero3/3.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/4.png"
                    srcSet="/assets/img/nft/hero3/4.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/5.png"
                    srcSet="/assets/img/nft/hero3/5.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/6.png"
                    srcSet="/assets/img/nft/hero3/6.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/7.png"
                    srcSet="/assets/img/nft/hero3/7.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/8.png"
                    srcSet="/assets/img/nft/hero3/8.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/9.png"
                    srcSet="/assets/img/nft/hero3/9.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/10.png"
                    srcSet="/assets/img/nft/hero3/10.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/12.png"
                    srcSet="/assets/img/nft/hero3/12.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/13.png"
                    srcSet="/assets/img/nft/hero3/13.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/14.png"
                    srcSet="/assets/img/nft/hero3/14.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/15.png"
                    srcSet="/assets/img/nft/hero3/15.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/16.png"
                    srcSet="/assets/img/nft/hero3/16.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/17.png"
                    srcSet="/assets/img/nft/hero3/17.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/18.png"
                    srcSet="/assets/img/nft/hero3/18.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/19.png"
                    srcSet="/assets/img/nft/hero3/19.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/20.png"
                    srcSet="/assets/img/nft/hero3/20.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/21.png"
                    srcSet="/assets/img/nft/hero3/21.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/22.png"
                    srcSet="/assets/img/nft/hero3/22.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/23.png"
                    srcSet="/assets/img/nft/hero3/23.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/24.png"
                    srcSet="/assets/img/nft/hero3/24.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/25.png"
                    srcSet="/assets/img/nft/hero3/25.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/26.png"
                    srcSet="/assets/img/nft/hero3/26.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/27.png"
                    srcSet="/assets/img/nft/hero3/27.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/28.png"
                    srcSet="/assets/img/nft/hero3/28.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/29.png"
                    srcSet="/assets/img/nft/hero3/29.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/30.png"
                    srcSet="/assets/img/nft/hero3/30.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/31.png"
                    srcSet="/assets/img/nft/hero3/31.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/32.png"
                    srcSet="/assets/img/nft/hero3/32.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/33.png"
                    srcSet="/assets/img/nft/hero3/33.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/34.png"
                    srcSet="/assets/img/nft/hero3/34.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/35.png"
                    srcSet="/assets/img/nft/hero3/35.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/36.png"
                    srcSet="/assets/img/nft/hero3/36.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/37.png"
                    srcSet="/assets/img/nft/hero3/37.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/38.png"
                    srcSet="/assets/img/nft/hero3/38.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/39.png"
                    srcSet="/assets/img/nft/hero3/39.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/40.png"
                    srcSet="/assets/img/nft/hero3/40.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero3/50.png"
                    srcSet="/assets/img/nft/hero3/11.png 1x"
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                style={{ padding: "0px 50px 10px 50px", background: "black" }}
                spaceBetween={3}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  765: {
                    // width: 768,
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/1.png"
                    srcSet="/assets/img/nft/dr/1.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/3.png"
                    srcSet="/assets/img/nft/dr/3.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/5.png"
                    srcSet="/assets/img/nft/dr/5.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/4.png"
                    srcSet="/assets/img/nft/dr/4.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/34.png"
                    srcSet="/assets/img/nft/dr/34.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/6.png"
                    srcSet="/assets/img/nft/dr/6.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/9.png"
                    srcSet="/assets/img/nft/dr/9.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/10.png"
                    srcSet="/assets/img/nft/dr/10.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/12.png"
                    srcSet="/assets/img/nft/dr/12.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/11.png"
                    srcSet="/assets/img/nft/dr/11.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/21.png"
                    srcSet="/assets/img/nft/dr/21.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/13.png"
                    srcSet="/assets/img/nft/dr/13.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/16.png"
                    srcSet="/assets/img/nft/dr/16.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/24.png"
                    srcSet="/assets/img/nft/dr/24.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/14.png"
                    srcSet="/assets/img/nft/dr/15.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/17.png"
                    srcSet="/assets/img/nft/dr/17.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/18.png"
                    srcSet="/assets/img/nft/dr/18.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/19.png"
                    srcSet="/assets/img/nft/dr/19.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/20.png"
                    srcSet="/assets/img/nft/dr/20.png 1x"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/22.png"
                    srcSet="/assets/img/nft/dr/22.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/23.png"
                    srcSet="/assets/img/nft/dr/23.png 1x"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/25.png"
                    srcSet="/assets/img/nft/dr/25.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/26.png"
                    srcSet="/assets/img/nft/dr/26.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/27.png"
                    srcSet="/assets/img/nft/dr/27.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/28.png"
                    srcSet="/assets/img/nft/dr/28.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/29.png"
                    srcSet="/assets/img/nft/dr/29.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/30.png"
                    srcSet="/assets/img/nft/dr/30.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/31.png"
                    srcSet="/assets/img/nft/dr/31.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/32.png"
                    srcSet="/assets/img/nft/dr/32.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/33.png"
                    srcSet="/assets/img/nft/dr/33.png 1x"
                  />
                </SwiperSlide>

                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/35.png"
                    srcSet="/assets/img/nft/dr/35.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/36.png"
                    srcSet="/assets/img/nft/dr/36.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/37.png"
                    srcSet="/assets/img/nft/dr/37.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/38.png"
                    srcSet="/assets/img/nft/dr/38.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/39.png"
                    srcSet="/assets/img/nft/dr/39.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/40.png"
                    srcSet="/assets/img/nft/dr/40.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/31.png"
                    srcSet="/assets/img/nft/dr/41.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/32.png"
                    srcSet="/assets/img/nft/dr/42.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/33.png"
                    srcSet="/assets/img/nft/dr/43.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/34.png"
                    srcSet="/assets/img/nft/dr/44.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/35.png"
                    srcSet="/assets/img/nft/dr/45.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/36.png"
                    srcSet="/assets/img/nft/dr/46.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/37.png"
                    srcSet="/assets/img/nft/dr/47.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/38.png"
                    srcSet="/assets/img/nft/dr/48.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/39.png"
                    srcSet="/assets/img/nft/dr/49.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/dr/40.png"
                    srcSet="/assets/img/nft/dr/50.png 1x"
                  />
                </SwiperSlide>
              </Swiper>
              <Swiper
                style={{ padding: "0px 50px 10px 50px", background: "black" }}
                spaceBetween={3}
                slidesPerView={1}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  reverseDirection: true,
                  disableOnInteraction: false,
                }}
                onSwiper={(swiper) => console.log(swiper)}
                navigation={true}
                modules={[Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  765: {
                    // width: 768,
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/1.png"
                    srcSet="/assets/img/nft/hero5/1.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/2.png"
                    srcSet="/assets/img/nft/hero5/2.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/3.png"
                    srcSet="/assets/img/nft/hero5/3.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/4.png"
                    srcSet="/assets/img/nft/hero5/4.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/5.png"
                    srcSet="/assets/img/nft/hero5/5.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/6.png"
                    srcSet="/assets/img/nft/hero5/6.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/7.png"
                    srcSet="/assets/img/nft/hero5/7.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/8.png"
                    srcSet="/assets/img/nft/hero5/8.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/9.png"
                    srcSet="/assets/img/nft/hero5/9.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/10.png"
                    srcSet="/assets/img/nft/hero5/10.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/11.png"
                    srcSet="/assets/img/nft/hero5/11.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/12.png"
                    srcSet="/assets/img/nft/hero5/12.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/13.png"
                    srcSet="/assets/img/nft/hero5/13.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/14.png"
                    srcSet="/assets/img/nft/hero5/14.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/15.png"
                    srcSet="/assets/img/nft/hero5/15.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/16.png"
                    srcSet="/assets/img/nft/hero5/16.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/17.png"
                    srcSet="/assets/img/nft/hero5/17.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/18.png"
                    srcSet="/assets/img/nft/hero5/18.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/19.png"
                    srcSet="/assets/img/nft/hero5/19.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/20.png"
                    srcSet="/assets/img/nft/hero5/20.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/21.png"
                    srcSet="/assets/img/nft/hero5/21.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/22.png"
                    srcSet="/assets/img/nft/hero5/22.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/23.png"
                    srcSet="/assets/img/nft/hero5/23.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/24.png"
                    srcSet="/assets/img/nft/hero5/24.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/25.png"
                    srcSet="/assets/img/nft/hero5/25.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/26.png"
                    srcSet="/assets/img/nft/hero5/26.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/27.png"
                    srcSet="/assets/img/nft/hero5/27.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/28.png"
                    srcSet="/assets/img/nft/hero5/28.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/29.png"
                    srcSet="/assets/img/nft/hero5/29.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/30.png"
                    srcSet="/assets/img/nft/hero5/30.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/31.png"
                    srcSet="/assets/img/nft/hero5/31.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/32.png"
                    srcSet="/assets/img/nft/hero5/32.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/33.png"
                    srcSet="/assets/img/nft/hero5/33.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/34.png"
                    srcSet="/assets/img/nft/hero5/34.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/35.png"
                    srcSet="/assets/img/nft/hero5/35.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/36.png"
                    srcSet="/assets/img/nft/hero5/36.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/37.png"
                    srcSet="/assets/img/nft/hero5/37.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/38.png"
                    srcSet="/assets/img/nft/hero5/38.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/39.png"
                    srcSet="/assets/img/nft/hero5/39.png 1x"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img
                    className="img-hero-card"
                    src="/assets/img/nft/hero5/40.png"
                    srcSet="/assets/img/nft/hero5/40.png 1x"
                  />
                </SwiperSlide>
              </Swiper>
              <div
                className="content technique"
                data-scrollview="true"
                style={{ background: "transparent" }}
              >
                <div
                  className="content-desc"
                  style={
                    this.globalState.getLang() == "ar"
                      ? {
                          textAlign: "right",
                          color: "white",
                        }
                      : { textAlign: "left", color: "white" }
                  }
                  dangerouslySetInnerHTML={{ __html: strings.nft_20 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="content technique"
          data-scrollview="true"
          style={{
            backgroundImage: "url(/assets/img/img_bg_made.png)",
          }}
        >
          {/* <div className="content-bg">
            <img
              className="top"
              src="/assets/img/img_bg_distribution.png"
              srcSet="/assets/img/img_bg_distribution.png 1x"
            />
          </div> */}
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title" style={{ color: "#eb8f22" }}>
              {strings.shape_made_tech}
            </div>
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}
              }
              dangerouslySetInnerHTML={{ __html: strings.shape_made_tech_desc }}
            />
            <div className="image-container">
              <img
                className="tech-img"
                src="/assets/img/nft/img_self_made_1.png"
                srcSet="/assets/img/nft/img_self_made_1.png 1x"
              />
              <div className="step-indicator">
                {this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "►"
                  : "▼"}
              </div>
              <img
                className="tech-img"
                src="/assets/img/nft/img_self_made_2.png"
                srcSet="/assets/img/nft/img_self_made_2.png 1x"
              />
              <div className="step-indicator">
                {this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "►"
                  : "▼"}
              </div>
              <img
                className="tech-img"
                src="/assets/img/nft/img_self_made_3.png"
                srcSet="/assets/img/nft/img_self_made_3.png 1x"
              />
            </div>
          </div>
          <div data-animation="true" data-animation-type="fadeInDown">
            <div className="content-title" style={{ color: "#eb8f22" }}>
              {strings.shape_made_system}
            </div>
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar" ? { textAlign: "right" } : {}
              }
              dangerouslySetInnerHTML={{
                __html: strings.shape_made_system_desc,
              }}
            />
            <div className="image-container">
              <img
                className="tech-img"
                src="/assets/img/nft/img_self_made_4.png"
                srcSet="/assets/img/nft/img_self_made_4.png 1x"
              />
              {this.uiState.display_mode === Const.DISPLAY.DESKTOP && (
                <div style={{ marginLeft: 40, marginRight: 40 }}></div>
              )}
              <div className="tech-img" style={{ position: "relative" }}>
                <img
                  style={{ width: "100%" }}
                  src="/assets/img/nft/img_self_made_5.png"
                  srcSet="/assets/img/nft/img_self_made_5.png 1x"
                />
                <video autoPlay loop /*controls*/ muted>
                  <source
                    src="/assets/video/Dark slezinger_Final.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
          {/* <img
            className="waterfall-img"
            src="/assets/img/nft/img_nft_wipple1.png"
            srcSet="/assets/img/nft/img_nft_wipple1.png 1x"
          /> */}
        </div>
      </div>
    );
  }
}

export default withRouter(inject("rootStore")(observer(NftCard)));
