import { decorate, observable } from 'mobx';
import Const from '../config/const';

export class GlobalState {
    lang = "en";

    constructor() {
        this.load();
    }

    load() {
        try {
            let store = JSON.parse(localStorage.getItem(Const.STORE.GlobalState));
            if (!store) {
                this.reset();
                return;
            }

            this.lang = store.lang;
        } catch (e) {
            console.log(e);
            this.reset();
        }
    }

    save() {
        localStorage.setItem(Const.STORE.GlobalState, JSON.stringify(this));
    }

    getLang() {
        return this.lang;
    }

    setLang(lang) {
        this.lang = lang;
        this.save();
    }

    reset() {
        this.lang = "en";
        this.save();
    }
}

decorate(GlobalState, {
    lang: observable,
});