import { AIRDRO_END_DAY } from "../config/const";
const moment = require("moment");

export default {
  app_name: "BitcoinLegend",
  app_name_short: "BCL",

  bcl: "BCL",
  days: "DAYS",
  hours: "HOURS",
  minutes: "MINUTES",
  seconds: "SECONDS",

  header_menu_list: [
    { code: "home", title: "Home" },
    {
      code: "nft",
      title: "Hero NFT Card",
      sub_header_menu_list: [
        { code: "/whitelist", title: "White List of NFT" },
        { code: "/card", title: "NFT Card" },
        { code: "/mint", title: "NFT Mint" },
        { code: "/gamefi", title: "Game-Fi Card" },
      ],
    },
    // { code: "about", title: "About" },
    { code: "airdrop", title: "Airdrop&Migration" },
    // { code: "levelup", title: "Level Up Program" },
    { code: "kyc", title: "KYC Certification" },
    // { code: "total_supply", title: "Total supply & Distribution" },
    { code: "roadmap", title: "RoadMap" },
    // { code: "levelup", title: "LevelUp", }
  ],

  widely_known_desc:
    "BitcoinLegend is widely known. Touch the search bar below.",

  earn_project_title: "3 Earn Project",
  earn_project_desc: "Mine to Earn, Make to Earn, Play to Earn",

  airdrop_ending_title: "AIRDROP Ending",
  airdrop_remain_count: "Airdrop Remaining Count",
  airdrop_first_desc: "1st AIRDROP End<br/>10M BCL",
  airdrop_second_desc: "2nd/3rd/4rd AIRDROP End<br/>15M BCL",
  airdrop_third_desc: "5th AIRDROP Running<br/>5M BCL",
  airdrop: "AIRDROP",
  pre_sale: "Pre-Sale",

  ntf_avatar_metaverse_game: "NFT Avatar Metaverse Game",
  three_earn_strategy: "3 Earn Strategy",
  mining_to_earn_desc:
    "التعدين والنصف<br/>الإحالات (+ 25٪)<br/>اقتصاد رمزي<br/>تطبيق Android و iOS<br/>حزمة متعددة اللغات<br/> Lockup & Staking",
  make_to_earn_desc:
    "بيع وشراء NFTcard الفنون ، براءات الاختراع<br/>نظام عصامي للمستخدم<br/>→ استوديو BCL<br/>مزاد سوق NFT",
  play_to_earn_desc:
    "1: 1 لعبة قتال / لاعب ضد لاعب<br/>صفقة العقارات الافتراضية<br/>الإعلانات / رسوم الدخول في القتال<br/>قناة البث<br/>التسوق عبر الانترنت",
  increase_strategy_title:
    "Token Buyback, Burning, NFT Business -> Price Strategy for Increase",
  increase_strategy_0:
    "ICO(600m BCL): المستخدم 50M → 10M ، Buring 10B BCL (خطوة بخطوة)",
  increase_strategy_1: "IEO(CoinMarketCap : CMC Level)",
  increase_strategy_2: "CoinMarketCap",
  increase_strategy_3: "الإدراج الأول / 15 مارس. 2022. / القائمة الأولى",
  increase_strategy_4: "LBank",
  increase_strategy_5: "القائمة الثانية / يونيو. 2022. / PancakeSwap",
  increase_strategy_6: "PancakeSwap",
  increase_strategy_7: "الإدراج الثالث / سبتمبر. 2022. / ~Lv.20",
  increase_strategy_8: "القائمة الرابعة / الربع الرابع. 2022. / ~ Lv. 20.",
  increase_strategy_9: "القائمة الخامسة / 2023 / ~ Lv. 10.",
  increase_strategy_10: "القائمة السادسة / 2023 / ~ Lv. 5.",

  about_title: "About BitcoinLegend",
  about_desc:
    "<p>BitcoinLegend (BCL) هو أصول عملة مشفرة تعتمد على ERC-20." +
    " سنصنع منصة سوق NFT (بطاقات NFT ، أعمال فنية).<br/>" +
    "أيضًا ، سنطلق لعبة metaverse ببطاقة Hero NFT مستوحاة من Marvel's Avengers.</p>" +
    "<p> يمر عالم اليوم بالثورة الصناعية الرابعة ، ولا شك في أن تقنية blockchain ستلعب دورًا مهمًا فيها. <br/>" +
    "تهدف BITCOIN LEGEND (BCL) ، التي تم إنشاؤها لتحقيق مُثُل الناس ، إلى أن تصبح منصة تبني نظامًا بيئيًا لامركزيًا للتطبيق باستخدام تقنية blockchain ، والعملات المشفرة ، وأنظمة metaverse في مجال الثورة الصناعية الرابعة. </ p>" +
    "<p> باعتبارها عملة مشفرة تستند إلى ERC-20 ، تتميز BITCOIN LEGEND (BCL) بمنصة سوق NFT قادمة ولعبة metaverse مستقبلية ببطاقة NFT البطل المستوحاة من Marvel’s Avengers. <p />" +
    "<p> هناك 3 إستراتيجية للكسب في BITCOIN LEGEND توفر للمستخدمين 3 طرق لكسب المزيد من الرموز المميزة. <p />" +
    "<p> الأول هو Mine to Earn ، والذي يعالج مشاكل تعدين البيتكوين من خلال إجراء عملية التعدين على الهاتف الذكي <br/>" +
    "عندما يتم إدراج BCL المُعدن في بورصات العملات المشفرة الرئيسية ، فإنه يعمل كأصل رقمي بالإضافة إلى استثمار. <p />" +
    "<p> الخيار الثاني هو Make to Earn ، حيث يمكن للمستخدمين إنشاء صور NFT الرمزية الخاصة بهم ، وعناصر NFT ، ثم شرائها وبيعها لتحقيق ربح. <br/>" +
    "تستخدم BITCOIN LEGEND تقنية الذكاء الاصطناعي لإنشاء شخصيات webtoon مماثلة للمستخدمين الحقيقيين أنفسهم. <br/>" +
    "يمكن للمستخدمين أيضًا إنتاج الملابس والزخارف وعناصر اللعبة الأخرى. <br/>" +
    "Hero History من تأليف Webtoon و Song (Webtoon + Rapper 'Rap + NFT animation). <br/>" +
    "لقد حصل على 300000 مشاهدة في 10 أيام بعد تحميله على YouTube. <p />" +
    "<p> الثالث هو Play to Earn ، ستنفذ شبكة BITCOIN LEGEND أنشطة اقتصادية على metaverse باستخدام بطاقات NFT الرمزية التي تم إنشاؤها مسبقًا ، بالإضافة إلى العقارات الافتراضية ، ويمكن للمستخدمين ممارسة اللعبة أو الاستثمار في العقارات الافتراضية على metaverse وتحقيق ربح من هذه الأنشطة. <p /> ",
  smart_contract: "العقد الذكي (الرمز القديم: ERC20)",
  smart_contract1: "العقد الذكي (رمز جديد: BEP20)",
  smart_contract2: "احتراق (ERC20)",
  smart_url: "URL : ",
  smart_amount: "كمية الحرق : ",

  token_symbol: "الرمز المميز : ",
  contract_address: "عنوان العقد : ",
  decimals: "الكسور العشرية : ",
  total_supply: "إجمالي العرض : ",
  blockchain: "Blockchain : ",
  white_paper: "ورق ابيض",

  airdrop_title: "Airdrop",
  airdrop_quantity: "كمية Airdrop : ",
  airdrop_rules: "قواعد Airdrop",
  airdrop_rules_airdrop_desc:
    "يمكن لمستخدمي المجتمع المشاركة في الإنزال الجوي عن طريق تثبيت تطبيق BitcoinLegend.<br/>" +
    "يجب أن يتم التعدين على الأقل حتى نهاية الإنزال الجوي. المقدار الأولي من الإنزال الجوي هو 25 BCL.<br/>" +
    "إن الإنزال الجوي مجاني تمامًا ، وسيتم توزيع مكافآت الإسقاط الجوي على تطبيق التعدين بعد انتهاء عملية الإنزال الجوي.",
  invitation_rules: "قواعد الدعوة",
  invitation_rules_desc:
    "يمكن للمستخدمين الحصول على مكافآت Token إضافية من خلال دعوة الأصدقاء للمشاركة في Airdrop. <br /> يمكنك الحصول على 50 رموز BCL لكل صديق تدعوه. يمكنك دعوة ما يصل إلى 50 صديقًا ، والحصول على ما يصل إلى 2500 رمز BCL. <br /> كمية الهواء محدودة ، ونسخة BCL صغيرة.",
  invite_team: "دعوة الفريق",
  invite_team_desc:
    "+ 25 BCL/المستخدم. الأعلى. 50 مستخدمًا <br/>+@25 ٪ عدد التعدين للمستخدم المدعو",

  recommend_title: "كيف تنصح أصدقائك",
  recommend_code_right: "هل الكود الموصى به على ما يرام؟",
  recommend_input_code: "أدخل كود التوصية",
  recommend_inputted_code: "الكود الموصى به",
  recommend_msg_copy: "المس هنا",
  recommend_code_non_exist:
    "هذا رمز إحالة غير صالح. يرجى التحقق من ذلك مرة أخرى.",
  recommend_code_verify: "الرجاء إدخال رمز الإحالة والتحقق منه.",
  recommend_msg_part1:
    "BitcoinLegend ليس تطبيقًا للتعدين ولكنه أيضًا لعبة metaverse باستخدام بطاقة Hero NFT مستوحاة من Marvel's Avengers.<br/><br/>☆ انضم إلى BitcoinLegend للحصول على 10 BCL مجانًا.<br/>الكود الموصى به: ",
  recommend_msg_part2: "<br/><br/>☆Official website<br/>",

  pre_sale_title: "Pre-sale was closed",
  pre_sale_quantity: "كمية ما قبل البيع : ",
  pre_sale_address: "عنوان ما قبل البيع : ",
  pre_sale_price: "سعر البيع : ",
  pre_sale_price_desc:
    "الحد الأدنى للشراء هو 0.1 ETH ، والحد الأقصى للشراء هو 10 ETH.",

  airdrop_rules_presale: "معايير ما قبل البيع",
  airdrop_rules_pre_sale_desc:
    "يمكن للمستخدمين الحصول على مكافآت Token إضافية من خلال دعوة الأصدقاء للمشاركة في Airdrop. <br /> يمكنك الحصول على 50 رموز BCL لكل صديق تدعوه. يمكنك دعوة ما يصل إلى 50 صديقًا ، والحصول على ما يصل إلى 2500 رمز BCL. <br /> كمية Airdrop محدودة ، وإصدار BCL صغير. السعر المقدر لـ BCL هو 0.75 دولار. اسرع ومشاركته مع أصدقائك.",
  how_to_do_pre_sale: "كيف نفعل ما قبل البيع",
  how_to_do_pre_sale_desc:
    "استخدم محفظتك لإرسال ETH إلى عنوان ما قبل البيع. سيرسل نظامنا الرموز المميزة لـ BCL إلى محفظتك على الفور. <br /> يجب إجراء التحويلات باستخدام محفظة تشفير شخصية دون التحقق من KYC.",
  pre_sale_rules: "قواعد ما قبل البيع",
  pre_sale_rules_desc:
    "سينهي النظام البيع المسبق في <h6><u> " +
    moment(AIRDRO_END_DAY).format("YYYY-MM-DD HH:mm(UTC+8)") +
    "</u></h6>.<br/>" +
    "استخدم محفظتك لإرسال ETH إلى عنوان ما قبل البيع ، وسيقوم النظام فورًا بإرسال المبلغ المقابل من رموز BCL إلى محفظتك.<br/>" +
    "كمية الرموز المميزة BCL للبيع المسبق محدودة ، ويتم فرز الحصة وفقًا لوقت وصول ETH. من يأتي اولا، يخدم اولا.",

  once_the_pre_sale_over: "بمجرد انتهاء البيع المسبق",
  once_the_pre_sale_over_desc:
    "<h6><u>سيتم استخدام 60٪ من الأموال التي تم جمعها في سوق NFT وإدراجها في LBank & MEXC.</u></h6><br/>" +
    "سيتم استخدام الـ 40٪ الأخرى من الأموال التي تم جمعها لإعادة شراء الرموز المميزة BCL. هذا الإجراء سيمنع الناس من الإغراق.",

  check_pre_sale: "تحقق من البيع المسبق",
  go: "Go",
  check_pre_sale_hint:
    "لا يوجد KYC ، أرسل BCL فورًا إلى عنوان ما قبل البيع بعد إرسال ETH",

  total_supply_and_distribution: "إجمالي العرض والتوزيع",
  the_number_of_miner: "عدد عامل المنجم :",
  users: "Users",
  end_of_mining: "نهاية التعدين :",
  end_of_mining_desc: "سينتهي التعدين عندما اقترب 10 ملايين مستخدم.",
  total_supply1: "إجمالي العرض:",
  burning_and_buyback: "الحرق وإعادة الشراء:",
  community_airdrop: "الإنزال الجوي للمجتمع:",
  bcl_halving: "BitcoinLegend Halving",
  distribution_bcl_supply: "Distribution of BCL supply",

  roadmap: "Roadmap",
  roadmap_last_title: "Accomplished milestones up to date(Phase1 & Phase2)",
  phase_1_2_date: '020- Q2. 2021',
  roadmap_last_item_0: " فريق تطوير المشروع (الفريق الأساسي BCL) بني",
  roadmap_last_item_1:
    "Mobile التطبيق و BitcoinLegend Concept تم تطويرهما<br/>(ميزة العملة ، طريقة التعدين ، اقتصاد الرمز المميز ، تطبيق التعدين وتصميم المحفظة)",
  roadmap_last_item_3: "ورق أبيض V1.0 مصنوع",
  roadmap_last_item_4: "تطوير حزمة اللغة الموسعة لـ 44 دولة",
  roadmap_last_item_5: "حرر تطبيق iOS للجوال",
  roadmap_last_item_6: "تحديث اقتصاد الرمز المميز (بما في ذلك التدوير)",
  roadmap_last_item_7: "ضع خطة قائمة في بورصات العملات المشفرة الرئيسية",

  now: "Now",
  phase3: "Phase3",
  phase3_date: "Q3.-Q4. 2021.",
  phase3_item_0: "إصدار تطبيق جوال Android / iOS",
  phase3_item_1: "المجتمع الرسمي (Telegram و Twitter و Youtube) مفتوح",
  phase3_item_2: "تمت إضافة حزمة اللغة الموسعة في √44 دولة",
  phase3_item_3: "قم بتحديث اقتصاد الرمز (بما في ذلك التدوير)",
  phase3_item_4: "الانتهاء من تصميم بطاقة Hero NFT",

  phase4: "Phase4",
  phase4_sector_0_date: "Q1.-Q2. 2022",
  phase4_sector_0_item_0: "إطلاق الموقع الرسمي",
  phase4_sector_0_item_1: "تجديد المستند التعريفي التمهيدي V2.0 (إنفاق 3 استراتيجية كسب)",
  phase4_sector_0_item_2: "تم تطوير مراقب تعدين المستخدم ووظيفة اختبار الاتصال NFT",
  phase4_sector_0_item_3: "تم فتح Hero NFT Card Webtoon و Rap Youtube",
  phase4_sector_0_item_4: "فتح الخلاف الرسمي",
  phase4_sector_0_item_5: "إطلاق نظام Lockup & Staking للتمويل",
  phase4_sector_0_item_6: "بدأ التطوير التوسعي لمصادقة \"اعرف عميلك\" بعد Sulvey",
  phase4_sector_0_item_7: "BCL مدرج في L Bank و PancakeSwap",

  phase4_sector_1_date: "Q3.-Q4. 2022",
  phase4_sector_1_item_0:
    "Network الترحيل من ERC20 إلى BEP20 (سلسلة ذكية Binance)",
  phase4_sector_1_item_1: "بدأت القائمة البيضاء وسك بطاقة Hero NFT",
  phase4_sector_1_item_2: 'بدأت شهادة KYC',
  phase4_sector_1_item_3: 'تم فتح بطاقة Hero NFT على الويب',
  phase4_sector_1_item_4: 'أول لعبة NFT RPG محققة (Dark Worrior)',
  phase4_sector_1_item_5: 'BCL مدرجة في Coingecko و CoinMarketGap',

  phase4_sector_2_date: "June. 2022",
  phase4_sector_2_item_0:
    "ترحيل الشبكة من ERC20 إلى BEP20 (Binance Smart Chain)",
  phase4_sector_2_item_1: "إطلاق برنامج Level Up",
  phase4_sector_2_item_2: "BCL مدرج في PancakeSwap",
  phase4_sector_2_item_3: "BCL مدرج في بورصة MEXC Crytocurrency",
  phase4_sector_2_item_4: "الحرق الثاني وإعادة الشراء",

  phase5: "Phase5",
  phase5_sector_0_date: "Q3. 2022",
  phase5_sector_0_item_0: "سك بطاقة Hero NFT على Binance NFT و Opensea",
  phase5_sector_0_item_1:
    "قم بتطوير بطاقة Hero NFT باستخدام الاستوديو المصمم ذاتيًا",
  phase5_sector_0_item_2:
    "تصور وتطوير nft plaform <br/> (بيع وشراء ومزاد بطاقة NFT والعناصر)",
  phase5_sector_0_item_3: "تم إدراج BCL في بورصة MEXC Crytocurrency",
  phase5_sector_0_item_4: "الحرق الثالث",
  phase5_sector_0_item0:
    'القائمة البيضاء لبطاقات NFT (اشتر واحرق فقط BCL) في مشروع "Make to Earn"',
  phase5_sector_0_item1: 'بدأ تفويض "اعرف عميلك"',
  phase5_sector_0_item2: "بدأت تسوية وسحب BCL",

  phases5_sector_0_date: "Q1.-Q2. 2023",
  phases5_sector_0_item_0: "بدء تسوية وسحب BCL",
  phases5_sector_0_item_1: " تمت إضافة نظام تسجيل الدخول لألعاب NFT RPG (Super Fighter & Dark warrior)",
  phases5_sector_0_item_2:
    "إصدار موقع مكافأة اللعبة",
  phases5_sector_0_item_3: "بدأت خدمة تأجير NFT (بما في ذلك ميزة الندرة)",
  phases5_sector_0_item_4: "(بما في ذلك متصفح الويب والدردشة واتصال ألعاب NFT) تم تحديث وظيفة الويب 3.0 لتطبيق BCL",
  phases5_sector_0_item_5: "أدركت لعبة روجولايك الثالثة",
  phases5_sector_0_item_6: "7. تنقيح معيار التعدين",
  phases5_sector_0_item_7: "8. تم فتح فيديو ترويجي لمشروع NFT",

  phases5_sector_0_date_2: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_2: " تمت إضافة وظيفة المزاد لموقع سك العملة Hero NFT",
  phases5_sector_0_item_1_2:
    "يتم عرض بطاقة NFT لتطبيق BCL",
  phases5_sector_0_item_2_2:
    "إصدار اللعبة الرابعة لبطاقة NFT Hero (مع لاعبين متعددين)",
  phases5_sector_0_item_3_2: 'تم إصدار اللعبة الخامسة MMORG',

  phases5_sector_0_date_3: "ديسمبر. 2022",
  phases5_sector_0_item_0_3: "1. ترقية البطاقة السادسة للبطل NFT",
  phases5_sector_0_item_1_3:
    "2. غداء MaxForm City ذو الطبقات الثلاثية الأبعاد وبدأت القائمة البيضاء لـ NFT",
  phases5_sector_0_item_2_3: "3. مشاركة تاريخ تطوير بطاقة Maxform البطل",
  phases5_sector_0_item_3_3: "4. بدأ تطوير لعبة Maxform city war (4 أشهر) ",
  phases5_sector_0_item_4_3: "5. إطلاق النموذج الأولي للعبة PlaytoEarn",

  phases5_sector_0_date_4: "س 1. 2023",
  phases5_sector_0_item_0_4: "1. تم فتح بطاقة 6 Hero NFT على موقع YouTube ",
  phases5_sector_0_item_1_4: "2. مبيعات MaxForm City NFT",
  phases5_sector_0_item_2_4: "3. تم إصدار لعبة بطاقة MaxForm Hero",
  phases5_sector_0_item_3_4: "4. مشاركة تاريخ تطوير لعبة MaxForm City الحربية",
  phases5_sector_0_item_4_4: "5. تطوير MaxForm world (Metaverse) (9 أشهر)",
  phases5_sector_0_item_5_4:
    "∙ قم بتطوير بطاقة Hero NFT باستخدام الاستوديو المصمم ذاتيًا",
  phases5_sector_0_item_6_4:
    "تصور وتطوير منصة NFT (البيع والشراء والمزاد لبطاقات وعناصر NFT) ",
  phases5_sector_0_item_7_4:
    "∙ منصة النشاط الاقتصادي الرمزية التي أنشأتها بنفسك ",

  phases5_sector_0_date_5: "Q2. 2023 ",
  phases5_sector_0_item_0_5:
    "1. مبيعات بطاقة Hero NFT السادسة (مكافأة أرباح لأفضل مشتر NFT)",
  phases5_sector_0_item_1_5: "2. تم تفعيل لعبة بطاقة MaxForm Hero",
  phases5_sector_0_item_2_5: "3. تم إصدار لعبة MaxForm City الحربية",
  phases5_sector_0_item_3_5: "4. تاريخ التنمية في العالم MaxForm المشتركة",

  phases6: "Phase6",
  phases6_sector_0_date_5: "2024",
  phases6_sector_0_item_0_5: "بدأ تطوير عالم MaxForm (Metaverse)",
  phases6_sector_0_item_1_5: "  - تطوير بطاقة Hero NFT باستخدام الاستوديو المصمم ذاتيًا (باستخدام وظيفة AI)",
  phases6_sector_0_item_2_5: "  -منصة النشاط الاقتصادي Avatar التي أنشأتها بنفسك",
  phases6_sector_0_item_3_5: "تم إصدار اللعبة السادسة لبطاقة TCG of Hero",

  phases7: "Phase7",
  phases7_sector_0_date_5: "2025",
  phases7_sector_0_item_0_5: "تم فتح MaxForm world (Metaverse)",
  phases7_sector_0_item_1_5: "تم توقيع المزيد من شراكات عالم MaxForm",
  phases7_sector_0_item_2_5:
    " المزيد من انتشار النظام البيئي BCL",
  phases7_sector_0_item_3_5: " تطوير شبكة BCL الرئيسية",

  phase6: "Phase6",
  phase6_sector_0_date: "Q4. 2022",
  phase6_sector_0_item_0: 'إطلاق منصة NFT في مشروع "Make to Earn"',
  phase6_sector_0_item_1: "طور لعبة Metaverse باستخدام بطاقات NFT",
  phase6_sector_0_item_2: "BCL مدرج في البورصات الرئيسية تحت CMC Lv.20",
  phase6_sector_0_item_3: "الرابعة حرق",

  phase7: "Phase7",
  phase7_sector_0_date: "2023",
  phase7_sector_0_item_0:
    'بيتا testfied لعبة NFT Avatar Metaverse في مشروع "العب لتكسب"<br/>• لعبة 1: 1 و PVP (لعبة الصورة الرمزية)<br/>• تأجير وبيع العقارات الافتراضية',
  phase7_sector_0_item_1: "منصة النشاط الاقتصادي الرمزية التي أنشأتها بنفسك",
  phase7_sector_0_item_2:
    "BCL مدرجة في البورصات الرئيسية تحت CMC Lv.10 & Lv. 5",
  phase7_sector_0_item_3: "الحرق الخامس",

  download_now: "Download now",
  download_now_desc:
    "قم بتنزيل تطبيق ملف androroid أو iOS أو APK وابدأ التعدين اليوم.",
  referring_hint: "اكسب 25٪ إضافية بإحالة شخص آخر",
  join_us_hint:
    "انضم إلينا على قناتنا على الإنترنت للبقاء<br/>" +
    "محدث مع مجتمع BitcoinLegend",
  license: "© 2021 BitcoinLegend. All rights reserved",

  nft_about_title: "حول بطاقة Hero NFT و Metaverse",
  nft_about_desc:
    '<p> تم ذكر metaverse لأول مرة في " Snow Crash "نيل ستيفنسون في عام 1992. <br/>' +
    "metaverse هو نظام أساسي للحياة الثانية وهو عالم يمكنك من خلاله التواصل مع عقول الآخرين في أي وقت وفي أي مكان. <br/>" +
    "metaverse هي حالة يتم فيها دمج الواقع والعوالم المختلفة ، مثل الكون 1 والكون 2 والكون 3 ، <br/>" +
    "وهي في حالة من الواقع الزائف لدرجة أننا لا نعرف أي واحد هو حقيقي أنا في metaverse ، الذي يعيش حياة مختلفة عن حياتي الحقيقية. <p />" +
    "<p> يمكن للصورة الرمزية الالتقاء والتواصل في مساحة فائقة والمشاركة في الأنشطة الاقتصادية باستخدام وظائف AR و VR مع إخفاء الهوية. <br/>" +
    "الالتقاء كشخصية رمزية في فضاء متسامي هو ابتكار سيقود الابتكار العالمي على مدار العشرين عامًا القادمة في شكل انهيار لمفهوم الزمان والمكان. </ p>" +
    '<p> "لكل فرد رؤية وحلم. <br/>' +
    "ومع ذلك ، فإن معظمهم لا يستطيع تحويلها إلى حقيقة ويضعها في أذهانهم. <br/>" +
    "على وجه الخصوص ، تُبذل الجهود لحل مشكلة الفقر الاقتصادي وعدم الرضا عن الواقع. <br/>" +
    "ومع ذلك ، فإن نتائج هذه الجهود يصعب تحقيقها في الواقع. </ p>" +
    "<p> تم إنشاء Bitcoin Legend لتحقيق مُثُل الناس. </ p>" +
    "<p> يصنع المستخدمون صور NFT الرمزية الخاصة بهم ، ويصنعون عناصر NFT ، ويشترونها ويبيعونها لتحقيق ربح. <br/>" +
    "يستخدم فريق BCL Core تقنية الذكاء الاصطناعي لإنشاء شخصيات webtoon مشابهة لشخصيتي الحقيقية. <br/>" +
    "التقنيات المطبقة في هذا الوقت هي Facial Landmark Engine و Facial Landmark Trigger في الاستوديو الذاتي الصنع. <br/>" +
    "باستخدام هذه التقنية ، يمكن للمستخدمين إنشاء صورة رمزية تشبه شخصيتي الحقيقية مباشرة. ومن الممكن تغيير أسلوب الشعر وشكل الوجه وحتى لون العين. <br/>" +
    "بالإضافة إلى ذلك ، يمكنك إنتاج الملابس المتنوعة ، والحلي ، وأدوات اللعبة. أيضًا ، يمكنك شرائها التي صنعتها. <br/>" +
    "يمكن بيع هذه العناصر بالمزاد عن طريق تشفيرها باستخدام تقنية صنع NFT. </p>",
  maxform_desc:
    "<p> يعد MaxForm Studio نظامًا يتمحور حول المستخدم. <br/>" +
    "يغير المستخدم وجهه بعد التقاط صورة ويستعيد إخفاء الهوية بقناع. <br/>" +
    "إنه مزين أيضًا بمحتويات من إنشاء المستخدم (UGC). <br/>" +
    "Maxform هي مساحة حيث تكون الصور الرمزية نشطة في مساحة metaverse التي تم إنشاؤها بواسطة Hero NFT Card. </p>",
  shape_made_tech: "شكل صنع التقنية",
  shape_made_tech_desc:
    "<p> عند التصوير بهاتف ذكي ، يعمل محرك ومشغل معالم الوجه وفقًا لوظيفة التعرف على الوجه بالذكاء الاصطناعي. <br/>" +
    "تم صنع الوجه بواسطة صورة Webtoon ولدى بعض المستخدمين ميول مختلفة تجاه إخفاء الهوية. <br/>" +
    "عندما تريد تغيير وجهك ، يمكنك تغيير لون وجهك ، ولون شعرك ، وتصفيفة شعرك ، وحاجبيك ، وما إلى ذلك. <br/>",
  shape_made_system: "نظام مصنوع ذاتيًا للمستخدم",
  shape_made_system_desc:
    "<p> يمكن تصميم وإنتاج جميع إكسسوارات AMD من قبل الفرد على منصة صناعة الأزياء. <br/>" +
    "بالإضافة إلى ذلك ، يمكنك اختيار نوع القناع الذي تريده للحفاظ على أناقته. <br/>" +
    "يمكن شراء هذا وبيعه في سوق NFT ، وفي لعبة Max Form metaverse ، <br/>" +
    "ستصبح الإكسسوارات والأزياء التي تم ربحها في ألعاب القتال 1: 1 أكثر شهرة وسترتفع أسعارها. <br/>" +
    "والمصمم الذي صنعها يرتفع لتكريم المزيد والمزيد. وبطبيعة الحال ، تزيد جائزته في السعر. </ p>",
  hero_nft_card: "Hero NFT Card",
  hero_nft_card_desc:
    "<p> إن BitcoinLegend ليس مجرد تطبيق تعدين ، ولكنه سيصبح من الدرجة الأولى في لعبة بطاقات NFT و metaverse. <br/>" +
    "تعتزم شبكة BCL الريادة في النظام البيئي للعملات المشفرة من خلال وضع إستراتيجية 3 Earn. <br/>" +
    'هذه إستراتيجية "Make to Earn " من بين إستراتيجيات Earn الثلاث. تضع بطاقة Hero NFT حياة البطل في بطاقة. <br/>' +
    "يظهر بطل بطاقة Hero NFT في لعبة metaverse. تحتوي البطاقة على قصة بطل وجرد من العناصر. <br/>" +
    "تتيح قصص الأبطال للمستخدمين سرد قصصهم. يمكن للمخزون تخزين المهارات والمعدات والمواد. <br/>" +
    "يمكن شراء جميع هذه العناصر وبيعها وبيعها بالمزاد. </ p>",
  media_partners: "Media & Partners",
  nft_modal_content:
    "<p> نشاط تعدين عالي السرعة بمعدل 4 أضعاف (جميع المستخدمين) <br/> <br /> " +
    "☑️1NFT = 3,900BCL/94Matic/0.05 ETH</p>",
  go_hero_nft_card: "شراء بطاقة Hero NFT",
  go_hero_nft_close: "أغلق النافذة",
  bep_title: "الهجرة من ERC20 إلى BEP20",
  bep_title1: "الغرض من الهجرة",
  bep_desc1: "لتقليل رسوم الغاز المتكبدة أثناء السحب وسك NFT",
  bep_title2: "نسبة المبادلة عند الترحيل",
  bep_desc2:
    "1. يتم إجراء المبادلة للمستخدمين الذين اشتروا BCL سابقًا ، <br /> يتم إجراء مدفوعات إضافية لتقليل تكلفة رسوم الغاز المتكبدة عند الإيداع. <br /> يتم دفع Bcl Mined كـ BEP20 عند الدفع بعد KYC ، لذلك ليست هناك حاجة للمبادلة. أقل",
  bep_title3: "العملة المشفرة Lbank",
  bep_desc3: "ERC20: BEP20 = 1: 1.1 (10٪ دفعة إضافية)",
  bep_title4: "كيف نفعل الهجرة",
  bep_desc4:
    "1. Lbank Crytocurrency exchange <br />" +
    "لا يوجد شيء يمكنك القيام به. يتم تبديل BCL داخل بورصة Lbandk وإيداعها في محفظة جديدة. <br />" +
    "2. موقع BCL الإلكتروني (هنا)<br />" +
    "استخدم محفظة شخصية مثل Metamask أو Trust Wallet أو Im Wallet التي أودعتها مسبقًا.<br />" +
    "أرسل BCL المستندة إلى ERC20 إلى عنوان BCL foudation على النحو التالي. سيرسل نظامنا رمز BCL المستند إلى BEP20 إلى محفظتك على الفور.",
  bep_desc41: "3. صرخة الشخصية المحفظة التي يمكن ترحيلها <br />",
  bep_desc42: " Metamask ( كيفية الحصول على عنوان BEP20 ) ",
  bep_desc43: " Trust wallet ( كيفية الحصول على عنوان BEP20 )  ",
  bel_desc44:
    "Etherscan: تحقق من النقل من محفظتك إلى مؤسسة BCL باستخدام BCL المستند إلى ERC20",

  bep_title5: "طلب الهجرة",
  bep_desc5:
    "يرجى الاستعداد لنسخ عنوان محفظة ERC20 وعنوان محفظة BEP20 قبل التقدم بطلب الهجرة.",
  bep_button_title: "تبديل في Lbank بحلول 29 يونيو ، من فضلك",
  bep_submit: "يُقدِّم",
  bep_exit: "مخرج",
  bep_popup_desc1:
    '[إشعار حول الترحيل] <br /> 1. يرجى التأكد من صحة عنوان محفظتك قبل لمس الزر "إرسال" <br /> 2. نحن لسنا مسؤولين عن الإهمال أو الإغفال عن عنوان المحفظة <br /> 3. تستغرق المعاملة حوالي 3-5 دقائق.',
  bep_popup_title1: "طلب الهجرة",
  bep_popup_agree: "توافق على الإشعار أعلاه",
  bep_input_email: "أدخل عنوان البريد الإلكتروني",
  bep_input_erc: "أدخل عنوان ERC20",
  bep_input_ber: "إدخال عنوان BEP20",
  bep_input_amount: "كمية الإدخال",
  bep_check_box: "الرجاء النقر فوق خانة الاختيار",
  bep_request_complete: "تم الانتهاء من الطلب.",
  bep_request_check: "يرجى التحقق من العنصر",
  bep_title8:
    "<font color='#aaaaaa'>عنوان إيداع BCL foudation :</font> <font color='#34D5FF'>0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  bep_title9: "أين تفعل الهجرة",
  bep_title10:
    "1. يحظر إيداع Lbank Crytocurrency <br/> في اليوم السابق للتاريخ الذي تم إخطاره مقدمًا. تبديل داخل Lbank لمدة 3 أيام. سيتم إجراء إيداع جديد ومعاملات وسحب. <br/> 2. موقع BCL (هنا) <br/> للمستخدمين الذين لم يتمكنوا من المشاركة في مبادلة Lbank Cryptocurrency Exchange ، يقوم موقع BCL بإجراء ترحيله الخاص.",
  bep_title11:
    "☑ نسبة المبادلة عند الترحيل <br /> 1. Lbank crytocurrency Exchange <br /> erc20: bep20 = 1: 1.1 (Util 29th 2pm uTC+8) <br />",
  // bep_title12: "<font color='yellow'>Lbank crytocurrency exchange</font><br />ERC20: BEP20 = 1: 1.1 (10 ٪ دفع إضافي)",
  // bep_title13: "<font color='yellow'>موقع Bitcoinlegend</font><br />موقع Bitcoinlegend",
  levelup_title1:
    "تم تطوير هذا البرنامج للمستخدمين الذين يرغبون في زيادة سرعة التعدين بأقل تكلفة.",
  levelup_desc1:
    "تم تصميم برنامج المستوى لزيادة سرعة التعدين لفترة زمنية محددة. <br /> 1. بعد التطبيق والدفع ، سيتم تطبيقه على الفور. <br /> 2. تعمل فترة التطبيق كموقت. <br /> 3. بعد انتهاء الموقت ، تتم استعادة سرعة التعدين المقابلة لنصف العمر.",
  levelup_title2: "الحالي",
  levelup_title3: "برنامج UP",
  levelup_popup_title1: "نموذج الطلب للحصول على مستوى",
  levelup_popup_desc1:
    "[إشعار على المستوى لأعلى] <br /> 1. متجر Google Play Store Apple Store ، عند تثبيت Bitcin Legend Mining <br /> التطبيق كملف APK ، تأكد من إدخال عنوان البريد الإلكتروني للتسجيل. <br /> 2. يرجى الرجوع إلى الدليل أعلاه للحصول على كيفية التحقق من عنوان بريدك الإلكتروني. <br /> 3. يبدأ برنامج Level-Up فورًا بعد التطبيق <br /> 4. يبعد وقت التشغيل في برنامج المستوى بعد 30 يومًا من التسجيل.",
  levelup_popup_confirm: "يتأكد",
  bep_button_title2: "تحقق من ترحيلك",
  levelup_title4: "كيفية التحقق من عنوان البريد الإلكتروني الخاص بك",
  levelup_title5:
    "1. أيقونة إعداد المس في تطبيق التعدين من BitcoinleGend. <br /> 2. تحقق من البريد الإلكتروني المسجل BCL وأدخل نفس المعروض.",

  nft_about_title2: "حول Maxform Hero Card & Metaverse",
  nft_about_desc2:
    "<p>该元评估在1992年首次在尼尔·斯蒂芬森（Neal Stephenson）的“降雪崩溃”中提到。<br/>" +
    "Metaverse هي منصة حياة ثانية وهي عالم يمكنك التواصل فيه مع عقول الآخرين في أي وقت وفي أي مكان.<br/>" +
    "Metaverse هي حالة يتم فيها الجمع بين الواقع والعوالم المختلفة ، مثل الكون 1 والكون 2 والكون 3 ،<br/>" +
    "وهي في حالة واقعية زائفة إلى الحد الذي لا نعرف فيه أيهما هو الحقيقي لي في Metaverse ، الذي يعيش حياة مختلفة عن الواقع.<p/>" +
    "<p>يمكن أن تلتقي Avatar والتواصل في مساحة متعالية والمشاركة في أنشطة اقتصادية باستخدام وظائف AR و VR مع عدم الكشف عن هويتها.<br/>" +
    "للقاء كأفاتار في الفضاء المتسامي هو الابتكار. سيقود الابتكار العالمي على مدار العشرين عامًا القادمة في شكل انهيار مفهوم الزمان والمكان</p>" +
    "<p> كل شخص لديه رؤية وحلم. <br/>" +
    "ومع ذلك ، لا يمكن لمعظمهم أن يجعلوها حقيقة واقعة في أذهانهم. <br/>" +
    "على وجه الخصوص ، بذل الجهود لحل الفقر الاقتصادي وعدم الرضا عن الواقع. <br/>" +
    "ومع ذلك ، يصعب تحقيق نتائج هذه الجهود في الواقع. </p>" +
    "<p> تم إنشاء أسطورة Bitcoin لتحقيق مُثُل الناس. </p>" +
    "<p> يقوم المستخدمون بصنع الآلهة الخاصة بهم NFT ، وصنع عناصر NFT ، وشراءها وبيعها لتحقيق ربح. <br/>" +
    "فريق BCL Core يستخدم تقنية الذكاء الاصطناعي لإنشاء شخصيات WebToon مماثلة لـ Real Me. <br/>" +
    "التقنيات المطبقة في هذا الوقت هي محرك التمعج للوجه ومحرك المعالم في الاستوديو المصنوع الذاتي. <br/>" +
    "باستخدام هذه التكنولوجيا ، يمكن للمستخدمين إنشاء الصورة الرمزية مباشرة تشبه Me الحقيقي. من الممكن تغيير أسلوب الشعر وشكل الوجه وحتى لون العين. <br/>" +
    "بالإضافة إلى ذلك ، يمكنك إنتاج الملابس المختلفة والزخارف وعناصر اللعبة. أيضًا ، يمكنك شراءها. <br/>" +
    "يمكن بيع هذه العناصر عن طريق تشفيرها باستخدام تقنية صنع NFT. </p>",
  hero_nft_card2: "بطاقة Hero Game-Fi",
  hero_nft_card3: "القائمة البيضاء لبطاقة Maxform Hero NFT",
  hero_nft_card3_desc:
    "يمكن للناس العاديين أن يكونوا أبطالًا. <br /> ليس فقط الأبطال الجيدون يصبحون أبطالًا ، <br ​​/> ولكن حتى الأشرار يصبحون أبطالًا إذا تمكنوا من الفوز في اللعبة. <br /> تحدي نفسك.",
  hero_nft_card4: "القائمة البيضاء لبطاقة Maxform Hero",
  hero_nft_card_title1: "ما هو NFT؟",
  hero_nft_card_desc1:
    "الرمز المميز غير القابل للإلغاء (NFT) هو رمز افتراضي يستخدم تقنية blockchain لإثبات مالك الأصل الرقمي. <br /> " +
    "يتم تخزين عناوين تشير إلى الملفات الرقمية مثل الصور ومقاطع الفيديو في الرموز ، وتستخدم للإشارة إلى أصالتها وملكتها الفريدة. <br />" +
    "هذا هو نوع من شهادة الأصالة الافتراضية. <br />" +
    "تم تصميم بطاقة Hero NFT بمفهوم أنه لا يمكن أن يصبح شخصية معينة بطلًا فحسب ، بل يمكن أن يصبح الأشخاص العاديون أيضًا بطلاً.",
  hero_nft_card_title2: "ما هي خصائص بطاقة Maxform Hero؟",
  hero_nft_card_desc2:
    "سيتم تراجع بطاقة Maxform Hero من خلال سلسلة المضلع ، ويمكن لأي شخص شراءها وبيعها في Opensea. <br /> " +
    "تم إنشاؤه باستخدام 2130 × 2800 بكسل حسب تقنية التصنيع الدقيقة. <br />" +
    "القائمة البيضاء ليست فقط لشراء NFTs من وجهة نظر المشتري ، ولكن لشراء NFTs بسعر مخفض. ويمكنك الحصول على خدمات إضافية. <br />" +
    "توفر مؤسسة BCL فوائد مستوية لأولئك الذين يشترون بطاقات NFT من خلال القائمة البيضاء لزيادة سرعة التعدين الخاصة بهم. <br />" +
    "أولئك الذين يشترون مباشرة من Opensea أو Binance NFT ليس لديهم هذه الفائدة.",
  hero_nft_card_title3: "The Price and payment method for whitelist",
  hero_nft_card_desc3:
    "☑️القائمة البيضاء الأولى (1500 NFTs)<br /> " +
    "1. السعر: 1،000 BCL 2. طريقة الدفع: BEP20 على أساس BCL فقط<br />\n" +
    "☑️ القائمة البيضاء الثانية (4500 NFTs) <br />\n" +
    "1. السعر: 0.05 إيثريوم 2. طريقة الدفع: BCL ، ETH ، Matic (مضلع) أو طريقة دفع أخرى<br />\n" +
    "☑️ القائمة البيضاء الثالثة (2000 NFTs) <br />\n" +
    "1. السعر: 0.05 إيثريوم 2. طريقة الدفع: BCL ، ETH ، Matic (مضلع) أو طريقة دفع أخرى<br />\n" +
    "☑️ بدء المزاد الهولندي (1،000 NFTs)<br />" +
    "بيع وشراء على Opensea<br />",
  hero_nft_card_title4: "جدول القائمة البيضاء",
  hero_nft_card_desc4:
    '☑️ القائمة البيضاء الأولى (1500 NFTs) <br /> "+\n' +
    "1. ابدأ القائمة البيضاء الأولى على موقع BCL : 17 أغسطس - 31 أغسطس<br />" +
    "2. آخر موعد للإيداع: 17 أغسطس - 31 أغسطس<br />" +
    '      3. إضافة موقع سك على موقع BCL: 16 أغسطس - 19 أغسطس <br /> "+\n' +
    '      "4 .. إصدار القناة في OpenSea على شكل علبة هدايا: 5 سبتمبر <br />" +\n' +
    '      "5. كشف بطاقة البطل العشوائي:7 سبتمبر <br />" +\n' +
    '      "☑️ القائمة البيضاء الثانية (4500 NFTs) <br />" +\n' +
    '      "1. ابدأ القائمة البيضاء الثانية على موقع BCL على الويب: 14 سبتمبر - 23 سبتمبر <br />" +\n' +
    '      "2. الموعد النهائي للإيداع : 14 سبتمبر - 23 سبتمبر <br />" +\n' +
    '      3. إصدار القناة في OpenSea على شكل صندوق هدايا: 27 سبتمبر <br /> "+\n' +
    '      "4. كشف بطاقة البطل العشوائي: 29 سبتمبر <br />" +\n' +
    '      "☑️ القائمة البيضاء الثالثة (2000 NFTs) <br />" +\n' +
    '      "1. ابدأ القائمة البيضاء الثالثة على موقع BCL: 4 أكتوبر - 14 أكتوبر <br />" +\n' +
    '      "2. الموعد النهائي للإيداع (BCL & مدفوعات أخرى): 4 أكتوبر - 14 أكتوبر <br />" +\n' +
    '      3. إصدار القناة في OpenSea على شكل صندوق هدايا: 18 أكتوبر <br /> "+\n' +
    '      "4. كشف بطاقة البطل العشوائي: 20 أكتوبر <br />" +\n' +
    '      "بدء المزاد الهولندي (1000 NFTs): 26 أكتوبر',
  hero_nft_card_title5: "كيفية التقدم بطلب للحصول على قائمة بيضاء",
  hero_nft_card_desc5:
    "\"1. اشترِ BCL من Lbank أو Pancake Swap. <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://www.lbank.info');\"'>LBank</div> <div class='increase_strategy_mark2' style='display: inline'  onclick=\"window.open('https://pancakeswap.finance');\">PancakeSwap</div><br />" +
    "2. قم بإيداع BEP20 المستندة إلى BCL في محفظة Crypto الشخصية (Metamask و Trustwallet). <br />" +
    "* كيفية الحصول على العنوان BEP20 و Polygon (ماتيتش) <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />" +
    "3. أرسل BCL المستندة إلى BEP20 إلى عنوان Foudation BCL على النحو التالي:",
  hero_nft_card_desc6:
    "<font color='#aaaaaa'>BCL  Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  hero_nft_card_desc7: "تحقق من إيداع مؤسسة BCL",
  hero_nft_card_desc8:
    "4. أدخل عنوان بريدك الإلكتروني للاشتراك في تطبيق BCL وأرسل عنوان محفظة BCL في النافذة أدناه.<br />" +
    "*للمشاركة في حدث سرعة التعدين ، تأكد من إدخال عنوان البريد الإلكتروني لتطبيق الاشتراك في BCL.",
  hero_nft_card_desc9: "نموذج الطلب لتطبيق القائمة البيضاء",
  hero_nft_card_desc10_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>تحقق من البريد الإلكتروني الذي تم نشره على تطبيق BCL</span> ',
  hero_nft_card_desc10_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>أدخل عنوان بريدك الإلكتروني وأرسل BEP20 <br /> عنوان BCL المستند إلى BCL والمبلغ <br /> <font color = '# FFD500'> * 1NFT = 1،000BCL ، الرصيد غير قابل للاسترداد ، <br /> لذا يُرجى إدخال 1000 الوحدات. <br /> لا يوجد حد لعدد NFTs المشتراة لكل مشتر </ font></span> ",
  hero_nft_card_desc10_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>يتم تسجيل عنوان استلام بطاقة NFT تلقائيًا <br /> باعتباره عنوان Polygon (MATIC) لحساب الإيداع.</span> ',
  hero_nft_card_desc11:
    "5. تحقق من القائمة البيضاء لشراء Hero NFT<br />" +
    "*بعد شراء NFT خلال القائمة البيضاء الأولى ، سينشر فريق BCL Core هنا قائمة المشتري.<br />" +
    "بعد طرح القائمة ، سيحصل المشتري على سرعة التعدين ومعلومات Maxform Hero Card.",
  bep_input_nft_email: "إدخال عنوان البريد الإلكتروني",
  bep_input_bep_address: "إدخال عنوان BCL",
  bep_input_bep_amount: "إدخال BCL كمية",
  bep_input_bep_from: "يجب ربط المحفظة.",
  bep_input_polygon_address: "إدخال عنوان المضلع",
  bep_input_bep_amount2: "الرجاء إدخال رقم يزيد عن 3900",
  not_match_bep: "تحقق من عنوان BEP20 BCL الذي أودعته ومبلغ BEP20 BCL.",
  not_match_eth: "تحقق من عنوان ETH الذي أودعته ومبلغ ETH.",
  not_match_pol: "تحقق من عنوان MATIC الذي أودعته ومبلغ MATIC.",
  hero_nft_card_desc51:
    "* إذا قمت بإيداع BCL في Lbank ، فلن تتمكن من الحصول على بطاقة NFT. <br /> " +
    "تأكد من القيام بذلك في محفظتك الشخصية للعملات المشفرة <br />" +
    "* 1NFT = 1،000BCL ، الرصيد غير قابل للاسترداد ، <br ​​/>" +
    "لذا يرجى إدخال 1000 وحدة. <br />" +
    "لا يوجد حد لعدد NFTs المشتراة لكل مشترٍ",
  nft_request_complete: "تمت معالجتها بالفعل",

  hero_nft_card_desc12:
    "1. أرسل Ethereum (ETH) أو Polygon (MATIC) إلى عنوان foudation BCL على النحو التالي:<br />" +
    "* كيفية الحصول على عنوان المضلع (ماتيتش)<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />",
  hero_nft_card_desc52:
    "*1NFT = 0.05 ETH, 1 NFT = 40 MATIC <br />" +
    "الرصيد غير قابل للاسترداد<br />" +
    "لا يوجد حد لعدد NFTs المشتراة لكل مشتر",
  hero_nft_card_desc61:
    "<font color='#aaaaaa'>Polygon(Matic) Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   /><br />",
  hero_nft_card_desc71: "إيداع الشيكات من ETH",
  hero_nft_card_desc72: "إيداع الشيكات MATIC",
  hero_nft_card_desc81:
    "2. أدخل عنوان البريد الإلكتروني للاشتراك في تطبيق BCL وأرسل عنوان محفظة BCL في النافذة أدناه. <br />" +
    " * للمشاركة في حدث تعدين التسريع ، تأكد من إدخال عنوان البريد الإلكتروني لتطبيق اشتراك BCL الخاص بك.",
  hero_nft_card_desc91: "نموذج ETH / MATIC لتطبيق القائمة البيضاء",
  hero_nft_card_desc11_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>تحقق من البريد الإلكتروني الذي قمت بتسجيله في تطبيق BCL</span> ',
  hero_nft_card_desc11_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>أدخل عنوان بريدك الإلكتروني وأرسله ETH أو MATIC <br />العنوان والمبلغ  <br /><font color='#FFD500'>*1NFT = 0.05 ETH, 1NFT = 40 MATIC, <br />" +
    "الرصيد غير قابل للاسترداد. <br />" +
    "لا يوجد حد لعدد NFTs المشتراة لكل مشتر</font></span> ",
  hero_nft_card_desc11_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>أدخل عنوانك المستند إلى المضلع الذي تلقى NFT الخاص بك. </span> ',
  hero_nft_card_desc82:
    "بعد شراء NFT خلال القائمة البيضاء الأولى ، سينشر فريق BCL الأساسي هنا قائمة المشتري.  <br />" +
    "بعد طرح القائمة ، سيستفيد المشتري من سرعة التعدين ومعلومات بطاقة Maxform Hero. ",

  nft_event_string1:
    "🟢 يتم إصدار بطاقة Maxform NFT البطل ويتم صنعها بواسطة فريق Bitcoinlegend Core 🟢<br />" +
    "                        مفهوم بطاقة البطل في Bitcoin Legend هو أن أي شخص بشكل عام يمكن أن يصبح بطلاً.<br />" +
    "إنها نصف ملابس عادية ونصفها في ملابس البطل.<br />" +
    "                        أصبح هذا أكثر تميزًا من خلال برنامج الندرة.",
  nft_event_string2: "🎉سيتم توفير 300 بطاقة Hero NFT فقط مجانًا.🎉",
  nft_event_string3:
    " ☑️ القائمة البيضاء الأولى السعر: 𝟬.022 ETH / 45 MATIC / 1،000 BCL لكل NFT <br />" +
    "                        ☑️ الثانية ~ الثالثة القائمة البيضاء السعر: 𝟬.05 ETH / 105 MATIC / 2،200 BCL لكل NFT",
  nft_event_string4:
    " ☑️ المزيد من الأحداث (جمع 3 ملايين مستخدم)<br /><br />" +
    "                        ▶️ 4X تعدين عالي السرعة: 6 → 24 مليار برميل في اليوم<br />" +
    "                       (مشتري NFT + @ 24 BCL / يوم ، لمدة شهر واحد)<br /><br />" +
    "                         ▶️ مكافأة الإحالة تصل: 25 → 50 BCL / مستخدم",
  nft_event_string5: "شكرا للمشاركة في الحدث!",
  nft_event_string6: "🏷 ️ معلومات البيع",
  go_hero_nft_event: "حدث بطاقة Hero NFT",
  bep_request_check2: "لم يتم العثور على معلومات حساب مطابقة.",
  nft_event_qa:
    "إذا كان المحتوى الرئيسي مفقودًا ، أرسل <a href='https://forms.gle/SxLB81u6t5hBzUiV6' target='_blank'> <span style = 'text-decoration: underline؛ font-weight: bold' للقيام بذلك ، من فضلك> انقر </ span> </a> هنا.",
  hero_nft_card_title11: "[عرض خاص]",
  hero_nft_card_desc11_desc:
    "☑️ مسابقة الترتيب (تحديث في الوقت الفعلي) <br />" +
    "● المركز الأول <br />" +
    '1. ترقية البطل السادسة: "أنت البطل" <br />' +
    "(صورتك وقصتك ← إنتاج البطل + webtoon + إنتاج الراب) <br />" +
    "البطل الأول: <a href='https://youtu.be/s0FSVXZ-wSs' target='_blank'>https://youtu.be/s0FSVXZ-wSs</a> <br />" +
    "البطل الثاني: <a href='https://youtu.be/8Al9l7YVLC4' target='_blank'>https://youtu.be/8Al9l7YVLC4</a> <br />" +
    "الأبطال من الثالث إلى الخامس: <a href='https://youtu.be/gfjBqRpmh6U' target='_blank'>https://youtu.be/gfjBqRpmh6U</a> <br />" +
    "<br />" +
    "2. الإصدار المحدود 1،000NFT (البطل السادس) مع 26 عنصرًا <br />" +
    "1،000NFT * 0.1ETH (سعر القائمة البيضاء) = 100ETH <br />" +
    "مكافأة 5٪ على مبلغ المبيعات (5ETH) <br />" +
    "● المركز الثاني - المركز الثالث <br />" +
    "المركز الثاني) 5 NFTs إضافية + 2٪ مكافأة على البطل السادس (2ETH) <br />" +
    "المركز الثالث) 3 NFTs إضافية + 1٪ مكافأة على البطل السادس (1ETH) <br />" +
    "● المركز الرابع - العاشر <br />" +
    "2 NFTs إضافية <br />" +
    "* سيتم دفع NFT الإضافية المدفوعة من خلال NFT المباعة حاليًا. <br />" +
    "<br />" +
    "☑️ ترقية العضوية <br />" +
    "● Platinum (اشتر 21NFT أو أكثر) <br />" +
    "Minig Speed ​​Up (+ 24BCL / day) ، أولوية KYC ، NFT + 1ea / 20ea <br />" +
    "● ذهب (شراء 11 ~ 20 NFT) <br />" +
    "Minig Speed ​​Up (+24 BCL / day) ، أولوية KYC ، NFT + 1 <br />" +
    "● الفضة (شراء 5-10 NFT) <br />" +
    "Minig Speed ​​Up (+24 BCL / day) ، أولوية KYC <br />" +
    "● برونزية (شراء 1 ~ 4NFT) <br />" +
    "Minig Speed ​​Up (24 مليار لتر / يوم)",
  kyc_certification: "شهادة KYC",
  vision_of_bitcoinLegend: "رؤية بيتكوين أسطورة",
  kyc_desc1:
    "Bitcoin Legend هي عملة مشفرة من نوع التعدين المدرجة حاليًا. <br /> " +
    "هذا من أجل أن يلعب رمز BCL دورًا فعليًا كعملة تشغيل لنظام Maxform البيئي. <br />" +
    "نظام Maxform البيئي يصدر بطاقات NFT البطل ويلعب ألعاب الورق باستخدامها. <br />" +
    "بالإضافة إلى ذلك ، يتم تحقيق مدينة Maxform حيث يقيم البطل في عالم ثلاثي الأبعاد ، <br />" +
    "ومدينة الإقامة تم تحويلها إلى NFT. <br />" +
    "يتم تنفيذ هذه المدينة أيضًا في شكل لعبة مثل معركة المدينة. <br />" +
    "إذا كان الناس والبيئة من صنع NFT ، <br />" +
    "مؤسسة BCL ستنشئ عالم metaverse يسمى Maxform. <br />" +
    "يلتقي الأبطال في المقاهي أو مراكز التدريب الصحي للتحدث ولعب الألعاب. <br />" +
    "الوسيلة النقدية المستخدمة في هذا النظام البيئي هي BCL. <br />" +
    "هذه هي رؤية وهدف Bitcoin Legend.",
  kyc_title2: "الغرض من شهادة KYC",
  kyc_desc2:
    "تستمر مؤسسة BCL في التحقق من KYC لمنح مصداقية لـ BCL <br />" +
    "تم تعدينها بواسطة جميع المستخدمين من خلال الخادم السحابي. <br />" +
    "هذه عملية يقوم فيها شخص بالتعدين بجهاز نقال واحد وإثبات <br />" +
    "أنهم الشخص الحقيقي. <br />" +
    "نريد منع جني الأرباح بشكل غير عادل من خلال إنشاء حسابات مزيفة.",
  kyc_title3: "اتبع عملية اعرف عميلك",
  bep_input_name: "اسم الإدخال",
  bep_input_number: "رقم الإدخال",
  bep_input_address: "عنوان الإدخال",
  kyc_popup_title1: "[قيود العمر لشهادة KYC]",
  kyc_popup_desc1:
    "من أجل الامتثال للقوانين الدولية لجمع البيانات للأطفال والأشخاص غير البالغين , تقتصر شهادة KYC على الأشخاص الذين تزيد أعمارهم عن 18 عامًا",
  kyc_popup_desc2:
    "بالنسبة لأولئك الذين تقل أعمارهم عن 18 عامًا , فإن خطة مؤسسة BCL لإنشاء وتنفيذ نظام منفصل بموافقة الوالدين.",
  kyc_popup_desc3: "[احتياطات عند تطبيق KYC]",
  kyc_popup_desc4:
    "1. إذا تقدمت بطلب حتى إذا كان عمرك أقل من 18 عامًا , فسيحدث خطأ ولن يتم استرداد رسوم KYC إلا.",
  kyc_popup_desc5:
    "2. يجب أن يتطابق الاسم الحقيقي مع الاسم الموجود في بطاقة الهوية الوطنية أو جواز السفر أو رخصة القيادة أو هوية الناخب.",
  kyc_popup_desc6:
    "3. يجب أن يكون البريد الإلكتروني هو البريد الإلكتروني عندما تقوم بالتسجيل في Bitcoin Legend.",
  kyc_popup_desc7:
    "4. يجب أن يكون رقم الهاتف المحمول هو رقم الهاتف حيث قمت بتثبيت تطبيق Bitcoin Legend.",
  kyc_popup_desc8: "الاسم الحقيقي",
  kyc_popup_desc9: "البريد الإلكتروني  لتطبيق BCL",
  kyc_popup_desc10: "Mobile PhoneNumber",
  kyc_popup_desc10_1: "Transaction Hash",
  kyc_popup_title2: "[تحضير KYC]",
  kyc_popup_desc11:
    "1. المستندات (الهوية الوطنية أو رخصة القيادة أو جواز السفر أو معرف الناخب) غير منتهية الصلاحية.",
  kyc_popup_desc12:
    "إذا كنت تعرف على عميلك (KYC) مع مستندات منتهية الصلاحية , فسيحدث خطأ. ونتيجة لذلك , فإن رسوم KYC غير قابلة للاسترداد.",
  kyc_popup_desc13: "2. يجوز لنا استخدام معلومات مستنداتك.",
  kyc_popup_desc14:
    "3. سيتم استخدام عنوانك ورقم هاتفك المحمول ومعلومات الدولة للتحقق من KYC.",
  kyc_popup_desc15:
    "4. تُستخدم هذه المعلومات فقط للتحقق من KYC , وتُستخدم لتحديد ما إذا كنت تستخدم التطبيق بالفعل وفقًا لسياسة Bitcoin Legend.",
  kyc_popup_desc16: "أنت توافق على حقوق الاستخدام وفقًا لذلك.",
  kyc_popup_agree: "موافق",
  kyc_popup_reject: "رفض",
  kyc_popup_kyc: "GO KYC",
  kyc_popup_title3: "[شهادة KYC]",
  kyc_popup_desc17:
    "بمجرد أن يكون هاتفك به مستندات غير منتهية الصلاحية (الهوية الوطنية أو رخصة القيادة أو جواز السفر أو معرف الناخب) وتطبيقات مثبتة , فلنبدأ بالتحقق من KYC.",
  kyc_popup_desc18: "هناك رسوم 6 دولارات للتحقق من KYC.",
  kyc_popup_desc19: "هل تريد متابعة التحقق من KYC؟",
  kyc_result1:
    "أنت بالفعل مستخدم معتمد من KYC. انتقل إلى التطبيق وادفع داخل التطبيق وفقًا لطلب KYC.",
  kyc_result2: "لا توجد معلومات مطابقة.",
  kyc_popup_title4: "[نهاية التحقق من KYC]",
  kyc_popup_desc20: "شكرًا لك على التحقق من KYC الخاص بك.",
  kyc_popup_desc21:
    "إذا تم إكمال التحقق من KYC بشكل طبيعي , فسيتم تغيير الرمز الموجود على الشاشة الرئيسية لـ pp الخاص بك على النحو التالي.",
  kyc_popup_desc22:
    "إذا لم يتم تغييرها , فهذا يعني أنه تم الحكم على درجة الشهادة على أنها غير مطابقة.",
  kyc_popup_desc23: "سيتم التحقق من ذلك يدويًا مرة أخرى بواسطة BCL Foundation.",
  kyc_popup_desc24: "شكرًا جزيلاً",
  kyc_popup_desc25: "إثبات التحقق من KYC",
  kyc_popup_pay: "دفع",
  kyc_result5:
    "في تطبيق BitcoinLegend ، انتقل إلى ملف التعريف الخاص بك ووافق على KYC.",
  kyc_result6:
    "هذا رقم هاتف تم التحقق منه بالفعل من خلال KYC. انتقل إلى التطبيق وادفع من داخل التطبيق وفقًا لطلب اعرف عميلك (KYC).",
  kyc_result7: "KYC قيد التقدم.",
  kyc_popup_desc26: "※ يرجى البدء بالتطبيق أولاً",
  kyc_popup_desc27:
    "5. للاستفسارات ، يرجى وضع <a href='https://forms.gle/94ggLz5t1fUbh1Cq6' target='_blank' style='text-decoration: underline؛'> <B> هنا </ b> </a> .",
  kyc_result8: "استخدم وظيفة الدفع داخل التطبيق في التطبيق (الإصدار 1.4.8)",
  kyc_1: '[إشعار بالتعليق المؤقت لعملية التحقق من "اعرف عميلك"]',
  kyc_2:
    "نتيجة للاحتجاج القوي ضد Stripe ، جاري استرداد 100٪ لـ KYC. <br /> <br /> تم إبلاغنا بأنه سيكون هناك تأخير من 10 إلى 15 يومًا حسب شركة بطاقة الائتمان وفقًا للدول. <br /> إذا لم تسترد أموالك بعد ذلك ، فيرجى الاتصال بشركة بطاقة الائتمان الخاصة بك. <br /> USDT ، TRX ، الدفع داخل التطبيق (Google) قيد التقدم من خلال توسيع دفع KYC.",
  nft_1: "عرض ترويجي خاص للقائمة البيضاء",
  nft_2: "☑Ranking Competition (تحديث في الوقت الفعلي)",
  nft_3: "● المركز الأول",
  nft_4:
    '1. العرض الترويجي السادس للبطل: "أنت البطل" <br /> (صورتك وقصتك ← إنتاج البطل + webtoon + إنتاج الراب) <br /> البطل الأول: https://youtu.be/s0FSVXZ-wSs <br/> البطل الثاني: https://youtu.be/8Al9l7YVLC4 <br /> البطل الثالث - الخامس: https://youtu.be/8Al9l7YVLC4',
  nft_5:
    "2. الإصدار المحدود 1،000NFT (البطل السادس) مع 26 عنصرًا <br /> 1،000NFT * 0.1ETH (سعر القائمة البيضاء) = 100ETH <br /> مكافأة بنسبة 5٪ على مبلغ المبيعات (5ETH)",
  nft_6: "● المركز الثاني - الثالث",
  nft_7:
    "المركز الثاني) 5 NFTs إضافية + 2٪ مكافأة على البطل السادس (2ETH) <br /> المركز الثالث) 3 NFTs إضافية + 1٪ مكافأة على البطل السادس (1ETH)",
  nft_8: "● المركز الرابع - العاشر",
  nft_9: "2 NFTs إضافية",
  nft_10: "* سيتم دفع NFT إضافية مدفوعة من خلال NFT المباعة حاليًا.",
  nft_11: "☑ ترقية العضوية",
  nft_12: "● Platinum (اشترِ 21NFT أو أكثر)",
  nft_13:
    "تسريع التعدين (+ 24 مليار لتر / يوم) ، أولوية KYC ، NFT + 1 لكل وحدة / 20 لكل وحدة",
  nft_14: "● الذهب (اشتر 11 ~ 20 NFT)",
  nft_15: "تسريع التعدين (+24 BCL / يوم)، أولوية KYC، NFT + 1",
  nft_16: "● الفضة (اشتر 5-10 NFT)",
  nft_17: "تسريع التعدين (+24 BCL / يوم) ، أولوية KYC",
  nft_18: "● برونزية (اشترِ 1 ~ 4NFT)",
  nft_19: "تسريع التعدين (24 مليار لتر / يوم)",
  nft_20:
    "تم تطوير بطاقة Maxform NFT Hero Card بواسطة فريق BitcoinLegend Core. <br />" +
    "كانت تتألف من مجموعات رقمية فريدة من نوعها على Ethereum blockchain ERC-721. <br />" +
    "<br />" +
    "خمسة أبطال يولدون من جديد ببطاقات NFT. إنه مصمم بمفهوم أن أي شخص عادي يمكن أن يصبح بطلاً. <br />" +
    "إنها نصف لباس عادي ونصفها بزي البطل. <br />" +
    "<br />" +
    "أصبح هذا الأمر أكثر تميزًا من خلال برنامج الندرة. لا يوجد شكل متطابق بين البطاقات الصادرة. تختلف عناصر الشعر ، والملابس ، والأسلحة ، والإكسسوارات ، والقناع ، والخلفية ، وما إلى ذلك. <br />" +
    "من بينها ، ليس هناك شخصية واحدة ولكن ما مجموعه 5 أحرف في أوضاع ومواقف مختلفة. <br />" +
    "لكل شخصية من هذا القبيل ، يتم إصدار 2000 بطاقة بطل. <br />" +
    "النهج يختلف عن NFTs الأخرى التي تنشئ 10000 قطعة بحرف واحد. <br />" +
    "بهذه الطريقة ، يظهر إجمالي خمسة أحرف في عام 2000 لكل منها ، ليصبح المجموع 10000 بطاقة. <br />" +
    "سيكون لديك العنصر النادر والفريد الخاص بك.",
  kyc_result9: "هذا عنوان Tron تم التحقق منه بالفعل. لا يوجد دفع إضافي مطلوب.",
  kyc_result10: "التحقق من الحساب",
  kyc_result11: "تحقق من عنوان بريدك الإلكتروني",
  kyc_result12: "الدفع ، اكتمل التحقق من KYC",
  kyc_result13: "الدفع غير مكتمل ، KYC مكتمل",
  kyc_result14: "الدفع غير مكتمل ، اعرف عميلك غير مكتمل",
  kyc_result15:
    'اكتمل الدفع ، ولم يكتمل اعرف عميلك. انتقل إلى التطبيق وتابع "اعرف عميلك" وفقًا لأمر "اعرف عميلك"',
  kyc_result16:
    "5. بالنسبة إلى USDT ، تحتاج إلى إرسال 6 دولارات إلى المحفظة الأساسية. <font color = 'red'> (تأكد من إرسالها إلى محفظتك الشخصية) </ font>",
  kyc_result17: "- عنوان USDT: TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG",
  kyc_result18: "- التكلفة: 6 دولارات أمريكية",
  kyc_result19:
    "اكتمل التحقق من KYC ، ولكن لم يتم تأكيد تفاصيل الإيداع. قم بتأكيد مبلغ الإيداع في USDT و TRON.",
  kyc_result20:
    'لم يكتمل التحقق من "اعرف عميلك". لم يتم تأكيد تفاصيل الإيداع. قم بتأكيد مبلغ الإيداع في USDT و TRON.',
  kyc_result21: "[إشعار]",
  kyc_result22:
    'تم تعليق مصادقة KYC حاليًا بسبب مشاكل خادم Passbase ، شركة مصادقة KYC. <br /> <br /> نحن على اتصال بـ Passbase وسنخطرك مرة أخرى عندما يعمل خادم KYC بشكل طبيعي. <br /> <br /> بعد دفع رسوم شهادة "اعرف عميلك" ، سنتخذ إجراءً حتى يتمكن المستخدمون الذين لم يجروا "اعرف عميلك" من الحصول على شهادة "اعرف عميلك" دون أي تكلفة إضافية.',
  kyc_result23:
    "[يلاحظ]\n" +
    "عند الإيداع باستخدام UDST و Tron ، يرجى الإيداع باستثناء رسوم الغاز عادةً * 6USDT * 100TRX",
  kyc_result24: "[إشعار متعلق بالتحقق والدفع KYC]",
  kyc_result25:
    "1. تأخر التحقق من KYC <br />" +
    "عندما لا يتم التحقق من التحقق من KYC بسبب خطأ من المستخدم ، فقد وضعنا فريقًا منفصلاً للتحقق اليدوي للتحقق من ذلك. (عند اكتمال التحقق من KYC ، يتغير إلى رمز أخضر ؛ <br />" +
    "نعتذر عن استمرار إرباكك بالمهام المتعلقة بالدفع KYC. يُرجى إيقاف الدفع في تطبيق KYC لبعض الوقت. <br />" +
    "<br />" +
    "2. خطأ في دفع KYC واسترداد الأموال <br /> " +
    "1) من الصعب علينا الرد بنسبة 1: 1 لأننا نعالج استردادًا كاملاً لمضاعفة أو إغفال الدفع أثناء الدفع الشريطي. يرجى الانتظار حتى تتم معالجة رد الأموال. <br />" +
    "3) إذا تم السداد مرتين بسبب عدم كفاية مدفوعات USDT و Tron ، فسنرد بعد الفحص اليدوي. <br />" +
    "3) حدث رد الأموال داخل التطبيق بسبب إغفال فريق BCL الأساسي لدينا لترميز التأكيد داخل التطبيق. جاري حاليًا ترميز التأكيد داخل التطبيق. يُرجى التوقف عن الدفع قبل الإعلان عن إصدار جديد محدث. <br />" +
    "4) يمكن لـ USDT و Tron متابعة دفع KYC. <br />" +
    "<br />" +
    "* تتطلب الحالة المذكورة أعلاه الكثير من تعبئة القوى العاملة ، لذلك قامت مؤسسة BCL بتأمين القوى العاملة وتتعامل معها بقدر الإمكان ، لذا يرجى الانتظار. <br />" +
    "* ستستمر تسوية وسحب BCL بعد تنفيذ التشغيل العادي للأعمال المذكورة أعلاه.",
  kyc_result26:
    "هذا هو البريد الإلكتروني أو رقم الهاتف الذي تم التحقق منه بالفعل من خلال KYC.",
  kyc_result27: "لا توجد معلومات هاتف مطابقة.",
  kyc_result28: "تاريخ الإيداع <br /> الصور",
  kyc_result29:
    "تم الانتهاء من طلبك. يُرجى الانتظار من يوم إلى يومي عمل لمعالجة الإيداع الخاص بك.",
  bep_input_block: "الرجاء إدخال BLOCK",
  kyc_result30:
    "يرجى كتابة كتلة العنوان الذي أودعت فيه. <a href='https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers' target=\"_new\"> انطلق </a>",
  kyc_result31:
    'يمكن فقط للمستخدمين الذين تظهر أيقونة "KYC Failed" المعروضة في التطبيق التسجيل.',
  kyc_result32: "لا توجد نتائج KYC",
  kyc3_1: "احتياطات عند تطبيق KYC",
  kyc3_2: "※ قم بالتحديث إلى أحدث تطبيق قبل المتابعة مع KYC.",
  kyc3_3: "لقد قمت بالإيداع ، هل هناك مشكلة؟",
  kyc3_4: "الرجاء تحديد طريقة الدفع التي أودعتها وتعويض النقص في المستندات.",
  kyc3_5: "نسخة كاملة",
  kyc3_6: "يرجى تحديد نوع العملة المشفرة التي أودعتها.",
  kyc3_7: "اربط محفظتك",
  kyc3_8: "رجاءا أدخل بريدك الإلكتروني",
  kyc3_9: "1. قم بالإيداع من محفظتك الشخصية على العنوان أدناه ، وحدد نوع العملة المشفرة التي أودعتها.",
  kyc3_10: "اذهب إلى التالي",
  kyc3_11: "2. لصق قيمة التجزئة",
  kyc3_12: "مكان لصق قيمة التجزئة المنسوخة",
  kyc3_13: "3. أدخل عنوان البريد الإلكتروني المسجل في تطبيق Bitcoinlegend.",
  kyc3_14: "4. تم السداد.",
  kyc3_15: "تابع مصادقة KYC على تطبيق Bitcoinlegend.",
  kyc3_16: "4. لم يودع.",
  kyc3_17: "يرجى إيداع رسوم KYC مرة أخرى.",
  kyc3_18: "سوف تقوم بعمل وديعة",
  kyc3_19: "إيداع ، ولكن هناك مشكلة",
};
