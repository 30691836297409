import React from "react";
import { withRouter } from "react-router-dom";
import { PageSettings } from "./config/page-settings.js";
import axios from "axios";
import { inject } from "mobx-react";
import Const, { BASE_URL } from "./config/const";
// Layout
import HeaderMobile from "./components/header/header-mobile.jsx";
import HeaderDesktop from "./components/header/header-desktop.jsx";
import Content from "./components/content/content.jsx";
import strings from "./lang/strings.js";

// Modal, LoadingBar
import CommonModal from "./components/controls/common-modal.jsx";

import "paroller.js/dist/jquery.paroller.min";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "jquery-ui-dist/jquery-ui.min";
import { Modal, ModalBody } from "reactstrap";
import $ from "jquery/dist/jquery";
import Swal from "sweetalert2";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.notificationRef = React.createRef();

    this.showCommonModal = (title, text, subtext, type, buttons, callback) => {
      this.setState({
        modalShow: true,
        modalTitle: title,
        modalSubText: subtext,
        modalType: type,
        modalText: text,
        modalCallback: callback ? callback : () => {},
        modalButtons: buttons,
      });
    };
    this.showModal = (text, type, buttons, callback) => {
      this.showCommonModal("", text, "", type, buttons, callback);
    };
    this.showAlert = (text, callback) => {
      this.showModal(text, CommonModal.ALERT, false, callback);
    };
    this.showConfirm = (text, callback) => {
      this.showModal(text, CommonModal.CONFIRM, false, callback);
    };
    this.onModalClose = (result) => {
      this.setState({
        modalShow: false,
        modalText: "",
      });
      this.state.modalCallback(result);
    };

    this.post = async (url, param, callback, showProgress = false) => {
      if (showProgress) this.handleSetPageLoading(true);

      try {
        let result = await axios.post(Const.BASE_URL + url, param);
        // console.log(result);

        if (showProgress) this.handleSetPageLoading(false);

        if (
          result.data === 505 ||
          result.data === 500 ||
          result.data === "505" ||
          result.data === "500"
        ) {
          this.showAlert(strings.api_error);
        } else {
          if (callback) {
            callback(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (showProgress) this.handleSetPageLoading(false);

        this.showAlert(error.message);
      }
    };

    this.postWithFullUrl = async (
      url,
      param,
      callback,
      showProgress = false
    ) => {
      if (showProgress) this.handleSetPageLoading(true);

      try {
        let result = await axios.post(url, param);
        // console.log(result);

        if (showProgress) this.handleSetPageLoading(false);

        if (
          result.data === 505 ||
          result.data === 500 ||
          result.data === "505" ||
          result.data === "500"
        ) {
          this.showAlert(strings.api_error);
        } else {
          if (callback) {
            callback(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (showProgress) this.handleSetPageLoading(false);

        this.showAlert(error.message);
      }
    };

    this.get = async (url, param, callback, showProgress = false) => {
      if (showProgress) this.handleSetPageLoading(true);

      try {
        let result = await axios.get(Const.BASE_URL + url, { params: param });

        if (showProgress) this.handleSetPageLoading(false);

        if (
          result.data === 505 ||
          result.data === 500 ||
          result.data === "505" ||
          result.data === "500"
        ) {
          this.showAlert(strings.api_error);
        } else {
          if (callback) {
            callback(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (showProgress) this.handleSetPageLoading(false);

        this.showAlert(error.message);
      }
    };

    this.getWithFullUrl = async (
      url,
      param,
      callback,
      showProgress = false
    ) => {
      if (showProgress) this.handleSetPageLoading(true);

      try {
        let result = await axios.get(url, { params: param });

        if (showProgress) this.handleSetPageLoading(false);

        if (
          result.data === 505 ||
          result.data === 500 ||
          result.data === "505" ||
          result.data === "500"
        ) {
          this.showAlert(strings.api_error);
        } else {
          if (callback) {
            callback(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (showProgress) this.handleSetPageLoading(false);

        this.showAlert(error.message);
      }
    };

    this.upload = async (url, param, callback, showProgress = false) => {
      if (showProgress) this.handleSetPageLoading(true);

      try {
        let result = await axios.post(Const.UPLOAD_URL + url, param);
        // console.log(result);

        if (showProgress) this.handleSetPageLoading(false);

        if (
          result.data === 505 ||
          result.data === 500 ||
          result.data === "505" ||
          result.data === "500"
        ) {
          this.showAlert(strings.api_error);
        } else {
          if (callback) {
            callback(result.data);
          }
        }
      } catch (error) {
        console.log(error);

        if (showProgress) this.handleSetPageLoading(false);

        this.showAlert(error.message);
      }
    };

    this.handleApiError = (e) => {
      if (
        Number(e.result_code) === Const.RESULT_CODE.EMPTY_TOKEN ||
        Number(e.result_code) === Const.RESULT_CODE.TOKEN_EXPIRED
      ) {
        this.props.history.replace("/login");
        return;
      }

      let msg = e.result_msg;
      if (msg === "") {
        msg = "API 오류가 발생하였습니다.";
      }

      this.showAlert(msg);
    };

    this.handleSetPageLoading = (value) => {
      this.setState((state) => ({
        pageLoading: value,
      }));
    };

    this.showGallery = (image_list, index = 0) => {
      this.setState({
        galleryShow: true,
        galleryData: image_list,
        galleryIndex: index,
      });
    };

    this.containerRef = React.createRef();
    this.scrollToTop = () => {
      this.containerRef.current.scrollTo(0, 0);
    };

    this.state = {
      post: this.post,
      postWithFullUrl: this.postWithFullUrl,
      get: this.get,
      getWithFullUrl: this.getWithFullUrl,
      upload: this.upload,
      handleApiError: this.handleApiError,
      pageLoading: false,

      modalShow: false,
      modalText: "",
      modalButtons: false,
      modalTitle: "",
      modalSubText: "",
      modalCallback: () => {},

      showModal: this.showModal,
      showCommonModal: this.showCommonModal,
      showQuestion: this.showQuestion,
      showAlert: this.showAlert,
      showConfirm: this.showConfirm,

      showGallery: this.showGallery,
      galleryShow: false,
      galleryData: [],
      galleryIndex: 0,

      scrollToTop: this.scrollToTop,
      showNftModal: false,

      inputFailedEmail: "",
    };

    this.uiState = this.props.rootStore.uiState;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    //hango
    setTimeout(() => {
      this.checkNftCardPopupShown();
    }, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  checkNftCardPopupShown = () => {
    if (
      !window.location.pathname.includes("nft_intro") &&
      !window.location.pathname.includes("levelup") &&
      !window.location.pathname.includes("nft_event") &&
      !window.location.pathname.includes("whitelist") &&
      !window.location.pathname.includes("gamefi") &&
      !window.location.pathname.includes("card") &&
      !window.location.pathname.includes("kyc")
    ) {
      this.setState({ showNftModal: true });
    }
  };

  handleScroll = () => {
    if (window.scrollY > 0) {
      this.setState((state) => ({
        hasScroll: true,
      }));
    } else {
      this.setState((state) => ({
        hasScroll: false,
      }));
    }
  };

  render() {
    return (
      <PageSettings.Provider value={this.state}>
        <div ref={this.containerRef}>
          <CommonModal
            isOpen={this.state.modalShow}
            type={this.state.modalType}
            buttons={this.state.modalButtons}
            title={this.state.modalTitle}
            text={this.state.modalText}
            subtext={this.state.modalSubText}
            onClose={this.onModalClose}
          />
          {this.uiState.display_mode === Const.DISPLAY.DESKTOP ? (
            <HeaderDesktop />
          ) : (
            <HeaderMobile />
          )}
          <Content />

          <Modal
            isOpen={this.state.showNftModal}
            toggle={this.toggleModal}
            style={{ maxWidth: 443, margin: "50px auto", borderRadius: 20 }}
          >
            <ModalBody
              style={{
                padding: 0,
                borderRadius: 20,
              }}
            >
              <div
                className="column text-center"
                style={{ display: "list-item" }}
              >
                {/*<div className="desc" style={{ fontWeight: 'bold', padding: 5 }}>*/}
                {/*    {strings.kyc_result21}*/}
                {/*</div>*/}
                {/*<div className="desc" style={{ padding: 5 }} dangerouslySetInnerHTML={{ __html: strings.kyc_result22 }} />*/}
                {/*<br />*/}
                {/*<img className="nft-header-img"  src="/assets/img/img_notice_stripe.jpg" srcSet="/assets/img/kyc_banner.jpeg 1x"*/}
                {/*     style={ this.uiState.display_mode === Const.DISPLAY.DESKTOP ?  { marginTop: 10, fontSize: 13, width: 400 } : { marginTop: 10, fontSize: 13, width: 350 }}  />*/}

                {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '40%' }}*/}
                {/*        onClick={() => {*/}
                {/*            this.setState({ showNftModal: false })*/}
                {/*                // this.props.history.push("/nft_event")*/}
                {/*            window.open('https://bit.ly/3h8MgBy', '_blank');*/}
                {/*                // window.location.href = 'https://max-form.vercel.app/gallery'*/}

                {/*        }}>*/}
                {/*  Go to Google play store*/}
                {/*</button>*/}
                {/* <img
                  className="nft-header-img"
                  src="/assets/img/img_kyc_fail.png"
                  srcSet="/assets/img/img_kyc_fail.png 1x"
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                      ? { marginTop: 20, fontSize: 13, width: 400 }
                      : { marginTop: 10, fontSize: 13, width: 350 }
                  }
                /> */}
                <div
                style={
                  { width: '100%', maxWidth:400, height:'auto', overflow:'hidden', position:'relative'}
                }
                >
                <img
                  className="nft-header-img"
                  src="/assets/img/popupImg_1.png"
                  srcSet="/assets/img/popupImg_1.png 1x"
                  style={
                    { marginTop: 60, fontSize: 13, width: '100%', maxWidth:400 }
                  }
                  onClick={() => {
                    window.open('https://bitcoinlegend.link/');
                  }}
                />
                <img
                  className="nft-header-img"
                  src="/assets/img/close_fff_1.png"
                  srcSet="/assets/img/close_fff_1.png 1x"
                  style={
                    {
                        position: 'absolute',
                        top: 80,
                        right: 20,
                        width: 50,
                        height: 50
                      }
                    }
                  onClick={() => {
                    this.setState({ showNftModal: false });
                  }}
                />
                </div>
                {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '40%' }}*/}
                {/*        onClick={() => {*/}
                {/*            Swal.fire({*/}
                {/*                html: strings.kyc_result30,*/}
                {/*                // imageUrl: '/assets/img/tronscan.png',*/}
                {/*                imageAlt: 'tronscan'*/}
                {/*            })*/}
                {/*        }}>*/}
                {/*    Click on Failed KYC Results*/}
                {/*</button>*/}
                {/* <div className="check-pre-sale" style={{ marginTop: 10 }}>
                  <input
                    type="text"
                    placeholder="bcl email address"
                    value={this.state.inputFailedEmail}
                    onChange={(e) => {
                      this.setState({ inputFailedEmail: e.target.value });
                    }}
                    style={{
                      border: "1px solid #aaaaaa",
                      width: 300,
                      height: 30,
                    }}
                  />
                  <button
                    onClick={() => {
                      axios
                        .get(
                          `https://pay.bitcoinlegend.org/admin/api/checkStatusKYC?email=${this.state.inputFailedEmail}`,
                          null
                        )
                        .then((res) => {
                          console.log(res.data);
                          if (res.data.data == -3) {
                            alert(strings.kyc_result31);
                            return false;
                          } else if (res.data.data == -2) {
                            alert(strings.kyc_result31);
                            return false;
                          } else if (res.data.data == -1) {
                            alert(strings.kyc_result3);
                            return false;
                          } else {
                            const anchor = document.createElement("a");
                            anchor.href =
                              "https://docs.google.com/forms/d/e/1FAIpQLSfVxkuL_SQBPhGeFZba-LIdOw-d7ZQcuTQZOZ7AvTgqMBF5ng/viewform?pli=1";
                            anchor.target = "_blank";
                            anchor.click();
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          // alert(strings.not_match_bep)
                        });
                    }}
                    style={{
                      backgroundColor: "#D07674",
                      color: "white",
                      width: 80,
                      height: 30,
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                    }}
                  >
                    {strings.go}
                  </button>
                </div> */}
                {/*<strong>{strings.kyc_result24}</strong><br />*/}
                {/*    <div className="desc" dangerouslySetInnerHTML={{ __html: strings.kyc_result25 }} />*/}
                {/*    <button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '60%' }}*/}
                {/*            onClick={() => {*/}
                {/*                this.setState({ showNftModal: false })*/}
                {/*                setTimeout(() => {*/}
                {/*                    // this.props.history.push("/nft_event")*/}
                {/*                    window.open('https://herocard.bitcoinlegend.org', '_blank');*/}
                {/*                    // window.location.href = 'https://max-form.vercel.app/gallery'*/}
                {/*                }, 500)*/}

                {/*            }}>*/}
                {/*        Go NFT Minting Site*/}
                {/*    </button>*/}
                {/*</div>*/}
                {/*<br />*/}
                {/*<img className="nft-header-img"  src="/assets/img/img_notice_stripe.jpg" srcSet="/assets/img/img_notice_stripe.jpg 1x" style={{ marginTop: 20, fontSize: 13, width: 400 }} />*/}
                {/*<strong> [Hero NFT Card] </strong> <br />*/}
                {/*<img className="nft-header-img" src="/assets/img/img_popup_nfthero.png" srcSet="/assets/img/img_popup_nfthero.png 1x" style={{ marginTop: 20, fontSize: 13 }} />*/}
                {/*<div className="desc" dangerouslySetInnerHTML={{ __html: strings.nft_modal_content }} style={{ marginTop: 20, fontSize: 13 }}></div>*/}

                {/*<div className="desc" dangerouslySetInnerHTML={{ __html: strings.bep_title11 }} style={{ marginTop: 20,marginBottom: 20, fontSize: 13 }}></div>*/}

                {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50, fontSize: 16, width: '60%' }}*/}
                {/*    onClick={() => {*/}
                {/*        this.setState({ showNftModal: false })*/}
                {/*        setTimeout(() => {*/}
                {/*            this.props.history.push("/nft_intro_v2")*/}
                {/*            // window.location.href = '//nft_intro_v2'*/}
                {/*        }, 500)*/}

                {/*    }}>*/}
                {/*    {strings.go_hero_nft_card}*/}
                {/*</button>*/}
                {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '60%' }}*/}
                {/*        onClick={() => {*/}
                {/*            this.setState({ showNftModal: false })*/}
                {/*            setTimeout(() => {*/}
                {/*                this.props.history.push("/nft_event")*/}
                {/*                // window.location.href = '//nft_intro_v2'*/}
                {/*            }, 500)*/}

                {/*        }}>*/}
                {/*    {strings.go_hero_nft_event}*/}
                {/*</button>*/}
                {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '60%' }}*/}
                {/*        onClick={() => {*/}
                {/*            this.setState({ showNftModal: false })*/}
                {/*            setTimeout(() => {*/}
                {/*                // this.props.history.push("/nft_event")*/}
                {/*                window.open('https://herocard.bitcoinlegend.org', '_blank');*/}
                {/*                // window.location.href = 'https://max-form.vercel.app/gallery'*/}
                {/*            }, 500)*/}

                {/*        }}>*/}
                {/*    Go NFT Minting Site*/}
                {/*</button>*/}
                {/* <div
                  className="desc"
                  style={{ marginTop: 8, marginBottom: 18, fontSize: 13 }}
                >
                  <input
                    type="checkbox"
                    onClick={() => {
                      this.setState({ showNftModal: false });
                    }}
                  />{" "}
                  {strings.go_hero_nft_close}
                </div> */}
              </div>
            </ModalBody>
          </Modal>
        </div>
      </PageSettings.Provider>
    );
  }
}

export default withRouter(inject("rootStore")(App));
