import { inject, observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Const, {APK_URL, BASE_URL, BCL_PRESALE_RECV_ADDRESS} from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from '../../lang/strings';
import {Modal, ModalBody} from "reactstrap";
import $ from "jquery/dist/jquery";
import Select from 'react-select'

import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";

// const stripePromise = loadStripe("pk_live_51LI7nzJ13Kc9U5mkQP8icxVCs7zdbOUolGoYKjwyCkGKDJY3YqsC592nOaFqKg5ApyGpDtOKb4rjid9dtLIr1lg000Cgu5gPxS");
const stripePromise = loadStripe("pk_test_51LI7nzJ13Kc9U5mkVZflUq2QFnNzkP9GbFnvev2mU4pg89wtdnk0M4XrTo2x0QWYJ6HDx8jAVOjevexD0J6rs1lW008Zg3HIFx");

class Levelup extends React.Component {
  static contextType = PageSettings;


  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      showBepModal: false,
      inputEmail: '',
      showGuideModal: false,
      showStripe: false,
      options: [],
      selectProduct: null,
      clientSecret: '',
      option: {},
    };


    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
    this.getPublicLevel();
  }

  getPublicLevel()  {
    axios
        .get(`${BASE_URL}/user/publicLevel`, {
        })
        .then((res) => {
            this.state.options = res.data;
            this.state.options.shift();
            this.state.options.pop();
            this.state.selectProduct = this.state.options[0];
            console.log(this.state.options);
        })
        .catch((err) => console.log("오류발생", err));

  };

  onClickCopyPreSaleAddress() {
    var copyText = document.querySelector("#presale_address");
    copyText.select();
    document.execCommand("copy");
    setTimeout(() => {
        alert("Copied")
    }, 500)
  }

  componentDidMount() {
      console.log(window.location.pathname)
      const query = new URLSearchParams(window.location.search);
      const imp_success = query.get('imp_success');
      const error_msg = query.get('error_msg');
      console.log(imp_success);
      if (imp_success != null && imp_success != '') {
          if (imp_success != 'false') {
              alert('SUCCESS');
          } else {
              alert('Fail');
          }
      }
    const _clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (_clientSecret) {
      this.setState( { option : {
          clientSecret: _clientSecret,
          theme: 'stripe'
        }});
      this.setState ( { clientSecret : _clientSecret } );
      this.setState ( { showStripe : true });

    }
      // this.checkBEP({ value: '50', label: 'Explorer Level 4' })
  }
  handleClose() {
    this.setState ( { showStripe : false });
  }

  componentWillUnmount() {

  }

  checkStripe = () => {
      let params = new URLSearchParams();
      params.append('email', this.state.inputEmail);
      params.append('type', 'levelup');

      axios.post(`${BASE_URL}/payment/create-payment-intent`,
          params)
          .then((res) => {
              console.log(res.data.clientSecret);
              this.setState( { option : {
                  clientSecret: res.data.clientSecret,
                  theme: 'stripe'
              }});
              this.setState ( { clientSecret : res.data.clientSecret } );
              this.setState ( { showStripe : true });
          });
  }

  checkBEP = (obj) => {
      console.log(obj);
    this.state.selectProduct = obj;
    console.log(obj.name);
    console.log(obj.levelKey);
    this.setState({ showBepModal: true })
  }

  render() {
    return (
      <div id="levelup" className="main">

          <div id="levelup" className="content has-bg home">
          <div >
              <div className="container home-content">
                  <div >
                      <img className="video-bg"
                           style={ (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { textAlign: "center" } : { textAlign: "center", width: "100%" } )}
                           src="/assets/img/levelup/img_levelup.png" srcSet="/assets/img/levelup/img_levelup.png 1x" />
                  </div>
              </div>
          </div>
          </div>
          <div id="levelup" className="content airdrop" data-scrollview="true">
              <div className="content-bg" style={{ marginTop: "60vh"}}>
                  <img className="top" src="/assets/img/img_bg_distribution.png"
                       srcSet="/assets/img/img_bg_distribution.png 1x" />
              </div>
              <div className="airdrop-content" data-animation="true" data-animation-type="fadeInDown">
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-40")} style={{ color: '#FFD500' }}>
                      {strings.levelup_title1}
                  </div>
                  <div className="content-desc m-t-15"
                       ref={(el) => {
                           if (el) {
                               el.style.setProperty('margin-top', '10vh', 'important');
                           }
                       }}
                       style={this.globalState.getLang() == "ar" ? { textAlign: "right", marginTop: "5vh" } : { marginTop: "8vh important" }}
                       dangerouslySetInnerHTML={{ __html: strings.levelup_desc1 }} />
                  <div
                      className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-65" : "m-t-30")} style={{ color: "#627EEA", fontSize: "38px" }}>{strings.levelup_title2}</div>
                  <div
                      className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}>
                  <div className={"mark-icon "}
                       style={ (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? { textAlign: "center",width: "40vh" } : { textAlign: "center", width: "100%" } )}
                       src="/assets/img/levelup/levelup_2.png" srcSet="/assets/img/levelup/levelup_2.png 1x"
                  />
                  </div>
                  { this.state.options.length > 0 &&
                  <>
                      <div
                          className={"section-title " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-95" : "m-t-30")}
                          style={{color: "#627EEA", fontSize: "38px"}}>{strings.levelup_title3}</div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[7]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level7.png"
                                       srcSet="/assets/img/levelup/level7.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[7].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[7].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[7].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[7].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[7].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[6]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level6.png"
                                       srcSet="/assets/img/levelup/level6.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[6].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[6].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[6].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[6].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[6].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[5]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level5.png"
                                       srcSet="/assets/img/levelup/level5.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[5].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[5].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[5].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[5].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[5].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[4]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level4.png"
                                       srcSet="/assets/img/levelup/level4.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[4].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[4].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[4].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[4].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[4].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[3]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level4.png"
                                       srcSet="/assets/img/levelup/level4.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[3].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[3].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[3].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[3].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[3].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[2]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level3.png"
                                       srcSet="/assets/img/levelup/level3.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[2].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[2].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[2].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[2].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[2].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div onClick={() => {
                          this.checkBEP(this.state.options[1]);
                      }}
                           className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                           style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      cursor: 'pointer',
                                      border: "1px solid #aaa",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level2.png"
                                       srcSet="/assets/img/levelup/level2.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[1].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[1].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[1].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[1].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {this.state.options[1].amount}$
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_up.png"
                                       srcSet="/assets/img/levelup/nft_charge_up.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                      <div
                          className={"content-desc " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-15" : "m-t-30")}
                          style={{textAlign: "center", cursor: 'pointer'}}>
                          <div
                              className={"mark-icon"}
                              style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ?
                                  {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      border: "1px solid #EC8F22",
                                      maxWidth: "680px",
                                      width: "100%",
                                      height: "10vh",
                                      borderRadius: "10px"
                                  }
                                  : {
                                      display: "inline-flex",
                                      textAlign: "center",
                                      border: "1px solid #EC8F22",
                                      width: "100%",
                                      height: "9vh",
                                      borderRadius: "10px"
                                  })}>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 1}}>
                                  <img className={"img1"} src="/assets/img/levelup/level1.png"
                                       srcSet="/assets/img/levelup/level1.png 1x" style={{maxWidth: "97px"}}/></div>
                              <div style={{float: "left", marginTop: "15px", height: "100%", flex: 2.6}}>
                                  {this.state.options[0].name}<br/>
                                  <span style={{color: "#777777"}}> + {this.state.options[0].hr} BCL / hr &nbsp;</span>
                              </div>
                              <div className={"content-desc3"} style={{
                                  padding: 0,
                                  marginTop: "5px",
                                  height: "100%",
                                  color: "#A0A0A0",
                                  textAlign: "left",
                                  flex: 4.4
                              }}>
                                  Mining Speed : + {(parseFloat(this.state.options[0].hr) * 24).toLocaleString()} BCL/day <br/>
                                  Contract : 30 Days<br/>
                                  Full Mining for 30 days : {(parseFloat(this.state.options[0].hr) * 24 * 30).toLocaleString()} BCL<br/>
                                  Cost : {parseFloat(this.state.options[0].amount) > 0 ? this.state.options[0].amount + "$" : "Free"}
                              </div>
                              <div style={{float: "right", marginRight: 5, height: "100%", flex: 1}}>
                                  <img className={"img2"} src="/assets/img/levelup/nft_charge_now.png"
                                       srcSet="/assets/img/levelup/nft_charge_now.png 1x" style={{maxWidth: "97px"}}/>
                              </div>
                          </div>
                      </div>
                  </>
                  }
              </div>

          </div>
          <div id="download" className="content download">

              <div className="action-content">
                  <div className="title" style={{ backgroundImage: 'url(/assets/img/ic_gradient2.png)' }}>
                      <div>{strings.download_now}</div>
                  </div>
                  <div className="download-hint">{strings.download_now_desc}</div>
                  <div className="referring-hint">{strings.referring_hint}</div>
                  <img className={"mark-icon " + (this.uiState.display_mode === Const.DISPLAY.DESKTOP ? "m-t-100" : "m-t-50")}
                       src="/assets/img/ic_logo_bottom.png" srcSet="/assets/img/ic_logo_bottom.png 1x" />

                  <div className="market-link">
                      <a href="http://bit.ly/3h8MgBy" target="_blank">
                          <img src="/assets/img/ic_download_google.png" srcSet="/assets/img/ic_download_google.png 1x" />
                      </a>
                      <a href="http://apple.co/3oEODPq" target="_blank">
                          <img src="/assets/img/ic_download_apple.png" srcSet="/assets/img/ic_download_apple.png 1x" />
                      </a>
                      <a href={APK_URL} target="_blank">
                          <img src="/assets/img/ic_download_apk.png" srcSet="/assets/img/ic_download_apk.png 1x" />
                      </a>
                  </div>
                  <div className="join-us-hint" dangerouslySetInnerHTML={{ __html: strings.join_us_hint }} />
                  <div className="link-content">
                      <a href="https://www.bitcoinlegend.org" target="_blank">
                          <img src="/assets/img/ic_sns_website.png" srcSet="/assets/img/ic_sns_website.png 1x" />
                      </a>
                      {/* <a href="https://www.facebook.com" target="_blank">
                <img src="/assets/img/ic_sns_facebook.png" srcSet="/assets/img/ic_sns_facebook.png 1x" />
              </a> */}
                      <a href="http://bit.ly/3tDkKkA" target="_blank">
                          <img src="/assets/img/ic_sns_insta.png" srcSet="/assets/img/ic_sns_insta.png 1x" />
                      </a>
                      <a href="http://bit.ly/3AsXqcJ" target="_blank">
                          <img src="/assets/img/ic_sns_youtube.png" srcSet="/assets/img/ic_sns_youtube.png 1x" />
                      </a>
                      <a href="http://bit.ly/38WoRyN" target="_blank">
                          <img src="/assets/img/ic_sns_telegram.png" srcSet="/assets/img/ic_sns_telegram.png 1x" />
                      </a>
                      <a href="http://bit.ly/3tEQDt4" target="_blank">
                          <img src="/assets/img/ic_sns_twitter.png" srcSet="/assets/img/ic_sns_twitter.png 1x" />
                      </a>
                      <a href="https://cafe.naver.com/bitcoinlegendkorea/807" target="_blank">
                          <img src="/assets/img/ic_sns_kakao.png" srcSet="/assets/img/ic_sns_kakao.png 1x" />
                      </a>
                  </div>
              </div>

          </div>
          <Modal isOpen={this.state.showBepModal} toggle={this.toggleModal} style={{ maxWidth: 480, margin: '100px auto', borderRadius: 20}}>
              <ModalBody style={{ padding: 0, borderRadius: 20, background: "#272831", border: 0   }}>
                  <div className="column text-center">
                      <div className="desc" style={{ marginTop: 30, fontSize: 20, color: "white", marginLeft:18, marginRight:18 }}>{strings.levelup_popup_title1}</div>
                      <div className="desc"  style={{ marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>
                          <div style={{ width: "30%", marginRight: 5, height:50, padding: 5, background: "#FFFFFF"}}>E-mail <br />
                              for BCL app</div>
                          <div style={{ width: "70%", background: "#FFFFFF",height:50, padding: 5}}>
                              <input type="text" placeholder="" value={this.state.inputEmail} onChange={(e) => {
                                  this.setState({ inputEmail: e.target.value });
                              }} style={{ width: "100%", height: "100%" }}  />
                          </div>
                      </div>
                      <div className="desc"  style={{ marginTop: 5, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>
                          <div style={{ width: "20%", marginRight: 0, height:30, padding: 5, background: "transparent" }}></div>
                          <div style={{ width: "80%", background: "transparent", height:30, textAlign: "right", float: "right"}}>
                              <img src="/assets/img/img_popup_guide1.png" srcSet="/assets/img/img_popup_guide1.png 1x"                                   onClick={() => {
                                  this.setState({ showGuideModal: true })
                              }} />
                          </div>
                      </div>

                      <div className="desc"  style={{ marginTop: 5, fontSize: 13, display: "flex", marginLeft:18, marginRight:18 }}>
                          <div style={{ width: "30%", marginRight: 5, height:50, padding: 5, background: "#FFFFFF"}}>Level Up<br />
                              Program</div>
                          <div style={{ width: "70%", background: "#FFFFFF",height:50, padding: 5}}>
                              <Select
                                  id="selData"
                                  options={this.state.options}
                                  onChange={(selected) => this.state.selectProduct = selected}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.levelKey}
                                  defaultValue={this.state.selectProduct}  />
                              {/*<input type="text" placeholder="" value={this.state.inputErcAddress} onChange={(e) => {*/}
                              {/*    this.setState({ inputErcAddress: e.target.value });*/}
                              {/*}} style={{ width: "100%", height: "100%" }} />*/}
                          </div>
                      </div>
                      <div className="desc" dangerouslySetInnerHTML={{ __html: strings.levelup_popup_desc1 }} style={{ marginTop: 30, fontSize: 13, color: "white", textAlign: "left", marginLeft:18, marginRight:18 }}></div>

                      <div className="desc"  style={{ marginTop: 30, fontSize: 13 }}>
                          <button style={{ width: "30%", backgroundColor: "#36365C", borderRadius: 25, height: 35, color: "white",  fontSize: 16, marginBottom: 18 }}
                                  onClick={() => {

                                      this.setState({ showBepModal: false })
                                  }}>
                              {strings.bep_exit}
                          </button>
                          <button style={{  width: "30%", backgroundColor: "#D07674", borderRadius: 25, height: 35, color: "white", marginLeft:28,  fontSize: 16, marginBottom: 18 }}
                              type="button"
                              onClick={() => {
                                    this.checkStripe();
                                  // fetch("/create-payment-intent", {
                                  //     method: "POST",
                                  //     headers: { "Content-Type": "application/json" },
                                  //     body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
                                  // })
                                  //     .then((res) => res.json())
                                  //     .then((data) => setClientSecret(data.clientSecret));

                                  // if ($.trim(this.state.inputEmail) != '') {
                                  //     console.log(this.state.selectProduct);
                                  //
                                  //     let params = new URLSearchParams();
                                  //     params.append('email', this.state.inputEmail);
                                  //     params.append('levelKey', this.state.selectProduct.levelKey);
                                  //
                                  //     axios.post(`${BASE_URL}/user/miningLevel`,
                                  //         params)
                                  //         .then((res) => {
                                  //
                                  //             if (res.data == 1) {
                                  //                 alert(strings.bep_request_complete);
                                  //                 this.state.inputEmail = '';
                                  //                 this.setState({ showBepModal: false });
                                  //             } else {
                                  //                 console.log('false');
                                  //                 alert(strings.bep_request_check2)
                                  //             }
                                  //         })
                                  //         .catch((err) => {
                                  //             console.log(err);
                                  //         });
                                  // } else {
                                  //     alert('Input your email account address')
                                  // }
                              }}
                          >
                              {strings.levelup_popup_confirm}
                          </button>
                          {/*<Iamport*/}
                          {/*    identificationCode="imp04344303"*/}
                          {/*    params={{*/}
                          {/*        pg: 'paypal',*/}
                          {/*        popup : true,*/}
                          {/*        pay_method: 'card',*/}
                          {/*        merchant_uid: 'merchant_' + new Date().getTime(),*/}
                          {/*        name: this.state.selectProduct.label,*/}
                          {/*        amount: this.state.selectProduct.value,*/}
                          {/*        buyer_email: this.state.inputEmail,*/}
                          {/*        buyer_name: this.state.inputEmail,*/}
                          {/*        buyer_tel: '010-1234-5678',*/}
                          {/*        buyer_addr: '서울특별시 강남구 삼성동',*/}
                          {/*        buyer_postcode: '123-456',*/}
                          {/*        m_redirect_url: 'http://localhost:8012/levelup',*/}
                          {/*    }}*/}
                          {/*    jqueryLoaded={false}*/}
                          {/*    onFailed={err => console.log(err)}*/}
                          {/*    onSuccess={res => console.log(res)}*/}
                          {/*    render={(renderProps) => (*/}
                          {/*        <button style={{  width: "30%", backgroundColor: "#D07674", borderRadius: 25, height: 35, color: "white", marginLeft:28,  fontSize: 16, marginBottom: 18 }}*/}
                          {/*            type="button"*/}
                          {/*            onClick={() => {*/}
                          {/*                if ($.trim(this.state.inputEmail) != '') {*/}
                          {/*                    renderProps.onClick.apply()*/}
                          {/*                } else {*/}
                          {/*                    alert('Input your email account address')*/}
                          {/*                }*/}
                          {/*            }}*/}
                          {/*        >*/}
                          {/*            {strings.levelup_popup_confirm}*/}
                          {/*        </button>*/}
                          {/*    )}*/}
                          {/*/>*/}

                      </div>

                  </div>
              </ModalBody>
          </Modal>
          <Modal isOpen={this.state.showStripe}  style={{ background: 'white', maxWidth: 480, margin: '100px auto', borderRadius: 20}}>
              <ModalBody>
                  {this.state.clientSecret && (
                      <Elements options={this.state.option} stripe={stripePromise} clientSecret={this.state.clientSecret}>
                          <CheckoutForm   email={this.state.inputEmail} />
                      </Elements>
                  )}
                <button className={'button3'} style={{marginTop: 30}} onClick={() => this.setState ( { showStripe : false} ) }>
            <span id="button-text">
             CLOSE
            </span>
                </button>
          </ModalBody>

      </Modal>
          <Modal isOpen={this.state.showGuideModal} toggle={this.toggleModal} style={{ maxWidth: 480, margin: '100px auto', borderRadius: 20}}>
              <ModalBody style={{ padding: 0, borderRadius: 20, background: "#272831", border: 0   }}>
                  <div className="column text-center">
                      <div style={{ marginRight: 5, height:50, paddingTop: 10, position: "absolute", right:10, top: 5 }}>
                          <img src="/assets/img/ic_close_popup.png" srcSet="/assets/img/ic_close_popup.png 1x"  onClick={() => {
                              this.setState({ showGuideModal: false })
                          }}/>
                      </div>
                      <div className="desc" style={{ marginTop: 30, fontSize: 20, color: "white", marginLeft:18, marginRight:18 }}>{strings.levelup_title4}</div>
                      {/*<div className="desc"  style={this.globalState.getLang() == "ar" ?  { marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18, color: "#FFFFFF",textAlign: "right" } :{ marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18, color: "#FFFFFF",textAlign: "left" } }   >*/}
                          {/*<img src="/assets/img/img_popup_guid2.png" srcSet="/assets/img/img_popup_guid2.png 1x"    />*/}
                      <div className="desc"  style={{ marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18, color: "#FFFFFF",textAlign: "left" } }   >
                          <div  dangerouslySetInnerHTML={{ __html: strings.levelup_title5 }} />
                      </div>
                      <div className="desc"  style={{ marginTop: 15, fontSize: 13, display: "block", marginLeft:18, marginRight:18, marginBottom: 30,   }}>
                          <img src="/assets/img/img_popup_guide3.png" srcSet="/assets/img/img_popup_guide3.png 1x" />
                      </div>
                  </div>
              </ModalBody>
          </Modal>
      </div>
    )
  }
}

export default withRouter(inject('rootStore')(observer(Levelup)));
