import { AIRDRO_END_DAY } from "../config/const";

const moment = require("moment");

export default {
  app_name: "BitcoinLegend",
  app_name_short: "BCL",

  bcl: "BCL",
  days: "DAYS",
  hours: "HOURS",
  minutes: "MINUTES",
  seconds: "SECONDS",

  header_menu_list: [
    { code: "home", title: "Home" },
    {
      code: "nft",
      title: "Hero NFT Card",
      sub_header_menu_list: [
        { code: "/whitelist", title: "White List of NFT" },
        { code: "/card", title: "NFT Card" },
        { code: "/mint", title: "NFT Mint" },
        { code: "/gamefi", title: "Game-Fi Card" },
      ],
    },
    // { code: "about", title: "About" },
    { code: "airdrop", title: "Airdrop&Migration" },
    // { code: "levelup", title: "Level Up Program" },
    { code: "kyc", title: "KYC Certification" },
    // { code: "total_supply", title: "Total Supply & Distribution" },
    { code: "roadmap", title: "RoadMap" },
    // { code: "levelup", title: "LevelUp", }
  ],

  widely_known_desc: "BitcoinLegend 广为人知。 触摸下面的搜索栏。",

  earn_project_title: "3 Earn Project",
  earn_project_desc: "Mine to Earn, Make to Earn, Play to Earn",

  airdrop_ending_title: "AIRDROP Ending",
  airdrop_remain_count: "Airdrop Remaining Count",
  airdrop_first_desc: "1st AIRDROP End<br/>10M BCL",
  airdrop_second_desc: "2nd/3rd/4rd AIRDROP End<br/>15M BCL",
  airdrop_third_desc: "5th AIRDROP Running<br/>5M BCL",
  airdrop: "AIRDROP",
  pre_sale: "Pre-Sale",

  ntf_avatar_metaverse_game: "NFT Avatar Metaverse Game",
  three_earn_strategy: "3 Earn Strategy",
  mining_to_earn_desc:
    "挖矿和减半<br/>推荐人(+25%)<br/>代币经济<br/>安卓、iOS应用<br/>多语言包<br/>锁仓与质押",
  make_to_earn_desc:
    "买卖 NFTcard、艺术、专利<br/>用户自制系统<br/>→BCL Studio<br/>NTF 市场拍卖",
  play_to_earn_desc:
    "1:1格斗/PVP游戏<br/>虚拟房地产交易<br/>格斗中的广告/入场费<br/>广播频道<br/>网上购物",
  increase_strategy_title: "代币回购、销毁、NFT业务→涨价策略",
  increase_strategy_0: "ICO（600m BCL）:用户50m→10m，Buring 10B BCL（逐步）",
  increase_strategy_1: "IEO(CoinMarketCap : CMC 级别)",
  increase_strategy_2: "CoinMarketCap",
  increase_strategy_3: "第一次上市 / 3 月 15 日。 2022. / 首次上市",
  increase_strategy_4: "LBank",
  increase_strategy_5: "第二次上市 / 六月。 2022. / PancakeSwap",
  increase_strategy_6: "PancakeSwap",
  increase_strategy_7: "3 日上市 / 9 月。 2022. / ~Lv.20",
  increase_strategy_7_1: "MEXC",
  increase_strategy_8: "第 4 次上市 / 2022年 第 4 季度 / ~Lv. 20.",
  increase_strategy_9: "第 5 次上市 / 2023年 / ~Lv. 10.",
  increase_strategy_10: "第 6 次上市 / 2023年 / ~Lv. 5.",

  about_title: "About BitcoinLegend",
  about_desc:
    "<p>BitcoinLegend (BCL) 是基于 ERC-20 的加密货币资产。" +
    " 我们将做NFT市场平台（NFT卡、艺术品）。<br/>" +
    "此外，我们推出了 Metavers Game 英雄 NFT 卡牌，灵感来自 Marvel's Avangers。</p>" +
    "<p>当今世界正处于第四次工业革命，毫无疑问，区块链技术将在其中发挥重要作用。<br/>" +
    "BITCOIN LEGEND (BCL) 为实现人们的理想而创建，旨在成为第四次工业革命领域利用区块链技术、加密货币和元界系统构建去中心化应用生态系统的平台。</p>" +
    "<p>作为基于 ERC-20 的加密货币，BITCOIN LEGEND (BCL) 具有即将推出的 NFT 市场平台和未来的元界游戏，英雄 NFT 卡以漫威复仇者联盟为灵感。<p/>" +
    "<p>BITCOIN LEGEND 中有一个 3 Earn 策略，为用户提供 3 种赚取更多代币的方式。<p/>" +
    "<p>第一个是Mine to Earn，它通过在智能手机上进行挖矿来解决比特币挖矿的问题<br/>" +
    "当开采出来的BCL在各大加密货币交易所上市时，它既是一种数字资产，也是一种投资。<p/>" +
    "<p>第二个是Make to Earn，用户可以制作自己的NFT头像、NFT物品，然后进行买卖赚取利润。<br/>" +
    "BITCOIN LEGEND 使用 AI 技术创造出与真实用户本人相似的网络卡通人物。<br/>" +
    "用户还可以制作服装、饰品等游戏道具。<br/>" +
    "英雄史由Webtoon和Song组成（Webtoon+Rapper'说唱+NFT动画）。<br/>" +
    "上传到 YouTube 后 10 天内获得了 300,000 次观看。<p/>" +
    "<p>第三个是Play to Earn，BITCOIN LEGEND网络将使用之前创建的NFT头像卡，以及虚拟房地产在元界上进行经济活动，用户可以在元界上玩游戏或投资虚拟房地产并从这些活动中获利。<p/>",
  smart_contract: "智能合约（旧代币:ERC20）",
  smart_contract1: "智能合约（新代币:BEP20）",
  smart_contract2: "燃烧(ERC20)",
  smart_url: "网址 : ",
  smart_amount: "燃烧量 : ",

  token_symbol: "代币符号 : ",
  contract_address: "合约地址 : ",
  decimals: "小数点 : ",
  total_supply: "总供应量 : ",
  blockchain: "区块链 : ",
  white_paper: "白皮书",

  airdrop_title: "Airdrop",
  airdrop_quantity: "空投数量 : ",
  airdrop_rules: "空投规则",
  airdrop_rules_airdrop_desc:
    "社区用户可以通过安装BitcoinLegend App参与空投。<br/>" +
    "挖矿必须至少在空投结束之前完成。初始空投数量为 25 BCL。<br/>" +
    "空投完全免费，空投奖励将在空投结束后分发给挖矿应用。",
  invitation_rules: "邀请规则",
  invitation_rules_desc:
    "用户可以通过邀请朋友参加Airdrop来获得其他BCL代币奖励。 <br />您可以为您邀请的每个朋友获得50个BCL令牌。您最多可以邀请50个朋友，并最多获得2500个BCL代币。<br /> Airdrop的数量有限，并且BCL发行很小。",
  invite_team: "邀请团队",
  invite_team_desc:
    " + 25 BCL/用户。最大限度。 50个用户<br/>+@25％的被邀请用户的采矿计数",

  recommend_title: "如何推荐你的朋友",
  recommend_code_right: "你推荐的代码好吗？",
  recommend_input_code: "输入推荐码",
  recommend_inputted_code: "您推荐的代码",
  recommend_msg_copy: "在这里触摸",
  recommendation_code_non_exist: "这是一个无效的推荐代码。 请再次检查。",
  recommendation_code_verify: "请输入推荐码并验证。",
  recommend_msg_part1:
    "BitcoinLegend 不是挖矿应用程序，而是带有受漫威复仇者联盟启发的英雄 NFT 卡的元宇宙游戏。<br/><br/>☆加入BitcoinLegend免费获得10 BCL。<br/>推荐代码 : ",
  recommend_msg_part2: "<br/><br/>☆Official website<br/>",

  pre_sale_title: "Pre-sale was closed",
  pre_sale_quantity: "Pre-sale quantity : ",
  pre_sale_address: "Pre-sale address : ",
  pre_sale_price: "Pre-sale price : ",
  pre_sale_price_desc: "最低购买0.1 ETH，最高购买10 ETH。",

  airdrop_rules_presale: "预售标准",
  airdrop_rules_pre_sale_desc:
    "用户可以通过邀请朋友参加Airdrop来获得其他BCL代币奖励。 <br />您可以为您邀请的每个朋友获得50个BCL令牌。您最多可以邀请50个朋友，并最多获得2500个BCL代币。<br /> Airdrop的数量有限，并且BCL发行很小。 BCL的估计价格为0.75美元。快点与您的朋友分享。",
  how_to_do_pre_sale: "How to do Pre-sale",
  how_to_do_pre_sale_desc:
    "使用您的钱包将ETH发送到预售地址。我们的系统将立即将BCL代币发送到您的钱包。<br />必须使用个人加密货币钱包进行无KYC验证的汇款。<br />个人加密货币钱包包括MetAmask，Trustwallet，Mew Wallet和Imtoken和Imtoken等。",
  pre_sale_rules: "Pre-sale rules",
  pre_sale_rules_desc:
    "系统将于 <h6><u>" +
    moment(AIRDRO_END_DAY).format("YYYY年MM月DD日 HH:mm（UTC+8）") +
    "</u></h6><br/>" +
    "结束预售，使用钱包向预售地址发送ETH，系统会立即向您的钱包发送相应数量的 BCL 代币。<br/>" +
    "预售BCL代币数量有限，按照ETH到账时间排序。先到先得。",

  once_the_pre_sale_over: "Once the pre-sale is over",
  once_the_pre_sale_over_desc:
    "<h6><u>募集资金的 60% 将用于 NFT Market 和 LBank & MEXC 上市。</u></h6><br/>" +
    "其他 40% 募集的资金将用于回购 BCL 代币。这一行动将防止人们倾倒。",

  check_pre_sale: "Check Pre-sale",
  go: "Go",
  check_pre_sale_hint:
    "No KYC, Send Immediately BCL to Pre-sale address after sending  ETH",

  total_supply_and_distribution: "总供应和分配",
  the_number_of_miner: "矿工数量 : ",
  users: "Users",
  end_of_mining: "采矿结束 : ",
  end_of_mining_desc: "当接近 1000 万用户时，挖矿将完成.",
  total_supply1: "总供应量 : ",
  burning_and_buyback: "燃烧和回购 : ",
  community_airdrop: "社区空投 : ",
  bcl_halving: "BitcoinLegend Halving",
  distribution_bcl_supply: "Distribution of BCL supply",

  roadmap: "Roadmap",
  roadmap_last_title: "Accomplished milestones up to date(Phase1 & Phase2)",
  phase_1_2_date: '020- Q2. 2021',
  roadmap_last_item_0: "建立了项目开发团队（BCL核心团队）。",
  roadmap_last_item_1:
    "开发了移动应用程序和BitcoinLegend概念<br/>(币种优势、挖矿方式、代币经济、挖矿应用和钱包设计",
  roadmap_last_item_3: "制作了白皮书V1.0",

  now: "Now",
  phase3: "Phase3",
  phase3_date: "Q3.-Q4. 2021.",
  phase3_item_0: "Android/iOS移动应用发布",
  phase3_item_1: "官方社区（Telegram, Twitter, Youtube）开放",
  phase3_item_2: "增加44个国家的扩展语言包 ",
  phase3_item_3: "更新代币经济（包括Burining）。 ",
  phase3_item_4: "英雄NFT卡设计完成 ",

  phase4: "Phase4",
  phase4_sector_0_date: "Q1.-Q2. 2022",
  phase4_sector_0_item_0: "官方网站启动",
  phase4_sector_0_item_1: "更新白皮书V2.0(消耗3赚战略)",
  phase4_sector_0_item_2: "开发了用户挖矿监控和PING功能",
  phase4_sector_0_item_3: "开设英雄NFT卡网络漫画和说唱Youtube平台",
  phase4_sector_0_item_4: "开通官方论坛",
  phase4_sector_0_item_5: "锁定和押注资金系统启动",
  phase4_sector_0_item_6: "在Sulvey之后开始扩大开发KYC认证。",
  phase4_sector_0_item_7: "在L Bank和PancakeSwap上市。",

  phase4_sector_1_date: "Q3.-Q4. 2022",
  phase4_sector_1_item_0: "网络从ERC20迁移到BEP20（Binance智能链）。",
  phase4_sector_1_item_1: "白名单和英雄NFT卡的铸币开始",
  phase4_sector_1_item_2: "开始进行KYC认证",
  phase4_sector_1_item_3: "英雄NFT卡网站开通",
  phase4_sector_1_item_4: "第一款NFTRPG游戏发布（暗黑战士）。",
  phase4_sector_1_item_5: "在Coingecko和CoinMarketGap上市。",

  phase4_sector_2_date: "June. 2022",
  phase4_sector_2_item_0: "从ERC20到BEP20的网络迁移（币安智能链）",
  phase4_sector_2_item_1: "升级计划启动",
  phase4_sector_2_item_2: "BCL 在 PancakeSwap 上市",
  phase4_sector_2_item_3: "BCL上线MEXC加密货币交易所",
  phase4_sector_2_item_4: "第二次销毁和回购",

  phase5: "Phase5",
  phase5_sector_0_date: "Q3. 2022",
  phase5_sector_0_item_0: "在 Binance NFT 和 Opensea 上铸造 Hero NFT 卡",
  phase5_sector_0_item_1: "使用自制工作室开发 Hero NFT 卡",
  phase5_sector_0_item_2:
    "概念化和开发NFT Plaform <br/>（销售，购买和拍卖NFT卡和物品）",
  phase5_sector_0_item_3: "BCL 在 MEXC 加密货币交易所上市",
  phase5_sector_0_item_4: "第三次燃烧",
  phase5_sector_0_item0:
    '"Make to Earn"项目中的 NFT 卡白名单（Buy & Burn only BCL）',
  phase5_sector_0_item1: "KYC 认证开始",
  phase5_sector_0_item2: "BCL结算和提现开始",

  phases5_sector_0_date: "Q1.-Q2. 2023",
  phases5_sector_0_item_0: "BCL结算和提款开始",
  phases5_sector_0_item_1: "增加了NFTRPG游戏的登录系统（超级战士和黑暗战士）。",
  phases5_sector_0_item_2: "游戏奖励网站发布",
  phases5_sector_0_item_3: "NFT租赁服务开始（包括稀有度的提高）。",
  phases5_sector_0_item_4: "更新了BCL应用程序的Web 3.0功能。(包括网页浏览器、聊天和NFT游戏连接）。",
  phases5_sector_0_item_5: "第3个Roguelike游戏重新发布",
  phases5_sector_0_item_6: "7。采矿标准修订",
  phases5_sector_0_item_7: "8。 NFT项目宣传片开播",

  phases5_sector_0_date_2: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_2: "增加了英雄NFT铸币网站的拍卖功能。",
  phases5_sector_0_item_1_2: "显示BCL应用程序的NFT卡",
  phases5_sector_0_item_2_2:
    "第4届NFT英雄卡牌游戏发布（多人游戏）。",
  phases5_sector_0_item_3_2: "第5款MMORG游戏发布",

  phases5_sector_0_date_3: "十二月。 2022",
  phases5_sector_0_item_0_3: "1。第 6 次英雄 NFT 卡升级",
  phases5_sector_0_item_1_3: "2。 3D 分层 MaxForm City 午餐并启动 NFT 白名单",
  phases5_sector_0_item_2_3: "3。 Maxform英雄卡的发展历程分享》",
  phases5_sector_0_item_3_3: "4。 Maxform城市战争游戏开发开始（4个月）",
  phases5_sector_0_item_4_3: "5。 PlaytoEarn 的原型游戏发布",

  phases5_sector_0_date_4: "第一季度。 2023",
  phases5_sector_0_item_0_4: "1。在 YouTube 上打开的第 6 张英雄 NFT 卡",
  phases5_sector_0_item_1_4: "2。 MaxForm City NFT 已售出",
  phases5_sector_0_item_2_4: "3。 MaxForm Hero纸牌游戏发布",
  phases5_sector_0_item_3_4: "4。 MaxForm City战争游戏的发展历程分享",
  phases5_sector_0_item_4_4: "5。 MaxForm 世界（元界）的开发（9 个月）",
  phases5_sector_0_item_5_4: "∙ 使用自制工作室开发英雄 NFT 卡",
  phases5_sector_0_item_6_4:
    "∙ 构思和开发 NFT 平台（销售、购买和拍卖 NFT 卡和物品）",
  phases5_sector_0_item_7_4: "∙ 自己创建的头像经济活动平台",

  phases5_sector_0_date_5: "第二季度。 2023",
  phases5_sector_0_item_0_5: "1。 第六英雄NFT卡销售（最佳NFT购买者的利润奖励）",
  phases5_sector_0_item_1_5: "2。 MaxForm Hero纸牌游戏已激活",
  phases5_sector_0_item_2_5: "3。 MaxForm城市战争游戏发布",
  phases5_sector_0_item_3_5: "4。 MaxForm世界共享的发展历程",

  phases6: "阶段6",
  phases6_sector_0_date_5: "2024",
  phases6_sector_0_item_0_5: "开始开发MaxForm世界（Metaverse）。",
  phases6_sector_0_item_1_5: "使用自制工作室开发英雄NFT卡（使用AI功能）。",
  phases6_sector_0_item_2_5: "自己创建的阿凡达经济活动平台",
  phases6_sector_0_item_3_5: "第6个英雄卡牌游戏TCG发布",

  phases7: "阶段7",
  phases7_sector_0_date_5: "2025",
  phases7_sector_0_item_0_5: "开启MaxForm世界（Metaverse）。",
  phases7_sector_0_item_1_5: "签署了更多MaxForm世界的伙伴关系",
  phases7_sector_0_item_2_5: "更多的BCL生态系统得到了推广",
  phases7_sector_0_item_3_5: "开发BCL主网",

  phase6: "Phase6",
  phase6_sector_0_date: "Q4. 2022",
  phase6_sector_0_item_0: 'NFT平台上线"Make to Earn"项目',
  phase6_sector_0_item_1: "使用 NFT 卡开发元界游戏",
  phase6_sector_0_item_2: "CMC Lv.20下在各大交易所上市的BCL",
  phase6_sector_0_item_3: "第四次燃烧",

  phase7: "Phase7",
  phase7_sector_0_date: "2023",
  phase7_sector_0_item_0:
    '"Play to Earn"项目中经过 Beta 测试的 NFT Avatar 元界游戏<br/>• 1:1 和 PVP 游戏（阿凡达游戏）<br/>• 虚拟房地产的出租和销售',
  phase7_sector_0_item_1: "自己打造的头像经济活动平台",
  phase7_sector_0_item_2: "BCL 在主要交易所上市 CMC Lv.10 & Lv. 5",
  phase7_sector_0_item_3: "第五次燃烧",

  download_now: "Download now",
  download_now_desc: "下载安卓、iOS 或 APK 文件应用程序并立即开始挖矿。",
  referring_hint: "通过推荐其他人赚取 25% 的额外收入",
  join_us_hint:
    "加入我们的在线频道以留下来<br/>" + "与 BitcoinLegend 社区保持同步",
  license: "© 2021 BitcoinLegend. All rights reserved",

  nft_about_title: "关于英雄 NFT 卡和元界",
  nft_about_desc:
    "<p>1992 年，尼尔·斯蒂芬森 (Neal Stephenson) 的《雪崩》(Snow Crash) 中首次提到了元宇宙。<br/>" +
    "元界是第二人生平台，是一个随时随地与他人心灵相通的世界。<br/>" +
    "元宇宙是现实与各种世界相结合的状态，比如宇宙1、宇宙2、宇宙3，<br/>" +
    "它处于一种伪现实的状态，以至于我们不知道哪个是元宇宙中真正的我，过着与真正的我不同的生活。<p/>" +
    "<p>阿凡达可以在一个超然的空间中相遇和交流，并使用 AR 和 VR 功能匿名从事经济活动。<br/>" +
    "化身在超凡的空间相遇是一种创新，它将以时空观念的崩溃形式引领世界未来20年的创新。</p>" +
    "<p>每个人都有远见和梦想。<br/>" +
    "但是，他们中的大多数人无法将其变为现实并牢记在心。<br/>" +
    "特别是努力解决经济贫困和对现实的不满。<br/>" +
    "但是，这些努力的成果在现实中很难取得。</p>" +
    "<p>比特币传奇的诞生是为了实现人们的理想。</p>" +
    "<p>用户制作自己的 NFT 头像，制作 NFT 物品，通过买卖赚取利润。<br/>" +
    "BCL Core Team 使用 AI 技术创作出和真我相似的网络漫画人物。<br/>" +
    "此时应用的技术是自制工作室中的人脸地标引擎和人脸地标触发器。<br/>" +
    "使用这项技术，用户可以直接创建一个与真我相似的头像。可以改变发型、脸型，甚至是眼睛的颜色。<br/>" +
    "此外，您可以制作各种服装、饰品和游戏道具。此外，您还可以购买自己制作的物品。<br/>" +
    "这些物品可以通过使用 NFT 制造技术加密来进行拍卖。</p>",
  maxform_desc:
    "<p>MaxForm Studio是一个以用户为中心的自制系统。<br/>" +
    "用户拍照后换脸，戴口罩恢复匿名。<br/>" +
    "它还装饰有用户生成内容(UGC)。<br/>" +
    "Maxform 是由 Hero NFT Card 创建的虚拟世界空间中化身活跃的空间。</p>",
  shape_made_tech: "形状制造技术",
  shape_made_tech_desc:
    "<p>当你用智能手机拍摄时，Facial landmark Engine 和 Trigger 根据 AI 人脸识别功能工作。<br/>" +
    "人脸是网漫形象塑造的，部分用户有不同的匿名倾向。<br/>" +
    "当你想换脸的时候，可以改变脸的颜色、发色、发型、眉毛等。<br/>",
  shape_made_system: "用户自制系统",
  shape_made_system_desc:
    "<p>所有的服装和配饰都可以在服装制作平台上自行设计制作。<br/>" +
    "另外，你可以选择你想要保持优雅的面具类型。<br/>" +
    "这个可以在 NFT 市场买卖，在 Max Form 元界游戏中，<br/>" +
    "1:1格斗游戏中获得的饰品和服饰会更出名，价格也会上涨。<br/>" +
    "而制作它的设计师获得的荣誉越来越多。他的奖品自然会涨价。</p>",
  hero_nft_card: "英雄 NFT 卡片",
  hero_nft_card_desc:
    "<p>BitcoinLegend 不只是一个挖矿应用，而是将成为 NFT 卡业务和元界游戏的 Top Tier。<br/>" +
    "BCL 网络打算通过建立 3 Earn 战略来开拓加密货币生态系统。<br/>" +
    '这是 3 种 Earn 策略中的"Make to Earn"策略。Hero NFT Card 将英雄的生命放在卡片上。<br/>' +
    "Hero NFT卡牌英雄出现在元界游戏中。卡牌有英雄故事和物品库存。<br/>" +
    "英雄故事让用户讲述自己的故事。物品栏可以存放技能、装备和材料。<br/>" +
    "所有这些物品都可以购买、出售和拍卖。</p>",
  media_partners: "媒体与合作伙伴",
  nft_modal_content:
    "<p>☑️4x高速挖矿活动(所有用户)<br/><br />" +
    "☑️1NFT = 1,000BCL/45Matic/0.022 ETH</p>",
  go_hero_nft_card: "购买英雄 NFT 卡",
  go_hero_nft_close: "关闭窗口",
  bep_title: "从 ERC20 迁移到 BEP20",
  bep_title1: "迁移目的",
  bep_desc1: "减少提现和铸造 NFT 过程中产生的 gas 费用",
  bep_title2: "迁移时的掉期比率",
  bep_desc2:
    "1.互换是针对以前购买过BCL的用户进行的<br />，并进行了额外的付款，以减少存款时产生的汽油费用。 <br /> bcl开采在KYC之后付款时以BEP20的价格支付，因此不需要交换。 <br /> 2.交换LBANK加密货币交换以换取BCL流动性的交易活动时增加额外付款率",
  bep_title3: "Lbank 加密货币",
  bep_desc3: "ERC20 : BEP20 = 1 : 1.1 (10% 额外付款)",
  bep_title4: "如何进行迁移",
  bep_desc4:
    "1. Lbank 加密货币兑换 <br />" +
    "       你没有什么可做的。BCL 在 Lbandk 交易所内被交换并存入一个新的钱包。<br />\n" +
    "       2. BCL 网站(这里) <br />" +
    "       使用您之前存入的个人钱包，例如 Metamask、Trust Wallet 或 Im Wallet。 <br />" +
    "       将基于 ERC20 的 BCL 发送到 BCL 基金会的地址如下。我们的系统会立即将基于 BEP20 的 BCL 代币发送到您的钱包。",
  bep_desc41: "3.可以迁移 的个人Cryto-Wallet <br />",
  bep_desc42: " Metamask ( 如何获得BEP20地址 ) ",
  bep_desc43: " Trust wallet ( 如何获得BEP20地址 )  ",
  bel_desc44: "Etherscan:使用基于ERC20的BCL检查从钱包转移到BCL基金会",

  bep_title5: "迁移申请",
  bep_desc5: "申请迁移前请准备好复制ERC20钱包地址和BEP20钱包地址。",
  bep_button_title: "请在6月29日之前在Lbank交换",
  bep_submit: "提交",
  bep_exit: "出口",
  bep_popup_desc1:
    '【迁移须知】<br /> 1.请确认您的钱包地址正确后再点击"提交"按钮<br /> 2.钱包地址错误或遗漏我们概不负责<br /> 3.交易大约需要3-5分钟。',
  bep_popup_title1: "迁移申请",
  bep_popup_agree: "同意以上通知",
  bep_input_email: "输入电子邮件地址",
  bep_input_erc: "输入 ERC20 地址",
  bep_input_ber: "输入 BEP20 地址",
  bep_input_amount: "输入金额",
  bep_check_box: "请点击复选框",
  bep_request_complete: "请求已完成。",
  bep_request_check: "请检查项目",
  bep_title8:
    "<font color='#aaaaaa'>BCL基金会充值地址 :</font> <font color='#34D5FF'>0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  bep_title9: "在哪里迁移",
  bep_title10:
    "1. Lbank Crytocurrency Exchange <br/>禁止在预先通知日期的前一天。在Lbank中交换3天。将进行新的存款，交易和提款。<br/> 2。 BCL网站（此处）<br/>对于无法参加LBANK加密货币交易所交换的用户，BCL网站执行了自己的迁移。",
  bep_title11:
    "☑迁移时交换比率 <br />1. Lbank Crytocurrency Exchange <br />ERC20:BEP20 = 1:1:1（UTIL 6月29日2pm UTC+8） <br />2.比特币根网站（从7月1日起）<br />ERC20:BEP20 = 1:1.03",
  // bep_title12: "<font color='yellow'>Lbank crytocurrency exchange</font><br />ERC20:BEP20 = 1:1.1（额外付款10％）",
  // bep_title13: "<font color='yellow'>比特币网站</font><br />ERC20:BEP20 = 1:1.1（额外付款3％）",
  levelup_title1: "该计划是为希望以最低成本提高采矿速度的用户开发的。",
  levelup_desc1:
    "升级程序旨在提高设定一段时间的采矿速度。 <br /> 1。申请和付款后，将立即应用。 <br /> 2。申请期是计时器。 <br /> 3。计时器到期后，恢复了与半衰期相对应的采矿速度。",
  levelup_title2: "当下",
  levelup_title3: "升级程序",
  levelup_popup_title1: "升级的申请表",
  levelup_popup_desc1:
    "[升级通知] <br /> 1. Google Play商店Apple App Store，安装Bitcin Legend Mining <br /> 应用程序为APK文件，请确保输入注册电子邮件地址。 <br /> 2.请参阅上面的手册以获取如何检查您的电子邮件地址。 <br /> 3.升级程序在应用程序<br />之后立即开始 4.升级程序的运行时间是注册后30天。",
  levelup_popup_confirm: "确认",
  bep_button_title2: "检查您的迁移",
  levelup_title4: "如何检查您的电子邮件地址",
  levelup_title5:
    "1.触摸比特币挖掘应用程序中的设置图标。<br />2.检查注册的BCL电子邮件并输入与显示的相同的。",

  nft_about_title2: "关于Maxform Hero Card和Metaverse",
  nft_about_desc2:
    '<p>该元评估在1992年首次在尼尔·斯蒂芬森（Neal Stephenson）的 "降雪崩溃"中提到。<br/>' +
    "Metaverse是一个第二寿命平台，您可以随时随地与他人的思想联系。<br/>" +
    "metaverse是一种结合现实和各个世界的状态，例如Universe 1，Universe 2和Universe 3，<br/>" +
    "这正处于伪现实状态，以至于我们不知道哪一个是Metaverse中的真实我，他过着与真实的我不同的生活。<p/>" +
    "<p>阿凡达（Avatar）可以在超越的空间中见面并进行交流，并使用匿名的AR和VR功能从事经济活动。<br/>" +
    "在超越空间中以化身的身份见面是一项创新。它将以时间和空间的概念崩溃的形式领导未来20年的世界创新</p>" +
    "<p>每个人都有一个愿景和梦想。<br/>" +
    "但是，他们中的大多数人都无法实现现实并将其留在他们的脑海中。<br/> <br/>" +
    "尤其是为了解决经济贫穷和对现实的不满。<br/> " +
    "但是，这些努力的结果在现实中很难实现。</p>" +
    " <p>比特币传说是为了实现人们的理想。</p>" +
    " <p>用户制作自己的NFT化身，制作NFT物品并购买和出售以获利。<br/>" +
    " BCL核心团队使用AI技术来创建类似于真实ME的WebToon字符。<br/>" +
    "此时应用的技术是自我制作工作室中的面部标志性引擎和面部地标扳机。<br/> <br/> " +
    "使用这项技术，用户可以直接创建类似于真实ME的化身。可以更改发型，脸型甚至眼睛的颜色。<br/> <br/>" +
    "此外，您可以生产各种服装，装饰品和游戏物品。此外，您可以购买您制作的它们。<br/> <br/>" +
    "可以通过使用NFT制造技术对它们进行加密来拍卖这些项目。</p>",
  hero_nft_card2: "Game-Fi英雄卡",
  hero_nft_card3: "Maxform Hero NFT卡的白名单",
  hero_nft_card3_desc:
    "普通人可以成为英雄。 <br />不仅好英雄成为英雄，<br />，即使恶棍可以在比赛中获胜，他们也会成为英雄。<br />挑战自己。",
  hero_nft_card4: "Maxform Hero卡的白名单",
  hero_nft_card_title1: "什么是nft？",
  hero_nft_card_desc1:
    "一个无牙的令牌（NFT）是一种虚拟令牌，它使用区块链技术证明数字资产的所有者。 <br />" +
    "指向数字文件（例如图片和视频）的地址存储在令牌中，并用于指示其独特的独创性和所有权。<br />" +
    "也就是说，某种虚拟证书的真实性。<br />" +
    "英雄NFT卡的设计概念不仅是特定角色可以成为英雄，而且普通百姓可以成为英雄。",
  hero_nft_card_title2: "Maxform Hero卡的特征是什么？",
  hero_nft_card_desc2:
    "Maxform Hero Card将通过多边形链铸造，任何人都可以在Opensea购买和出售。<br /> +" +
    "它是通过精确制造技术用2130 x 2800像素创建的。<br /> " +
    "白名单不仅是从买家的角度购买NFT，而且要以折扣价购买NFT。您可以获得其他服务。<br /> <br />" +
    " BCL基金会为那些通过白名单购买英雄NFT卡以提高其采矿速度的人提供了升级的好处。<br />" +
    "那些直接从Opensea或Binance NFT购买的人没有这种好处。",
  hero_nft_card_title3: "The Price and payment method for whitelist",
  hero_nft_card_desc3:
    "☑️ 第一个白名单（1,500 NFT）<br />" +
    "价格:1,000BCL 2.付款方式:仅限以BEP20为基础的BCL<br />\n" +
    "☑️第二次白名单（4,500 NFT）<br />\n" +
    "价格:0.05ETH 2.付款方式:BCL、ETH、Matic（多边形）或其他付款方式<br />\n" +
    "☑️ 第三白名单（2,000 NFT）<br />\n" +
    "价格:0.05ETH 2.付款方式:BCL、ETH、Matic（多边形）或其他付款方式<br />\n" +
    "☑️ 开始荷兰拍卖(1,000件非关税商品)<br />" +
    "在公海买卖<br />",
  hero_nft_card_title4: "白色列表的时间表",
  hero_nft_card_desc4:
    "☑️ 第一个白名单（1,500 NFT）<br />" +
    "1. BCL网站启动第一期白名单:8月17日-8月31日<br />" +
    "2. 存款截止日期:8月17日-8月31日<br />" +
    "3. BCL网站新增铸币站点:8月16日-8月19日<br />" +
    "4. OpenSea 以礼盒形式发布频道:9 月 5 日<br />" +
    "5. 随机英雄卡揭晓:9月7日<br />" +
    "☑️第二个白名单(4,500 NFT)<br />" +
    "1. BCL网站启动第二期白名单:9月14日-9月23日<br />" +
    "2. 存款截止日期:9月14日-9月23日<br />" +
    "3. OpenSea以礼盒形式发布频道:9月27日<br />" +
    "4. 随机英雄卡揭晓:9月29日<br />" +
    "☑️第三个白名单(2,000个NFT)<br />" +
    "1. BCL网站启动第三期白名单:10月4日-10月14日<br />" +
    "2. 存款截止日期(BCL&其他支付):10月4日-10月14日<br />" +
    "3. OpenSea以礼盒形式发布频道:10月18日<br />" +
    "4. 随机英雄卡揭晓:10月20日<br />" +
    "☑️开始荷兰式拍卖（1,000 NFT）:10 月 26 日",
  hero_nft_card_title5: "如何申请白名单",
  hero_nft_card_desc5:
    "1. 从 Lbank 或 Pancake Swap 购买 BCL。<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://www.lbank.info');\"'> LBank</div> <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://pancakeswap.finance');\">PancakeSwap</div><br /> " +
    "2. 将基于 BEP20 的 BCL 存入您的个人加密钱包（Metamask 和 Trustwallet）。<br />" +
    "* 如何获得地址BEP20和多边形(matic) <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</ div> <br />" +
    "3. 将基于 BEP20 的 BCL 发送到 BCL 基金会地址如下:",
  hero_nft_card_desc6:
    "<font color='#aaaaaa'>BCL  Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  hero_nft_card_desc7: "BCL基金会的支票存款",
  hero_nft_card_desc8:
    "4.输入您的BCL应用订阅电子邮件地址，并在下面的窗口中发送BCL钱包地址。<br />" +
    "*要参加采矿速度活动，请确保输入您的BCL订阅应用电子邮件地址。",
  hero_nft_card_desc9: "适用白名单的申请表",
  hero_nft_card_desc10_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>检查您在BCL应用程序上订阅的电子邮件</span> ',
  hero_nft_card_desc10_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>输入您的电子邮件地址并发送BEP20 <br />基于BCL地址和金额<br /><font color='#FFD500'>*1NFT =1,000BCL，余额不予退还，<br />所以请输入1000 单位。 <br />每个买家购买的 NFT 数量没有限制</font></span> ",
  hero_nft_card_desc10_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>NFT收卡地址自动<br />注册为充值账户的Polygon（MATIC）地址。</span> ',
  hero_nft_card_desc11:
    "5.检查购买英雄NFT的白色列表<br />" +
    "*在第1个白色列表中购买NFT之后，BCL核心团队将在此处发布买家列表。 <br />" +
    "在摆姿势清单之后，买家将获得采矿速度和Maxform Hero Card的信息。 ",
  bep_input_nft_email: "输入电子邮件地址",
  bep_input_bep_address: "输入BCL地址",
  bep_input_bep_amount: "输入BCL金额",
  bep_input_bep_from: "必须连接“钱包”。",
  bep_input_polygon_address: "输入多边形地址",
  bep_input_bep_amount2: "请输入超过 3900 的数字",
  not_match_bep: "检查您存入的 MATIC 地址和 MATIC 的数量。",
  not_match_eth: "检查您存入的 ETH 地址和 ETH 金额。",
  not_match_pol: "检查您存入的 MATIC 地址和 MATIC 的数量。",
  hero_nft_card_desc51:
    "*如果您在 Lbank 存入 BCL，您将无法收到 NFT 卡。 <br />" +
    "请务必在您的个人加密货币钱包中进行操作<br />" +
    "*1NFT = 1,000BCL，余额不可退，<br />" +
    " 所以请输入 1000 个单位。<br />" +
    "每个买家购买的 NFT 数量没有限制",
  nft_request_complete: "已经处理",

  hero_nft_card_desc12:
    "1. 将 Ethereum(ETH) 或 Polygon(MATIC) 发送到 BCL 基金会地址如下:<br />" +
    "* 如何获取地址 Polygon (matic)<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />",
  hero_nft_card_desc52:
    "*1NFT = 0.05 ETH, 1 NFT = 40 MATIC <br />" +
    "余额不可退还 <br />" +
    "每个买家购买的 NFT 数量没有限制",
  hero_nft_card_desc61:
    "<font color='#aaaaaa'>Polygon(Matic) Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   /><br />",
  hero_nft_card_desc71: "检查 ETH 存款",
  hero_nft_card_desc72: "MATIC的支票存款",
  hero_nft_card_desc81:
    "2. 在下面的窗口中输入您的 BCL 应用订阅电子邮件地址和发送 BCL 钱包地址。 <br />" +
    " *要参与挖矿提速活动，请务必输入您的 BCL 订阅应用电子邮件地址。",
  hero_nft_card_desc91: "ETH/MATIC form for applying White List",
  hero_nft_card_desc11_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>检查您在 BCL 应用程序上订阅的电子邮件</span> ',
  hero_nft_card_desc11_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>输入您的电子邮件地址并发送 ETH 或 MATIC <br />地址和金额 <br /><font color='#FFD500'>*1NFT = 0.05 ETH, 1NFT = 40 MATIC, <br />" +
    "余额不可退还。<br />" +
    "每个买家购买的 NFT 数量没有限制</font></span> ",
  hero_nft_card_desc11_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>输入收到 NFT 的基于 Polygon 的地址。 </span> ',
  hero_nft_card_desc82:
    "在第一次白名单期间购买 NFT 后，BCL 核心团队将在此处发布买家名单。  <br />" +
    "提交清单后，买家将获得挖矿速度和Maxfor英雄卡信息的优势。",

  nft_event_string1:
    "🟢 Maxform NFT英雄卡正在铸造，由Bitcoinlegend Core团队制作 🟢<br />" +
    "                        比特币传奇的英雄卡概念是，任何人通常都可以成为英雄。<br />" +
    "一半是普通服装，一半是英雄服装。<br />" +
    "                        稀有计划使它更加独特。",
  nft_event_string2: "🎉免费提供300张英雄NFT卡。🎉",
  nft_event_string3:
    " ☑️ 第一个白名单价格:每个 NFT 𝟬.022 ETH/45 MATIC/1,000 BCL <br />" +
    "                        ☑️ 2~3白名单价格:𝟬.05 ETH/105 MATIC/2,200 BCL/NFT",
  nft_event_string4:
    " ☑️ 更多活动(util聚集300万用户)<br /><br />" +
    "                        ▶️ 4X高速挖矿:6→24BCL/天<br />" +
    "                        （NFT 买家 +@24 BCL/天，为期 1 个月）<br /><br />" +
    "                         ▶️ 推荐红利:25→50 BCL/用户",
  nft_event_string5: "感谢参与活动！",
  nft_event_string6: "🏷️ 销售信息",
  go_hero_nft_event: "英雄 NFT 卡片活动",
  bep_request_check2: "未找到匹配的帐户信息。",
  nft_event_qa:
    "如果缺少父内容，请发送 <a href='https://forms.gle/SxLB81u6t5hBzUiV6' target='_blank'><span style='text-decoration: underline;font-weight: bold' 来执行此操作， 请>点击</span></a>这里。",
  hero_nft_card_title11: "[特别促销]",
  hero_nft_card_desc11_desc:
    "☑️排名赛(实时更新)<br />" +
    "●第一名<br />" +
    "1. 第六次英雄晋级:《你就是英雄》<br />" +
    "(你的照片&故事→英雄制作+网络漫画+说唱制作)<br />" +
    "第一英雄:<a href='https://youtu.be/s0FSVXZ-wSs' target='_blank'>https://youtu.be/s0FSVXZ-wSs</a> <br />" +
    "第二英雄:<a href='https://youtu.be/8Al9l7YVLC4' target='_blank'>https://youtu.be/8Al9l7YVLC4</a> <br />" +
    "第 3 - 5 <a href='https://youtu.be/gfjBqRpmh6U' target='_blank'>位英雄:https://youtu.be/gfjBqRpmh6U</a> <br />" +
    "<br />" +
    "2. 1,000NFT限量版（第六英雄）26件<br />" +
    "1,000NFT*0.1ETH(白名单价格)=100ETH <br />" +
    "销售额的5%奖励(5ETH)<br />" +
    "●第2～第3名<br />" +
    "第 2 名）5 个额外 NFT + 6 位英雄 2% 奖励（2ETH）<br />" +
    "第 3 名）3 个额外的 NFT + 6 位英雄的 1% 奖励（1ETH）<br />" +
    "●第4-10位<br />" +
    "2 个额外的 NFT <br />" +
    "*额外支付的 NFT 将使用当前出售的 NFT 支付。<br />" +
    "<br />" +
    "☑️会员推广<br />" +
    "●白金(购买21NFT或更多)<br />" +
    "挖矿提速(+24BCL/天)，KYC优先，NFT+1ea/20ea <br />" +
    "●黄金(买11~20 NFT) <br />" +
    "挖矿提速（+24 BCL/天），KYC 优先，NFT+1 <br />" +
    "●银(买5-10 NFT) <br />" +
    "迷你加速（+24 BCL/天），KYC 优先 <br />" +
    "●青铜(买1~4NFT)<br />" +
    "迷你加速（24BCL/天）",
  kyc_certification: "KYC 认证",
  vision_of_bitcoinLegend: "BitcoinLegend 愿景",
  kyc_desc1:
    "比特币传奇是目前上市的挖矿类加密货币。<br />" +
    "这是为了让BCL代币作为Maxform生态系统的运营货币发挥实际作用。<br />" +
    "Maxform 生态系统发行英雄 NFT 卡牌并使用它们玩纸牌游戏。<br />" +
    "另外，主人公居住的Maxform City是在3D世界中实现的，<br />" +
    "将居住城市做成NFT。<br />" +
    "这座城市也像城战一样以游戏的形式实现。<br />" +
    "如果人和环境都是NFT造的，<br />" +
    "BCL 基金会将创建一个名为 Maxform 的元宇宙世界。<br />" +
    "英雄们在咖啡店或健康培训中心见面聊天和玩游戏。<br />" +
    "在这个生态系统中使用的货币手段是 BCL。<br />" +
    "这就是比特币传奇的愿景和目标。",
  kyc_title2: "KYC 认证的目的",
  kyc_desc2:
    "BCL 基金会继续进行 KYC 验证以赋予 BCL 可信度<br />" +
    "所有用户通过云服务器挖矿。<br />" +
    "这是一个人用一部手机挖矿并证明的过程<br />" +
    "他们是真实的人。<br />" +
    "我们希望通过创建虚假账户来防止不公平地获利。",
  kyc_title3: "遵循 KYC 流程",
  bep_input_name: "输入名称",
  bep_input_number: "输入数字",
  bep_input_address: "输入地址",
  kyc_popup_title1: "[KYC 认证的年龄限制]",
  kyc_popup_desc1:
    "为了遵守有关儿童和非成人数据收集的国际法律，KYC 认证仅限于 18 岁以上的人。",
  kyc_popup_desc2:
    "对于 18 岁以下的人，BCL 基金会计划建立并实施一个单独的系统，并征得家长同意。",
  kyc_popup_desc3: "[应用KYC时的注意事项]",
  kyc_popup_desc4:
    "1. 如果您未满 18 岁也申请，则会出现错误，只有 KYC 费用不予退还。",
  kyc_popup_desc5:
    "2. 真实姓名必须与国民身份证、护照、驾照或选民身份证上的姓名一致。",
  kyc_popup_desc6: "3. 邮箱应该是你注册比特币传奇时的邮箱。",
  kyc_popup_desc7: "4. 手机号应该是你安装比特币传奇app的手机号",
  kyc_popup_desc8: "真实 姓名",
  kyc_popup_desc9: "电子邮件 用于 BCL 应用程序",
  kyc_popup_desc10: "手机 电话号码",
  kyc_popup_desc10_1: "Transaction Hash",
  kyc_popup_title2: "[KYC 准备]",
  kyc_popup_desc11: "1. 文件（国民身份证、驾照、护照或选民身份证）未过期。",
  kyc_popup_desc12:
    "如果您对过期文件进行 KYC，则会发生错误。因此，KYC 费用不予退还。",
  kyc_popup_desc13: "2. 我们可能会使用您文档的信息。",
  kyc_popup_desc14: "3. 您的地址、手机号码和国家信息将用于 KYC 验证。",
  kyc_popup_desc15:
    "4. 此信息仅用于KYC验证，用于判断您是否真正按照Bitcoin Legend的政策使用该应用程序。",
  kyc_popup_desc16: "您同意相应的使用权。",
  kyc_popup_agree: "同意",
  kyc_popup_reject: "拒绝",
  kyc_popup_kyc: "去 KYC",
  kyc_popup_title3: "[KYC 认证]",
  kyc_popup_desc17:
    "一旦你的手机上安装了未过期的文件（国民身份证、驾照、护照或选民身份证）和应用程序，让我们从 KYC 验证开始吧。",
  kyc_popup_desc18: "KYC 验证需要 6 美元的费用。",
  kyc_popup_desc19: "您要继续进行 KYC 验证吗？",
  kyc_result1: "您已经是KYC认证用户。进入应用，根据KYC订单进行应用内支付。",
  kyc_result2: "没有匹配的信息。",
  kyc_popup_title4: "[KYC 验证结束]",
  kyc_popup_desc20: "感谢您的 KYC 验证。",
  kyc_popup_desc21: "如果KYC验证正常完成，你的pp主界面图标会发生如下变化。",
  kyc_popup_desc22: "如果不更改，则表示认证分数被判定为不合格。",
  kyc_popup_desc23: "这些将由 BCL 基金会再次手动验证。",
  kyc_popup_desc24: "非常感谢",
  kyc_popup_desc25: "KYC 验证证明",
  kyc_popup_pay: "支付",
  kyc_result5: "在 BitcoinLegend 应用程序中，转到您的个人资料并同意 KYC。",
  kyc_result6:
    "这是一个已经过 KYC 验证的电话号码。 转到应用程序并根据 KYC 订单在应用程序内付款。",
  kyc_result7: "KYC 正在进行中。",
  kyc_popup_desc26: "※ 请先从应用程序开始",
  kyc_popup_desc27:
    "5. 查询请在<a href='https://forms.gle/94ggLz5t1fUbh1Cq6' target='_blank' style='text-decoration: underline;'><B>这里</B></a>。",
  kyc_result8: "使用应用内支付功能（Ver 1.4.8）",
  kyc_1: "[暂停KYC验证的通知]",
  kyc_2:
    "由于对 Stripe 的强烈抗议，100% KYC 退款正在进行中。 <br /><br />我们被告知，根据国家/地区的不同，将延迟 10 到 15 天，具体取决于信用卡公司。 <br />如果您在此之后没有收到退款，请联系您的信用卡公司。 <br />USDT、TRX、应用内支付（谷歌）正在扩大KYC支付。",
  nft_1: "白名单特别促销",
  nft_2: "☑排名赛(实时更新)",
  nft_3: "●第一名",
  nft_4:
    "1. 第6英雄宣传:《你就是英雄》<br />（你的照片和故事→英雄制作+网络漫画+说唱制作）<br />第1英雄:https://youtu.be/s0FSVXZ-wSs <br /> br/>第二位英雄:https://youtu.be/8Al9l7YVLC4 <br />第三-第五位英雄:https://youtu.be/8Al9l7YVLC4",
  nft_5:
    "2. 1,000NFT 限量版(6th Hero) 26 件<br />1,000NFT*0.1ETH(白名单价格)=100ETH <br />5% 销售额奖励(5ETH) ",
  nft_6: "●第二至第三名",
  nft_7:
    "第二名）5 个额外 NFT + 6 位英雄 2% 奖励（2ETH）<br />第三位）3 个额外 NFT + 6 位英雄 1% 奖励（1ETH）",
  nft_8: "●第 4 -10 位",
  nft_9: "2 个额外的 NFT",
  nft_10: "*额外付费 NFT 将使用当前出售的 NFT 支付。",
  nft_11: "☑会员推广",
  nft_12: "●白金（购买 21NFT 或更多）",
  nft_13: "挖矿速度提升（+24BCL/天），KYC 优先，NFT+1ea/20ea",
  nft_14: "●黄金(购买 11~20 NFT)",
  nft_15: "挖矿速度提升（+24 BCL/天），KYC 优先，NFT+1",
  nft_16: "●白银(购买 5-10 NFT)",
  nft_17: "挖矿速度提升（+24 BCL/天），KYC 优先",
  nft_18: "●青铜(买1~4NFT)",
  nft_19: "挖矿速度提升（24BCL/天）",
  nft_20:
    "Maxform NFT英雄卡由BitcoinLegend Core团队开发。<br />" +
    "它由以太坊区块链 ERC-721 上的 UNIQUE DIGITAL COLLECTIBLES 组成。<br />" +
    "<br />" +
    "五位英雄用NFT卡重生，设计理念是任何普通人都可以成为英雄。<br />" +
    " 一半是普通装，一半是英雄装。<br />" +
    "<br />" +
    "稀有程序让这更加独特。发行的卡片没有相同的形式。头发，衣服，武器，配饰，面具，背景等物品物品都不同。<br />" +
    "其中不是一个角色，而是一共5个不同姿势的角色。<br />" +
    "每个这样的角色，发放2000张英雄卡。<br />" +
    "这种方法不同于其他 NFT 用一个字符创建 10,000 件。<br />" +
    "这样一来，一共出现了五个角色，每人2000个，一共制作了10000张卡片。<br />" +
    "您将拥有稀有而独特的物品。",
  kyc_result9: "这是一个已经检查过的 Tron 地址。 无需额外付款。",
  kyc_result10: "支票账户",
  kyc_result11: "检查您的电子邮件地址",
  kyc_result12: "付款，KYC验证完成",
  kyc_result13: "付款未完成，KYC 完成",
  kyc_result14: "付款不完整，KYC 不完整",
  kyc_result15: "付款完成，KYC 未完成。 转到应用程序并根据 KYC 顺序进行 KYC",
  kyc_result16:
    "5. 对于USDT，您需要将6$发送到基金会钱包。<font color='red'>（一定要发送到您的个人钱包）</font>",
  kyc_result17: "- USDT 地址：TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG",
  kyc_result18: "- 费用：USDT 6$",
  kyc_result19:
    "KYC 验证已完成，但存款详情尚未确认。 确认USDT和TRON的存款金额。",
  kyc_result20:
    "KYC 验证尚未完成。 存款详情尚未确认。 确认USDT和TRON的存款金额。",
  kyc_result21: "[通知]",
  kyc_result22:
    "由于KYC认证公司Passbase服务器出现问题，目前KYC认证暂停。<br /><br />我们正在与Passbase取得联系，待KYC服务器正常运行后会再次通知您。 <br /><br />支付KYC认证费用后，我们将采取行动，让未进行KYC的用户可以免费获得KYC认证。",
  kyc_result23:
    "[注意]\n使用UDST和Tron充值时，请正常充值不包括gas费 *6USDT *100TRX",
  kyc_result24: "【KYC验证及支付相关通知】",
  kyc_result25:
    "1. KYC 验证延迟<br />" +
    "当因为用户错误导致KYC验证未通过时，我们已经成立了单独的人工验证团队进行验证。（KYC验证完成后变为绿色图标；<br />" +
    " 对于继续在 KYC 支付相关的任务上给您造成困扰，我们深表歉意。请暂时停止 KYC 应用内支付。<br />" +
    "<br />" +
    "2.KYC支付错误及退款<br />" +
    "1) 我们很难1:1回复，因为我们正在处理条带支付过程中重复或不支付的全额退款，请等待退款处理完毕。<br />" +
    "3) 如因USDT和Tron支付不足导致两次支付，我们将在人工审核后回复。<br />" +
    "3) 由于我们的BCL核心团队遗漏了应用内确认编码，导致应用内退款。目前应用内确认编码正在进行中，请在宣布新的更新版本之前停止支付。<br />" +
    "4) USDT 和 Tron 可以进行 KYC 支付。<br />" +
    "<br />" +
    "*上述案件需要大量人手调动，BCL基金会已经争取到人手，正在尽量处理，请稍等。<br />" +
    "* BCL结算和提现将在上述业务正常进行后进行。",
  kyc_result26: "这是已经过 KYC 验证的电子邮件或电话号码。",
  kyc_result27: "没有匹配的电话信息。",
  kyc_result28: "存款记录<br />图片",
  kyc_result29: "您的请求已完成。 请允许 1-2 个工作日来处理您的存款。",
  bep_input_block: "请输入方块",
  kyc_result30:
    "请写下您存入地址的区块。 <a href='https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers' target=\"_new\">GO</a>",
  kyc_result31: "只有在应用程序中显示“KYC 失败”图标的用户才能注册。",
  kyc_result32: "没有KYC结果",
  kyc3_1: "申请KYC时的注意事项",
  kyc3_2: "※ 在进行 KYC 之前更新到最新的应用程序。",
  kyc3_3: "你存款了，有问题吗？",
  kyc3_4: "请选择您存入的付款方式，并补齐文件的不足。",
  kyc3_5: "复制完成",
  kyc3_6: "请选择您存入的加密货币类型。",
  kyc3_7: "链接你的钱包",
  kyc3_8: "请输入您的电子邮箱",
  kyc3_9: "1. 从您的个人钱包充值到以下地址，并选择您充值的加密货币类型。",
  kyc3_10: "转到下一步",
  kyc3_11: "2.粘贴哈希值",
  kyc3_12: "将复制的哈希值粘贴到哪里",
  kyc3_13: "3. 输入在 Bitcoinlegend 应用程序中注册的电子邮件地址。",
  kyc3_14: "4. 已付款。",
  kyc3_15: "在 Bitcoinlegend 应用程序上继续进行 KYC 身份验证。",
  kyc3_16: "4.未存入。",
  kyc3_17: "请再次存入KYC费用。",
  kyc3_18: "打算存钱",
  kyc3_19: "存款，但有问题",
};
