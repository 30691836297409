import { AIRDRO_END_DAY } from "../config/const";
const moment = require("moment");

export default {
  app_name: "BitcoinLegend",
  app_name_short: "BCL",

  bcl: "BCL",
  days: "DAYS",
  hours: "HOURS",
  minutes: "MINUTES",
  seconds: "SECONDS",

  header_menu_list: [
    { code: "home", title: "Home" },
    {
      code: "nft",
      title: "Hero NFT Card",
      sub_header_menu_list: [
        { code: "/whitelist", title: "White List of NFT" },
        { code: "/card", title: "NFT Card" },
        { code: "/mint", title: "NFT Mint" },
        { code: "/gamefi", title: "Game-Fi Card" },
      ],
    },
    // { code: "about", title: "About" },
    { code: "airdrop", title: "Airdrop&Migration" },
    // { code: "levelup", title: "Level Up Program" },
    { code: "kyc", title: "KYC Certification" },
    // { code: "total_supply", title: "Total Supply & Distribution" },
    { code: "roadmap", title: "RoadMap" },
    // { code: "levelup", title: "LevelUp", }
  ],

  widely_known_desc:
    "BitcoinLegend는 널리 알려져 있습니다. 아래 검색창을 터치하세요.",

  earn_project_title: "3 Earn Project",
  earn_project_desc: "Mine to Earn, Make to Earn, Play to Earn",

  airdrop_ending_title: "AIRDROP Ending",
  airdrop_remain_count: "Airdrop Remaining Count",
  airdrop_first_desc: "1st AIRDROP End<br/>10M BCL",
  airdrop_second_desc: "2nd/3rd/4rd AIRDROP End<br/>15M BCL",
  airdrop_third_desc: "5th AIRDROP Running<br/>5M BCL",
  airdrop: "AIRDROP",
  pre_sale: "Pre-Sale",

  ntf_avatar_metaverse_game: "NFT 아바타 메타버스 게임",
  three_earn_strategy: "3 Earn  전략",
  mining_to_earn_desc:
    "채굴 & 반감기<br/>추천인(+25%)<br/>Token Economy<br/>Android, iOS app<br/>다국적 언어팩<br/>락업 및 스테이킹",
  make_to_earn_desc:
    "Sell & Buy NFTcard, Arts, Patent<br/>사용자 자체 제작시스템<br/>→BCL Studio<br/>NFT 마켓 경매",
  play_to_earn_desc:
    "1:1 결투/PVP 게임<br/>가상 부동산 거래<br/>격투장 입장료/광고료<br/>방송채널<br/>온라인 쇼핑",
  increase_strategy_title: "토큰 바이백, 소각, NFT 사업→가격 상승 유지 전략",
  increase_strategy_0:
    "ICO(600m BCL) : 사용자 50m → 10m, Buring 10B BCL (단계별)",
  increase_strategy_1: "IEO(CoinMarketCap : CMC Level)",
  increase_strategy_2: "CoinMarketCap",
  increase_strategy_3: "1차 상장 / 3월 15일. 2022. / 첫 상장",
  increase_strategy_4: "LBank",
  increase_strategy_5: "2차 상장 / 6월. 2022. / 팬케이크스왑",
  increase_strategy_6: "PancakeSwap",
  increase_strategy_7: "3차 상장 / 2022년 9월 / 레벨 20위권 상장",
  increase_strategy_7_1: "MEXC",
  increase_strategy_8: "4차 상장 / 2022년 4분기 / 레벨 20위권 상장",
  increase_strategy_9: "5차 상장 / 2023년 / 레벨 10위권 상장",
  increase_strategy_10: "6차 상장 / 2023년 / 레벨 5위권 상장",

  about_title: "About BitcoinLegend",
  about_desc:
    "<p>BitcoinLegend (BCL)는 ERC-20에 기반을 둔 암호화폐이다." +
    " BCL재단은  NFT 마켓 플랫폼(NFT카드,예술품)을 제작예정이다.<br/>" +
    "또한, BCL재단은 마블사의 어벤져스 영화에서 영감을 얻은 히어로 NFT카드 메타버스 게임을 런칭할 계획이다.</p>" +
    "<p>오늘날의 세계는 4차 산업혁명 시대이고 블록체인 기술이 그 안에서 중요한 역할을 할 것이라는 데는 의심의 여지가 없습니다.<br/>" +
    "사람들의 이상을 실현하기 위해 탄생한 BITCOIN LEGEND(BCL)는 4차 산업혁명 분야에서 블록체인 기술, 암호화폐, 메타버스 시스템을 활용하여 탈중앙화된 애플리케이션 생태계를 구축하는 플랫폼이 되는 것을 목표로 합니다.</p>" +
    "<p>ERC-20 기반 암호화폐인 BITCOIN LEGEND(BCL)는 마블 어벤져스에서 영감을 받은 히어로 NFT 카드를 사용한 향후 NFT 시장 플랫폼과 미래 메타버스 게임을 제공합니다.<p/>" +
    "<p>BITCOIN LEGEND에는 사용자에게 더 많은 토큰을 얻을 수 있는 3가지 방법을 제공하는 3번의 적립 전략이 있습니다.<p/>" +
    "<p>첫 번째는 스마트폰에서 마이닝 프로세스를 만들어 비트코인 ​​마이닝 문제를 해결하는 Mine to Earn<br/>" +
    "마이닝된 BCL이 주요 암호화폐 거래소에 상장되면 디지털 자산이자 투자 역할을 합니다.<p/>" +
    "<p>두 번째는 Make to Earn으로, 사용자는 자신의 NFT 아바타, NFT 아이템을 만든 다음 구매 및 판매하여 수익을 올릴 수 있습니다.<br/>" +
    "BITCOIN LEGEND는 AI 기술을 사용하여 실제 사용자와 유사한 웹툰 캐릭터를 만듭니다.<br/>" +
    "사용자는 의류, 장식품 및 기타 게임 아이템도 제작할 수 있습니다.<br/>" +
    "영웅의 역사는 웹툰과 송이가 만든다(웹툰+래퍼' 랩+NFT 애니메이션).<br/>" +
    "유튜브에 올린 지 10일 만에 30만 뷰를 기록했다.<p/>" +
    "<p>세 번째는 Play to Earn, BITCOIN LEGEND 네트워크는 이전에 생성된 NFT 아바타 카드와 가상 부동산을 사용하여 메타버스에서 경제 활동을 구현하며 사용자는 메타버스에서 게임을 하거나 가상 부동산에 투자할 수 있습니다. 이러한 활동을 통해 이익을 얻으십시오.<p/>",
  smart_contract: "스마트 계약(구 토큰:ERC20)",
  smart_contract1: "스마트 계약(신규 토큰 : BEP20)",
  smart_contract2: "소각(ERC20)",
  smart_url: "URL : ",
  smart_amount: "소각량 : ",

  token_symbol: "토큰심벌 : ",
  contract_address: "계약서 주소 : ",
  decimals: "소수점 : ",
  total_supply: "총공급량 : ",
  blockchain: "블록체인기반 : ",
  white_paper: "백서",

  airdrop_title: "Airdrop",
  airdrop_quantity: "에어드롭량 : ",
  airdrop_rules: "에어드롭 원칙",
  airdrop_rules_airdrop_desc:
    "커뮤니티 참가자는 BitcoinLegend 앱을 다운로드 받아서 설치함으로써 참가할 수 있다.<br/>" +
    "채굴은 에어드롭종결될때까지 적어도 진행하여야 한다. 최초 에어드롭량은 25 BCL이다.<br/>" +
    "에어드롭은 완전히 무료이다. 에어드롭 리워드는 추천인을 초대할 때 바로 지급된다.",
  invitation_rules: "초대 원칙",
  invitation_rules_desc:
    "사용자가 AirDrop에 참여하도록 친구를 초대함으로써 추가 BCL 토큰 보상을받을 수 있습니다. <br /> 초대하는 각 친구마다 25 개의 BCL 토큰을 얻을 수 있습니다. 최대 50 명의 친구를 초대하고 최대 1,250 BCl 토큰을 얻을 수 있습니다. <br /> Airdrop의 수량은 제한되어 있으며 BCL 발급은 적습니다.",
  invite_team: "Invite Team",
  invite_team_desc:
    "+ 25 BCL/사용자. Max. 50 사용자 <br/>+@25% 마이닝 초청 사용자",

  recommend_title: "친구를 추천하는 방법",
  recommend_code_right: "입력하신 추천 코드가 정확합니까?",
  recommend_input_code: "추천코드를 입력해주세요.",
  recommend_inputted_code: "추천코드",
  recommend_msg_copy: "여기를 터치하세요",
  recommend_code_non_exist: "잘못된 추천 코드입니다. 다시 확인해 주세요.",
  recommend_code_verify: "추천 코드를 입력하고 확인하십시오.",
  recommend_msg_part1:
    "BitcoinLegend는 마이닝 앱이 아니라 Marvel's Avengers에서 영감을 받은 Hero NFT 카드를 사용한 메타버스 게임입니다.<br/><br/>☆BitcoinLegend에 가입하여 10 BCL을 무료로 받으세요.<br/>추천 코드 : ",
  recommend_msg_part2: "<br/><br/>☆Official website<br/>",

  pre_sale_title: "Pre-sale",
  pre_sale_quantity: "사전판매량 : ",
  pre_sale_address: "사전판매 입금 주소 : ",
  pre_sale_price: "사전판매 가격 : ",
  pre_sale_price_desc: "최소구매는 0.1 ETH이고,  최대구매는 10 ETH이다. ",

  airdrop_rules_presale: "사전 판매 기준",
  airdrop_rules_pre_sale_desc:
    "사용자가 AirDrop에 참여하도록 친구를 초대함으로써 추가 BCL 토큰 보상을받을 수 있습니다. <br /> 초대하는 각 친구마다 50 개의 BCL 토큰을 얻을 수 있습니다. 최대 50 명의 친구를 초대하고 최대 2,500 BCl 토큰을 얻을 수 있습니다. <br /> Airdrop의 수량은 제한되어 있으며 BCL 발급은 적습니다. BCL의 추정 가격은 $ 0.75입니다. 서둘러 친구들과 공유하십시오.",
  how_to_do_pre_sale: "Pre-sale 참여방법",
  how_to_do_pre_sale_desc:
    "지갑을 사용하여 ETH를 사전 판매 주소로 보내십시오. 당사의 시스템은 BCL 토큰을 지갑으로 즉시 보낼 것입니다. <br /> KYC 검증이없는 개인 암호 화폐 지갑을 사용하여 송금을해야합니다. <br /> 개인 암호 화폐 지갑은 메타 마스크, 신뢰할레, MEW 지갑 및 imtoken 등이 포함됩니다.",
  pre_sale_rules: " Pre-sale 규칙",
  pre_sale_rules_desc:
    "Pre-sale은<h6><u> " +
    moment(AIRDRO_END_DAY).format("YYYY년 MM월 DD일 HH:mm(UTC+8)에 종결") +
    "</u></h6>된다.<br/>" +
    "여러분의 개인지갑에서  pre-sale주소로 ETH를 보내라, 전산시스템은 당신의 지갑에 보낸 ETH에 맞게 자동산출해서  BCL 토큰을 보낼 것이다. <br/>" +
    "Pre-sale BCL 물량은제한적이고, ETH 도착시간에 맞게 순차적으로 발급된다. 즉 먼저 들어오는 ETH에게 먼저 BCL이 지급된다.",

  once_the_pre_sale_over: "Pre-sale 종료되면",
  once_the_pre_sale_over_desc:
    "<h6><u>Pre-sale 금액의 60%는 NFT Market 개발비와 LBank & MEXC 상장비용으로 사용</u></h6>될 것이다.<br/>" +
    "나머지 40%는 상장될 때 BCL 토큰 바이백에 사용될 것이다. 이방법은 사용자와 투자자들의 덤핑으로 가격하락을 방지할 것이다.",

  check_pre_sale: "Check Pre-sale",
  go: "Go",
  check_pre_sale_hint:
    "No KYC, Send Immediately BCL to Pre-sale address after sending  ETH",

  total_supply_and_distribution: "Total supply & Distribution ",
  the_number_of_miner: "채굴하는 사용자수 : ",
  users: "Users",
  end_of_mining: "채굴종료시점 : ",
  end_of_mining_desc: "채굴은 1,000 만명 사용자가 도달하면 종료될 것이다.",
  total_supply1: "총공급량 : ",
  burning_and_buyback: "소각 & 바이백 : ",
  community_airdrop: "커뮤니티 에어드롭 : ",
  bcl_halving: "BitcoinLegend Halving",
  distribution_bcl_supply: "Distribution of BCL supply",

  roadmap: "Roadmap",
  roadmap_last_title: "현재까지 달성한 진행내역(Phase1 & Phase2)",
  phase_1_2_date: '020- Q2. 2021',
  roadmap_last_item_0: "프로젝트 개발팀(BCL 코어팀) 구축",
  roadmap_last_item_1:
    "모바일 앱 및 비트코인레전드 컨셉 개발<br/>(코인 어드밴티지, 채굴 방식, 토큰 이코노미, 채굴 앱 및 월렛 디자인)",
  roadmap_last_item_3: "백서 V1.0 제작",

  now: "Now",
  phase3: "Phase3",
  phase3_date: "Q3.-Q4. 2021.",
  phase3_item_0: "안드로이드/iOS 모바일 앱 출시",
  phase3_item_1: "공식 커뮤니티(텔레그램, 트위터, 유튜브) 오픈",
  phase3_item_2: "44개 국가 언어팩 확장 추가",
  phase3_item_3: "토큰 이코노미 업데이트(부리닝 포함)",
  phase3_item_4: "영웅 NFT 카드 디자인 완료",

  phase4: "Phase4",
  phase4_sector_0_date: "Q1.-Q2. 2022",
  phase4_sector_0_item_0: "공식 웹사이트 런칭",
  phase4_sector_0_item_1: "백서 V2.0 리뉴얼(3 Earn 전략 확장)",
  phase4_sector_0_item_2: "사용자 마이닝 모니터 및 핑 기능 개발",
  phase4_sector_0_item_3: "영웅 NFT 카드 웹툰 및 랩 유튜브 오픈",
  phase4_sector_0_item_4: "공식 디스코드 오픈",
  phase4_sector_0_item_5: "락업 & 스테이킹 펀딩 시스템 런칭",
  phase4_sector_0_item_6: "설비 이후 KYC 인증 확장 개발 시작",
  phase4_sector_0_item_7: "엘뱅크 및 팬케이크스왑에 BCL 상장",

  phase4_sector_1_date: "Q3.-Q4. 2022",
  phase4_sector_1_item_0: "ERC20에서 BEP20(바이낸스 스마트 체인)으로의 네트워크 마이그레이션",
  phase4_sector_1_item_1: "히어로 NFT 카드 화이트리스트 및 발행 시작",
  phase4_sector_1_item_2: "KYC 인증 시작",
  phase4_sector_1_item_3: "히어로 NFT 카드 웹 사이트 오픈",
  phase4_sector_1_item_4: "첫 번째 NFT RPG 게임 재출시(다크 워리어)",
  phase4_sector_1_item_5: "코인게코 및 코인마켓갭에 BCL 상장",

  phase4_sector_2_date: "June. 2022",
  phase4_sector_2_item_0:
    "ERC20에서 BEP20(바이낸스 스마트체인)으로의 네트워크 마이그레이션",
  phase4_sector_2_item_1: "레벨업 프로그램 런칭",
  phase4_sector_2_item_2: "PancakeSwap에 BCL 상장",
  phase4_sector_2_item_3: "MEXC 암호화폐 거래소 BCL 상장",
  phase4_sector_2_item_4: "2차 소각 및 바이백",

  phase5: "Phase5",
  phase5_sector_0_date: "Q3. 2022",
  phase5_sector_0_item_0: "Binance NFT 및 Opensea에서 히어로 NFT 카드 발행",
  phase5_sector_0_item_1: "암호화폐 지갑출시(NFT 카드 저장)",
  phase5_sector_0_item_2:
    "nft plaform <br/> 개념화 및 개발 (판매, 구매 및 경매 NFT 카드 및 항목)",
  phase5_sector_0_item_3: "MEXC 암호화폐 거래소에 BCL 상장",
  phase5_sector_0_item_4: "3차 소각",
  phase5_sector_0_item0:
    '"Make to 적립" 프로젝트의 NFT 카드 화이트리스트(BCL만 구매 및 소각)',
  phase5_sector_0_item1: "KYC 인증 시작됨",
  phase5_sector_0_item2: "BCL 정산 및 출금 시작",

  phases5_sector_0_date: "Q1.-Q2. 2023",
  phases5_sector_0_item_0: "BCL 정산 및 출금 시작",
  phases5_sector_0_item_1: "NFT RPG 게임 로그인 시스템 추가 (슈퍼파이터 & 다크워리어)",
  phases5_sector_0_item_2: "게임 보상 웹사이트 출시",
  phases5_sector_0_item_3: "NFT 대여 서비스 시작(레어 어드밴티지 포함)",
  phases5_sector_0_item_4: "BCL 앱의 웹 3.0 기능 업데이트 (웹 브라우저, 채팅 및 NFT 게임 연결 포함)",
  phases5_sector_0_item_5: "3번째 로그라이크 게임 재출시",
  phases5_sector_0_item_6: "7. 채굴기준 개정",
  phases5_sector_0_item_7: "8. NFT 프로젝트 홍보영상 오픈",

  phases5_sector_0_date_2: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_2: "영웅 NFT 채굴 웹사이트의 경매 기능 추가",
  phases5_sector_0_item_1_2: "BCL 앱의 NFT 카드 표시",
  phases5_sector_0_item_2_2:
    "4번째 NFT 영웅 카드 게임 출시(멀티플레이어 포함)",
  phases5_sector_0_item_3_2: '5번째 MMORG 게임 출시',

  phases5_sector_0_date_3: "12월. 2022",
  phases5_sector_0_item_0_3: "1. 6번째 영웅 NFT 카드 승격",
  phases5_sector_0_item_1_3:
    "2. 3D 계층 MaxForm City 점심 및 NFT 화이트리스트 시작",
  phases5_sector_0_item_2_3: "3. Maxform 히어로카드 개발 이력 공유",
  phases5_sector_0_item_3_3: "4. Maxform 도시 전쟁 게임 개발 시작(4개월) ",
  phases5_sector_0_item_4_3: "5. PlaytoEarn의 프로토타입 게임 출시",

  phases5_sector_0_date_4: "Q1.-Q2 2023",
  phases5_sector_0_item_0_4: "1. 6번째 히어로 NFT 카드 '유튜브' 공개",
  phases5_sector_0_item_1_4: "2. MaxForm City NFT 매각",
  phases5_sector_0_item_2_4: "3. 맥스폼 히어로 카드게임 출시",
  phases5_sector_0_item_3_4: "4. MaxForm City 워 게임의 개발 이력 공유",
  phases5_sector_0_item_4_4: "5. 맥스폼월드(메타버스) 개발(9개월)",
  phases5_sector_0_item_5_4:
    "∙ 자체 제작 스튜디오를 사용하여 영웅 NFT 카드 개발",
  phases5_sector_0_item_6_4:
    "∙ NFT 플랫폼 개념화 및 개발(NFT 카드 및 아이템 판매, 구매 및 경매) ",
  phases5_sector_0_item_7_4: "내가 만드는 아바타 경제활동 플랫폼",

  phases5_sector_0_date_5: "Q3.-Q4. 2023",
  phases5_sector_0_item_0_5:
    "1. 6번째 히어로 NFT 카드 판매(NFT 최고 구매자에게 혜택 보상)",
  phases5_sector_0_item_1_5: "2. MaxForm Hero 카드 게임 활성화",
  phases5_sector_0_item_2_5: "3. 맥스폼시티 워게임 '출시'",
  phases5_sector_0_item_3_5: "4. MaxForm 세계의 개발 역사 공유",

  phases6: "6단계",
  phases6_sector_0_date_5: "2024",
  phases6_sector_0_item_0_5: "맥스폼 월드(메타버스) 개발 시작",
  phases6_sector_0_item_1_5: "자체 제작 스튜디오를 활용한 히어로 NFT 카드 개발(AI 기능 사용)",
  phases6_sector_0_item_2_5: "아바타 경제활동 플랫폼 자체 제작",
  phases6_sector_0_item_3_5: "6번째 영웅 카드 게임 TCG 출시",

  phases7: "7단계",
  phases7_sector_0_date_5: "2025",
  phases7_sector_0_item_0_5: "맥스폼 월드(메타버스) 오픈",
  phases7_sector_0_item_1_5: "맥스폼 월드 파트너십 추가 체결",
  phases7_sector_0_item_2_5:
    "BCL 생태계 확산 확대",
  phases7_sector_0_item_3_5: "BCL 메인넷 개발",
  phase6: "Phase6",
  phase6_sector_0_date: "Q4. 2022",
  phase6_sector_0_item_0: '"Make to Earn" 프로젝트로 NFT 플랫폼 런칭',
  phase6_sector_0_item_1: "NFT 카드활용 메타버스 게임 개발",
  phase6_sector_0_item_2: "CMC Lv.20 이하 주요 암호화폐거래소 BCL 상장",
  phase6_sector_0_item_3: "4차 소각",

  phase7: "Phase7",
  phase7_sector_0_date: "2023",
  phase7_sector_0_item_0:
    '"Play to Earm" 프로젝트로 NFT Avatar Metaverse 게임 베타테스트 런칭<br/>• 1:1 및 PVP 게임(아바타 게임)<br/>• 가상 부동산 임대 및 판매',
  phase7_sector_0_item_1: "자신이 만든 아바타 경제활동 플랫폼",
  phase7_sector_0_item_2: "BCL은 CMC Lv.10 & Lv.에 따라 주요 거래소에 상장",
  phase7_sector_0_item_3: "5차 소각",

  download_now: "Download now",
  download_now_desc:
    "오늘 Androroid, iOS or APK file앱을 다운로드하여 채굴해 보세요.",
  referring_hint: "다른 친구 초대할 때 25% 추가로 받을 수 있어요.",
  join_us_hint:
    "온라인 채널에 참여해보세요.<br/>" +
    "BitcoinLegend 커뮤니티의 최신정보를 받으세요.",
  license: "© 2021 BitcoinLegend. All rights reserved",

  nft_about_title: "히어로 NFT 카드 및 메타버스 정보",
  nft_about_desc:
    '<p>메타버스는 1992년 Neal Stephenson의 "Snow Crash"에서 처음 언급되었습니다. <br/>' +
    "메타버스는 언제 어디서나 타인의 마음과 소통할 수 있는 세상인 제2의 인생 플랫폼입니다.<br/>" +
    "메타버스는 우주 1, 우주 2, 우주 3 등 현실과 다양한 세계가 결합된 상태, <br/>" +
    "그리고 실제 나와 다른 삶을 살고 있는 메타버스 속의 누가 진짜 나인지 모를 정도로 사이비 현실 상태다.<p/>" +
    "<p>아바타는 초월적인 공간에서 만나고 소통하며, AR과 VR 기능을 익명으로 활용하여 경제활동을 할 수 있습니다.<br/>" +
    "초월의 공간에서 아바타로 만나는 것은 혁신입니다. 시공의 개념이 무너지는 형태로 향후 20년 동안 세계 혁신을 주도할 것입니다.</p>" +
    "<p>모든 사람에게는 비전과 꿈이 있습니다.<br/>" +
    "하지만 대부분은 현실로 만들지 못하고 마음에 새기지 못한다.<br/>" +
    "특히 경제적 빈곤과 현실에 대한 불만을 해소하기 위해 노력하고 있다.<br/>" +
    "그러나 이러한 노력의 결과는 현실적으로 달성하기 어렵다.</p>" +
    "<p>Bitcoin Legend는 사람들의 이상을 실현하기 위해 만들어졌습니다.</p>" +
    "<p>사용자는 자신만의 NFT 아바타를 만들고 NFT 아이템을 만들고 매매하여 수익을 창출합니다. <br/>" +
    "BCL 코어팀은 AI 기술로 실제 나와 비슷한 웹툰 캐릭터를 만든다. <br/>" +
    "이때 적용된 기술은 Self Made Studio에서 Face Landmark Engine과 Facial Landmark Trigger입니다. <br/>" +
    "이 기술을 사용하면 사용자가 실제 나와 유사한 아바타를 직접 만들 수 있습니다. 헤어 스타일, 얼굴 모양, 눈 색깔까지 변경 가능합니다. <br/>" +
    "그 외에도 다양한 의상, 장식품, 게임 아이템을 제작할 수 있습니다. 또한 직접 제작한 아이템을 구매할 수도 있습니다. <br/>" +
    "NFT 제작 기술을 사용하여 암호화하여 이러한 항목을 경매할 수 있습니다.</p>",
  maxform_desc:
    "<p>MaxForm Studio는 사용자 중심의 자체 제작 시스템입니다.<br/>" +
    "사용자는 사진 촬영 후 얼굴을 바꾸고 마스크로 익명을 복원합니다.<br/>" +
    "또한 UGC(User Generated Contents)로 장식되어 있습니다.<br/>" +
    "맥스폼은 히어로 NFT 카드가 만든 메타버스 공간에서 아바타가 활동하는 공간입니다.</p>",
  shape_made_tech: "셰이프 메이드 테크",
  shape_made_tech_desc:
    "<p>스마트폰으로 촬영하면 AI 얼굴 인식 기능에 따라 얼굴 랜드마크 엔진과 트리거가 작동합니다.<br/>" +
    "얼굴은 웹툰 이미지로 만들어지는데, 일부 이용자들은 익명성에 대한 성향이 다르다. <br/>" +
    "얼굴을 바꾸고 싶을 때 얼굴색, 머리색, 헤어스타일, 눈썹 등을 변경할 수 있습니다.<br/>",
  shape_made_system: "사용자를 위한 자체 제작 시스템",
  shape_made_system_desc:
    "<p>의상 제작 플랫폼에서 모든 의상과 액세서리를 직접 디자인하고 제작할 수 있습니다. <br/>" +
    "또한, 우아함을 유지하고 싶은 마스크의 종류를 선택할 수 있습니다.<br/>" +
    "이건 NFT마켓, 맥스폼 메타버스 게임 에서 사고팔 수 있습니다.<br/>" +
    "1:1 격투 게임에서 획득한 액세서리와 의상은 더욱 유명해지고 가격도 오를 것입니다.<br/>" +
    "그리고 그것을 만든 디자이너는 점점 더 명예를 얻게 됩니다. 당연히 그의 상은 가격도 오릅니다.</p>",
  hero_nft_card: "영웅 NFT 카드",
  hero_nft_card_desc:
    "<p>BitcoinLegend는 단순한 마이닝 앱이 아니라 NFT 카드 비즈니스 및 메타버스 게임에서 Top Tier가 될 것입니다.<br/>" +
    "BCL 네트워크는 3번의 적립 전략을 수립하여 암호화폐 생태계를 개척하고자 합니다. <br/>" +
    '3 적립 전략 중 "수익 창출" 전략입니다. 영웅 NFT 카드는 영웅의 생명을 카드에 담습니다. <br/>' +
    "히어로 NFT 카드 영웅은 메타버스 게임에 등장합니다. 카드에는 영웅 스토리와 아이템 인벤토리가 있습니다.<br/>" +
    "영웅 이야기를 통해 사용자는 자신의 이야기를 할 수 있습니다. 인벤토리는 기술, 장비 및 재료를 저장할 수 있습니다. <br/>" +
    "이 모든 항목은 구매, 판매 및 경매가 가능합니다.</p>",
  media_partners: "미디어 및 파트너",
  nft_modal_content:
    "<p>☑️4x 고속 채굴 이벤트(모든 사용자) <br/><br />" +
    "☑️1NFT = 3,900BCL/94Matic/0.05 ETH</p>",
  go_hero_nft_card: "히어로 NFT 카드 구매",
  go_hero_nft_close: "창 닫기",
  bep_title: "ERC20에서 BEP20으로 마이그레이션",
  bep_title1: "마이그레이션 목적",
  bep_desc1: "NFT 출금 및 발행 시 발생하는 가스 수수료를 줄이기 위해",
  bep_title2: "마이그레이션 시 스왑 비율",
  bep_desc2:
    "1. 스왑은 이전에 BCL을 구매 한 사용자를 위해 수행되며, <br /> 예금시 가스 수수료 비용을 줄이기 위해 추가 지불이 이루어집니다. <br /> Bcl Mined는 KYC 이후에 지불하면 BEP20으로 지불되므로 스왑이 필요하지 않습니다. <br /> 2. BCL 유동성에 대한 거래 활동에 대한 LBANK Cryptocurrency 교환을 교환 할 때 추가 결제율을 늘리십시오.",
  bep_title3: "Lbank 암호화폐",
  bep_desc3: "ERC20 : BEP20 = 1 : 1.1 (10% 부가세)",
  bep_title4: "마이그레이션 방법",
  bep_desc4:
    "1. Lbank 암호화폐 거래소<br />" +
    "할 일이 없습니다. BCL은 Lbandk 거래소 내에서 스왑되어 새 지갑에 입금됩니다. <br />" +
    "2. BCL 홈페이지(여기)<br />" +
    "기존에 입금한 메타마스크, 트러스트월렛, 임월렛 등 개인지갑을 사용하세요.<br />" +
    "다음과 같이 ERC20 기반 BCL을 BCL 재단 주소로 보내십시오. 우리 시스템은 BEP20 기반 BCL 토큰을 즉시 지갑으로 보냅니다.",
  bep_desc41: "3. 마이그레이션 할 수있는 개인 Cryto-Wallet <br />",
  bep_desc42: " Metamask ( BEP20 주소를 얻는 방법 ) ",
  bep_desc43: " Trust wallet ( BEP20 주소를 얻는 방법 )  ",
  bel_desc44:
    "ETHERSCAN : ERC20 기반 BCL을 사용하여 지갑에서 BCL 재단으로 전송하는지 확인",

  bep_title5: "마이그레이션 신청",
  bep_desc5:
    "마이그레이션을 신청하기 전에 ERC20 지갑 주소와 BEP20 지갑 주소 복사를 준비하십시오.",
  bep_button_title: "6 월 29 일까지 LBANK에서 교체하십시오",
  bep_submit: "신청",
  bep_exit: "취소",
  bep_popup_desc1:
    '[ 이전 안내 ]<br /> 1. "제출" 버튼을 누르기 전에 지갑 주소가 올바른지 확인하십시오.<br /> 2. 지갑 주소의 오류 및 누락에 대해서는 책임지지 않습니다<br /> 3. 거래는 약 3-5분 정도 소요됩니다.',
  bep_popup_title1: "마이그레이션 신청",
  bep_popup_agree: "위의 공지에 동의합니다",
  bep_input_email: "이메일 주소 입력하세요",
  bep_input_erc: "ERC20 주소 입력하세요",
  bep_input_ber: "BEP20 주소 입력하세요",
  bep_input_amount: "양을 입력하세요",
  bep_check_box: "체크박스를 눌러주세요",
  bep_request_complete: "요청이 완료되었습니다.",
  bep_request_check: "항목을 확인해주세요",
  bep_title8:
    "<font color='#aaaaaa'>BCL 재단의 예금 주소 :</font> <font color='#34D5FF'>0x7B75E328829f66BB1da0B3bCBe7e8123751a05F7</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  bep_title9: "마이그레이션을 수행 할 곳",
  bep_title10:
    "1. lbank crytocurrency exchange <br/> 예금은 미리 통보 된 날짜 전날에 금지됩니다. 3 일 동안 LBANK 내에서 스왑. 새로운 예금, 거래 및 인출이 수행됩니다. <br/>2. BCL 웹 사이트 (여기) <br/>LBANK Cryptocurrency Exchange 교환에 참여할 수없는 사용자의 경우 BCL 웹 사이트는 자체 마이그레이션을 수행합니다.",
  bep_title11:
    "☑ 마이그레이션시 교환 비율 <br /> 1. lbank crytocurrency exchange <br /> erc20 : bep20 = 1 : 1.1 (6 월 29 일 29 일 UTC+8) <br /> 2. Bitcoinlegend 웹 사이트 (7 월 1 일부터) <br /> erc20 : bep20 = 1 : 1.03",
  // bep_title12: "<font color='yellow'>lbank crytocurrency 교환 :</font><br />ERC20 : BEP20 = 1 : 1.1 (10% 추가 지불)",
  // bep_title13: "<font color='yellow'>BitcoInlegend 웹 사이트</font><br />ERC20 : BEP20 = 1 : 1.1 (3% 추가 지불)",
  levelup_title1:
    "이 프로그램은 최소한의 비용으로 채굴 속도를 높이고 자하는 사용자를 위해 개발되었습니다.",
  levelup_desc1:
    "레벨 업 프로그램은 정해진 기간 동안 채굴 속도를 높이도록 설계되었습니다. <br /> 1. 신청 및 지불 후에는 즉시 적용됩니다. <br /> 2. 적용 기간은 타이머로 작동합니다. <br /> 3. 타이머가 만료 된 후, 반감기에 해당하는 채굴 속도가 복원됩니다.",
  levelup_title2: "현재",
  levelup_title3: "레벨 업 프로그램",
  levelup_popup_title1: "레벨 업 신청서",
  levelup_popup_desc1:
    "[레벨 업에 대한 통지] <br /> 1. Google Play Store Apple App Store, Bitcin Legend Mining을 설치할 때 <br /> 앱 APK 파일로 등록 이메일 주소를 입력하십시오. <br /> 2. 이메일 주소를 확인하는 방법은 위의 설명서를 참조하십시오. <br /> 3. 레벨 업 프로그램은 응용 프로그램 직후에 시작됩니다<br />  4. 레벨 업 프로그램의 운영 시간은 등록 후 30 일입니다.",
  levelup_popup_confirm: "확인",
  bep_button_title2: "마이그레이션을 확인하세요",
  levelup_title4: "이메일 주소를 확인하는 방법",
  levelup_title5:
    "1. BitcoInlegend의 채굴 앱에서 아이콘을 터치합니다.<br />2. 등록 된 BCL 이메일을 확인하고 표시된 것과 동일하게 입력하십시오.",

  nft_about_title2: "Maxform Hero Card & Metaverse",
  nft_about_desc2:
    '<p>이 메타버스는 1992 년 Neal Stephenson의  "Snow Crash"에서 처음 언급되었습니다.<br/>' +
    "이 메타버스는 언제 어디서나 다른 사람들의 마음과 연결될 수있는 세상의 세 번째 라이프 플랫폼입니다.<br/>" +
    "메타버스는 Universe 1, Universe 2 및 Universe 3과 같은 현실과 다양한 세계가 결합되는 상태입니다. <br/>" +
    "그리고 그것은 우리가 진짜 나와 다른 삶을 사는 메타버스에서 어떤 사람이 진짜 나인지 알지 못하는 지점까지 의사 현실 상태에 있습니다.<p/>" +
    "<p>아바타는 초월적인 공간에서 만나고 의사 소통 할 수 있으며 익명으로 AR 및 VR 기능을 사용하여 경제 활동에 참여할 수 있습니다.<br/>" +
    "초월적인 공간에서 아바타로 만나는 것은 혁신입니다. 그것은 시간과 공간의 개념의 붕괴의 형태로 향후 20 년 동안 세계 혁신을 이끌 것입니다..</p>" +
    "<p> 모두가 비전과 꿈을 가지고 있습니다. <br/>" +
    "그러나 그들 대부분은 그것을 현실로 만들고 마음 속으로 유지할 수 없습니다. <br/>" +
    "특히, 경제 빈곤과 현실에 대한 불만을 해결하기위한 노력이 이루어지고있다. <br/>" +
    "그러나 이러한 노력의 결과는 실제로 달성하기가 어렵습니다. </p>" +
    "<p> 비트 코인 전설은 사람들의 이상을 깨닫기 위해 만들어졌습니다. </p>" +
    "<p> 사용자는 자신의 NFT 아바타를 만들고, NFT 품목을 만들고, 사고 판매하여 이익을 창출합니다. <br/>" +
    "BCL Core 팀은 AI 기술을 사용하여 Real Me와 유사한 웹 툰 캐릭터를 만듭니다. <br/>" +
    "현재 적용되는 기술은 자체 제작 스튜디오에서 얼굴 랜드 마크 엔진과 안면 랜드 마크 트리거입니다. <br/>" +
    "이 기술을 사용하면 사용자는 실제 나와 비슷한 아바타를 직접 만들 수 있습니다. 헤어 스타일, 얼굴 모양 및 눈 색상을 바꿀 수 있습니다. <br/>" +
    "또한 다양한 의류, 장신구 및 게임 아이템을 생산할 수 있습니다. 또한 구입할 수 있습니다. <br/>" +
    "이 품목들은 NFT 제조 기술을 사용하여 암호화하여 경매를 할 수 있습니다. </p>",
  hero_nft_card2: "게임 파이 영웅 카드",
  hero_nft_card3: "Maxform Hero NFT 카드 화이트리스트",
  hero_nft_card3_desc:
    "평범한 사람들은 영웅이 될 수 있습니다. <br /> 좋은 영웅만 영웅이 될뿐만 아니라 <br /> 악당조차도 게임에서 이길 수 있다면 영웅이됩니다. <br /> 스스로 도전하십시오.",
  hero_nft_card4: "Maxform Hero 카드의 화이트리스트",
  hero_nft_card_title1: "NFT는 무엇입니까?",
  hero_nft_card_desc1:
    "비전 할 수없는 토큰 (NFT)은 블록 체인 기술을 사용하여 디지털 자산의 소유자를 증명하는 가상 토큰입니다. <br /> " +
    "사진 및 비디오와 같은 디지털 파일을 가리키는 주소는 토큰에 저장되며 고유 한 독창성과 소유권을 나타내는 데 사용됩니다. <br />" +
    "즉, 일종의 가상 진위 인증서. <br />" +
    "Hero NFT 카드는 특정 캐릭터뿐만 아니라 영웅이 될 수있을뿐만 아니라 평범한 사람들도 영웅이 될 수 있다는 개념으로 설계되었습니다.",
  hero_nft_card_title2: "Maxform Hero 카드의 특성은 무엇입니까?",
  hero_nft_card_desc2:
    "Maxform Hero Card는 다각형 체인을 통해 민트를 받고 누구나 Opensea에서 사서 팔 수 있습니다. <br /> " +
    "정밀 제조 기술에 의해 2130 x 2800 픽셀로 만들어졌습니다. <br />" +
    "화이트리스트는 구매자의 관점에서 NFT를 구매하는 것이 아니라 할인 된 가격으로 NFT를 구매하는 것입니다. 추가 서비스를받을 수 있습니다. <br />" +
    "BCL 재단은 화이트리스트를 통해 영웅 NFT 카드를 구매하는 사람들에게 광업 속도를 높이기 위해 레벨 업 혜택을 제공합니다. <br />" +
    "Opensea 또는 Binance NFT에서 직접 구매하는 사람들은이 혜택이 없습니다.",
  hero_nft_card_title3: "The Price and payment method for whitelist",
  hero_nft_card_desc3:
    "☑️ 1차 화이트리스트(1,500 NFT)<br />" +
    "1. 가격 : 1,000 BCL 2. 결제방법 : BEP20 기반 BCL만<br />\n" +
    "☑️ 2차 화이트리스트(4,500 NFT) <br />\n" +
    "1. 가격 : 0.05 ETH 2. 결제방법 : BCL, ETH, Matic(다각형) 또는 기타 결제수단<br />\n" +
    "☑️ 세 번째 화이트리스트(2,000 NFT) <br />\n" +
    "1. 가격 : 0.05 ETH 2. 결제방법 : BCL, ETH, Matic(다각형) 또는 기타 결제수단<br />\n" +
    "☑️ 경매 시작(1,000 NFT)<br />" +
    "오픈시 판매 및 구매<br />",
  hero_nft_card_title4: "화이트리스트 일정",
  hero_nft_card_desc4:
    "☑️ 1차 화이트리스트(1,500 NFT) <br />" +
    "1. BCL 웹사이트에서 1차 화이트리스트 시작 : 8월 17일 ~ 8월 31일<br />" +
    "2. 입금마감 : 8월 17일 ~ 8월 31일<br />" +
    "3. BCL 홈페이지에 민팅 사이트 추가 : 8월 16일 ~ 8월 19일<br />" +
    "4. OpenSea에서 선물 상자 형태로 채널 공개 : 9월 5일<br />" +
    "5. 랜덤 영웅 카드 공개 : 9월 7일<br />" +
    "☑️ 2차 화이트리스트(4,500 NFT)<br />" +
    "1. BCL 웹사이트에서 2차 화이트리스트 시작 : 9월 14일 ~ 9월 23일<br />" +
    "2. 입금마감 : 9월 14일 ~ 9월 23일<br />" +
    "3. 오픈씨에서 선물상자 형태로 채널 공개 : 9월 27일<br />" +
    "4. 랜덤 영웅 카드 공개 : 9월 29일<br />" +
    "☑️ 3번째 화이트리스트(2,000 NFT)<br />" +
    "1. BCL 웹사이트에서 3차 화이트리스트 시작: 10월 4일 - 10월 14일<br />" +
    "2. 입금마감(BCL&기타결제) : 10월 4일 ~ 10월 14일<br />" +
    "3. 오픈씨에서 선물상자 형태로 채널 공개 : 10월 18일<br />" +
    "4. 랜덤 영웅 카드 공개 : 10월 20일<br />" +
    "☑️더치 옥션 시작(1,000 NFT) : 10월 26일",
  hero_nft_card_title5: "화이트리스트 신청 방법",
  hero_nft_card_desc5:
    "1. Lbank 또는 Pancake Swap에서 BCL을 구입하십시오. <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://www.lbank.info');\"'> LBank</div> <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://pancakeswap.finance');\">PancakeSwap</div><br /> " +
    "2. BEP20 기반 BCL을 개인 Cryto-wallet(Metamask 및 Trustwallet)에 입금합니다." +
    "* 주소 BEP20 및 폴리곤(매틱)을 가져오는 방법  <div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />" +
    "3. BEP20 기반 BCL을 다음과 같이 BCL 재단 주소로 보냅니다.",
  hero_nft_card_desc6:
    "<font color='#aaaaaa'>BCL  Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   />",
  hero_nft_card_desc7: "BCL 재단의 예금을 확인하십시오",
  hero_nft_card_desc8:
    "4. BCL 앱 구독 이메일 주소를 입력하고 아래 창에 BCL 지갑 주소를 보냅니다.<br />" +
    "*채굴 속도 업 이벤트에 참여하려면 BCL 구독 앱 이메일 주소를 입력하십시오.",
  hero_nft_card_desc9: "화이트리스트 적용을위한 신청서",
  hero_nft_card_desc10_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>BCL 앱에서 가입한 이메일을 확인하십시오</span> ',
  hero_nft_card_desc10_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>이메일 주소를 입력하고 보낸 BEP20 <br /> 기반 BCL 주소 및 금액 <br /><font color='#FFD500'>*1NFT = 1,000BCL, 잔액은 환불되지 않습니다.<br />1000 단위를 입력하세요. . <br />구매자당 구매한 NFT 수에는 제한이 없습니다.</font></span> ",
  hero_nft_card_desc10_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>NFT 카드 수신 주소는 자동으로 <br />입금 계좌의 폴리곤(MATIC) 주소로 등록됩니다.</span> ',
  hero_nft_card_desc11:
    "5. Hero NFT를 구매하려는 White List를 확인하십시오<br />" +
    "*1st White 목록에서 NFT를 구매 한 후 BCL Core 팀은 여기에 구매자 목록을 게시합니다.<br />" +
    "목록을 제시 한 후 구매자는 채굴 속도를 높이고 Maxform Hero 카드의 정보를 이용할 수 있습니다. ",
  bep_input_nft_email: "이메일 주소를 입력하세요",
  bep_input_bep_address: "BCL 주소를 입력하세요",
  bep_input_bep_amount: "BCL 금액을 입력하세요",
  bep_input_bep_from: "'Wallet'을 연결해야합니다.",
  bep_input_polygon_address: "폴리곤 주소를 입력하세요",
  bep_input_bep_amount2: "3900이상의 수를 입력하세요 ",
  not_match_bep: "입금한 BEP20 BCL 주소와 BEP20 BCL 금액을 확인 하세요.",
  not_match_eth: "입금한 ETH 주소와 ETH 금액을 확인하세요.",
  not_match_pol: "입금한 MATIC 주소와 MATIC 금액을 확인하세요.",
  hero_nft_card_desc51:
    "*엘뱅크에 BCL을 입금하면 NFT 카드를 받을 수 없습니다. <br />" +
    "개인 암호화폐 지갑에서 꼭 하세요<br />" +
    "*1NFT = 1,000BCL, 잔액은 환불 불가,<br />" +
    "그러니 1000단위를 입력하세요. <br />" +
    "구매자 1인당 구매한 NFT 수에는 제한이 없습니다.",
  nft_request_complete: "이미 처리되었습니다",
  hero_nft_card_desc12:
    "1. 다음과 같이 BCL 재단의 주소로 Ethereum(ETH) 또는 Polygon(MATIC)을 보냅니다.:<br />" +
    "* 주소 Polygon을 얻는 방법(matic)<div class='increase_strategy_mark2' style='display: inline' onclick=\"window.open('https://youtu.be/Y_gaBqbGvvo');\">Polygon</div> <br />",
  hero_nft_card_desc52:
    "*1NFT = 0.05 ETH, 1 NFT = 40 MATIC <br />" +
    "잔액은 환불되지 않습니다<br />" +
    "구매자당 구매한 NFT 수에는 제한이 없습니다.",
  hero_nft_card_desc61:
    "<font color='#aaaaaa'>Polygon(Matic) Deposit Address :</font> <font color='#34D5FF'>0xF30Eea37313Cf59D67448346F8319826c1B131c6</font> <img src=\"/assets/img/ic_paste.png\" srcSet=\"/assets/img/ic_paste.png 1x\"   /><br />",
  hero_nft_card_desc71: "ETH 입금 확인",
  hero_nft_card_desc72: "MATIC 입금 확인",
  hero_nft_card_desc81:
    "2. 아래 창에서 BCL 앱 구독 이메일 주소와 보낸 BCL 지갑 주소를 입력합니다. <br />" +
    " *채굴 속도 향상 이벤트에 참여하려면 BCL 채굴 앱 이메일 주소를 입력해야 합니다.",
  hero_nft_card_desc91: "화이트리스트 적용을 위한 ETH/MATIC 양식",
  hero_nft_card_desc11_1:
    '<img src="/assets/img/nft/ic_no1.png" srcSet="/assets/img/nft/ic_no1.png 1x"  style=\'height:3vh\' />&nbsp; <span>BCL 앱에서 구독한 이메일 확인</span> ',
  hero_nft_card_desc11_2:
    "<img src=\"/assets/img/nft/ic_no2.png\" srcSet=\"/assets/img/nft/ic_no2.png 1x\"  style='height:3vh' />&nbsp; <span>이메일 주소를 입력하고 ETH 또는 MATIC을 전송했습니다. <br /주소와 금액  <br /><font color='#FFD500'>*1NFT = 0.05 ETH, 1NFT = 40 MATIC,<br />" +
    "잔액은 환불되지 않습니다. <br />" +
    "구매자당 구매한 NFT 수에는 제한이 없습니다.</font></span> ",
  hero_nft_card_desc11_3:
    '<img src="/assets/img/nft/ic_no3.png" srcSet="/assets/img/nft/ic_no3.png 1x"  style=\'height:3vh\' />&nbsp; <span>NFT를 수신한 폴리곤 기반 주소를 입력합니다. </span> ',
  hero_nft_card_desc82:
    "1차 화이트리스트 기간 동안 NFT를 구매한 후 BCL 코어팀은 여기에 구매자 목록을 게시합니다.  <br />" +
    "구매자 목록 등록 후 구매자는 Mining 속도 향상 및 Maxform Hero 카드 정보를 얻을 수 있습니다. ",
  nft_event_string1:
    "🟢 Maxform NFT 히어로 카드는 비트코인레전드 코아팀에 의해서 제작되고 민팅되었다🟢<br />" +
    '비트코인 레전드의 히어로 카드 컨셉은 "일반인도 영웅이 될수 있다"이다.<br />' +
    "반은 일반인 복장이고 반은 영웅복장이다.<br />\n" +
    "                        이것은 희귀도 산출 프로그램에 의해 더욱 유니크하게 만들어 졌다.",
  nft_event_string2: "🎉단지 300개 히어로카드만이 무료로 지급되는 이벤트🎉\n",
  nft_event_string3:
    " ☑️ 1차 화이트리스트 가격 : 𝟬.022 ETH/45 MATIC/1,000 BCL per NFT<br />" +
    "                        ☑️ 2차~3차 화이트리스트 가격 : 𝟬.05 ETH/105 MATIC/2,200 BCL per NFT",
  nft_event_string4:
    " ☑️ 추가 이벤트(사용자수 현재 266만명. 3백만명이 될때까지 진행)\n<br /><br />" +
    "                        ▶️ 4배속 스피드 마이닝 : 6→24BCL/day<br />" +
    "                       (NFT 구매자 +추가 24 BCL/day, 1개월간)<br /><br />" +
    "                       ▶️ 추천인 보너스 업 : 25→50 BCL/사용자",
  nft_event_string5: "이벤트 참여해주셔서 감사합니다!",
  nft_event_string6: "🏷판매정보",
  go_hero_nft_event: "영웅 NFT 카드 이벤트",
  bep_request_check2: "일치하는 계정 정보가 없습니다.",
  nft_event_qa:
    "상위 내용에 누락된 것이 있는 경우,  <a href='https://forms.gle/SxLB81u6t5hBzUiV6' target='_blank'><span style={{ textDecoration: 'underline', fontWeight: 'bold' }} 보내려면 여기를 >클릭</span></a>하세요.",
  hero_nft_card_title11: "[스페셜 프로모션]",
  hero_nft_card_desc11_desc:
    "☑️랭킹전(실시간 업데이트) <br />" +
    "●1등 <br />" +
    '1. 6차 히어로 프로모션 : "너는 히어로야" <br />' +
    "(당신의 사진&스토리 → 히어로 제작 + 웹툰 + 랩 제작) <br />" +
    "첫 번째 히어로 : <a href='https://youtu.be/s0FSVXZ-wSs' target='_blank'>https://youtu.be/s0FSVXZ-wSs</a> <br />" +
    "두 번째 히어로 : <a href='https://youtu.be/8Al9l7YVLC4' target='_blank'>https://youtu.be/8Al9l7YVLC4</a> <br />" +
    "3~5번째 히어로 : <a href='https://youtu.be/gfjBqRpmh6U' target='_blank'>https://youtu.be/gfjBqRpmh6U</a> <br />" +
    " <br />" +
    "2. 1,000NFT 한정판(26종 아이템으로 제작 6번째 히어로) <br />" +
    "1,000NFT*0.1ETH(화이트리스트 가격)=100ETH <br />" +
    "판매금액의 5% 보상(5ETH) <br />" +
    "●2등~3등 <br />" +
    "2등) 5개 NFT추가지급 + 6번째 히어로 판매금액 2%보상(2ETH) <br />" +
    "3등) 3개 NFT추가지급 + 6번째 히어로 판매금액 1% 보상(1ETH) <br />" +
    "●4등~10등 <br />" +
    "2개 NFT 추가 지급 <br />" +
    "*추가 지급 NFT는 현재 판매중인 NFT로 지급됩니다. <br />" +
    " <br />" +
    "☑️멤버십 프로모션 <br />" +
    "●플래티넘(21NFT 이상 구매) <br />" +
    "마이닝 스피드업(+24BCL/day), KYC 우선순위, NFT+1ea/구입 20ea <br />" +
    "●골드(11~20NFT 구매) <br />" +
    "마이닝 스피드업(+24 BCL/day), KYC 우선순위, NFT+1 <br />" +
    "●실버(5-10 NFT 구매) <br />" +
    "마이닝 스피드업(+24 BCL/day), KYC 우선순위 <br />" +
    "●브론즈(1~4NFT 구매) <br />" +
    "마이닝 스피드업(+24BCL/day)",
  kyc_certification: "KYC 인증",
  vision_of_bitcoinLegend: "BitcoinLegend의 비전",
  kyc_desc1:
    "Bitcoin Legend는 현재 상장된 채굴형 암호화폐입니다. <br />" +
    "이것은 BCL 토큰이 Maxform 생태계의 운영 통화로서 실질적인 역할을 하기 위한 것입니다. <br />" +
    "Maxform 생태계는 영웅 NFT 카드를 발행하고 이를 사용하여 카드 게임을 합니다. <br />" +
    "또한, 영웅이 거주하는 Maxform City는 3D 세계에서 구현됩니다. <br />" +
    "그리고 거주 도시는 NFT로 만들어집니다. <br />" +
    "이 도시도 도시전과 같은 게임 형태로 구현되어 있습니다. <br />" +
    "사람과 환경이 NFT가 만든다면 <br />" +
    "BCL 재단은 Maxform이라는 메타버스 세계를 만들 것입니다. <br />" +
    "영웅들은 커피숍이나 헬스 트레이닝 센터에서 만나서 이야기하고 게임을 한다. <br />" +
    "이 생태계에서 사용되는 금전적 수단은 BCL입니다. <br />" +
    "이것이 비트코인 레전드의 비전이자 목표입니다.",
  kyc_title2: "KYC 인증 목적",
  kyc_desc2:
    "BCL 재단은 BCL에 대한 신뢰성을 제공하기 위해 KYC 검증을 진행합니다. <br />" +
    "클라우드 서버를 통해 모든 사용자가 채굴합니다. <br />" +
    "한 사람이 하나의 모바일로 채굴하고 증명하는 과정입니다 <br />" +
    "그들이 실존 인물이라는 것을. <br />" +
    "가짜 계정을 만들어 부당한 이익을 취하는 것을 방지하고 싶습니다.",
  kyc_title3: "KYC 절차 따르기",
  bep_input_name: "이름 입력",
  bep_input_number: "입력 번호",
  bep_input_address: "주소 입력",
  kyc_popup_title1: "[ KYC 인증을 위한 연령 제한 ]",
  kyc_popup_desc1:
    "어린이 및 성인이 아닌 사람의 데이터 수집에 관한 국제법을 준수하기 위해 KYC 인증은 18세 이상으로 제한됩니다.",
  kyc_popup_desc2:
    "만 18세 미만의 경우, BCL 재단은 부모 동의하에 별도의 시스템을 구축하여 시행할 계획입니다.",
  kyc_popup_desc3: "[KYC 적용 시 주의사항]",
  kyc_popup_desc4:
    "1. 만 18세 미만이라도 신청하면 오류가 발생하며 KYC 수수료만 환불 불가합니다.",
  kyc_popup_desc5:
    "2. 실명은 주민등록증, 여권, 운전면허증 또는 유권자 신분증의 이름과 일치해야 합니다.",
  kyc_popup_desc6:
    "3. 이메일은 Bitcoin Legend에 가입할 때의 이메일이어야 합니다.",
  kyc_popup_desc7:
    "4. 휴대폰 번호는 비트코인 ​​레전드 앱을 설치한 전화번호여야 합니다.",
  kyc_popup_desc8: "실제이름",
  kyc_popup_desc9: "BCL 앱용 이메일",
  kyc_popup_desc10: "모바일 전화번호",
  kyc_popup_desc10_1: "Transaction Hash",
  kyc_popup_title2: "[ KYC 준비 ]",
  kyc_popup_desc11:
    "1. 문서(주민등록증, 운전면허증, 여권 또는 유권자 신분증)가 만료되지 않았습니다.",
  kyc_popup_desc12:
    "만료된 문서로 KYC하면 오류가 발생합니다. 결과적으로 KYC 수수료는 환불되지 않습니다.",
  kyc_popup_desc13: "2. 귀하의 문서 정보를 사용할 수 있습니다.",
  kyc_popup_desc14: "3. 주소, 휴대폰 번호, 국가 정보는 KYC 인증에 사용됩니다.",
  kyc_popup_desc15:
    "4. 이 정보는 KYC 확인용으로만 사용되며, Bitcoin Legend의 정책에 따라 앱을 실제로 사용하는지 여부를 판단하는 데 사용됩니다.",
  kyc_popup_desc16: "따라서 사용 권한에 동의합니다.",
  kyc_popup_agree: "동의",
  kyc_popup_reject: "거부",
  kyc_popup_kyc: "KYC로 이동",
  kyc_popup_title3: "[ KYC 인증 ]",
  kyc_popup_desc17:
    "만료되지 않은 문서(주민등록증, 운전면허증, 여권 또는 유권자 신분증)가 있는 전화기와 앱을 설치했으면 KYC 인증을 시작하겠습니다.",
  kyc_popup_desc18: "KYC 인증에는 6$의 수수료가 있습니다.",
  kyc_popup_desc19: "KYC 인증을 진행하시겠습니까?",
  kyc_result1:
    "이미 KYC 인증을 받은 사용자입니다. KYC 순서에 따라 앱으로 이동하여 인앱 결제를 진행하세요.",
  kyc_result2: "일치하는 정보가 없습니다.",
  kyc_popup_title4: "[ KYC 인증 종료 ]",
  kyc_popup_desc20: "KYC 인증에 감사드립니다.",
  kyc_popup_desc21:
    "KYC 인증이 정상적으로 완료되었다면 앱 메인 화면의 아이콘이 다음과 같이 변경되었을 것입니다.",
  kyc_popup_desc22:
    "변경되지 않으면 인증 점수가 부적합으로 판정되었음을 의미합니다. ",
  kyc_popup_desc23: "BCL 재단에서 수동으로 다시 한 번 확인합니다. ",
  kyc_popup_desc24: "정말 감사합니다",
  kyc_popup_desc25: "KYC 인증 증명",
  kyc_result3: "이메일이 틀렸습니다",
  kyc_result4:
    "이메일에 해당하는 이름과 전화번호를 확인하고 다시 입력해주세요.",
  kyc_popup_pay: "결제",
  kyc_result5: "BitcoinLegend App 에서, 프로필로 이동 후, KYC 동의를 해주세요.",
  kyc_result6:
    "이미 KYC 인증을 받은 전화번호입니다. 앱으로 이동하여 KYC 순서에 따라 인앱 결제합니다.",
  kyc_result7: "KYC 진행중입니다.",
  kyc_popup_desc26: "※ 먼저 앱에서 시작해주세요",
  kyc_popup_desc27:
    "5. 문의사항은 <a href='https://forms.gle/94ggLz5t1fUbh1Cq6' target='_blank' style='text-decoration: underline;'><B><underline>여기</underline></B></a>로 접수해주세요.",
  kyc_result8: "앱에서 인앱결제기능을 이용하세요.(Ver 1.4.8)",
  kyc_1: "[KYC 인증 일시 중단 안내]",
  kyc_2:
    "As a result of a strong protest against Stripe, 100% KYC refund is in progress. <br /><br />We was informed that there will be a delay of 10 to 15 days depending on the credit card company according to the countries. <br />If you do not receive a refund after that, please contact your credit card company.  <br />USDT, TRX, in-app payment (Google) is in progress by expanding KYC payment.",
  nft_1: "화이트리스트 특별 프로모션",
  nft_2: "☑랭킹 경쟁(실시간 업데이트)",
  nft_3: "●1위",
  nft_4:
    "1. 6차 히어로 프로모션 : “너는 히어로야“ <br />(당신의 포토&스토리 → 히어로 제작 + 웹툰 + 랩 제작) <br />1차 히어로 : https://youtu.be/s0FSVXZ-wSs <br />2차 영웅 : https://youtu.be/8Al9l7YVLC4 <br />3차 - 5차 영웅 : https://youtu.be/8Al9l7YVLC4",
  nft_5:
    "2. 1,000NFT 한정판(6th Hero) 26개 품목 <br />1,000NFT*0.1ETH(화이트리스트 가격)=100ETH <br />판매량에 대한 5% 보상(5ETH) ",
  nft_6: "●2위 - 3위",
  nft_7:
    "2등) 5개의 추가 NFT + 6번째 영웅(2ETH)에 2% 보상 <br />3등) 3개의 추가 NFT + 6번째 영웅(1ETH)에 1% 보상",
  nft_8: "●4위 -10위",
  nft_9: "2개의 추가 NFT",
  nft_10: "*추가 유료 NFT는 현재 판매되는 NFT로 지급됩니다.",
  nft_11: "☑멤버십 프로모션",
  nft_12: "●플래티넘(21NFT 이상 구매)",
  nft_13: "채굴 속도 향상(+24BCL/일), KYC 우선 순위, NFT+1개/20개",
  nft_14: "●골드(11~20 NFT 매수)",
  nft_15: "채굴 속도 향상(+24 BCL/일), KYC 우선 순위, NFT+1",
  nft_16: "●실버(5-10 NFT 구매)",
  nft_17: "채굴 속도 향상(+24 BCL/일), KYC 우선 순위",
  nft_18: "●브론즈(1~4NFT 구매)",
  nft_19: "채굴 속도 향상(24BCL/일)",
  nft_20:
    "Maxform NFT Hero Card는 BitcoinLegend Core 팀에서 개발했습니다. <br />" +
    "이더리움 블록체인 ERC-721의 UNIQUE DIGITAL COLLECTIBLES로 구성되었습니다. <br />" +
    "<br />" +
    "5명의 영웅이 NFT 카드로 다시 태어났습니다. 평범한 사람도 영웅이 될 수 있다는 컨셉으로 디자인되었습니다.<br />" +
    "일반 복장 반, 영웅 복장 반입니다. <br />" +
    "<br />" +
    "레어리티 프로그램으로 더욱 유니크하게 제작되었습니다. 발급된 카드 중 동일한 형태가 없습니다. 헤어, 의상, 무기, 악세서리, 마스크, 배경 등 아이템 아이템이 모두 다릅니다. <br />" +
    "그 중 캐릭터는 한 명도 아니고 총 5명의 캐릭터가 각기 다른 포즈와 포즈를 취하고 있다. <br />" +
    "각 캐릭터당 2000장의 영웅카드가 지급됩니다. <br />" +
    "하나의 캐릭터로 10,000개의 조각을 만드는 다른 NFT와 접근 방식이 다릅니다. <br />" +
    "이렇게 해서 총 5명의 캐릭터가 각각 2000장씩 등장하여 총 10,000장의 카드가 됩니다. <br />" +
    "당신은 당신의 희귀하고 독특한 아이템을 갖게 될 것입니다.",
  kyc_result9: "이미 확인된 Tron 주소입니다. 추가 지불이 필요하지 않습니다.",
  kyc_result10: "KYC 상태 확인",
  kyc_result11: "이메일 주소 확인",
  kyc_result12: "결제, KYC 인증 완료",
  kyc_result13: "결제 미완료, KYC 완료",
  kyc_result14: "결제 미완료, KYC 미완료",
  kyc_result15:
    "결제 완료, KYC가 완료되지 않았습니다. 앱으로 이동하여 KYC 순서에 따라 KYC 진행",
  kyc_result16:
    "5. USDT의 경우 6$를 재단 지갑으로 보내주셔야 합니다.<font color='red'>(반드시 개인 지갑으로 보내주세요)</font>",
  kyc_result17: "- USDT 주소 : TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG",
  kyc_result18: "- 비용 : USDT 6$ ",
  kyc_result19:
    "KYC 인증이 완료되었지만 입금내역이 확인되지 않습니다. USDT 및 TRON으로 입금 금액을 확인하십시오.",
  kyc_result20:
    "KYC 인증이 완료되지 않았습니다. 입금 내역이 확인되지 않습니다. USDT 및 TRON으로 입금 금액을 확인하십시오.",
  kyc_result21: "[공지]",
  kyc_result22:
    "현재 KYC 인증업체인 Passbase의 서버 문제로 KYC 인증이 중단된 상태입니다.<br /><br />Passbase와 연락을 취하고 있으며 KYC 서버가 정상적으로 실행되면 다시 알려드리겠습니다. <br /><br />KYC 인증 수수료 납부 후 KYC를 수행하지 않은 사용자가 추가 비용 없이 KYC 인증을 받을 수 있도록 조치하겠습니다.",
  kyc_result23:
    "[공지]\nUDST, Tron으로 입금시 보통 가스비 제외하고 입금해주세요 *6USDT *100TRX",
  kyc_result24: "[KYC 인증 및 결제 관련 공지]",
  kyc_result25:
    "1. KYC 인증 지연<br />" +
    "사용자 오류로 KYC 인증이 되지 않을 경우, 별도의 수동 인증팀을 구성하여 인증하고 있습니다. (KYC 인증이 완료되면 녹색 아이콘으로 변경됩니다.<br />" +
    " 계속해서 KYC 결제 관련 업무로 혼란을 드려 죄송합니다. 잠시 KYC 인앱 결제를 중단해주세요.<br />" +
    "<br />" +
    "2. KYC 결제 오류 및 환불<br />" +
    "1) 스트라이프 결제 중 이중 또는 결제 누락에 대한 전액 환불을 처리하고 있어 1:1 대응이 어렵습니다. 환불이 처리될 때까지 기다려주세요.<br />" +
    "3) USDT 및 Tron 결제가 미비하여 2회 결제하신 경우 수동 확인 후 회신 드리겠습니다.<br />" +
    "3) 인앱 환불은 BCL 핵심팀의 인앱 확인 코딩 누락으로 발생했습니다. 현재 인앱 확인 코딩이 진행 중입니다. 새로운 업데이트 버전을 발표하기 전에 결제를 중단하십시오.<br />" +
    "4) USDT와 Tron은 KYC 결제를 진행할 수 있습니다.<br />" +
    "<br />" +
    "*위의 경우 많은 인력 동원이 필요하므로 BCL 재단에서 인력을 확보하여 최대한 처리하고 있으니 잠시만 기다려주세요.<br />" +
    "* BCL 결제 및 출금은 위 사업의 정상 운영이 완료된 후 진행됩니다.",
  kyc_result26: "이미 KYC 인증을 하신 이메일이나 전화번호입니다.",
  kyc_result27: "일치하는 폰정보가 없습니다.",
  kyc_result28: "입금내역 <br />사진",
  kyc_result29:
    "귀하의 요청이 완료되었습니다. 입금을 처리하는 데 영업일 기준 1-2일이 소요됩니다.",
  bep_input_block: "BLOCK을 입력하세요",
  kyc_result30:
    "입금하신 주소의 블록을 적어주세요. <a href='https://tronscan.org/#/address/TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG/transfers' target=\"_new\">이동</a>",
  kyc_result31: '앱에 "KYC 실패" 아이콘이 표시된 사용자만 등록할 수 있습니다.',
  kyc_result32: "KYC 결과 없음",
  kyc3_1 : "KYC 신청 시 주의사항",
  kyc3_2: "※ KYC 진행 전 최신 앱으로 업데이트 해주세요.",
  kyc3_3: "입금했는데 문제가 있나요?",
  kyc3_4: "입금하신 결제수단을 선택하시고 부족한 서류를 보충해주세요.",
  kyc3_5: "복사 완료",
  kyc3_6: "입금하신 암호화폐의 종류를 선택해주세요.",
  kyc3_7: "지갑 연동을 하세요",
  kyc3_8: "이메일을 입력해주세요",
  kyc3_9: "1.당신의 개인지갑에서 아래의 주소로 입금하고, 입금한 암호화폐 종류를 선택해 주세요.",
  kyc3_10: "다음으로 이동",
  kyc3_11: "2. 해시값 붙여넣기",
  kyc3_12: "복사한 해시값을 붙여 넣는곳",
  kyc3_13: "3. Bitcoinlegend 앱에 등록된 이메일 주소를 입력하세요. ",
  kyc3_14: "4. 결제가 완료되었습니다.",
  kyc3_15: "Bitcoinlegend 앱에서 KYC 인증을 진행합니다.",
  kyc3_16: "4. 입금되지 않았습니다.",
  kyc3_17: "KYC 수수료를 다시 입금해주세요.",
  kyc3_18: "입금하러가기",
  kyc3_19: "입금했는데 문제가 있습니다",
};
