import React, {useEffect, useState} from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import './CheckoutForm.css';
import axios from "axios";
import {BASE_URL} from "../../config/const";
import strings from "../../lang/strings";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [payText, setPayText] = useState('Pay now');
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    console.log(props.email);
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    setClientSecret(clientSecret);
    console.log(clientSecret);

    stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
      console.log(paymentIntent.amount);
      let params = new URLSearchParams();
      params.append('clientSecret', clientSecret);
      params.append('kstatus', paymentIntent.status);
      params.append('amount', paymentIntent.amount);
      params.append('kcurrency', paymentIntent.currency);
      params.append('payment_method', paymentIntent.payment_method);
      params.append('payment_id', paymentIntent.id);
      params.append('jsondata', paymentIntent.object.toString());
      params.append('email',props.email);
      params.append('type', 'kyc');
      // params.append('polygon_address', this.state.inputPolygonAddress);

      axios.post(`${BASE_URL}/payment/payment-complete`,
        params)
        .then((res) => {

        });
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          axios.post(`${BASE_URL}/payment/payment-success`,
            params)
            .then((res) => {

            });
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let params = new URLSearchParams();
    // params.append('email', this.state.inputEmail);
    // params.append('name', this.state.inputName);
    // params.append('phone', this.state.inputNumber);
    // // params.append('polygon_address', this.state.inputPolygonAddress);
    //
    // axios.post(`${BASE_URL}/user/checkAuth`,
    //   params)
    //   .then((res) => {
    //
    //   });
    //
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const {error} = await stripe.confirmPayment({
      elements,
      confirmParams: {return_url: window.location.origin + `/kyc`,}
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error && error.type != undefined) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {!message && <>
        <form className='form3' id="payment-form" onSubmit={handleSubmit}>
          <div style={{ textAlign: 'center'}} >{ strings.kyc_popup_desc18 }</div><br />
          <PaymentElement id="payment-element"/>
          <button className={'button3'} disabled={isLoading || !stripe || !elements || message} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : payText}
        </span>
          </button>
        </form>
      </>
      }

      {message && <div id="payment-message">{message}</div>}

    </>
  );
}
