import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter } from "react-router-dom";
import Const, { BASE_URL } from "../../config/const";
import { PageSettings } from "../../config/page-settings";
import strings from "../../lang/strings";
import "./whitelist.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Modal, ModalBody } from "reactstrap";
import axios from "axios";
import $ from "jquery/src/jquery";
import MultiColorProgressBar from "../../components/controls/multi-progress-bar";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@material-ui/data-grid";

const columns = [
  {
    field: "id",
    headerName: "Rank",
    flex: 2,
  },
  {
    field: "from",
    headerName: "Address",
    flex: 2,
  },
  {
    field: "amt",
    headerName: "Quantity",
    flex: 2,
  },
  {
    field: "cnt",
    headerName: "Count",
    flex: 2,
  },
];

const youtube = [
  "https://www.youtube.com/embed/gfjBqRpmh6U",
  "https://www.youtube.com/embed/8Al9l7YVLC4",
  "https://www.youtube.com/embed/s0FSVXZ-wSs",
];

var ua = navigator.userAgent.toLowerCase();
var isSafari = false;
if (ua.indexOf("safari") != -1 || ua.indexOf("iphone") != -1) {
  if (ua.indexOf("chrome") > -1) {
    isSafari = false;
  } else {
    isSafari = true;
  }
}

class Whitelist extends React.Component {
  static contextType = PageSettings;

  constructor(props) {
    super(props);
    this.state = {
      strings: strings,
      showGuideModal: false,
      inputNftEmail: "",
      inputBepAddress: "",
      inputBepAmount: "",
      // inputPolygonAddress: '',
      inputNftEmail2: "",
      inputBepAddress2: "",
      inputBepType2: 1,
      inputBepAmount2: "",
      // inputPolygonAddress2: '',
      showMore11: false,
      showMore22: false,
      showMore33: false,
      remain_first: 0,
      remain_first2: 0,
      griddata: [],
      readings: [
        {
          name: "EVENT",
          value: 700,
          percent: 60,
          color: "#ff0000",
        },
        {
          name: "SALES including Event",
          value: 635,
          percent: 30,
          color: "yellow",
        },
        {
          name: "",
          value: 18,
          percent: 10,
          color: "#aaaaaa",
        },
      ],
    };
    this.iframeInt = 0;
    this.uiState = this.props.rootStore.uiState;
    this.globalState = this.props.rootStore.globalState;
  }

  componentDidMount() {
    $("#a1").css("display", "none");
    $("#a2").css("display", "none");
    $("#a3").css("display", "none");
    $("#a4").css("display", "none");
    $("#a5").css("display", "none");
    $("#a6").css("display", "none");

    $("#b1").css("display", "none");
    $("#b2").css("display", "none");
    $("#b3").css("display", "none");
    $("#b4").css("display", "none");
    $("#b5").css("display", "none");
    $("#b6").css("display", "none");

    // $("#c1").css("display",'none');
    // $("#c2").css("display",'none');
    // $("#c3").css("display",'none');
    this.getTotaFirstCount();
    this.getTotaFirstCount2();
    axios
      .get(`${BASE_URL}/user/nftRank`, {
        headers: {
          Authorization:
            "Bearer " +
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MTE5NjM1OSwiZXhwIjoxNjgyNzMyMzU5fQ.5ZxqvUdLOS8zrbCZuDqZqv4Zjox1POUrZ0Ah0u9LEbs",
        },
      })
      .then(({ data }) => {
        var i = 1;
        const newData = data.map((row) => {
          const createdAt = new Date(row["createdAt"]).toLocaleString();
          row["id"] = i;
          // props.setNumber((number: any) => {
          //     row["no"] = number + 1;
          //     return number + 1;
          // });
          row["from"] = row["from"];
          // row["notSettle"] = row["miningAmount"] - row["settleAmount"];
          i++;
          return row;
        });
        this.state.griddata = newData;
        console.log(this.state.griddata);
        // this.state( griddata: .setData(newData);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getTotaFirstCount2 = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo2`, {})
      .then((res) => {
        const max = 3000;
        // const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 3900);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.05);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 94);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt) / max) * 100
        );
        console.log(bcl_cnt + eth_cnt + poly_cnt);
        // const sales_percent =  Math.round(sales / max * 100) ;
        const other_percent = 100 - cnt_percent; // - sales_percent;
        this.state.remain_first2 = other_cnt;

        this.state.readings2 = [
          {
            name: "SALES",
            value: bcl_cnt + eth_cnt + poly_cnt, //+ sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "green",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  getTotaFirstCount = () => {
    axios
      .get(`${BASE_URL}/user/nftFirstInfo`, {})
      .then((res) => {
        const max = 1500;
        const sales = 700;

        const bcl_cnt = Math.floor(res.data[0].bcl_amount / 1000);
        const eth_cnt = Math.floor(res.data[0].eth_amount / 0.022);
        const poly_cnt = Math.floor(res.data[0].poly_amount / 45);
        const other_cnt = max - (bcl_cnt + eth_cnt + poly_cnt + sales);
        const cnt_percent = Math.round(
          ((bcl_cnt + eth_cnt + poly_cnt + sales) / max) * 100
        );
        const sales_percent = Math.round((sales / max) * 100);
        const other_percent = 100 - cnt_percent - sales_percent;
        this.state.remain_first = other_cnt;
        this.state.readings = [
          // {
          //     name: 'EVENT',
          //     value: sales,
          //     percent: sales_percent,
          //     color: '#ff0000'
          // },
          {
            name: "SALES including Event",
            value: bcl_cnt + eth_cnt + poly_cnt + sales,
            percent: cnt_percent,
            color: "yellow",
          },
          {
            name: "",
            value: other_cnt,
            percent: other_percent,
            color: "#aaaaaa",
          },
        ];
      })
      .catch((err) => console.log("오류발생", err));
  };

  clickWhitelistMore1(flag) {
    if (!flag) {
      $("#a1").css("display", "none");
      $("#a2").css("display", "none");
      $("#a3").css("display", "none");
      $("#a4").css("display", "none");
      $("#a5").css("display", "none");
      $("#a6").css("display", "none");
    } else {
      $("#a1").css("display", "");
      $("#a2").css("display", "");
      $("#a3").css("display", "");
      $("#a4").css("display", "");
      $("#a5").css("display", "");
      $("#a6").css("display", "inline-block");
    }
  }

  clickWhitelistMore2(flag) {
    console.log(flag);
    if (!flag) {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly.png");
      $("#b1").css("display", "none");
      $("#b2").css("display", "none");
      $("#b3").css("display", "none");
      $("#b4").css("display", "none");
      $("#b5").css("display", "none");
      $("#b6").css("display", "none");
    } else {
      $("#b2img").attr("src", "/assets/img/nft/img_nft_poly_on.png");
      $("#b1").css("display", "");
      $("#b2").css("display", "");
      $("#b3").css("display", "");
      $("#b4").css("display", "");
      $("#b5").css("display", "");
      $("#b6").css("display", "inline-block");
    }
  }
  clickWhitelistMore3(flag) {
    console.log(flag);
    if (!flag) {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist.png");
      $("#c1").css("display", "none");
      $("#c2").css("display", "none");
      $("#c3").css("display", "none");
    } else {
      $("#c1img").attr("src", "/assets/img/nft/img_nft_whitelist_on.png");
      $("#c1").css("display", "");
      $("#c2").css("display", "");
      $("#c3").css("display", "");
    }
  }

  onCopy() {
    var copyText = document.querySelector("#migration_address");

    function handler(event) {
      event.clipboardData.setData("text/plain", copyText.value);
      event.preventDefault();
      document.removeEventListener("copy", handler, true);
    }

    document.addEventListener("copy", handler, true);
    document.execCommand("copy");
    setTimeout(() => {
      alert("Copied");
    }, 500);
  }

  onConfirmTrasaction2() {
    if (this.state.inputNftEmail2 == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress2 == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount2 == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    // if (this.state.inputBepAmount < 1000) {
    //     alert(strings.bep_input_bep_amount2)
    //     return
    // }
    // if (this.state.inputPolygonAddress2 == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail2);
    params.append("bep_address", this.state.inputBepAddress2);
    params.append("amount_type", this.state.inputBepType2);
    params.append("bep_amount", this.state.inputBepAmount2);
    // params.append('polygon_address', this.state.inputPolygonAddress2);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst2`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        if (this.state.inputBepType2 == 1) {
          alert(strings.not_match_eth);
        } else {
          alert(strings.not_match_pol);
        }
      });
  }

  onConfirmTrasaction() {
    if (this.state.inputNftEmail == "") {
      alert(strings.bep_input_nft_email);
      return;
    }
    if (this.state.inputBepAddress == "") {
      alert(strings.bep_input_bep_address);
      return;
    }
    if (this.state.inputBepAmount == "") {
      alert(strings.bep_input_bep_amount);
      return;
    }
    if (this.state.inputBepAmount < 3900) {
      alert(strings.bep_input_bep_amount2);
      return;
    }
    // if (this.state.inputPolygonAddress == "") {
    //     alert(strings.bep_input_polygon_address)
    //     return
    // }
    let params = new URLSearchParams();
    params.append("email", this.state.inputNftEmail);
    params.append("bep_address", this.state.inputBepAddress);
    params.append("bep_amount", this.state.inputBepAmount);
    // params.append('polygon_address', this.state.inputPolygonAddress);

    axios
      .post(`${BASE_URL}/payment/confirmNftFirst`, params)
      .then((res) => {
        console.log(res);
        if (res.data.data === "success") {
          alert(strings.bep_request_complete);
        } else if (res.data.data === "checked") {
          alert(strings.nft_request_complete);
        } else {
          alert(strings.bep_request_check);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(strings.not_match_bep);
      });
  }

  checkBEP2 = () => {
    {
      window.open(
        `https://bscscan.com/token/0x02b84f8b64e6c67ae6c797ff4ec4efb06ff19b44?a=0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };

  checkEtheScan = () => {
    {
      window.open(
        `https://etherscan.io/address/0xf30eea37313cf59d67448346f8319826c1b131c6`
      );
    }
  };
  checkPolyScan = () => {
    {
      window.open(
        `https://polygonscan.com/address/0xF30Eea37313Cf59D67448346F8319826c1B131c6`
      );
    }
  };

  componentWillUnmount() {}

  render() {
    return (
      <div
        id="nft"
        className="nft-main"
        style={{
          // height: "0",
          background: "#092721",
          backgroundImage: "url(/assets/img/nft/img_bg_nft6.png)",
          backgroundSize: "auto",
        }}
      >
        <div
          className="airdrop-content media-partners technique"
          data-scrollview="true"
          style={{ background: "transparent" }}
        >
          <div
            className="whitelist-background"
            style={{
              backgroundImage: "url(/assets/img/nft/img_bg_nft5.png)",
              // height: "3500px",
            }}
          >
            <div className="bottom-video" style={{ marginTop: "80px" }}>
              <div
                className="video-content"
                style={{
                  width:
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                      ? "60%"
                      : "75%",
                }}
              >
                <video controls muted>
                  <source
                    src="/assets/video/6208561207041853863.mp4"
                    type="video/mp4"
                  />
                </video>
                <img
                  className="video-bg"
                  src="/assets/img/ic_video_container1.png"
                  srcSet="/assets/img/ic_video_container1.png 1x"
                  // width="300px"
                />
              </div>
            </div>
            <div className="content-title2" style={{ color: "#EB8F23" }}>
              {strings.hero_nft_card4}
            </div>
            <div
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
              style={{ color: "#ff6607" }}
            >
              {strings.hero_nft_card_title1}
            </div>
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc1 }}
            />
            <div
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
              style={{ color: "#ff6607" }}
            >
              {strings.hero_nft_card_title2}
            </div>
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc2 }}
            />
            <div
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
              style={{ color: "#ff6607" }}
            >
              {strings.hero_nft_card_title3}
            </div>
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc3 }}
            />
            <div
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
              style={{ color: "#ff6607" }}
            >
              {strings.hero_nft_card_title4}
            </div>
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc4 }}
            />
            <div
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
              style={{ color: "#ff6607" }}
            >
              {strings.nft_1}
            </div>
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_2 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_3 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_4 }}
            />
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_5 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_6 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_7 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_8 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_9 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_10 }}
            />
            <div
              className="content-desc m-t-15"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_11 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_12 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_13 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_14 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_15 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_16 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_17 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_18 }}
            />
            <div
              className="content-desc"
              style={
                this.globalState.getLang() == "ar"
                  ? { textAlign: "right" }
                  : { textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.nft_19 }}
            />
            {/* <img src="/assets/img/nft/img_ellipse.png" /> */}
          </div>
          <div
            style={{
              backgroundImage: "url(/assets/img/nft/img_bg_nft7.png)",
            }}
          >
            <div
              name="buy_nft"
              className={
                "section-title " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-65"
                  : "m-t-30")
              }
            >
              {strings.hero_nft_card_title5}
            </div>
            <div
              className={
                "content-desc " +
                (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                  ? "m-t-30"
                  : "m-t-30")
              }
              style={{ textAlign: "center" }}
            >
              {/* First WhiteList Until 2022.09.06 ( Sold out ) */}
              {/*( Remain : {this.state.remain_first} / 1500 )*/}
            </div>
            {/* <div className={"section-title "}>
            <MultiColorProgressBar readings={this.state.readings} />
          </div> */}
            {/* <div
            className={
              "content-desc " +
              (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                ? "m-t-30"
                : "m-t-30")
            }
            style={{ textAlign: "center" }}
          >
            2nd whitelist until 2022.09.27 ( Remain : {this.state.remain_first2}{" "}
            / 3000 )
          </div> */}
            {/* <div className={"section-title "}>
            <MultiColorProgressBar readings={this.state.readings2} />
          </div> */}
            {/* <div
            className={
              "section-title " +
              (this.uiState.display_mode === Const.DISPLAY.DESKTOP
                ? "m-t-65"
                : "m-t-30")
            }
          >
            {strings.hero_nft_card_title11}
          </div> */}
            {/* <div
            className="content-desc m-t-15"
            style={
              this.globalState.getLang() == "ar"
                ? { display: "block", textAlign: "right" }
                : { display: "block", textAlign: "left" }
            }
            dangerouslySetInnerHTML={{
              __html: strings.hero_nft_card_desc11_desc,
            }}
          ></div> */}
            {/* <div style={{ height: 500 }} className={"content-desc m-t-15 "}>
            <DataGrid
              disableColumnMenu
              style={{ background: "#fff" }}
              rows={this.state.griddata}
              columns={columns}
              pageSize={100}
            />
          </div> */}
            {/*<div className="section-title" > [Free airdrop of NFT hero card]</div>*/}
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: 50,
                zIndex: 99999,
              }}
              onClick={() => {
                this.state.showMore11 = !this.state.showMore11;
                this.clickWhitelistMore1(this.state.showMore11);
              }}
            >
              <img
                src={
                  this.state.showMore11 == false
                    ? "/assets/img/nft/img_nft_bcl.png"
                    : "/assets/img/nft/img_nft_bcl_on.png"
                }
                style={
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? { width: "40vw", zIndex: 99999 }
                    : { width: "80vw", zIndex: 99999 }
                }
              />
            </div>
            <div
              className="content-desc m-t-15"
              id="a1"
              style={
                this.globalState.getLang() == "ar"
                  ? { display: "block", textAlign: "right" }
                  : { display: "block", textAlign: "center" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc5 }}
            />
            <div
              className="content-desc m-t-15"
              id="a2"
              style={
                this.globalState.getLang() == "ar"
                  ? {
                      textAlign: "right",
                      color: "#FFD500",
                      fontSize: "1.8vw",
                      display: "block",
                    }
                  : { textAlign: "center", color: "#FFD500", display: "block" }
              }
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc51 }}
            />
            <div
              className="content-desc m-t-15"
              style={{ textAlign: "center" }}
              id="a3"
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc6 }}
              onClick={() => this.onCopy()}
            ></div>
            <div className="check-pre-sale" style={{ marginTop: 15 }} id="a4">
              <input
                type="text"
                placeholder={strings.hero_nft_card_desc7}
                onClick={() => this.checkBEP2()}
                readOnly
              />
              <button onClick={() => this.checkBEP2()}>{strings.go}</button>
            </div>
            <div
              className="content-desc m-t-15"
              style={{ textAlign: "center" }}
              id="a5"
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc8 }}
            ></div>
            <div style={{ width: "100vw", textAlign: "center" }}>
              <div
                id="a6"
                style={
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? {
                        marginTop: 15,
                        height: "80vh",
                        backgroundImage:
                          "url(/assets/img/nft/img_nft_popup_back.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }
                    : {
                        marginTop: 15,
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }
                }
              >
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center", padding: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc9,
                  }}
                ></div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center", display: "inline-flex" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc10_1,
                  }}
                ></div>
                <div style={{ width: "100%" }}>
                  <img
                    src="/assets/img/nft/ic_check_email.png"
                    onClick={() => {
                      this.setState({ showGuideModal: true });
                    }}
                  />
                </div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc10_2,
                  }}
                ></div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center", padding: 0 }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "5vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      E-mail <br />
                      for BCL app
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "5vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="somalin@gmail.com"
                        value={this.state.inputNftEmail}
                        onChange={(e) => {
                          this.setState({ inputNftEmail: e.target.value });
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center", padding: 0 }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "5vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      BEP20 BCL <br />
                      address
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "5vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="0xFbD086Ae57964A3777C5fB738f2b428eac89Cc35"
                        value={this.state.inputBepAddress}
                        onChange={(e) => {
                          this.setState({ inputBepAddress: e.target.value });
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center", padding: 0 }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "5vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      BEP20 BCL <br />
                      amount
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "5vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="5000"
                        value={this.state.inputBepAmount}
                        onChange={(e) => {
                          if (!isNaN(e.target.value.trim())) {
                            this.setState({ inputBepAmount: e.target.value });
                          }
                        }}
                        style={
                          this.state.inputBepAmount.length == 0
                            ? {
                                width: "100%",
                                height: "100%",
                                textAlign: "left",
                                // }} style={ ( this.state.inputBepAmount.length == 0 ?  { width: "100%", height: "100%",textAlign: "right", backgroundImage: 'url(/assets/img/nft/nft_amount_bg.png)',
                                backgroundPosition: "enter",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                              }
                            : {
                                width: "100%",
                                height: "100%",
                                textAlign: "left",
                              }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center", padding: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc10_3,
                  }}
                ></div>
                {/*<div className="content-desc m-t-15" style={{textAlign: "center", padding: 0 }}>*/}
                {/*    <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {*/}
                {/*        width: "33vw",*/}
                {/*        display: "-webkit-inline-box",*/}
                {/*        flexWrap: "wrap"*/}
                {/*    } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>*/}
                {/*        <div style={{*/}
                {/*            width: "29%",*/}
                {/*            marginRight: 5,*/}
                {/*            height: "5vh",*/}
                {/*            padding: 5,*/}
                {/*            background: "#FFFFFF",*/}
                {/*            color: '#000000',*/}
                {/*            fontSize: "1.5vh"*/}
                {/*        }}>Polygon<br/>*/}
                {/*            address*/}
                {/*        </div>*/}
                {/*        /!*<div style={{*!/*/}
                {/*        /!*    width: "69%",*!/*/}
                {/*        /!*    background: "#FFFFFF",*!/*/}
                {/*        /!*    height: "5vh",*!/*/}
                {/*        /!*    padding: 5,*!/*/}
                {/*        /!*    fontSize: "1.5vh"*!/*/}
                {/*        /!*}}>*!/*/}
                {/*        /!*    <input type="text" placeholder="0xFbD086Ae57964A3777C5fB738f2b428eac89Cc35"*!/*/}
                {/*        /!*           value={this.state.inputPolygonAddress} onChange={(e) => {*!/*/}
                {/*        /!*        this.setState({inputPolygonAddress: e.target.value});*!/*/}
                {/*        /!*    }} style={{width: "100%", height: "100%", textAlign: "left"}}/>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                      ? {
                          width: "100%",
                          display: "table",
                          marginTop: "1vh",
                        }
                      : { width: "100%", display: "table", marginTop: "3vh" }
                  }
                >
                  <div
                    style={{
                      width: "15vw",
                      height: "5vh",
                      backgroundImage:
                        "url(/assets/img/nft/img_round_button.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      display: "table-cell",
                      color: "white",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      this.onConfirmTrasaction();
                    }}
                  >
                    {strings.levelup_popup_confirm}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "15px",
                }}
                onClick={() => {
                  this.state.showMore22 = !this.state.showMore22;
                  // console.log(this.state.showMore11);
                  this.clickWhitelistMore2(this.state.showMore22);
                }}
              >
                <img
                  id="b2img"
                  src={
                    this.state.showMore22 === false
                      ? "/assets/img/nft/img_nft_poly.png"
                      : "/assets/img/nft/img_nft_poly_on.png"
                  }
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                      ? { width: "40vw" }
                      : { width: "80vw" }
                  }
                />
              </div>
              <div
                className="content-desc m-t-15"
                id="b1"
                style={
                  this.globalState.getLang() == "ar"
                    ? {
                        textAlign: "right",
                      }
                    : { textAlign: "center" }
                }
                dangerouslySetInnerHTML={{
                  __html: strings.hero_nft_card_desc12,
                }}
              />
              <div
                className="content-desc m-t-15"
                id="b2"
                style={
                  this.globalState.getLang() == "ar"
                    ? {
                        textAlign: "right",
                        color: "#FFD500",
                      }
                    : { textAlign: "center", color: "#FFD500" }
                }
                dangerouslySetInnerHTML={{
                  __html: strings.hero_nft_card_desc52,
                }}
              />
              <div
                className="content-desc m-t-15"
                style={{ textAlign: "center" }}
                id="b3"
                dangerouslySetInnerHTML={{
                  __html: strings.hero_nft_card_desc61,
                }}
                onClick={() => this.onCopy()}
              ></div>
              {/*<div className="content-desc m-t-15" style={{textAlign: "center"}}*/}
              {/*     dangerouslySetInnerHTML={{__html: strings.hero_nft_card_desc11}}>*/}
              {/*</div>*/}
              <div
                id="b4"
                className="content-desc m-t-15"
                style={
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? { textAlign: "center", display: "flex" }
                    : { textAlign: "center" }
                }
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    className="check-pre-sale"
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            border: "#84D475 2px solid",
                            marginTop: 15,
                            width: "30vw",
                          }
                        : { border: "#84D475 2px solid", marginTop: 15 }
                    }
                  >
                    <input
                      type="text"
                      style={{ color: "#84d475" }}
                      placeholder={strings.hero_nft_card_desc71}
                      onClick={() => this.checkEtheScan()}
                      readOnly
                    />
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "#84D475 2px solid",
                      }}
                      onClick={() => this.checkEtheScan()}
                    >
                      {strings.go}
                    </button>
                  </div>
                  <div
                    className="check-pre-sale"
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            border: "#d4ce75 2px solid",
                            marginTop: 15,
                            width: "30vw",
                          }
                        : { border: "#d4ce75 2px solid", marginTop: 15 }
                    }
                  >
                    <input
                      type="text"
                      style={{ color: "#D4CE75" }}
                      placeholder={strings.hero_nft_card_desc72}
                      onClick={() => this.checkPolyScan()}
                      readOnly
                    />
                    <button
                      style={{
                        backgroundColor: "transparent",
                        border: "#d4ce75 2px solid",
                      }}
                      onClick={() => this.checkPolyScan()}
                    >
                      {strings.go}
                    </button>
                  </div>
                </div>
              </div>
              <div
                id="b5"
                className="content-desc m-t-15"
                style={{ textAlign: "center" }}
                dangerouslySetInnerHTML={{
                  __html: strings.hero_nft_card_desc81,
                }}
              ></div>

              <div
                id="b6"
                style={
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? {
                        marginTop: 15,
                        height: "80vh",
                        backgroundImage:
                          "url(/assets/img/nft/img_nft_popup_back.png)",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }
                    : {
                        marginTop: 15,
                        display: "inline-block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }
                }
              >
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc91,
                  }}
                ></div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center", display: "inline-flex" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc11_1,
                  }}
                ></div>
                <div style={{ width: "100%" }}>
                  <img
                    src="/assets/img/nft/ic_check_email.png"
                    onClick={() => {
                      this.setState({ showGuideModal: true });
                    }}
                  />
                </div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc11_2,
                  }}
                ></div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center" }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "5vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      E-mail <br />
                      for BCL app
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "5vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Somalin@gmail.com"
                        value={this.state.inputNftEmail2}
                        onChange={(e) => {
                          this.setState({ inputNftEmail2: e.target.value });
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center" }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "8vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      {" "}
                      <input
                        type="radio"
                        name="address_type"
                        value="1"
                        onChange={(e) => {
                          console.log(e.target.value);
                          this.setState({ inputBepType2: e.target.value });
                        }}
                        checked={this.state.inputBepType2 == 1}
                      />
                      &nbsp; ETH &nbsp; <br />
                      <input
                        type="radio"
                        name="address_type"
                        value="2"
                        onChange={(e) => {
                          console.log(e.target.value);
                          this.setState({ inputBepType2: e.target.value });
                        }}
                        checked={this.state.inputBepType2 == 2}
                      />
                      MATIC <br />
                      address
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "8vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="0xFbD086Ae57964A3777C5fB738f2b428eac89Cc35"
                        value={this.state.inputBepAddress2}
                        onChange={(e) => {
                          this.setState({ inputBepAddress2: e.target.value });
                        }}
                        style={{
                          width: "100%",
                          height: "100%",
                          textAlign: "left",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-5"
                  style={{ textAlign: "center" }}
                >
                  <div
                    style={
                      this.uiState.display_mode === Const.DISPLAY.DESKTOP
                        ? {
                            width: "33vw",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                        : {
                            width: "100%",
                            display: "-webkit-inline-box",
                            flexWrap: "wrap",
                          }
                    }
                  >
                    <div
                      style={{
                        width: "29%",
                        marginRight: 5,
                        height: "5vh",
                        padding: 5,
                        background: "#FFFFFF",
                        color: "#000000",
                        fontSize: "1.5vh",
                      }}
                    >
                      {this.state.inputBepType2 == 1 ? "ETH" : "MATIC"} <br />
                      amount
                    </div>
                    <div
                      style={{
                        width: "69%",
                        background: "#FFFFFF",
                        height: "5vh",
                        padding: 5,
                        fontSize: "1.5vh",
                      }}
                    >
                      <input
                        type="number"
                        value={this.state.inputBepAmount2}
                        onChange={(e) => {
                          if (!isNaN(e.target.value.trim())) {
                            this.setState({ inputBepAmount2: e.target.value });
                          }
                        }}
                        style={
                          this.state.inputBepAmount.length == 0
                            ? {
                                width: "100%",
                                height: "100%",
                                textAlign: "left",
                                // }} style={ ( this.state.inputBepAmount.length == 0 ?  { width: "100%", height: "100%",textAlign: "right", backgroundImage: 'url(/assets/img/nft/nft_amount_bg.png)',
                                backgroundPosition: "enter",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                              }
                            : {
                                width: "100%",
                                height: "100%",
                                textAlign: "left",
                              }
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="content-desc m-t-15"
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: strings.hero_nft_card_desc10_3,
                  }}
                ></div>
                {/*<div className="content-desc m-t-15" style={{textAlign: "center",}}>*/}
                {/*    <div style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {*/}
                {/*        width: "33vw",*/}
                {/*        display: "-webkit-inline-box",*/}
                {/*        flexWrap: "wrap"*/}
                {/*    } : {width: "100%", display: "-webkit-inline-box", flexWrap: "wrap"})}>*/}
                {/*        <div style={{*/}
                {/*            width: "29%",*/}
                {/*            marginRight: 5,*/}
                {/*            height: "5vh",*/}
                {/*            padding: 5,*/}
                {/*            background: "#FFFFFF",*/}
                {/*            color: '#000000',*/}
                {/*            fontSize: "1.5vh"*/}
                {/*        }}>Polygon<br/>*/}
                {/*            address*/}
                {/*        </div>*/}
                {/*        /!*<div style={{*!/*/}
                {/*        /!*    width: "69%",*!/*/}
                {/*        /!*    background: "#FFFFFF",*!/*/}
                {/*        /!*    height: "5vh",*!/*/}
                {/*        /!*    padding: 5,*!/*/}
                {/*        /!*    fontSize: "1.5vh"*!/*/}
                {/*        /!*}}>*!/*/}
                {/*        /!*    <input type="text" placeholder="0xFbD086Ae57964A3777C5fB738f2b428eac89Cc35"*!/*/}
                {/*        /!*           value={this.state.inputPolygonAddress2} onChange={(e) => {*!/*/}
                {/*        /!*        this.setState({inputPolygonAddress2: e.target.value});*!/*/}
                {/*        /!*    }} style={{width: "100%", height: "100%", textAlign: "left"}}/>*!/*/}
                {/*        /!*</div>*!/*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div
                  style={
                    this.uiState.display_mode === Const.DISPLAY.DESKTOP
                      ? {
                          width: "100%",
                          display: "table",
                          marginTop: "1vh",
                        }
                      : { width: "100%", display: "table", marginTop: "3vh" }
                  }
                >
                  <div
                    style={{
                      width: "15vw",
                      height: "5vh",
                      backgroundImage:
                        "url(/assets/img/nft/img_round_button.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      display: "table-cell",
                      color: "white",
                      verticalAlign: "middle",
                    }}
                    onClick={() => {
                      this.onConfirmTrasaction2();
                    }}
                  >
                    {strings.levelup_popup_confirm}
                  </div>
                </div>
              </div>
            </div>
            {/*<div style={{width: "100%", marginTop: "15px", textAlign: "center"}}   onClick={()=> {*/}
            {/*    this.state.showMore3 = !this.state.showMore3;*/}
            {/*    // console.log(this.state.showMore1);*/}
            {/*    this.clickMore3(this.state.showMore3);*/}
            {/*}}>*/}
            {/*    <img id="c1img" src="/assets/img/nft/img_nft_whitelist.png"*/}
            {/*         style={(this.uiState.display_mode === Const.DISPLAY.DESKTOP ? {width: "40vw"} : {width: "80vw"})}/>*/}
            {/*</div>*/}
            <div
              style={{ width: "100%", textAlign: "center", marginTop: "15px" }}
              onClick={() => {
                this.state.showMore33 = !this.state.showMore33;
                // console.log(this.state.showMore11);
                this.clickWhitelistMore3(this.state.showMore33);
              }}
            >
              <img
                id="b2img"
                src={
                  this.state.showMore33 === false
                    ? "/assets/img/nft/img_nft_whitelist.png"
                    : "/assets/img/nft/img_nft_whitelist_on.png"
                }
                style={
                  this.uiState.display_mode === Const.DISPLAY.DESKTOP
                    ? { width: "40vw" }
                    : { width: "80vw" }
                }
              />
            </div>
            <div
              className="content-desc m-t-15"
              style={{ textAlign: "center" }}
              id="c1"
              dangerouslySetInnerHTML={{ __html: strings.hero_nft_card_desc82 }}
            ></div>
            <div
              className="m-t-15"
              style={{ width: "100%", textAlign: "center", zIndex: 10 }}
              id="whitegroup"
            >
              <a
                href="https://docs.google.com/spreadsheets/d/10l-T8VLnZ7JfpacEFkVfxFotZ9smHGHjb-VviLW68sQ/edit#gid=63590156"
                target="_blank"
              >
                <img src="/assets/img/nft/img_nft_whitelist_btn.png" />
              </a>
            </div>
            <div
              className="m-t-15"
              style={{ width: "100%", textAlign: "center" }}
            >
              <div
                className="content-desc m-t-15"
                style={{
                  display: "inline-table",
                  paddingTop: 30,
                  height: "10vh",
                  textAlign: "center",
                  // border: "1px yellow solid",
                }}
                id="c3"
                dangerouslySetInnerHTML={{ __html: strings.nft_event_qa }}
              ></div>
            </div>
            <div
              className="m-t-15"
              style={{ width: "100%", textAlign: "center" }}
            >
              {/*<button style={{ backgroundColor: "#D07674", borderRadius: 25, height: 40, color: "white", marginLeft: 50, marginRight: 50,marginTop: 10, fontSize: 16, width: '60%' }}*/}
              {/*        onClick={() => {*/}
              {/*            this.setState({ showNftModal: false })*/}
              {/*            setTimeout(() => {*/}
              {/*                // this.props.history.push("/nft_event")*/}
              {/*                window.open('https://max-form.vercel.app/gallery', '_blank');*/}
              {/*                // window.location.href = 'https://max-form.vercel.app/gallery'*/}
              {/*            }, 500)*/}

              {/*        }}>*/}
              {/*    Go NFT Minting Site*/}
              {/*</button>*/}
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.showGuideModal}
          toggle={this.toggleModal}
          style={{ maxWidth: 480, margin: "100px auto", borderRadius: 20 }}
        >
          <ModalBody
            style={{
              padding: 0,
              borderRadius: 20,
              background: "#272831",
              border: 0,
            }}
          >
            <div className="column text-center">
              <div
                style={{
                  marginRight: 5,
                  height: 50,
                  paddingTop: 10,
                  position: "absolute",
                  right: 10,
                  top: 5,
                }}
              >
                <img
                  src="/assets/img/ic_close_popup.png"
                  srcSet="/assets/img/ic_close_popup.png 1x"
                  onClick={() => {
                    this.setState({ showGuideModal: false });
                  }}
                />
              </div>
              <div
                className="desc"
                style={{
                  marginTop: 30,
                  fontSize: 20,
                  color: "white",
                  marginLeft: 18,
                  marginRight: 18,
                }}
              >
                {strings.levelup_title4}
              </div>
              {/*<div className="desc"  style={this.globalState.getLang() == "ar" ?  { marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18, color: "#FFFFFF",textAlign: "right" } :{ marginTop: 30, fontSize: 13, display: "flex", marginLeft:18, marginRight:18, color: "#FFFFFF",textAlign: "left" } }   >*/}
              {/*<img src="/assets/img/img_popup_guid2.png" srcSet="/assets/img/img_popup_guid2.png 1x"    />*/}
              <div
                className="desc"
                style={{
                  marginTop: 30,
                  display: "flex",
                  marginLeft: 18,
                  marginRight: 18,
                  color: "#FFFFFF",
                  textAlign: "left",
                  fontSize: "1.8vw",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: strings.levelup_title5 }}
                />
              </div>

              <div
                className="desc"
                style={{
                  marginTop: 15,
                  fontSize: 13,
                  display: "block",
                  marginLeft: 18,
                  marginRight: 18,
                  marginBottom: 30,
                }}
              >
                <img
                  src="/assets/img/img_popup_guide3.png"
                  srcSet="/assets/img/img_popup_guide3.png 1x"
                />
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default withRouter(inject("rootStore")(observer(Whitelist)));
