import { UiState } from "./ui-state";
import { decorate, observable } from "mobx";
import { GlobalState } from "./global-state";

class RootStore {

    user = {
        token: ''
    };

    constructor() {
        this.uiState = new UiState(this);
        this.globalState = new GlobalState(this);
        this.loadStore();
    }

    login(user) {
        this.user = user;
        this.saveStore();
    }

    logout() {
        this.user = { token: '' };
        this.saveStore();
    }

    isLogin() {
        return this.user.token !== '';
    }

    getToken() {
        return this.user.token;
    }

    loadStore() {
        try {
            let store = JSON.parse(localStorage.getItem('store'));
            if (!store) {
                return;
            }

            this.user = store.user;
        } catch (e) {
            console.log(e);
        }
    }

    saveStore() {
        localStorage.setItem('store', JSON.stringify(this));
    }
}

decorate(RootStore, {
    user: observable
});

export default RootStore;