import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {withRouter} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import axios from "axios";
import {BASE_URL} from "../../config/const";
import strings from '../../lang/strings';
import Swal from 'sweetalert2'
import '../../styles/pages/main/kyc-v3.scss';

//월렛
// import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
// import { Web3Modal,Web3Button } from '@web3modal/react'
// import { configureChains, createClient, WagmiConfig } from 'wagmi'
// import { mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom } from 'wagmi/chains'


//트론
import {useWallet, WalletProvider} from '@tronweb3/tronwallet-adapter-react-hooks';
import {WalletDisconnectedError, WalletError, WalletNotFoundError} from '@tronweb3/tronwallet-abstract-adapter';
import {WalletConnectAdapter} from '@tronweb3/tronwallet-adapter-walletconnect';
import TronWeb from 'tronweb';
import {WalletConnectWallet, WalletConnectChainID} from '@tronweb3/walletconnect-tron';


//월렛
// const chains = [mainnet, polygon, avalanche, arbitrum, bsc, optimism, gnosis, fantom];
// const projectId = 'af08c6e0d4815705e1e4cdf18d577e8e';
// const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiClient = createClient({
//     autoConnect: true,
//     connectors: w3mConnectors({ projectId, version: 1, chains }),
//     provider
// });
// const ethereumClient = new EthereumClient(wagmiClient, chains);

const tab = (j) => {
    const d = document.getElementById('tab');
    const c = d.children;
    const l = document.getElementsByClassName('tab-list');
    if (c.length != l.length) return;
    for (var i = 0; i < c.length; i++) {
        c[i].classList.remove('select');
        l[i].style.display = 'none';
    }
    c[j].classList.add('select');
    l[j].style.display = 'block';
}

const textCopy = (v) => {
    navigator.clipboard.writeText(v)
        .then(() => {
            alert(strings.kyc3_5);
        })
}

const check = (j) => {
    const d = document.getElementsByClassName('checkbox');
    for (var i = 0; i < d.length; i++) d[i].classList.remove('check');
    d[j].classList.add('check');
}

const popup = (t = null) => {
    const b = document.getElementById("kycV3");
    if (b.children[b.children.length - 1].id == 'popup') {
        b.children[b.children.length - 1].remove();
    } else {
        const c = document.createElement('div');
        c.classList.add('popup');
        c.id = 'popup';
        const bk = document.createElement('div');
        bk.classList.add('popupBack');
        bk.addEventListener('click', () => popup());
        c.appendChild(bk);
        const ac = document.createElement('div');
        ac.classList.add('popupContent');
        if (t == 1) {
            var i = document.createElement('img');
            i.style.height = '80vh';
            i.src = '/assets/img/example.png';
            ac.appendChild(i);
        }
        c.appendChild(ac);
        b.appendChild(c);
    }
}

const contectForm = (t) => {
    if (t == 1) {
        sessionStorage.setItem('kyc-contect', 0);
        var d = contectForm();

    } else if (t == 2) {
        sessionStorage.setItem('kyc-contect', 1);
        var d = contectForm();
    }
    contectClick();
}

const contectClick = () => {
    const v = document.getElementById('contectInput');
    const u = (sessionStorage.getItem('kyc-contect'))
        ? 'https://pay.bitcoinlegend.org/admin/api/checkPayedKYC/2?email='
        : 'https://pay.bitcoinlegend.org/admin/api/checkPayedKYC/1?email=';
    const ur = (sessionStorage.getItem('kyc-contect'))
        ? 'https://forms.gle/nF3pcQ7naj9uKiKm7'
        : 'https://forms.gle/augtSKNc2MoRhFxj8';
    Swal.fire({
        title: 'Input BCL App EMAIL',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Look up',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
            return axios.get(u + login)
                .then(response => {
                    if (response.data.ok == -1) {
                        throw new Error(strings.kyc_result3 + ' (USDT Paid users only)')
                    }
                    return response
                })
                .catch(error => {
                    Swal.showValidationMessage(
                        `Request failed: ${error}`
                    )
                })
        },
        allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            const anchor = document.createElement('a');
            anchor.href = ur;
            anchor.target = "_blank";
            anchor.click();
        }
    })
}

const tronConnect = async () => {
    const connent = JSON.parse(window.localStorage.getItem('wc@2:client:0.3//session'));
    const tronaccount = (connent) ? connent[0]['namespaces']['tron']['accounts'][0] : false;
    if (!connent && !tronaccount) {
        const wallet = new WalletConnectWallet({
            network: WalletConnectChainID.Mainnet,
            options: {
                relayUrl: 'wss://relay.walletconnect.com',
                projectId: 'af08c6e0d4815705e1e4cdf18d577e8e',
                metadata: {
                    name: 'bcl-exchange',
                    description: 'BitcoinLegend KYC Tron Payment',
                    url: 'https://bitcoinlegend.org/',
                    icons: ['https://bitcoinlegend.org/assets/img/ic_logo.png'],
                },
                logging: true,
            }
        });

        //const adapter = new WalletConnectAdapter({
        //    network: 'Mainnet',
        //    options: {
        //        relayUrl: '',
        //        projectId: 'af08c6e0d4815705e1e4cdf18d577e8e',
        //        metadata: {
        //            name: 'bcl-exchange',
        //            description: 'BitcoinLegend KYC Tron Payment',
        //            url: 'https://bitcoinlegend.org/',
        //            icons: ['https://bitcoinlegend.org/assets/img/ic_logo.png'],
        //        },
        //    },
        //});
        // connect
        await wallet.connect();
        //await adapter.connect();
    } else {
        if (!window.confirm("트론에 이미 연결되었습니다. 연결 해제하시겠습니까?")) {
            document.querySelector('#walletBtn').innerText = tronaccount;
        } else {
            window.localStorage.removeItem('wc@2:client:0.3//session');
        }
    }


}

const KycV3 = () => {

    const submit = (j) => {
        if (j == 0) {
            var v = document.querySelector('.checkbox.check');
            if (!v) return alert(strings.kyc3_6);
            sessionStorage.removeItem('kyc-0');
            sessionStorage.setItem('kyc-0', v.dataset.value);
            createDom(1);
        } else if (j == 1) {
            //월렛
            // const walletAccount = JSON.parse(window.localStorage.getItem("wagmi.store"));
            // const walletConnect = window.localStorage.getItem("wagmi.connected");
            // if(!walletAccount || !walletConnect) return walletAccount['state']['data'] && alert(strings.kyc3_7);
            // if(!walletAccount['state']['data']['account']) return alert(strings.kyc3_7);

            //트론
            //const connent = JSON.parse(window.localStorage.getItem('wc@2:client:0.3//session'));
            //const tronaccount = (connent) ? connent[0]['namespaces']['tron']['accounts'][0] : false;
            //if (!tronaccount) return alert(strings.kyc3_7);
            //let tronaccountVal = tronaccount.split(':');
            //tronaccountVal = tronaccountVal[2];
            
            const connent = document.querySelector('#walletInput').value;
            
            sessionStorage.removeItem('kyc-1');
            sessionStorage.setItem('kyc-1', connent);
            createDom(2);
        } else if (j == 2) {
            var v = document.querySelector("#e-mail").value;
            var r = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
            if (!v) return alert(strings.kyc3_8);
            if (!r.test(v)) return alert(strings.kyc3_8);
            sessionStorage.removeItem('kyc-2');
            sessionStorage.setItem('kyc-2', v);
            createDom(3);
        } else if (j == 3) {
            window.location.reload();
        }
    }
    const createDom = (j = 0) => {
        sessionStorage.setItem('kyc-order', j);

        const id = document.querySelector("#indicator");
        const ic = id.children;
        for (var i = 0; i < ic.length; i++) ic[i].classList.remove('action');
        (!isNaN(j)) ? ic[j].classList.add('action') : ic[3].classList.add('action');

        const d = document.querySelector('#page-dom');
        if (d.children[0]) d.removeChild(d.children[0]);
        const c = document.createElement("div");
        c.appendChild(createPage[j]());
        d.appendChild(c);
    }
    const createPage = {
        next: (mc, t, j = null) => {
            var c = document.createElement("div");
            c.classList.add("btnbox");
            var b = document.createElement("button");
            b.classList.add("fst2", "color-white");
            b.innerText = t;
            j != null && b.addEventListener('click', () => submit(j));
            c.appendChild(b);
            mc.appendChild(c);
        },
        0: () => {
            const ads = 'TSpR19EXbEegd9YifnbWQtNXERXwMkmSnG';
            const mc = document.createElement("div");
            mc.id = 'page-0';

            var c = document.createElement("div");
            c.classList.add('fst3', 'fs-bold');
            c.innerText = strings.kyc3_9;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("copybox", "m-t-15");
            c.innerText = " USDT / TRX Address :\n" + ads;
            c.addEventListener('click', () => textCopy(ads));
            var i = document.createElement("img");
            i.src = "/assets/img/copy_1.png";
            c.appendChild(i);
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add('chk_box', "m-t-15");
            var ch = document.createElement("div");
            var chc = document.createElement("div");
            chc.classList.add('checkbox');
            chc.dataset.value = "6 USDT";
            chc.addEventListener('click', () => check(0));
            var cht = document.createElement("div");
            cht.classList.add('checkboxText');
            cht.innerText = "6 USDT(TRC20)";
            ch.appendChild(chc);
            ch.appendChild(cht);
            c.appendChild(ch);
            var ch = document.createElement("div");
            var chc = document.createElement("div");
            chc.classList.add('checkbox');
            chc.dataset.value = "100 TRX";
            chc.addEventListener('click', () => check(1));
            var cht = document.createElement("div");
            cht.classList.add('checkboxText');
            cht.innerText = "100 TRX(TRC20)";
            ch.appendChild(chc);
            ch.appendChild(cht);
            c.appendChild(ch);
            mc.appendChild(c);

            createPage['next'](mc, strings.kyc3_10, 0);

            return mc;
        },
        1: () => {
            const mc = document.createElement("div");
            mc.id = 'page-1';

            var c = document.createElement("div");
            c.classList.add('fst3', 'fs-bold');
            c.innerText = strings.kyc3_11;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("m-t-15");
            c.innerText = strings.kyc3_12;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("wallet", "m-t-40");
            
            ReactDOM.render(
                <div id='wallet' className='input'>
                    <input id='walletInput' type='text' />
                </div>
            , c);

            //트론주소
            //ReactDOM.render(
            //    <div id='wallet' className='btnbox'>
            //        <button id='walletBtn' className='fst2 color-white' onClick={() => tronConnect()}>TronConnect
            //            Button
            //        </button>
            //    </div>
            //, c);

            //커넥트월렛
            //ReactDOM.render(
            //    <div id='wallet'>
            //        <WagmiConfig client={wagmiClient}>
            //            <Web3Button />
            //        </WagmiConfig>
            //        <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
            //    </div>
            //, c);
            mc.appendChild(c);

            createPage['next'](mc, strings.kyc3_10, 1);

            return mc;
        },
        2: () => {
            const mc = document.createElement("div");
            mc.id = 'page-2';

            var c = document.createElement("div");
            c.classList.add('fst3', 'fs-bold');
            c.innerText = strings.kyc3_13;
            var cc = document.createElement("div");
            cc.classList.add('icon');
            cc.innerText = "?";
            cc.addEventListener('click', () => popup(1));
            c.appendChild(cc);
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add('input');
            var i = document.createElement('input');
            i.id = 'e-mail';
            i.placeholder = 'abc@gmail.com';
            c.appendChild(i);
            mc.appendChild(c);

            createPage['next'](mc, strings.kyc3_10, 2);

            return mc;
        },
        3: () => {
            var kyc1 = sessionStorage.getItem('kyc-0');
            var kyc2 = sessionStorage.getItem('kyc-1');
            var kyc3 = sessionStorage.getItem('kyc-2');
            let params = new URLSearchParams();
            params.append('email', kyc3.trim());
            params.append('address', kyc2);
            params.append('phone', '');
            params.append('name', '');
            axios.post(`${BASE_URL}/user/checkAuthUSDTV3`, params)
                .then((res) => {
                    if (res.data.userKey == -1) {
                        alert(strings.kyc_result4);
                    } else if (res.data.userKey == -2) {
                        alert(strings.kyc_result3);
                    } else if (res.data.userKey == -3) {
                        alert(strings.kyc_result6);
                    } else if (res.data.userKey == -4) {
                        alert(strings.kyc_result9);
                    } else if (res.data.userKey == -5) {
                        alert(strings.kyc_result2);
                    } else if (res.data.userKey == -5) {
                        alert(strings.kyc_result26`1`);
                    } else if (res.data) {
                        if (res.data.is_kyc == 1 && res.data.pay_new_kyc == 0) {
                            alert(strings.kyc_result19);
                        } else if (res.data.is_kyc == 0 && res.data.pay_new_kyc == 0) {
                            alert(strings.kyc_result20);
                        }
                            //else if (res.data.be_kyc == 0) {
                            //  alert(strings.kyc_result5);
                            //} else if (res.data.is_kyc == -2) {
                            //  alert(strings.kyc_result7);
                        //}
                        else {
                            createDom('success');
                            return;
                        }
                    } else {
                        alert(strings.kyc_result2);
                    }
                    createDom('fail');
                })
            return createPage['loding']();
        },
        loding: () => {
            const mc = document.createElement("div");
            mc.id = 'page-3';

            var c = document.createElement("div");
            c.classList.add('fst1', 'fs-bold');
            c.innerText = 'Lodding';
            mc.appendChild(c);

            return mc;
        },
        success: () => {
            const mc = document.createElement("div");
            mc.id = 'page-3';

            var c = document.createElement("div");
            c.classList.add('fst3', 'fs-bold');
            c.innerText = strings.kyc3_14;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("m-t-15");
            c.innerText = strings.kyc3_15;
            mc.appendChild(c);

            var c = document.createElement("div");
            var i = document.createElement("img");
            i.src = "/assets/img/check.png";
            c.appendChild(i);
            mc.appendChild(c);

            return mc;
        },
        fail: () => {
            const mc = document.createElement("div");
            mc.id = 'page-3';

            var c = document.createElement("div");
            c.classList.add('fst3', 'fs-bold');
            c.innerText = strings.kyc3_16;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("m-t-15");
            c.innerText = strings.kyc3_17;
            mc.appendChild(c);

            var c = document.createElement("div");
            c.classList.add("button");
            createPage['next'](c, strings.kyc3_18, 3);
            createPage['next'](c, strings.kyc3_19);
            mc.appendChild(c);

            return mc;
        }
    }

    useEffect(() => {
        sessionStorage.removeItem('kyc-order');
        sessionStorage.removeItem('kyc-0');
        sessionStorage.removeItem('kyc-1');
        sessionStorage.removeItem('kyc-2');
        createDom();
    }, []);
    return (
        <div id='kycV3' className='nft-main'>
            <div className='top'>
                <div className='fst1 fs-bold color-turquoise'>
                    {strings.kyc_certification}
                </div>
                <div className='fst2 color-turquoise'>
                    {strings.kyc_title2}
                </div>
                <div className='color-white' dangerouslySetInnerHTML={{__html: strings.kyc_desc2}}>
                </div>
            </div>
            <div className='middle'>
                <div id='tab' className='tab fst2 fs-bold color-turquoise'>
                    <div onClick={() => tab(0)} className='select'>USDT / TRX</div>
                    <div onClick={() => tab(1)}>Contact</div>
                </div>
                <div className='tab-view'>
                    <div className='app'>
                        <div className='fst2 color-turquoise'>{strings.kyc3_1}</div>
                        <div className='m-t-40 m-b-40'>{strings.kyc3_2}</div>
                        <div className='btn'>
                            <button onClick={() => {
                                window.location.href = 'https://play.google.com/store/apps/details?id=com.bitlegendapp'
                            }}><img src='/assets/img/store_google_1.png' alt=''/></button>
                            <button onClick={() => {
                                window.location.href = 'https://apps.apple.com/us/app/bitcoinlegend/id1591021356'
                            }}><img src='/assets/img/store_apple_1.png' alt=''/></button>
                        </div>
                    </div>
                    <div className='content'>
                        <div>
                            ※ {strings.kyc_popup_desc1}
                            <br/>
                            {strings.kyc_popup_desc2}
                        </div>
                        <div>
                            {strings.kyc_popup_desc4}<br/>
                            {strings.kyc_popup_desc5}<br/>
                            {strings.kyc_popup_desc6}<br/>
                            {strings.kyc_popup_desc7}<br/>
                            <div dangerouslySetInnerHTML={{__html: strings.kyc_result16}}></div>
                        </div>
                    </div>
                    <div className='tab-list' id='tab-0'>
                        <div id='page-dom'></div>
                        <div className='indicator' id='indicator'>
                            <span className='action'></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className='tab-list' id='tab-1'>
                        <div>
                            <div className='fst2 color-turquoise'>{strings.kyc3_3}</div>
                            <div className='m-t-20 m-b-40'>{strings.kyc3_4}</div>
                            <div className='btn-box'>
                                <button onClick={() => {
                                    contectForm(1)
                                }} className='fst3 bc-orange color-white'>USDT/TRX Payment
                                </button>
                                <button onClick={() => {
                                    contectForm(2)
                                }} className='fst3 bc-indigo color-white'>In-app Payment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter(inject('rootStore')(observer(KycV3)));