import { decorate, computed, observable } from 'mobx';
import Const from '../config/const';

var $ = require('jquery');

function getWindowDimensions() {
    return {
        width: $(window).width(),
        height: $(window).height(),
    };
}

function getDPI() {
    var div = document.createElement("div");
    div.style.height = "1in";
    div.style.width = "1in";
    div.style.top = "-100%";
    div.style.left = "-100%";
    div.style.position = "absolute";

    document.body.appendChild(div);

    var result = div.offsetHeight;

    document.body.removeChild(div);

    return result;
}

function getDisplayMode() {
    if ($(window).width() < 768) {
        return Const.DISPLAY.MOBILE;
    } else if ($(window).width() < 1024) {
        return Const.DISPLAY.PAD
    } else {
        return Const.DISPLAY.DESKTOP;
    }
}

export class UiState {
    pendingRequestCount = 0;

    // .struct makes sure observer won't be signaled unless the
    // dimensions object changed in a deepEqual manner
    windowDimensions = getWindowDimensions();

    display_mode = getDisplayMode();
    
    dpi = getDPI();

    constructor() {
        $(window).resize(() => {
            this.windowDimensions = getWindowDimensions();
            this.display_mode = getDisplayMode();
        });
    }

    get appIsInSync() {
        return this.pendingRequestCount === 0
    }
}

decorate(UiState, {
    language: observable,
    pendingRequestCount: observable,
    appIsInSync: computed,
    windowDimensions: observable.struct,
    display_mode: observable,
});
