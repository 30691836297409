import $ from "jquery";
import Const from './const';
const moment = require('moment');

String.prototype.currencyFormat = function () {
    return this.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

Number.prototype.currencyFormat = function () {
    return this.toString().currencyFormat();
};

$.fn.dateFormat = function (date, format) {
    if (date == null || date === '') {
        return '';
    }

    if (typeof format === 'undefined') {
        format = 'YYYY-MM-DD';
    }

    return moment(date).format(format);
};

$.fn.currencyFormat = function (cost) {
    return cost.replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default class Utils {
    static dateFormat(value, format = 'YYYY-MM-DD HH:mm:ss') {
        return value && !value.isEmpty() ? moment(value).format(format) : '';
    }

    static currencyFormat(value) {
        if (!value) {
            return value;
        }

        return value.toString().replace(/,/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
    static makePath(category, page = null) {
        if (page) {
            return Const.PATH_PREFIX + '/' + category + '/' + page;
        } else {
            return Const.PATH_PREFIX + '/' + category;
        }
    }
}